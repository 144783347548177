module.exports = {
  "props": [
    {
      "name": "activatable",
      "type": "boolean",
      "default": "false",
      "source": "v-treeview",
      "description": {
        "en": "Allows user to mark a node as active by clicking on it",
        "ja": "ノードをクリックすることで、アクティブなノードとしてマークすることができます。",
        "zh-Hans": "允许用户通过单击节点将其标记为活动节点"
      }
    },
    {
      "name": "active",
      "type": "array",
      "default": [],
      "source": "v-treeview",
      "description": {
        "en": "Syncable prop that allows one to control which nodes are active. The array consists of the `item-key` of each active item.",
        "ja": "どのノードをアクティブにするかをコントロールできる同期的なプロパティです。アクティブな項目の `item-key` からなる配列です。",
        "zh-Hans": "可同步属性，允许您控制哪些节点处于活动状态。 该数组由每个活动项目的 `item-key` 组成。"
      }
    },
    {
      "name": "active-class",
      "type": "string",
      "default": "'v-treeview-node--active'",
      "source": "v-treeview",
      "description": {
        "en": "The class applied to the node when active",
        "ja": "ノードがアクティブな時に適用されるクラスです。",
        "zh-Hans": "活动时应用于节点的类"
      }
    },
    {
      "name": "color",
      "type": "string",
      "default": "'primary'",
      "source": "v-treeview",
      "description": {
        "en": "Sets the color of the active node",
        "ja": "アクティブなノードの色をセットします。",
        "zh-Hans": "设置活动节点的颜色"
      }
    },
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにダークテーマを適応します。詳しく知りたい場合は、Material Design documentationの<a href=\"https://material.io/design/color/dark-theme.html\">dark themes</a>を参照してください。",
        "zh-Hans": "将暗色主题变量应用到组件。你可以在 [dark themes](https://material.io/design/color/dark-theme.html) 的 Material Design 文档中找到更多有关信息。"
      }
    },
    {
      "name": "dense",
      "type": "boolean",
      "default": "false",
      "source": "v-treeview",
      "description": {
        "en": "Decreases the height of the items",
        "ja": "項目の高さを下げます。",
        "zh-Hans": "降低项目的高度"
      }
    },
    {
      "name": "disable-per-node",
      "type": "boolean",
      "default": "false",
      "source": "v-treeview",
      "description": {
        "en": "Prevents disabling children nodes",
        "ja": "Prevents disabling children nodes",
        "zh-Hans": "Prevents disabling children nodes"
      }
    },
    {
      "name": "disabled",
      "type": "boolean",
      "default": "false",
      "source": "v-treeview",
      "description": {
        "en": "Disables selection for all nodes",
        "ja": "Disables selection for all nodes",
        "zh-Hans": "Disables selection for all nodes"
      }
    },
    {
      "name": "expand-icon",
      "type": "string",
      "default": "'$subgroup'",
      "source": "v-treeview",
      "description": {
        "en": "Icon used to indicate that a node can be expanded",
        "ja": "ノードが拡張できることを示すアイコンです。",
        "zh-Hans": "用于指示可以扩展节点的图标"
      }
    },
    {
      "name": "filter",
      "type": "function",
      "default": "null",
      "source": "v-treeview",
      "description": {
        "en": "Custom item filtering function. By default it will use case-insensitive search in item's label.",
        "ja": "項目をフィルタリングするカスタム関数です。デフォルトでは項目のラベルで大文字と小文字の区別をしない検索を使用します。",
        "zh-Hans": "自定义项目过滤功能。 默认情况下，它将在项目的标签中使用不区分大小写的搜索。"
      }
    },
    {
      "name": "hoverable",
      "type": "boolean",
      "default": "false",
      "source": "v-treeview",
      "description": {
        "en": "Applies a hover class when mousing over nodes",
        "ja": "ノードの上にマウスオーバーする時、hover クラスを適用します。",
        "zh-Hans": "将鼠标悬停在节点上时应用悬停类"
      }
    },
    {
      "name": "indeterminate-icon",
      "type": "string",
      "default": "'$checkboxIndeterminate'",
      "source": "v-treeview",
      "description": {
        "en": "Icon used when node is in an indeterminate state. Only visible when `selectable` is `true`.",
        "ja": "ノードが選択されていない時に使われるアイコンです。`selectable` が `true` の場合のみ表示されます。",
        "zh-Hans": "节点处于不确定状态时使用的图标。 仅在 `selectable` 为 `true` 时可见。"
      }
    },
    {
      "name": "item-children",
      "type": "string",
      "default": "'children'",
      "source": "v-treeview",
      "description": {
        "en": "Property on supplied `items` that contains its children",
        "ja": "子要素を含む`items`のプロパティ",
        "zh-Hans": "提供的包含其子项的 `items` 上的属性"
      }
    },
    {
      "name": "item-disabled",
      "type": "string",
      "default": "'disabled'",
      "source": "v-treeview",
      "description": {
        "en": "Property on supplied `items` that contains the disabled state of the item",
        "ja": "無効化された状態の項目プロパティです。",
        "zh-Hans": "提供的 `items` 上的属性，其中包含该项目的禁用状态"
      }
    },
    {
      "name": "item-key",
      "type": "string",
      "default": "'id'",
      "source": "v-treeview",
      "description": {
        "en": "Property on supplied `items` used to keep track of node state. The value of this property has to be unique among all items.",
        "ja": "ノードの状態を追跡するために使用される指定された`items`のプロパティ。このプロパティの値はすべてのアイテムの中で一意でなければなりません。",
        "zh-Hans": "提供的 `items` 上的属性用于跟踪节点状态。 此属性的值在所有项目中必须唯一。"
      }
    },
    {
      "name": "item-text",
      "type": "string",
      "default": "'name'",
      "source": "v-treeview",
      "description": {
        "en": "Property on supplied `items` that contains its label text",
        "ja": "ラベル テキストを含む、指定された `items` のプロパティ",
        "zh-Hans": "提供的包含标签文本的 `items` 上的属性"
      }
    },
    {
      "name": "items",
      "type": "array",
      "default": [],
      "source": "v-treeview",
      "description": {
        "en": "An array of items used to build the treeview",
        "ja": "ツリービューの構築に使用される項目の配列",
        "zh-Hans": "用于构建树视图的项目数组"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    },
    {
      "name": "load-children",
      "type": "function",
      "default": "null",
      "source": "v-treeview",
      "description": {
        "en": "A function used when dynamically loading children. If this prop is set, then the supplied function will be run if expanding an item that has a `item-children` property that is an empty array. Supports returning a Promise.",
        "ja": "子要素を動的に読み込むときに使用される関数。 このプロパティが設定されている場合 空の配列である `item-children` プロパティを持つアイテムを展開すると、与えられた関数が実行されます。 Promiseをreturnすることをサポートします。",
        "zh-Hans": "动态加载子代时使用的功能。 如果设置了此道具，则在扩展具有 `item-children` 属性（为空数组）的项时，将运行提供的功能。 支持回调。"
      }
    },
    {
      "name": "loading-icon",
      "type": "string",
      "default": "'$loading'",
      "source": "v-treeview",
      "description": {
        "en": "Icon used when node is in a loading state",
        "ja": "ノード読み込み中に使用されるアイコン",
        "zh-Hans": "节点处于加载状态时使用的图标"
      }
    },
    {
      "name": "multiple-active",
      "type": "boolean",
      "default": "false",
      "source": "v-treeview",
      "description": {
        "en": "When `true`, allows user to have multiple active nodes at the same time",
        "ja": "`true`の場合、ユーザーは同時に複数のアクティブノードを持つことができます。",
        "zh-Hans": "为 `true` 时，允许用户同时具有多个活动节点"
      }
    },
    {
      "name": "off-icon",
      "type": "string",
      "default": "'$checkboxOff'",
      "source": "v-treeview",
      "description": {
        "en": "Icon used when node is not selected. Only visible when `selectable` is `true`.",
        "ja": "ノードが選択されていないときに使用されるアイコン。`selectable` が `true` の場合にのみ表示されます。",
        "zh-Hans": "未选择节点时使用的图标。 仅在 `selectable` 为 `true` 时可见。"
      }
    },
    {
      "name": "on-icon",
      "type": "string",
      "default": "'$checkboxOn'",
      "source": "v-treeview",
      "description": {
        "en": "Icon used when leaf node is selected or when a branch node is fully selected. Only visible when `selectable` is `true`.",
        "ja": "リーフノードが選択されたときまたはブランチノードが完全に選択されたときに使用されるアイコン。 `selectable` が `true` の場合のみ表示されます。",
        "zh-Hans": "选择叶节点或完全选择分支节点时使用的图标。 仅在 `selectable` 为 `true` 时可见。"
      }
    },
    {
      "name": "open",
      "type": "array",
      "default": [],
      "source": "v-treeview",
      "description": {
        "en": "Syncable prop that allows one to control which nodes are open. The array consists of the `item-key` of each open item.",
        "ja": "どのノードが開かれているかを制御するためのSyncable prop です。配列はそれぞれの開いているアイテムの `item-key` で構成されています。",
        "zh-Hans": "可同步属性，允许您控制打开哪些节点。 该数组由每个打开项目的 `item-key` 组成。"
      }
    },
    {
      "name": "open-all",
      "type": "boolean",
      "default": "false",
      "source": "v-treeview",
      "description": {
        "en": "When `true` will cause all branch nodes to be opened when component is mounted",
        "ja": "When `true` will cause all branch nodes to be opened when component is mounted",
        "zh-Hans": "当为 `true` 时，将导致在安装组件时打开所有分支节点"
      }
    },
    {
      "name": "open-on-click",
      "type": "boolean",
      "default": "false",
      "source": "v-treeview",
      "description": {
        "en": "When `true` will cause nodes to be opened by clicking anywhere on it, instead of only opening by clicking on expand icon. When using this prop with `activatable` you will be unable to mark nodes with children as active.",
        "ja": "When `true` will cause nodes to be opened by clicking anywhere on it, instead of only opening by clicking on expand icon. When using this prop with `activatable` you will be unable to mark nodes with children as active.",
        "zh-Hans": "如果为 `true` ，将导致通过单击节点上的任意位置来打开节点，而不是仅通过单击展开图标来打开节点。 将此属性与 `activatable` 一起使用时，您将无法将带有子节点标记为活动状态。"
      }
    },
    {
      "name": "return-object",
      "type": "boolean",
      "default": "false",
      "source": "v-treeview",
      "description": {
        "en": "When `true` will make `v-model`, `active.sync` and `open.sync` return the complete object instead of just the key",
        "ja": "When `true` will make `v-model`, `active.sync` and `open.sync` return the complete object instead of just the key",
        "zh-Hans": "当 `true` 时，`v-model`、`active.sync` 和 `open.sync` 将返回完整的对象而不是只是键"
      }
    },
    {
      "name": "rounded",
      "type": "boolean",
      "default": "false",
      "source": "v-treeview",
      "description": {
        "en": "Provides an alternative active style for `v-treeview` node. Only visible when `activatable` is `true` and should not be used in conjunction with the `shaped` prop.",
        "ja": "Provides an alternative active style for `v-treeview` node. Only visible when `activatable` is `true` and should not be used in conjunction with the `shaped` prop.",
        "zh-Hans": "为 `v-treeview` 节点提供替代的活动样式。 仅在 `activatable` 为 `true` 时可见，并且不应与 `shaped`属性一起使用。"
      }
    },
    {
      "name": "search",
      "type": "string",
      "default": "undefined",
      "source": "v-treeview",
      "description": {
        "en": "The search model for filtering results",
        "ja": "The search model for filtering results",
        "zh-Hans": "筛选结果的搜索模型"
      }
    },
    {
      "name": "selectable",
      "type": "boolean",
      "default": "false",
      "source": "v-treeview",
      "description": {
        "en": "Will render a checkbox next to each node allowing them to be selected",
        "ja": "Will render a checkbox next to each node allowing them to be selected",
        "zh-Hans": "将在每个节点旁边渲染一个复选框，以允许选择它们"
      }
    },
    {
      "name": "selected-color",
      "type": "string",
      "default": "'accent'",
      "source": "v-treeview",
      "description": {
        "en": "The color of the selection checkbox",
        "ja": "選択チェックボックスの色",
        "zh-Hans": "选择框的颜色"
      }
    },
    {
      "name": "selection-type",
      "type": "string",
      "default": "'leaf'",
      "source": "v-treeview",
      "description": {
        "en": "Controls how the treeview selects nodes. There are two modes available: 'leaf' and 'independent'",
        "ja": "Controls how the treeview selects nodes. There are two modes available: 'leaf' and 'independent'",
        "zh-Hans": "控制树形视图如何选择节点。 有两种模式可用：'leaf' 和 'independent'"
      }
    },
    {
      "name": "shaped",
      "type": "boolean",
      "default": "false",
      "source": "v-treeview",
      "description": {
        "en": "Provides an alternative active style for `v-treeview` node. Only visible when `activatable` is `true` and should not be used in conjunction with the `rounded` prop.",
        "ja": "Provides an alternative active style for `v-treeview` node. Only visible when `activatable` is `true` and should not be used in conjunction with the `rounded` prop.",
        "zh-Hans": "为 `v-treeview` 节点提供替代的活动样式。 仅在 `activatable` 为 `true` 时可见，并且不应与 `rounded` 属性结合使用。"
      }
    },
    {
      "name": "transition",
      "type": "boolean",
      "default": "false",
      "source": "v-treeview",
      "description": {
        "en": "Applies a transition when nodes are opened and closed",
        "ja": "Applies a transition when nodes are opened and closed",
        "zh-Hans": "在打开和关闭节点时应用过渡"
      }
    },
    {
      "name": "value",
      "type": "array",
      "default": [],
      "source": "v-treeview",
      "description": {
        "en": "Allows one to control which nodes are selected. The array consists of the `item-key` of each selected item. Is used with `@input` event to allow for `v-model` binding.",
        "ja": "Allows one to control which nodes are selected. The array consists of the `item-key` of each selected item. Is used with `@input` event to allow for `v-model` binding.",
        "zh-Hans": "允许您控制选择哪些节点。 该数组由每个选定项目的 `item-key` 组成。 与 `@input` 事件一起使用以允许 `v-model` 绑定。"
      }
    }
  ],
  "mixins": [
    "registrable-provide",
    "themeable"
  ],
  "slots": [
    {
      "name": "append",
      "props": {
        "item": "any",
        "leaf": "boolean",
        "selected": "boolean",
        "indeterminate": "boolean",
        "active": "boolean",
        "open": "boolean"
      },
      "description": {
        "en": "Appends content after label",
        "ja": "ラベルの後にコンテンツを追加",
        "zh-Hans": "在标签后附加内容"
      }
    },
    {
      "name": "label",
      "props": {
        "item": "any",
        "leaf": "boolean",
        "selected": "boolean",
        "indeterminate": "boolean",
        "active": "boolean",
        "open": "boolean"
      },
      "description": {
        "en": "Label content",
        "ja": "ラベルの内容",
        "zh-Hans": "标签内容"
      }
    },
    {
      "name": "prepend",
      "props": {
        "item": "any",
        "leaf": "boolean",
        "selected": "boolean",
        "indeterminate": "boolean",
        "active": "boolean",
        "open": "boolean"
      },
      "description": {
        "en": "Prepends content before label",
        "ja": "ラベルの前にコンテンツを追加",
        "zh-Hans": "在标签之前添加内容"
      }
    }
  ],
  "events": [
    {
      "name": "input",
      "value": "array",
      "description": {
        "en": "Emits the array of selected items when this value changes",
        "ja": "Emits the array of selected items when this value changes",
        "zh-Hans": "当此值更改时，发出选定项的数组"
      }
    },
    {
      "name": "update:active",
      "value": "array",
      "description": {
        "en": "Emits the array of active items when this value changes",
        "ja": "Emits the array of active items when this value changes",
        "zh-Hans": "当此值更改时，发出活动项的数组"
      }
    },
    {
      "name": "update:open",
      "value": "array",
      "description": {
        "en": "Emits the array of open items when this value changes",
        "ja": "Emits the array of open items when this value changes",
        "zh-Hans": "当此值更改时，发出打开项的数组"
      }
    }
  ],
  "functions": [
    {
      "name": "updateAll",
      "signature": "(val: boolean): void",
      "description": {
        "en": "Opens or closes all nodes",
        "ja": "すべてのノードを開いたり閉じたりします",
        "zh-Hans": "打开或关闭所有节点"
      }
    }
  ],
  "name": "v-treeview",
  "sass": [
    {
      "name": "$treeview-transition",
      "default": ".2s map-get($transition, 'linear-out-slow-in') !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$treeview-label-font-size",
      "default": "inherit !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$treeview-node-height",
      "default": "48px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$treeview-node-height-dense",
      "default": "40px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$treeview-node-shaped-margin",
      "default": "8px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$treeview-node-padding",
      "default": "8px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$treeview-node-margin",
      "default": "6px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$treeview-node-level-width",
      "default": "24px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "component": true
}