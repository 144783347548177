module.exports = {
  "props": [
    {
      "name": "mode",
      "type": "string",
      "default": "'in-out'",
      "source": "transitions",
      "description": {
        "en": "Sets the transition mode (does not apply to transition-group). You can find more information on the Vue documentation [for transition modes](https://vuejs.org/v2/api/#transition).",
        "ja": "トランジション モードを設定します(transition-groupには適用されません)。詳細については、Vue のドキュメント [トランジションモード](https://jp.vuejs.org/v2/api/#transition) を参照してください。",
        "zh-Hans": "设置转换模式（不应用于转换组）。你可以在 Vue 文档浏览更多 [for transition modes](https://vuejs.org/v2/api/#transition)。"
      }
    }
  ],
  "mixins": [],
  "slots": [],
  "events": [],
  "functions": [],
  "name": "v-expand-transition",
  "sass": [],
  "component": true
}