module.exports = {
  "props": [
    {
      "name": "active-class",
      "type": "string",
      "source": "groupable",
      "description": {
        "en": "Configure the active CSS class applied when the link is active. You can find more information about the [**active-class** prop](https://router.vuejs.org/api/#active-class) on the vue-router documentation.",
        "ja": "リンクがアクティブなときに適用されるアクティブなCSSクラスを構成します。vue-routerのドキュメントで [**active-class** prop](https://router.vuejs.org/ja/api/#active-class) の詳細をご覧ください。",
        "zh-Hans": "配置在链接激活时应用的 CSS 类。你能够在 vue-router 文档浏览更多 [**active-class** prop](https://router.vuejs.org/api/#active-class)。"
      }
    },
    {
      "name": "disabled",
      "type": "boolean",
      "default": "false",
      "source": "groupable",
      "description": {
        "en": "Removes the ability to click or target the component.",
        "ja": "コンポーネントをクリックまたはターゲットにできなくします。",
        "zh-Hans": "移除组件的单击或 target 功能。"
      }
    },
    {
      "name": "eager",
      "type": "boolean",
      "default": "false",
      "source": "bootable",
      "description": {
        "en": "Will force the components content to render on mounted. This is useful if you have content that will not be rendered in the DOM that you want crawled for SEO.",
        "ja": "コンポーネントのコンテンツを mounted 時に render することを強制します。この機能は、SEO のために crawl させたい DOM の中に render されないコンテンツを持っているときに役に立ちます。",
        "zh-Hans": "将强制组件内容在加载时呈现。如果存在内容的话，则不会在 DOM 中渲染，如果你想优化 SEO，这是非常有用的。"
      }
    },
    {
      "name": "id",
      "type": "string",
      "default": "undefined",
      "source": "v-tab-item",
      "description": {
        "en": "Sets the DOM id on the component",
        "ja": "コンポーネントにDOM idを設定します",
        "zh-Hans": "设置组件上的 DOM id"
      }
    },
    {
      "name": "reverse-transition",
      "type": [
        "boolean",
        "string"
      ],
      "default": "undefined",
      "source": "v-window-item",
      "description": {
        "en": "Sets the reverse transition",
        "ja": "戻す際のトランジションを設定します。",
        "zh-Hans": "设置反转过渡"
      }
    },
    {
      "name": "transition",
      "type": [
        "boolean",
        "string"
      ],
      "default": "undefined",
      "source": "v-window-item",
      "description": {
        "en": "The transition used when the component progressing through items. Can be one of the [built in transitions](/styles/transitions) or one your own.",
        "ja": "コンポーネントが項目を進行するときに使用するときに使用されるトランジション。 [組み込みトランジション](/styles/transitions) のいずれか、または独自のものを使用できます。",
        "zh-Hans": "组件在逐项浏览时使用的过渡。 可以是 [built in transitions](/styles/transitions) 之一，或是你自己创建的过渡。"
      }
    },
    {
      "name": "value",
      "type": "any",
      "default": "undefined",
      "source": "v-window-item",
      "description": {
        "en": "Sets the value of the tab. If not provided, the index will be used.",
        "ja": "タブの値を設定します。指定されていない場合は、インデックスが使用されます。",
        "zh-Hans": "设置选项卡的值。 如果未提供，将使用索引。"
      }
    }
  ],
  "mixins": [
    "bootable",
    "groupable"
  ],
  "slots": [
    {
      "name": "default",
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    }
  ],
  "events": [],
  "functions": [],
  "name": "v-tab-item",
  "sass": [],
  "component": true
}