module.exports = {
  "props": [
    {
      "name": "aspect-ratio",
      "type": [
        "string",
        "number"
      ],
      "default": "undefined",
      "source": "v-responsive",
      "description": {
        "en": "Sets a base aspect ratio, calculated as width/height. This will only set a **minimum** height, the component can still grow if it has a lot of content.",
        "ja": "幅/高さを算出する基本アスペクト比を設定します。 これは**最小**の高さのみを設定します。コンポーネントに多くのコンテンツがある場合、コンポーネントは合わせて引き伸ばされます。",
        "zh-Hans": "设置以 宽度/高度 计算的基本长宽比。这将只设置一个 **最小** 高度，如果它有很多内容的话，组件仍然可以升高。"
      }
    },
    {
      "name": "content-class",
      "type": "string",
      "default": "undefined",
      "source": "v-responsive",
      "description": {
        "en": "Apply a custom class to the responsive content div.",
        "ja": "Apply a custom class to the responsive content div.",
        "zh-Hans": "应用自定义类到内容 div."
      }
    },
    {
      "name": "height",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the height for the component.",
        "ja": "コンポーネントに高さを設定します。",
        "zh-Hans": "设定组件的高度。"
      }
    },
    {
      "name": "max-height",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the maximum height for the component.",
        "ja": "コンテンツの高さの最大値を設定します。",
        "zh-Hans": "设定组件的最大高度。"
      }
    },
    {
      "name": "max-width",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the maximum width for the component.",
        "ja": "コンテンツの横幅の最大値を設定します。",
        "zh-Hans": "设定组件的最大宽度。"
      }
    },
    {
      "name": "min-height",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the minimum height for the component.",
        "ja": "コンテンツの高さの最小値を設定します。",
        "zh-Hans": "设定组件的最小高度。"
      }
    },
    {
      "name": "min-width",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the minimum width for the component.",
        "ja": "コンテンツの横幅の最小値を設定します。",
        "zh-Hans": "设定组件的最小宽度。"
      }
    },
    {
      "name": "width",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the width for the component.",
        "ja": "コンポーネントに横幅を設定します。",
        "zh-Hans": "设定组件的宽度。"
      }
    }
  ],
  "mixins": [
    "measurable"
  ],
  "slots": [
    {
      "name": "default",
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    }
  ],
  "events": [],
  "functions": [],
  "name": "v-responsive",
  "sass": [
    {
      "name": "$responsive-transition",
      "default": "padding-bottom 0.2s map-get($transition, 'swing') !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "component": true
}