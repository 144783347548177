module.exports = {
  "props": [
    {
      "name": "color",
      "type": "string",
      "default": "undefined",
      "source": "v-simple-checkbox",
      "description": {
        "en": "Applies specified color to the control - it can be the name of material color (for example `success` or `purple`) or css color (`#033` or `rgba(255, 0, 0, 0.5)`). You can find a list of built-in classes on the [colors page](/styles/colors#material-colors).",
        "ja": "Applies specified color to the control - it can be the name of material color (for example `success` or `purple`) or css color (`#033` or `rgba(255, 0, 0, 0.5)`). You can find a list of built-in classes on the [colors page](/styles/colors#material-colors).",
        "zh-Hans": "指定控件颜色，颜色值可以是 Material 颜色（例如 `success` 或者 `purple` ），也可以是 Css 颜色 （例如 `#033` 或者 `rgba(255, 0, 0, 0.5)`）。在 [colors page](/zh-Hans/styles/colors#material-colors) 中可以找到内置颜色类列表。"
      }
    },
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "v-simple-checkbox",
      "description": {
        "en": "Applies the dark theme variant to the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにダークテーマを適応します。詳しく知りたい場合は、Material Design documentationの[dark themes](https://material.io/design/color/dark-theme.html)を参照してください。",
        "zh-Hans": "将暗色主题变量应用到组件。你可以在 [dark themes](https://material.io/design/color/dark-theme.html) 的 Material Design 文档中找到更多有关信息。"
      }
    },
    {
      "name": "disabled",
      "type": "boolean",
      "default": "false",
      "source": "v-simple-checkbox",
      "description": {
        "en": "Disables simple checkbox.",
        "ja": "シンプルチェック ボックスを無効にします。",
        "zh-Hans": "禁用简单复选框。"
      }
    },
    {
      "name": "indeterminate",
      "type": "boolean",
      "default": "false",
      "source": "v-simple-checkbox",
      "description": {
        "en": "Sets an indeterminate state for the simple checkbox.",
        "ja": "チェックボックスを不確定な状態（indeterminate）に設定します.",
        "zh-Hans": "为简单复选框设置一个不确定的状态。"
      }
    },
    {
      "name": "indeterminate-icon",
      "type": "string",
      "default": "'$checkboxIndeterminate'",
      "source": "v-simple-checkbox",
      "description": {
        "en": "The icon used when in an indeterminate state.",
        "ja": "不確定な状態で使用されるアイコン。",
        "zh-Hans": "处于不确定状态时使用的图标。"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "v-simple-checkbox",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    },
    {
      "name": "off-icon",
      "type": "string",
      "default": "'$checkboxOff'",
      "source": "v-simple-checkbox",
      "description": {
        "en": "The icon used when inactive.",
        "ja": "非アクティブな場合に使用するアイコン。",
        "zh-Hans": "处于不活动状态时使用的图标。"
      }
    },
    {
      "name": "on-icon",
      "type": "string",
      "default": "'$checkboxOn'",
      "source": "v-simple-checkbox",
      "description": {
        "en": "The icon used when active.",
        "ja": "アクティブなときに使用するアイコン。",
        "zh-Hans": "处于激活状态时使用的图标."
      }
    },
    {
      "name": "ripple",
      "type": "boolean",
      "default": "true",
      "source": "v-simple-checkbox",
      "description": {
        "en": "Applies the [v-ripple](/directives/ripple) directive.",
        "ja": "[v-ripple](/directives/ripple) ディレクティブを適用します。",
        "zh-Hans": "应用 [v-ripple](/directives/ripples) 指令。"
      }
    },
    {
      "name": "value",
      "type": "boolean",
      "default": "false",
      "source": "v-simple-checkbox",
      "description": {
        "en": "A boolean value that represents whether the simple checkbox is checked.",
        "ja": "シンプルチェック ボックスがオンになっているかどうかを表す真偽値。",
        "zh-Hans": "一个 boolean，表示简单的复选框是否被选中。"
      }
    }
  ],
  "mixins": [],
  "slots": [],
  "events": [
    {
      "name": "input",
      "value": "Event",
      "description": {
        "en": "The updated bound model",
        "ja": "更新されたバウンドモデル",
        "zh-Hans": "被绑定模型的更新"
      }
    }
  ],
  "functions": [],
  "name": "v-simple-checkbox",
  "sass": [],
  "component": true
}