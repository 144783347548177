module.exports = {
  "props": [
    {
      "name": "background-color",
      "type": "string",
      "default": "'accent'",
      "source": "v-rating",
      "description": {
        "en": "The color used for empty icons",
        "ja": "The color used for empty icons",
        "zh-Hans": "使用空图标的颜色"
      }
    },
    {
      "name": "clearable",
      "type": "boolean",
      "default": "false",
      "source": "v-rating",
      "description": {
        "en": "Allows for the component to be cleared. Triggers when the icon containing the current value is clicked.",
        "ja": "コンポーネントをクリアすることができます。現在の値を含むアイコンがクリックされたときにトリガーします。",
        "zh-Hans": "允许清空该组件。当包含当前值的图标被点击时触发。"
      }
    },
    {
      "name": "close-delay",
      "type": [
        "number",
        "string"
      ],
      "default": 0,
      "source": "delayable",
      "description": {
        "en": "Milliseconds to wait before closing component. Only applies to hover and focus events.",
        "ja": "Milliseconds to wait before closing component. Only applies to hover and focus events.",
        "zh-Hans": "组件关闭前等待的毫秒数。仅应用于悬停和焦点事件。"
      }
    },
    {
      "name": "color",
      "type": "string",
      "default": "'primary'",
      "source": "colorable",
      "description": {
        "en": "Applies specified color to the control - it can be the name of material color (for example `success` or `purple`) or css color (`#033` or `rgba(255, 0, 0, 0.5)`). You can find a list of built-in classes on the [colors page](/styles/colors#material-colors).",
        "ja": "指定された色をコントロールに適用します。利用できるのはマテリアルカラーの名前 (たとえば、`success` または ` purple`) または css 表記の色 (`#033`または` rgba(255, 0, 0, 0.5) `) です。[colors のページ](/styles/colors#material-colors) で、組み込みクラスのリストが確認できます。",
        "zh-Hans": "指定控件颜色，颜色值可以是 Material 颜色（例如 <code>success</code>或者 <code>purple</code> ）也可以是 Css 颜色 （例如 <code>#033</code> 或者 <code>rgba(255, 0, 0, 0.5)</code>）。在 [colors page](/zh-Hans/styles/colors#material-colors)中可以找到内置颜色类列表。"
      }
    },
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにダークテーマを適応します。詳しく知りたい場合は、Material Design documentationの<a href=\"https://material.io/design/color/dark-theme.html\">dark themes</a>を参照してください。",
        "zh-Hans": "将暗色主题变量应用到组件。你可以在 [dark themes](https://material.io/design/color/dark-theme.html) 的 Material Design 文档中找到更多有关信息。"
      }
    },
    {
      "name": "dense",
      "type": "boolean",
      "default": "false",
      "source": "v-rating",
      "description": {
        "en": "Icons have a smaller size",
        "ja": "アイコンのサイズを小さくします",
        "zh-Hans": "图标大小较小"
      }
    },
    {
      "name": "empty-icon",
      "type": "string",
      "default": "'$ratingEmpty'",
      "source": "v-rating",
      "description": {
        "en": "The icon displayed when empty",
        "ja": "評価が点けられてないときのアイコン",
        "zh-Hans": "空时显示的图标"
      }
    },
    {
      "name": "full-icon",
      "type": "string",
      "default": "'$ratingFull'",
      "source": "v-rating",
      "description": {
        "en": "The icon displayed when full",
        "ja": "1つ分の評価が点けられたときのアイコン",
        "zh-Hans": "满时显示的图标"
      }
    },
    {
      "name": "half-icon",
      "type": "string",
      "default": "'$ratingHalf'",
      "source": "v-rating",
      "description": {
        "en": "The icon displayed when half (requires **half-increments** prop)",
        "ja": "半分評価がうまったときのアイコン（**half-increments** propが必要です）",
        "zh-Hans": "半时显示的图标（需要 **half-increments** 属性）"
      }
    },
    {
      "name": "half-increments",
      "type": "boolean",
      "default": "false",
      "source": "v-rating",
      "description": {
        "en": "Allows the selection of half increments",
        "ja": "半分の評価加点を選択可能にする",
        "zh-Hans": "允许选择半增量"
      }
    },
    {
      "name": "hover",
      "type": "boolean",
      "default": "false",
      "source": "v-rating",
      "description": {
        "en": "Provides visual feedback when hovering over icons",
        "ja": "アイコンの上にカーソルを合わせたときに視覚的なフィードバックを提供します",
        "zh-Hans": "将鼠标悬停在图标上时提供视觉反馈"
      }
    },
    {
      "name": "icon-label",
      "type": "string",
      "default": "'$vuetify.rating.ariaLabel.icon'",
      "source": "v-rating",
      "description": {
        "en": "The **aria-label** used for icons",
        "ja": "アイコンに使用される **aria-label**",
        "zh-Hans": "用于图标的**aria-label**"
      }
    },
    {
      "name": "large",
      "type": "boolean",
      "default": "false",
      "source": "sizeable",
      "description": {
        "en": "Makes the component large.",
        "ja": "コンポーネントをlargeにします。",
        "zh-Hans": "使组件尺寸变的巨大。"
      }
    },
    {
      "name": "length",
      "type": [
        "number",
        "string"
      ],
      "default": 5,
      "source": "v-rating",
      "description": {
        "en": "The amount of ratings to show",
        "ja": "表示するレーティングの数",
        "zh-Hans": "要显示的评分数量"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    },
    {
      "name": "open-delay",
      "type": [
        "number",
        "string"
      ],
      "default": 0,
      "source": "delayable",
      "description": {
        "en": "Milliseconds to wait before opening component. Only applies to hover and focus events.",
        "ja": "コンポーネントを開くまでのミリ秒を指定。hoverとfocusイベントのみに反映します。",
        "zh-Hans": "组件打开前等待的毫秒数。仅应用于悬停和焦点事件。"
      }
    },
    {
      "name": "readonly",
      "type": "boolean",
      "default": "false",
      "source": "v-rating",
      "description": {
        "en": "Removes all hover effects and pointer events",
        "ja": "すべてのホバーエフェクトとポインタイベントを削除します",
        "zh-Hans": "移除所有悬停效果和指针事件"
      }
    },
    {
      "name": "ripple",
      "type": [
        "boolean",
        "object"
      ],
      "default": true,
      "source": "rippleable",
      "description": {
        "en": "Applies the [v-ripple](/directives/ripple) directive.",
        "ja": "[v-ripple](/directives/ripple) ディレクティブを適用します。",
        "zh-Hans": "应用 [v-ripple](/directives/ripples) 指令。"
      }
    },
    {
      "name": "size",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "v-rating",
      "description": {
        "en": "Sets the height and width of the component.",
        "ja": "コンポーネントの幅と高さを設定します.",
        "zh-Hans": "设置组件的高度和宽度."
      }
    },
    {
      "name": "small",
      "type": "boolean",
      "default": "false",
      "source": "sizeable",
      "description": {
        "en": "Makes the component small.",
        "ja": "コンポーネントをsmallにします。",
        "zh-Hans": "使组件尺寸变的小。"
      }
    },
    {
      "name": "value",
      "type": "number",
      "default": 0,
      "source": "v-rating",
      "description": {
        "en": "The rating value",
        "ja": "レーティング値",
        "zh-Hans": "评分值"
      }
    },
    {
      "name": "x-large",
      "type": "boolean",
      "default": "false",
      "source": "sizeable",
      "description": {
        "en": "Makes the component extra large.",
        "ja": "コンポーネントをextra largeにします。",
        "zh-Hans": "使组件尺寸变的无比巨大。"
      }
    },
    {
      "name": "x-small",
      "type": "boolean",
      "default": "false",
      "source": "sizeable",
      "description": {
        "en": "Makes the component extra small.",
        "ja": "コンポーネントをextra smallにします。",
        "zh-Hans": "使组件尺寸变的更小。"
      }
    }
  ],
  "mixins": [
    "colorable",
    "delayable",
    "rippleable",
    "sizeable",
    "themeable"
  ],
  "slots": [
    {
      "name": "default",
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    },
    {
      "name": "item",
      "props": {
        "click": "(i: number) => void",
        "index": "number",
        "isFilled": "boolean",
        "isHalfFilled": "?boolean",
        "isHalfHovered": "?boolean",
        "isHovered": "boolean",
        "value": "number"
      },
      "description": {
        "en": "The slot for rendered items",
        "ja": "レンダリングされたアイテムのスロット",
        "zh-Hans": "已渲染项目的插槽"
      }
    }
  ],
  "events": [
    {
      "name": "input",
      "value": "Number",
      "description": {
        "en": "Emits the rating number when this value changes",
        "ja": "Emits the rating number when this value changes",
        "zh-Hans": "当此值发生变化时的评分数"
      }
    }
  ],
  "functions": [],
  "name": "v-rating",
  "sass": [
    {
      "name": "$rating-padding",
      "default": "0.5rem !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$rating-border-radius",
      "default": "50% !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$rating-dense-padding",
      "default": "0.1rem !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "component": true
}