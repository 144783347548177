module.exports = {
  "props": [
    {
      "name": "absolute",
      "type": "boolean",
      "default": "false",
      "source": "positionable",
      "description": {
        "en": "Applies **position: absolute** to the component.",
        "ja": "コンポーネントに **position: absolute** を適用します。",
        "zh-Hans": "给组件应用 **position: absolute**"
      }
    },
    {
      "name": "bottom",
      "type": "boolean",
      "default": "false",
      "source": "positionable",
      "description": {
        "en": "Aligns the component towards the bottom.",
        "ja": "コンポーネントを下部に配置します。",
        "zh-Hans": "将组件向底部对齐。"
      }
    },
    {
      "name": "direction",
      "type": "string",
      "default": "'top'",
      "source": "v-speed-dial",
      "description": {
        "en": "Direction in which speed-dial content will show. Possible values are `top`, `bottom`, `left`, `right`.",
        "ja": "スピード ダイヤルのコンテンツが表示される方向。指定できる値は、`top`, `bottom`, `left`, `right` です。",
        "zh-Hans": "Speed-dial 显示的方向。可设置的值有 `top`, `bottom`, `left`, `right`。"
      }
    },
    {
      "name": "fixed",
      "type": "boolean",
      "default": "false",
      "source": "positionable",
      "description": {
        "en": "Applies **position: fixed** to the component.",
        "ja": "コンポーネントに **position: fixed** を適用します。",
        "zh-Hans": "给组件应用 **position: fixed**"
      }
    },
    {
      "name": "left",
      "type": "boolean",
      "default": "false",
      "source": "positionable",
      "description": {
        "en": "Aligns the component towards the left.",
        "ja": "コンポーネントを左に配置します。",
        "zh-Hans": "将组件向左边对齐。"
      }
    },
    {
      "name": "mode",
      "type": "string",
      "default": "undefined",
      "source": "transitionable",
      "description": {
        "en": "Sets the transition mode (does not apply to transition-group). You can find more information on the Vue documentation [for transition modes](https://vuejs.org/v2/api/#transition).",
        "ja": "トランジション モードを設定します(transition-groupには適用されません)。詳細については、Vue のドキュメント [トランジションモード](https://jp.vuejs.org/v2/api/#transition) を参照してください。",
        "zh-Hans": "设置转换模式（不应用于转换组）。你可以在 Vue 文档浏览更多 [for transition modes](https://vuejs.org/v2/api/#transition)。"
      }
    },
    {
      "name": "open-on-hover",
      "type": "boolean",
      "default": "false",
      "source": "v-speed-dial",
      "description": {
        "en": "Opens speed-dial on hover",
        "ja": "ホバー時にスピードダイヤルを開く",
        "zh-Hans": "鼠标悬浮时开启 speed-dial"
      }
    },
    {
      "name": "origin",
      "type": "string",
      "default": "undefined",
      "source": "transitionable",
      "description": {
        "en": "Sets the transition origin on the element. You can find more information on the MDN documentation [for transition origin](https://developer.mozilla.org/en-US/docs/Web/CSS/transform-origin).",
        "ja": "要素にトランジションの起点を設定します。 MDNドキュメントで [for transition origin](https://developer.mozilla.org/ja/docs/Web/CSS/transform-origin) の詳細を参照できます。",
        "zh-Hans": "设置元素上的动画原点。您可以在 MDN 文档上浏览更多 [for transition origin](https://developer.mozilla.org/en-US/docs/Web/CSS/transform-origin)"
      }
    },
    {
      "name": "right",
      "type": "boolean",
      "default": "false",
      "source": "positionable",
      "description": {
        "en": "Aligns the component towards the right.",
        "ja": "コンポーネントを右に配置します。",
        "zh-Hans": "将组件向右边对齐。"
      }
    },
    {
      "name": "top",
      "type": "boolean",
      "default": "false",
      "source": "positionable",
      "description": {
        "en": "Aligns the content towards the top.",
        "ja": "コンポーネントを上部に配置します。",
        "zh-Hans": "将组件向顶部对齐。"
      }
    },
    {
      "name": "transition",
      "type": "string",
      "default": "'scale-transition'",
      "source": "transitionable",
      "description": {
        "en": "Sets the component transition. Can be one of the [built in transitions](/styles/transitions) or one your own.",
        "ja": "コンポーネントのトランジションを設定します。 [組み込みトランジション](/styles/transitions) のいずれか、または独自のものを使用できます。",
        "zh-Hans": "设置组件转换。可以是一个 [built in transitions](/styles/transitions) 或者是自己自定义的。"
      }
    },
    {
      "name": "value",
      "type": "any",
      "default": "undefined",
      "source": "toggleable",
      "description": {
        "en": "Controls whether the component is visible or hidden.",
        "ja": "コンポーネントを表示するか非表示にするかを制御します。",
        "zh-Hans": "控制组件可见还是隐藏。"
      }
    }
  ],
  "mixins": [
    "positionable",
    "toggleable",
    "transitionable"
  ],
  "slots": [
    {
      "name": "activator",
      "description": {
        "en": "When used, will activate the component when clicked (or hover for specific components). This manually stops the event propagation. Without this slot, if you open the component through its model, you will need to manually stop the event propagation",
        "ja": "使用すると、クリック時 (または特定のコンポーネントではホバー時) にコンポーネントを有効にします。これにより手動でイベントの propagation を stop されます。この slot がない場合、モデル経由でコンポーネントを開いたときには、自分でイベントの propagation を stop する必要があります。",
        "zh-Hans": "使用时，将在点击后激活组件（或悬停特定组件），它手动阻止事件传播。如果通过模型打开组件而没有此插槽，则需要手动阻止事件传播。"
      }
    },
    {
      "name": "default",
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    }
  ],
  "events": [],
  "functions": [],
  "name": "v-speed-dial",
  "sass": [
    {
      "name": "$speed-dial-padding",
      "default": "16px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$speed-dial-button-margin",
      "default": "6px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$speed-dial-z-index",
      "default": "1 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "component": true
}