module.exports = {
  "props": [
    {
      "name": "alt",
      "type": "string",
      "default": "undefined",
      "source": "v-img",
      "description": {
        "en": "Alternate text for screen readers. Leave empty for decorative images",
        "ja": "スクリーンリーダー用の代替テキストです。装飾用の画像の場合空にしてください。",
        "zh-Hans": "屏幕阅读器的备用文本。 留空以装饰图像"
      }
    },
    {
      "name": "aspect-ratio",
      "type": [
        "string",
        "number"
      ],
      "default": "undefined",
      "source": "v-responsive",
      "description": {
        "en": "Calculated as `width/height`, so for a 1920x1080px image this will be `1.7778`. Will be calculated automatically if omitted",
        "ja": "`width/height`として計算されるため、1920x1080 px画像の場合、`1.7778`になります。省略すると自動的に計算されます",
        "zh-Hans": "计算为`width/height`，因此对于 1920x1080px 的图片，其值为 `1.7778`。 如果省略，将自动计算"
      }
    },
    {
      "name": "contain",
      "type": "boolean",
      "default": "false",
      "source": "v-img",
      "description": {
        "en": "Prevents the image from being cropped if it doesn't fit",
        "ja": "画像が収まらない場合にトリミングされないようにします",
        "zh-Hans": "防止图像不合适时被裁剪"
      }
    },
    {
      "name": "content-class",
      "type": "string",
      "default": "undefined",
      "source": "v-responsive",
      "description": {
        "en": "Apply a custom class to the responsive content div.",
        "ja": "Apply a custom class to the responsive content div.",
        "zh-Hans": "应用自定义类到内容 div."
      }
    },
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにダークテーマを適応します。詳しく知りたい場合は、Material Design documentationの<a href=\"https://material.io/design/color/dark-theme.html\">dark themes</a>を参照してください。",
        "zh-Hans": "将暗色主题变量应用到组件。你可以在 [dark themes](https://material.io/design/color/dark-theme.html) 的 Material Design 文档中找到更多有关信息。"
      }
    },
    {
      "name": "eager",
      "type": "boolean",
      "default": "false",
      "source": "v-img",
      "description": {
        "en": "Will force the components content to render on mounted. This is useful if you have content that will not be rendered in the DOM that you want crawled for SEO.",
        "ja": "コンポーネントのコンテンツを mounted 時に render することを強制します。この機能は、SEO のために crawl させたい DOM の中に render されないコンテンツを持っているときに役に立ちます。",
        "zh-Hans": "将强制组件内容在加载时呈现。如果存在内容的话，则不会在 DOM 中渲染，如果你想优化 SEO，这是非常有用的。"
      }
    },
    {
      "name": "gradient",
      "type": "string",
      "default": "undefined",
      "source": "v-img",
      "description": {
        "en": "Overlays a gradient onto the image. Only supports [linear-gradient](https://developer.mozilla.org/en-US/docs/Web/CSS/linear-gradient) syntax, anything else should be done with classes (see examples)",
        "ja": "画像の上にグラデーションをオーバーレイします。 [linear-gradient](https://developer.mozilla.org/ja/docs/Web/CSS/linear-gradient) の構文のみをサポートしており、それ以外のものはクラスで行う必要があります (例を参照)。",
        "zh-Hans": "将渐变叠加到图像上。 仅支持 [linear-gradient](https://developer.mozilla.org/en-US/docs/Web/CSS/linear-gradient) 语法，其他任何操作都应使用类完成（请参见示例）"
      }
    },
    {
      "name": "height",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the height for the component.",
        "ja": "コンポーネントに高さを設定します。",
        "zh-Hans": "设定组件的高度。"
      }
    },
    {
      "name": "lazy-src",
      "type": "string",
      "default": "undefined",
      "source": "v-img",
      "description": {
        "en": "Something to show while waiting for the main image to load, typically a small base64-encoded thumbnail. Has a slight blur filter applied.\n\nUse [vuetify-loader](https://github.com/vuetifyjs/vuetify-loader) to generate automatically",
        "ja": "メイン画像が読み込まれるのを待っている間に表示されるもので、通常は小さなbase64でエンコードされたサムネイルです。若干のぼかしフィルタが適用されています。\n\n自動生成するには [vuetify-loader](https://github.com/vuetifyjs/vuetify-loader) を使用してください。",
        "zh-Hans": "在等待主映像加载时显示的内容，通常是一个小型的base64编码的缩略图。有一个轻微的模糊滤镜应用。\n\n使用 [vuetify-loader](https://github.com/vuetifyjs/vuetify-loader) 来自动生成"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    },
    {
      "name": "max-height",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the maximum height for the component.",
        "ja": "コンテンツの高さの最大値を設定します。",
        "zh-Hans": "设定组件的最大高度。"
      }
    },
    {
      "name": "max-width",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the maximum width for the component.",
        "ja": "コンテンツの横幅の最大値を設定します。",
        "zh-Hans": "设定组件的最大宽度。"
      }
    },
    {
      "name": "min-height",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the minimum height for the component.",
        "ja": "コンテンツの高さの最小値を設定します。",
        "zh-Hans": "设定组件的最小高度。"
      }
    },
    {
      "name": "min-width",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the minimum width for the component.",
        "ja": "コンテンツの横幅の最小値を設定します。",
        "zh-Hans": "设定组件的最小宽度。"
      }
    },
    {
      "name": "options",
      "type": "object",
      "default": {},
      "source": "v-img",
      "description": {
        "en": "Options that are passed to the [Intersection observer](https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API) constructor.",
        "ja": "[Intersection observer](https://developer.mozilla.org/ja/docs/Web/API/Intersection_Observer_API) コンストラクターに渡されるオプション。",
        "zh-Hans": "传递给 [Intersection observer](https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API) 构造函数的选项。"
      }
    },
    {
      "name": "position",
      "type": "string",
      "default": "'center center'",
      "source": "v-img",
      "description": {
        "en": "Overrides the default to change which parts get cropped off. Uses the same syntax as [`background-position`](https://developer.mozilla.org/en-US/docs/Web/CSS/background-position)",
        "ja": "切り取られるパーツを変更するには、既定値をオーバーライドします。[`background-position`](https://developer.mozilla.org/ja/docs/Web/CSS/background-position)と同じ構文を使用します。",
        "zh-Hans": "覆盖默认设置，以更改被裁剪的部分。 使用与以下相同的语法 [`background-position`](https://developer.mozilla.org/en-US/docs/Web/CSS/background-position)"
      }
    },
    {
      "name": "sizes",
      "type": "string",
      "default": "undefined",
      "source": "v-img",
      "description": {
        "en": "For use with `srcset`, see [MDN](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#attr-sizes)",
        "ja": "`srcset`で使用するには、[MDN](https://developer.mozilla.org/ja/docs/Web/HTML/Element/img#attr-sizes) を参照してください。",
        "zh-Hans": "使用 `srcset`，请参见 [MDN](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#attr-sizes)"
      }
    },
    {
      "name": "src",
      "type": [
        "string",
        "object"
      ],
      "default": "undefined",
      "source": "v-img",
      "description": {
        "en": "The image URL. This prop is mandatory",
        "ja": "画像の URL です。このプロパティは必須です。",
        "zh-Hans": "图像的 URL。这个属性是强制性的"
      }
    },
    {
      "name": "srcset",
      "type": "string",
      "default": "undefined",
      "source": "v-img",
      "description": {
        "en": "A set of alternate images to use based on device size. [Read more...](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#attr-srcset)",
        "ja": "デバイスのサイズに基づいて使用する代替画像のセット。 [続きを読む...](https://developer.mozilla.org/ja/docs/Web/HTML/Element/img#attr-srcset)",
        "zh-Hans": "根据设备大小使用的一组备用图像。[Read more...](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#attr-srcset)"
      }
    },
    {
      "name": "transition",
      "type": [
        "boolean",
        "string"
      ],
      "default": "fade-transition",
      "source": "v-img",
      "description": {
        "en": "The transition to use when switching from `lazy-src` to `src`",
        "ja": "`lazy-src` から `src` へ切り替える際に使用するトランジション",
        "zh-Hans": "从 `lazy-src` 切换到 `src` 时要使用的过渡"
      }
    },
    {
      "name": "width",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the width for the component.",
        "ja": "コンポーネントに横幅を設定します。",
        "zh-Hans": "设定组件的宽度。"
      }
    }
  ],
  "mixins": [
    "measurable",
    "themeable",
    "v-responsive"
  ],
  "slots": [
    {
      "name": "default",
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    },
    {
      "name": "placeholder",
      "description": {
        "en": "Slot for image placeholder",
        "ja": "画像プレースホルダーのスロット",
        "zh-Hans": "图像占位符的插槽"
      }
    }
  ],
  "events": [
    {
      "name": "error",
      "value": "object | string",
      "description": {
        "en": "Emitted when there is an error",
        "ja": "エラーがあった場合に発生します。",
        "zh-Hans": "当出现错误时发出"
      }
    },
    {
      "name": "load",
      "value": "object | string",
      "description": {
        "en": "Emitted when image is loaded",
        "ja": "画像が読み込まれたときに発生します",
        "zh-Hans": "在加载图像时发出"
      }
    },
    {
      "name": "loadstart",
      "value": "string",
      "description": {
        "en": "Emitted when the image starts to load",
        "ja": "画像の読み込みを開始したときに発生します。",
        "zh-Hans": "当图像开始加载时触发"
      }
    }
  ],
  "functions": [],
  "name": "v-img",
  "sass": [
    {
      "name": "$img-preload-filter",
      "default": "blur(2px) !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "component": true
}