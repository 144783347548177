module.exports = {
  "props": [
    {
      "name": "align-self",
      "type": "string",
      "default": "undefined",
      "source": "v-col",
      "description": {
        "en": "Applies the [align-items](https://developer.mozilla.org/en-US/docs/Web/CSS/align-items) css property. Available options are **start**, **center**, **end**, **auto**, **baseline** and **stretch**.",
        "ja": "[align-items](https://developer.mozilla.org/ja/docs/Web/CSS/align-items) cssプロパティを適用します。使用可能なオプションは、 **start** 、 **center** 、 **end** 、 **auto** 、 **baseline** および **stretch** です。",
        "zh-Hans": "应用 [align-items](https://developer.mozilla.org/en-US/docs/Web/CSS/align-items) css 属性。可用的选项是**start**, **center**, **end**, **auto**, **baseline** 和 **stretch**。"
      }
    },
    {
      "name": "cols",
      "type": [
        "boolean",
        "string",
        "number"
      ],
      "default": false,
      "source": "v-col",
      "description": {
        "en": "Sets the default number of columns the component extends. Available options are **1 -> 12** and **auto**.",
        "ja": "コンポーネントが拡張するカラムのデフォルト数を設定します。利用可能なオプションは **1 -> 12** および **auto** です。",
        "zh-Hans": "设置组件扩展的默认列数。可用的选项是 **1 -> 12** 和 **auto** 。"
      }
    },
    {
      "name": "lg",
      "type": [
        "boolean",
        "string",
        "number"
      ],
      "default": false,
      "source": "v-col",
      "description": {
        "en": "Changes the number of columns on large and greater breakpoints.",
        "ja": "画面が、largeサイズとgreaterサイズのときのbreakpointで表示する列の数を変更します。",
        "zh-Hans": "在更大和更大的断点上更改列数。"
      }
    },
    {
      "name": "md",
      "type": [
        "boolean",
        "string",
        "number"
      ],
      "default": false,
      "source": "v-col",
      "description": {
        "en": "Changes the number of columns on medium and greater breakpoints.",
        "ja": "画面がmediumとgreaterサイズのときのbreakpointで表示する列の数を変更します。",
        "zh-Hans": "更改中等和较大断点上的列数。"
      }
    },
    {
      "name": "offset",
      "type": [
        "string",
        "number"
      ],
      "default": "undefined",
      "source": "v-col",
      "description": {
        "en": "Sets the default offset for the column.",
        "ja": "カラムの既定のオフセットを設定します。",
        "zh-Hans": "设置列的默认偏移量。"
      }
    },
    {
      "name": "offset-lg",
      "type": [
        "string",
        "number"
      ],
      "default": "undefined",
      "source": "v-col",
      "description": {
        "en": "Changes the offset of the component on large and greater breakpoints.",
        "ja": "画面が、largeサイズとgreaterサイズのときのbreakpointでのコンポーネントのオフセット値を変更します。",
        "zh-Hans": "在更大和更大的断点上更改组件的偏移量。"
      }
    },
    {
      "name": "offset-md",
      "type": [
        "string",
        "number"
      ],
      "default": "undefined",
      "source": "v-col",
      "description": {
        "en": "Changes the offset of the component on medium and greater breakpoints.",
        "ja": "画面が、mediumサイズとgreaterサイズのときのbreakpointでのコンポーネントのオフセット値を変更します。",
        "zh-Hans": "在中等和更高的断点上更改组件的偏移量。"
      }
    },
    {
      "name": "offset-sm",
      "type": [
        "string",
        "number"
      ],
      "default": "undefined",
      "source": "v-col",
      "description": {
        "en": "Changes the offset of the component on small and greater breakpoints.",
        "ja": "画面が、smallサイズとgreaterサイズのときのbreakpointでのコンポーネントのオフセット値を変更します。",
        "zh-Hans": "在较小和较大的断点处更改组件的偏移量。"
      }
    },
    {
      "name": "offset-xl",
      "type": [
        "string",
        "number"
      ],
      "default": "undefined",
      "source": "v-col",
      "description": {
        "en": "Changes the offset of the component on extra large and greater breakpoints.",
        "ja": "画面が、extra largeサイズとgreaterサイズのときのbreakpointでのコンポーネントのオフセット値を変更します。",
        "zh-Hans": "在更大和更大的断点处更改组件的偏移量。"
      }
    },
    {
      "name": "order",
      "type": [
        "string",
        "number"
      ],
      "default": "undefined",
      "source": "v-col",
      "description": {
        "en": "Sets the default [order](https://developer.mozilla.org/en-US/docs/Web/CSS/order) for the column.",
        "ja": "カラムのデフォルト [order](https://developer.mozilla.org/ja/docs/Web/CSS/order) を設定します。",
        "zh-Hans": "设置列的默认 [order](https://developer.mozilla.org/en-US/docs/Web/CSS/order)"
      }
    },
    {
      "name": "order-lg",
      "type": [
        "string",
        "number"
      ],
      "default": "undefined",
      "source": "v-col",
      "description": {
        "en": "Changes the order of the component on large and greater breakpoints.",
        "ja": "画面が、largeサイズとgreaterサイズのときのbreakpointでのコンポーネントの表示順を変更します。",
        "zh-Hans": "在更大和更大的断点上更改组件的顺序。"
      }
    },
    {
      "name": "order-md",
      "type": [
        "string",
        "number"
      ],
      "default": "undefined",
      "source": "v-col",
      "description": {
        "en": "Changes the order of the component on medium and greater breakpoints.",
        "ja": "画面が、mediumサイズとgreaterサイズのときのbreakpointでのコンポーネントの表示順を変更します。",
        "zh-Hans": "在中等和更高的断点上更改组件的顺序。"
      }
    },
    {
      "name": "order-sm",
      "type": [
        "string",
        "number"
      ],
      "default": "undefined",
      "source": "v-col",
      "description": {
        "en": "Changes the order of the component on small and greater breakpoints.",
        "ja": "画面が、smallサイズとgreaterサイズのときのbreakpointでのコンポーネントの表示順を変更します。",
        "zh-Hans": "在较小和较大的断点上更改组件的顺序。"
      }
    },
    {
      "name": "order-xl",
      "type": [
        "string",
        "number"
      ],
      "default": "undefined",
      "source": "v-col",
      "description": {
        "en": "Changes the order of the component on extra large and greater breakpoints.",
        "ja": "画面が、extra largeサイズとgreaterサイズのときのbreakpointでのコンポーネントの表示順を変更します。",
        "zh-Hans": "在更大和更大的断点上更改组件的顺序。"
      }
    },
    {
      "name": "sm",
      "type": [
        "boolean",
        "string",
        "number"
      ],
      "default": false,
      "source": "v-col",
      "description": {
        "en": "Changes the number of columns on small and greater breakpoints.",
        "ja": "画面が、smallサイズとgreaterサイズのbreakpointで表示する列の数を変更します。",
        "zh-Hans": "在较小和较大的断点上更改列数。"
      }
    },
    {
      "name": "tag",
      "type": "string",
      "default": "'div'",
      "source": "v-col",
      "description": {
        "en": "Specify a custom tag used on the root element.",
        "ja": "コンポーネント内で使用するカスタムタグを指定します。",
        "zh-Hans": "指定在根元素上使用的自定义标签。"
      }
    },
    {
      "name": "xl",
      "type": [
        "boolean",
        "string",
        "number"
      ],
      "default": false,
      "source": "v-col",
      "description": {
        "en": "Changes the number of columns on extra large and greater breakpoints.",
        "ja": "画面が、extra largeサイズとgreaterサイズのときのbreakpointで表示する列の数を変更します。",
        "zh-Hans": "在更大和更大的断点上更改列数。"
      }
    }
  ],
  "mixins": [],
  "slots": [
    {
      "name": "default",
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    }
  ],
  "events": [],
  "functions": [],
  "name": "v-col",
  "sass": [],
  "component": true
}