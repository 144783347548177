module.exports = {
  "props": [
    {
      "name": "active-class",
      "type": "string",
      "default": "'v-window-item--active'",
      "source": "v-window",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "continuous",
      "type": "boolean",
      "default": "true",
      "source": "v-window",
      "description": {
        "en": "Determines whether carousel is continuous",
        "ja": "カルーセルが連続するかどうかを決定します。",
        "zh-Hans": "确定轮播是否连续"
      }
    },
    {
      "name": "cycle",
      "type": "boolean",
      "default": "false",
      "source": "v-carousel",
      "description": {
        "en": "Determines if the carousel should cycle through images.",
        "ja": "カルーセルをループさせるかを指定します。",
        "zh-Hans": "确定轮播是否应该循环显示图像。"
      }
    },
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにダークテーマを適応します。詳しく知りたい場合は、Material Design documentationの<a href=\"https://material.io/design/color/dark-theme.html\">dark themes</a>を参照してください。",
        "zh-Hans": "将暗色主题变量应用到组件。你可以在 [dark themes](https://material.io/design/color/dark-theme.html) 的 Material Design 文档中找到更多有关信息。"
      }
    },
    {
      "name": "delimiter-icon",
      "type": "string",
      "default": "'$delimiter'",
      "source": "v-carousel",
      "description": {
        "en": "Sets icon for carousel delimiter",
        "ja": "Carousel のデリミターとして使用するアイコンを設定します。",
        "zh-Hans": "设置轮播分隔符的图标"
      }
    },
    {
      "name": "height",
      "type": [
        "number",
        "string"
      ],
      "default": 500,
      "source": "v-carousel",
      "description": {
        "en": "Sets the height for the component",
        "ja": "コンポーネントの高さを設定します",
        "zh-Hans": "设置组件高度"
      }
    },
    {
      "name": "hide-delimiter-background",
      "type": "boolean",
      "default": "false",
      "source": "v-carousel",
      "description": {
        "en": "Hides the bottom delimiter background.",
        "ja": "下の区切り文字の背景を非表示にします。",
        "zh-Hans": "隐藏底部分隔符的背景。"
      }
    },
    {
      "name": "hide-delimiters",
      "type": "boolean",
      "default": "false",
      "source": "v-carousel",
      "description": {
        "en": "Hides the carousel's bottom delimiters.",
        "ja": "カルーセルの下の区切り記号を非表示にします。",
        "zh-Hans": "隐藏轮播的底部分隔符。"
      }
    },
    {
      "name": "interval",
      "type": [
        "number",
        "string"
      ],
      "default": 6000,
      "source": "v-carousel",
      "description": {
        "en": "The duration between image cycles. Requires the **cycle** prop.",
        "ja": "スライドがサイクルする間の持続時間。**cycle** propが必要です。",
        "zh-Hans": "轮播图像周期之间的持续时间。需要 **cycle** 属性。"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    },
    {
      "name": "mandatory",
      "type": "boolean",
      "default": "true",
      "source": "v-window",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "max",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "v-window",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "multiple",
      "type": "boolean",
      "default": "false",
      "source": "v-window",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "next-icon",
      "type": [
        "boolean",
        "string"
      ],
      "default": "$next",
      "source": "v-window",
      "description": {
        "en": "The displayed icon for forcing pagination to the next item.",
        "ja": "次のアイテムにページネーションを強制するための表示アイコン。",
        "zh-Hans": "强制对下一项分页而显示的图标。"
      }
    },
    {
      "name": "prev-icon",
      "type": [
        "boolean",
        "string"
      ],
      "default": "$prev",
      "source": "v-window",
      "description": {
        "en": "The displayed icon for forcing pagination to the previous item.",
        "ja": "前のアイテムにページネーションを強制するための表示アイコン。",
        "zh-Hans": "强制对前一项分页而显示的图标。"
      }
    },
    {
      "name": "progress",
      "type": "boolean",
      "default": "false",
      "source": "v-carousel",
      "description": {
        "en": "Displays a carousel progress bar. Requires the **cycle** prop and **interval**.",
        "ja": "カルーセルのプログレスバーを表示します。**cycle** prop および **interval** の設定が必要です。",
        "zh-Hans": "显示轮播进度条。需要 **cycle** 和 **interval** 属性。"
      }
    },
    {
      "name": "progress-color",
      "type": "string",
      "default": "undefined",
      "source": "v-carousel",
      "description": {
        "en": "Applies specified color to progress bar.",
        "ja": "指定した色をプログレス バーに適用します。",
        "zh-Hans": "将指定的颜色应用于进度条。"
      }
    },
    {
      "name": "reverse",
      "type": "boolean",
      "default": "false",
      "source": "v-window",
      "description": {
        "en": "Reverse the normal transition direction.",
        "ja": "トランジションの方向を逆にします。",
        "zh-Hans": "反转正常的过渡方向。"
      }
    },
    {
      "name": "show-arrows",
      "type": "boolean",
      "default": "true",
      "source": "v-window",
      "description": {
        "en": "Displays arrows for next/previous navigation.",
        "ja": "前後ナビゲーションの矢印を表示します。",
        "zh-Hans": "显示 下一个/前一个 导航的箭头。"
      }
    },
    {
      "name": "show-arrows-on-hover",
      "type": "boolean",
      "default": "false",
      "source": "v-window",
      "description": {
        "en": "Displays navigation arrows only when the carousel is hovered over.",
        "ja": "カルーセルがホバーされている場合のみ、ナビゲーションの矢印を表示します。",
        "zh-Hans": "仅当鼠标悬停到轮播其上时才显示导航箭头。"
      }
    },
    {
      "name": "tag",
      "type": "string",
      "default": "'div'",
      "source": "v-window",
      "description": {
        "en": "Specify a custom tag used on the root element.",
        "ja": "コンポーネント内で使用するカスタムタグを指定します。",
        "zh-Hans": "指定在根元素上使用的自定义标签。"
      }
    },
    {
      "name": "touch",
      "type": "object",
      "default": "undefined",
      "source": "v-window",
      "description": {
        "en": "Provide a custom **left** and **right** function when swiped left or right.",
        "ja": "左または右にスワイプされたときの、カスタムの**left**および**right**関数を提供します。",
        "zh-Hans": "左右滑动时提供自定义的 **left** 和 **right** 函数。"
      }
    },
    {
      "name": "touchless",
      "type": "boolean",
      "default": "false",
      "source": "v-window",
      "description": {
        "en": "Disable touch support.",
        "ja": "タッチ サポートを無効にします。",
        "zh-Hans": "禁用触摸支持."
      }
    },
    {
      "name": "value",
      "type": "any",
      "default": "undefined",
      "source": "proxyable",
      "description": {
        "en": "The designated model value for the component.",
        "ja": "コンポーネントの指定されたモデルの値。",
        "zh-Hans": "组件的指定 model 值。"
      }
    },
    {
      "name": "value-comparator",
      "type": "function",
      "default": "null",
      "source": "comparable",
      "description": {
        "en": "Apply a custom value comparator function",
        "ja": "カスタムの値の比較関数を適用します",
        "zh-Hans": "应用自定义的 comparator function"
      }
    },
    {
      "name": "vertical",
      "type": "boolean",
      "default": "false",
      "source": "v-window",
      "description": {
        "en": "Uses a vertical transition when changing windows.",
        "ja": "Uses a vertical transition when changing windows.",
        "zh-Hans": "改变窗口时使用垂直转换."
      }
    },
    {
      "name": "vertical-delimiters",
      "type": "string",
      "default": "undefined",
      "source": "v-carousel",
      "description": {
        "en": "Displays carousel delimiters vertically.",
        "ja": "カルーセル区切り記号を縦方向に表示します。",
        "zh-Hans": "垂直显示轮播的分隔符。"
      }
    }
  ],
  "mixins": [
    "comparable",
    "proxyable",
    "themeable"
  ],
  "slots": [
    {
      "name": "default",
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    },
    {
      "name": "next",
      "props": {
        "attrs": "{ aria-label: string }",
        "on": "{ click: eventHandler }"
      },
      "source": "v-window",
      "description": {
        "en": "Slot displaying the arrow switching to the next item",
        "ja": "Slot displaying the arrow switching to the next item",
        "zh-Hans": "显示箭头切换到下一件物品的槽位"
      }
    },
    {
      "name": "prev",
      "props": {
        "attrs": "{ aria-label: string }",
        "on": "{ click: eventHandler }"
      },
      "source": "v-window",
      "description": {
        "en": "Slot displaying the arrow switching to the previous item",
        "ja": "Slot displaying the arrow switching to the previous item",
        "zh-Hans": "显示箭头切换到下一件物品的槽位"
      }
    }
  ],
  "events": [
    {
      "name": "change",
      "value": "number",
      "description": {
        "en": "Emitted when the component value is changed by user interaction",
        "ja": "コンポーネントの値がユーザー操作によって変更されたときに発生します。",
        "zh-Hans": "当组件值被用户交互改变时发出。"
      }
    }
  ],
  "functions": [],
  "name": "v-carousel",
  "sass": [
    {
      "name": "$carousel-controls-bg",
      "default": "rgba(0, 0, 0, .3) !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$carousel-controls-size",
      "default": "50px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$carousel-dot-margin",
      "default": "0 8px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$carousel-dot-inactive-opacity",
      "default": ".5 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$carousel-dot-active-opacity",
      "default": "1 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$carousel-dot-hover-opacity",
      "default": ".8 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "component": true
}