module.exports = {
  "props": [
    {
      "name": "append-icon",
      "type": "string",
      "default": "undefined",
      "source": "v-input",
      "description": {
        "en": "Appends an icon to the component, uses the same syntax as `v-icon`",
        "ja": "`v-icon`と同じ構文を使用し、コンポーネントにアイコンを追加します",
        "zh-Hans": "在组件上附加一个图标，使用与 `v-icon` 相同的语法"
      }
    },
    {
      "name": "append-outer-icon",
      "type": "string",
      "default": "undefined",
      "source": "v-text-field",
      "description": {
        "en": "Appends an icon to the outside the component's input, uses same syntax as `v-icon`",
        "ja": "`v-icon`と同じ構文を使用して、入力コンポーネント外側の後方にアイコンを追加します",
        "zh-Hans": "在组件的外部添加一个图标，使用与 `v-icon` 相同的语法"
      }
    },
    {
      "name": "autofocus",
      "type": "boolean",
      "default": "false",
      "source": "v-text-field",
      "description": {
        "en": "Enables autofocus",
        "ja": "オートフォーカスを有効にします。",
        "zh-Hans": "启用自动聚焦"
      }
    },
    {
      "name": "background-color",
      "type": "string",
      "default": "undefined",
      "source": "v-input",
      "description": {
        "en": "Changes the background-color of the input",
        "ja": "inputの背景色を変更します",
        "zh-Hans": "更改输入的背景颜色"
      }
    },
    {
      "name": "chips",
      "type": "boolean",
      "default": "false",
      "source": "v-file-input",
      "description": {
        "en": "Changes display of selections to chips",
        "ja": "選択項目の表示をチップに変更",
        "zh-Hans": "改变一个已选择项为小纸片（chips）的显示方式"
      }
    },
    {
      "name": "clear-icon",
      "type": "string",
      "default": "'$clear'",
      "source": "v-text-field",
      "description": {
        "en": "Applied when using **clearable** and the input is dirty",
        "ja": "Applied when using **clearable** and the input is dirty",
        "zh-Hans": "当使用 **clearable** 且有输入值时应用"
      }
    },
    {
      "name": "clearable",
      "type": "boolean",
      "default": "true",
      "source": "v-text-field",
      "description": {
        "en": "Add input clear functionality, default icon is Material Design Icons **mdi-clear**",
        "ja": "入力クリア機能を追加します（デフォルトのアイコンは、Material Icons の **mdi-clear** ）",
        "zh-Hans": "添加清除已输入内容功能，默认图标是Material Design Icons **mdi-clear**"
      }
    },
    {
      "name": "color",
      "type": "string",
      "default": "undefined",
      "source": "colorable",
      "description": {
        "en": "Applies specified color to the control - it can be the name of material color (for example `success` or `purple`) or css color (`#033` or `rgba(255, 0, 0, 0.5)`). You can find a list of built-in classes on the [colors page](/styles/colors#material-colors).",
        "ja": "指定された色をコントロールに適用します。利用できるのはマテリアルカラーの名前 (たとえば、`success` または ` purple`) または css 表記の色 (`#033`または` rgba(255, 0, 0, 0.5) `) です。[colors のページ](/styles/colors#material-colors) で、組み込みクラスのリストが確認できます。",
        "zh-Hans": "指定控件颜色，颜色值可以是 Material 颜色（例如 <code>success</code>或者 <code>purple</code> ）也可以是 Css 颜色 （例如 <code>#033</code> 或者 <code>rgba(255, 0, 0, 0.5)</code>）。在 [colors page](/zh-Hans/styles/colors#material-colors)中可以找到内置颜色类列表。"
      }
    },
    {
      "name": "counter",
      "type": [
        "boolean",
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "v-text-field",
      "description": {
        "en": "Creates counter for input length; if no number is specified, it defaults to 25. Does not apply any validation.",
        "ja": "入力長のカウンタを作成します。数値が指定されていない場合、デフォルトは 25 になります。バリデーションは適用しません。",
        "zh-Hans": "为输入长度创建一个计数器，如果未指定数字，则默认为25，不会应用任何验证。"
      }
    },
    {
      "name": "counter-size-string",
      "type": "string",
      "default": "'$vuetify.fileInput.counterSize'",
      "source": "v-file-input",
      "description": {
        "en": "The text displayed when using the **counter** and **show-size** props. Can also be customized globally on the [internationalization page](/customization/internationalization).",
        "ja": "**counter**および **show-size** propを使用するときに表示されるテキスト。 [国際化](/customization/internationalization) 設定でグローバルにカスタマイズすることもできます。",
        "zh-Hans": "使用 **counter** 和 **show-size** 属性时显示的文本。也可以在 [internationalization page](/customization/internationalization) 上进行全局定制。"
      }
    },
    {
      "name": "counter-string",
      "type": "string",
      "default": "'$vuetify.fileInput.counter'",
      "source": "v-file-input",
      "description": {
        "en": "The text displayed when using the **counter** prop. Can also be customized globally on the [internationalization page](/customization/internationalization).",
        "ja": "**counter** propを使用するときに表示されるテキスト。 [国際化](/customization/internationalization) 設定でグローバルにカスタマイズすることもできます。",
        "zh-Hans": "使用 **counter** 属性时显示的文字。 也可以在 [internationalization page](/customization/internationalization) 上进行全局自定义。"
      }
    },
    {
      "name": "counter-value",
      "type": "function",
      "default": "null",
      "source": "v-text-field",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. This will default the components color to _white_ unless you've configured your [application theme](/customization/theme) to **dark** or if you are using the **color** prop on the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにdarkテーマのバリエーションを適用します。[アプリケーションのテーマ](/customization/theme) を **dark** に設定している場合や、コンポーネントに **color** propを使用している場合を除き、コンポーネントの色はデフォルトで _white_ になります。Material Design documentationの[ダークテーマ](https://material.io/design/color/dark-theme.html) に詳しい情報があります。",
        "zh-Hans": "将深色主题变量应用于组件。 除非您已将 [application theme](/customization/theme) 配置为 **dark** 或在组件上使用 **color** 属性，否则组件的颜色默认为 _white_。 您可以在材料设计文档中找到更多信息 [dark themes](https://material.io/design/color/dark-theme.html)。"
      }
    },
    {
      "name": "dense",
      "type": "boolean",
      "default": "false",
      "source": "v-input",
      "description": {
        "en": "Reduces the input height",
        "ja": "inputの高さを低くします",
        "zh-Hans": "降低输入高度"
      }
    },
    {
      "name": "disabled",
      "type": "boolean",
      "default": false,
      "source": "validatable",
      "description": {
        "en": "Disable the input",
        "ja": "入力を無効にする",
        "zh-Hans": "禁用输入"
      }
    },
    {
      "name": "error",
      "type": "boolean",
      "default": "false",
      "source": "validatable",
      "description": {
        "en": "Puts the input in a manual error state",
        "ja": "入力を手動でエラー状態にします。",
        "zh-Hans": "将输入框设置为手动错误状态。"
      }
    },
    {
      "name": "error-count",
      "type": [
        "number",
        "string"
      ],
      "default": 1,
      "source": "validatable",
      "description": {
        "en": "The total number of errors that should display at once",
        "ja": "一度に表示するエラーの数を設定します。",
        "zh-Hans": "一次性显示的错误总数"
      }
    },
    {
      "name": "error-messages",
      "type": [
        "string",
        "array"
      ],
      "default": [],
      "source": "validatable",
      "description": {
        "en": "Puts the input in an error state and passes through custom error messages. Will be combined with any validations that occur from the **rules** prop. This field will not trigger validation",
        "ja": "入力をerror状態にして、カスタムエラーメッセージをパススルーします。**rules**  propから発生するすべてのバリデーションと組み合わせられます。このフィールドはバリデーションのトリガーにはなりません。",
        "zh-Hans": "将输入框置于错误状态，并传入自定义的错误信息。将与来自 **rules** 属性的任何验证相结合。这个字段不会触发验证。"
      }
    },
    {
      "name": "filled",
      "type": "boolean",
      "default": "false",
      "source": "v-text-field",
      "description": {
        "en": "Applies the alternate filled input style",
        "ja": "代替のfilled入力スタイルを適用します",
        "zh-Hans": "应用替代填充输入样式"
      }
    },
    {
      "name": "flat",
      "type": "boolean",
      "default": "false",
      "source": "v-text-field",
      "description": {
        "en": "Removes elevation (shadow) added to element when using the **solo** or **solo-inverted** props",
        "ja": "**solo**または**solo-inverted** propを使用するときに要素に追加されたエレベーション（影）を削除します。",
        "zh-Hans": "当使用**solo**或者**solo-inverted**属性时，移除添加到元素的标高（阴影）"
      }
    },
    {
      "name": "full-width",
      "type": "boolean",
      "default": "false",
      "source": "v-text-field",
      "description": {
        "en": "Designates input type as full-width",
        "ja": "Designates input type as full-width",
        "zh-Hans": "指定输入类型为全宽度"
      }
    },
    {
      "name": "height",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "v-input",
      "description": {
        "en": "Sets the height of the input",
        "ja": "高さを設定します",
        "zh-Hans": "设置输入的高度"
      }
    },
    {
      "name": "hide-details",
      "type": [
        "boolean",
        "string"
      ],
      "default": "undefined",
      "source": "v-input",
      "description": {
        "en": "Hides hint and validation errors. When set to `auto` messages will be rendered only if there's a message (hint, error message, counter value etc) to display",
        "ja": "ヒントやバリデーションエラーを非表示にします。 `auto` が設定されている場合、表示するメッセージ（ヒント、エラーメッセージ、カウンター値など）がある場合のみ表示します",
        "zh-Hans": "隐藏提示和验证错误。当设置为 `auto` 时，只有在有信息（提示、错误信息、计数器值等）要显示时，才会显示信息。"
      }
    },
    {
      "name": "hide-input",
      "type": "boolean",
      "default": "false",
      "source": "v-file-input",
      "description": {
        "en": "Display the icon only without the input (file names)",
        "ja": "input (ファイル名) なしでアイコンのみを表示する",
        "zh-Hans": "只显示图标(不显示输入框和文件名)"
      }
    },
    {
      "name": "hint",
      "type": "string",
      "default": "undefined",
      "source": "v-input",
      "description": {
        "en": "Hint text",
        "ja": "ヒントを設定します。",
        "zh-Hans": "提示文本"
      }
    },
    {
      "name": "id",
      "type": "string",
      "default": "undefined",
      "source": "v-input",
      "description": {
        "en": "Sets the DOM id on the component",
        "ja": "コンポーネントにDOM idを設定します",
        "zh-Hans": "设置组件上的 DOM id"
      }
    },
    {
      "name": "label",
      "type": "string",
      "source": "v-input",
      "description": {
        "en": "Sets input label",
        "ja": "ラベルを設定します。",
        "zh-Hans": "设置输入标签"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    },
    {
      "name": "loader-height",
      "type": [
        "number",
        "string"
      ],
      "default": 2,
      "source": "loadable",
      "description": {
        "en": "Specifies the height of the loader",
        "ja": "loader の高さを指定します。",
        "zh-Hans": "指定加载器的高度"
      }
    },
    {
      "name": "loading",
      "type": [
        "boolean",
        "string"
      ],
      "default": false,
      "source": "v-input",
      "description": {
        "en": "Displays linear progress bar. Can either be a String which specifies which color is applied to the progress bar (any material color or theme color - **primary**, **secondary**, **success**, **info**, **warning**, **error**) or a Boolean which uses the component **color** (set by color prop - if it's supported by the component) or the primary color",
        "ja": "線形プログレス バーを表示します。 進行状況バー (任意のマテリアルカラーまたはテーマカラー - **primary**, **secondary**, **success**, **info**, **warning**, **error**) に適用する色を指定する文字列、あるいはコンポーネント **color** (color propで設定 - コンポーネントでサポートされている場合) またはprimary色を使用する真偽値を指定できます。",
        "zh-Hans": "显示线性进度条。可以是指定将哪种颜色应用于进度条的字符串（任何 material 色彩——主要（primary）, 次要（secondary）, 成功（success）, 信息（info），警告（warning），错误（error）），或者使用组件的布尔值 **color**（由色彩属性设置——如果它被组件支持的话）还可以是原色。"
      }
    },
    {
      "name": "messages",
      "type": [
        "string",
        "array"
      ],
      "default": [],
      "source": "validatable",
      "description": {
        "en": "Displays a list of messages or message if using a string",
        "ja": "文字列を使用している場合は、メッセージまたはメッセージの一覧を表示します。",
        "zh-Hans": "显示消息列表或消息（如果使用字符串）"
      }
    },
    {
      "name": "multiple",
      "type": "boolean",
      "default": "false",
      "source": "v-file-input",
      "description": {
        "en": "Adds the **multiple** attribute to the input, allowing multiple file selections.",
        "ja": "**multiple** 属性を追加し、複数のファイルを選択できるようにします。",
        "zh-Hans": "将 **multiple** 属性添加到输入中，允许选择多个文件。"
      }
    },
    {
      "name": "outlined",
      "type": "boolean",
      "default": "false",
      "source": "v-text-field",
      "description": {
        "en": "Applies the outlined style to the input",
        "ja": "アウトラインスタイルを適用します。",
        "zh-Hans": "将轮廓样式应用于输入"
      }
    },
    {
      "name": "persistent-hint",
      "type": "boolean",
      "default": "false",
      "source": "v-input",
      "description": {
        "en": "Forces hint to always be visible",
        "ja": "ヒントを常に表示します",
        "zh-Hans": "强制提示总是可见的"
      }
    },
    {
      "name": "persistent-placeholder",
      "type": "boolean",
      "default": "false",
      "source": "v-text-field",
      "description": {
        "en": "Forces placeholder to always be visible",
        "ja": "Forces placeholder to always be visible",
        "zh-Hans": "强制提示总是可见的"
      }
    },
    {
      "name": "placeholder",
      "type": "string",
      "default": "undefined",
      "source": "v-text-field",
      "description": {
        "en": "Sets the input's placeholder text",
        "ja": "プレースホルダーを設定します",
        "zh-Hans": "设置输入的占位符文本"
      }
    },
    {
      "name": "prefix",
      "type": "string",
      "default": "undefined",
      "source": "v-text-field",
      "description": {
        "en": "Displays prefix text",
        "ja": "前につけるプレフィックステキストを表示します",
        "zh-Hans": "显示前缀"
      }
    },
    {
      "name": "prepend-icon",
      "type": "string",
      "default": "'$file'",
      "source": "v-input",
      "description": {
        "en": "Prepends an icon to the component, uses the same syntax as `v-icon`",
        "ja": "コンポーネントの先頭にアイコンが追加されます。`v-icon` と同じ構文を使用します。",
        "zh-Hans": "在组件前添加一个图标，使用与 `v-icon` 相同的语法"
      }
    },
    {
      "name": "prepend-inner-icon",
      "type": "string",
      "default": "undefined",
      "source": "v-text-field",
      "description": {
        "en": "Prepends an icon inside the component's input, uses the same syntax as `v-icon`",
        "ja": "`v-icon`と同じ構文を使用して、入力コンポーネント内の先頭にアイコンを追加します",
        "zh-Hans": "在组件的输入中添加一个图标，使用与 `v-icon` 相同的语法"
      }
    },
    {
      "name": "reverse",
      "type": "boolean",
      "default": "false",
      "source": "v-text-field",
      "description": {
        "en": "Reverses the input orientation",
        "ja": "入力方向を反転します。",
        "zh-Hans": "反转输入方向"
      }
    },
    {
      "name": "rounded",
      "type": "boolean",
      "default": "false",
      "source": "v-text-field",
      "description": {
        "en": "Adds a border radius to the input",
        "ja": "Adds a border radius to the input",
        "zh-Hans": "向输入添加边框半径"
      }
    },
    {
      "name": "rules",
      "type": "array",
      "default": [],
      "source": "validatable",
      "description": {
        "en": "Accepts a mixed array of types `function`, `boolean` and `string`. Functions pass an input value as an argument and must return either `true` / `false` or a `string` containing an error message. The input field will enter an error state if a function returns (or any value in the array contains) `false` or is a `string`",
        "ja": "`function`, ` boolean`, `string`の混合配列を受け入れます。関数は入力値を引数として渡し、 `true` /`false`またはエラーメッセージを含む `string`のいずれかを返す必要があります。関数が `false`を返す（または配列内の任意の値に含まれる）場合、または`string`の場合、入力フィールドはエラー状態になります。",
        "zh-Hans": "接受不同类型的 `function`, `boolean` 和 `string` 。 函数传递输入值作为参数，必须返回 `true` / `false` 或包含错误消息的 `string` 。 如果函数返回 (或数组包含的任何值) false` 或 `string` ，输入字段将输入错误状态"
      }
    },
    {
      "name": "shaped",
      "type": "boolean",
      "default": "false",
      "source": "v-text-field",
      "description": {
        "en": "Round if `outlined` and increase `border-radius` if `filled`. Must be used with either `outlined` or `filled`",
        "ja": "Round if `outlined` and increase `border-radius` if `filled`. Must be used with either `outlined` or `filled`",
        "zh-Hans": "如果 `outlined` 则为圆形，如果 `filled` 则增加 `border-radius`。必须与 `outlined` 或 `filled` 一起使用"
      }
    },
    {
      "name": "show-size",
      "type": [
        "boolean",
        "number"
      ],
      "default": false,
      "source": "v-file-input",
      "description": {
        "en": "Sets the displayed size of selected file(s). When using **true** will default to _1000_ displaying (**kB, MB, GB**) while _1024_ will display (**KiB, MiB, GiB**).",
        "ja": "選択したファイルの表示サイズを設定します。**true**を使用している場合、デフォルトでは _1000_ には (**kB、MB、GB**) が表示され、 _1024_ には (**KiB、MiB、GiB**) が表示されます。",
        "zh-Hans": "设置所选文件的显示大小。当使用 **true** 默认值为 _1000_ 显示（**kB，MB，GB**） 时，_1024_ 将显示（**KiB，MiB，GiB**）。"
      }
    },
    {
      "name": "single-line",
      "type": "boolean",
      "default": "false",
      "source": "v-text-field",
      "description": {
        "en": "Label does not move on focus/dirty",
        "ja": "Label does not move on focus/dirty",
        "zh-Hans": "标签在 focus/dirty 上不移动"
      }
    },
    {
      "name": "small-chips",
      "type": "boolean",
      "default": "false",
      "source": "v-file-input",
      "description": {
        "en": "Changes display of selections to chips with the **small** property",
        "ja": "選択表示を **small** プロパティを持つチップに変更します",
        "zh-Hans": "使用 **small** 属性将选择的显示更改为纸片"
      }
    },
    {
      "name": "solo",
      "type": "boolean",
      "default": "false",
      "source": "v-text-field",
      "description": {
        "en": "Changes the style of the input",
        "ja": "入力のスタイルを変更",
        "zh-Hans": "改变输入框的样式"
      }
    },
    {
      "name": "solo-inverted",
      "type": "boolean",
      "default": "false",
      "source": "v-text-field",
      "description": {
        "en": "Reduces element opacity until focused",
        "ja": "フォーカスされるまで要素の不透明度を減らす",
        "zh-Hans": "减少元素的不透明度，直到获得焦点"
      }
    },
    {
      "name": "success",
      "type": "boolean",
      "default": "false",
      "source": "validatable",
      "description": {
        "en": "Puts the input in a manual success state",
        "ja": "入力を手動で成功状態にします。",
        "zh-Hans": "将输入设置为手动成功状态"
      }
    },
    {
      "name": "success-messages",
      "type": [
        "string",
        "array"
      ],
      "default": [],
      "source": "validatable",
      "description": {
        "en": "Puts the input in a success state and passes through custom success messages.",
        "ja": "入力をsuccess状態にして、カスタムの成功メッセージをパススルーさせます。",
        "zh-Hans": "将输入设置为成功状态，并传递自定义成功消息。"
      }
    },
    {
      "name": "suffix",
      "type": "string",
      "default": "undefined",
      "source": "v-text-field",
      "description": {
        "en": "Displays suffix text",
        "ja": "後につけるサフィックステキストを表示します",
        "zh-Hans": "显示后缀"
      }
    },
    {
      "name": "truncate-length",
      "type": [
        "number",
        "string"
      ],
      "default": 22,
      "source": "v-file-input",
      "description": {
        "en": "The length of a filename before it is truncated with ellipsis",
        "ja": "省略記号で切り捨てられる前のファイル名の長さ",
        "zh-Hans": "在用省略号截断之前的文件名的长度"
      }
    },
    {
      "name": "validate-on-blur",
      "type": "boolean",
      "default": "false",
      "source": "validatable",
      "description": {
        "en": "Delays validation until blur event",
        "ja": "blurイベントまでバリデーションを遅らせる",
        "zh-Hans": "延迟验证直到失去焦点的事件被触发"
      }
    },
    {
      "name": "value",
      "type": "any",
      "source": "v-input",
      "description": {
        "en": "A single or array of [File objects](https://developer.mozilla.org/en-US/docs/Web/API/File).",
        "ja": "[File objects](https://developer.mozilla.org/ja/docs/Web/API/File)単体あるいは配列",
        "zh-Hans": "一个或一组 [File objects](https://developer.mozilla.org/en-US/docs/Web/API/File)。"
      }
    }
  ],
  "mixins": [
    "colorable",
    "intersectable",
    "loadable",
    "registrable-inject",
    "themeable",
    "v-input",
    "validatable"
  ],
  "slots": [
    {
      "name": "append",
      "source": "v-input",
      "description": {
        "en": "Adds an item inside the input and after input content",
        "ja": "入力内容と入力内容の後に項目を追加します",
        "zh-Hans": "在输入框内部，文字之后添加一个内容"
      }
    },
    {
      "name": "append-outer",
      "source": "v-text-field",
      "description": {
        "en": "Adds an item outside the input and after input content",
        "ja": "Adds an item outside the input and after input content",
        "zh-Hans": "在输入框外部，文字之后添加一个内容"
      }
    },
    {
      "name": "counter",
      "source": "v-text-field",
      "props": {
        "props": {
          "dark": "boolean",
          "light": "boolean",
          "max": "string | number",
          "value": "string"
        }
      },
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "label",
      "source": "v-input",
      "description": {
        "en": "Replaces the default label",
        "ja": "デフォルトのラベルを置き換えます",
        "zh-Hans": "替换默认标签"
      }
    },
    {
      "name": "message",
      "props": {
        "key": "number, // the messages index",
        "message": "string, // the message"
      },
      "source": "v-input",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "prepend",
      "source": "v-input",
      "description": {
        "en": "Adds an item outside the input and before input content",
        "ja": "入力の外側と入力の内容の前にアイテムを追加します",
        "zh-Hans": "在输入框外部，前面添加一个内容"
      }
    },
    {
      "name": "prepend-inner",
      "source": "v-text-field",
      "description": {
        "en": "Adds an item inside the input and before input content",
        "ja": "Adds an item inside the input and before input content",
        "zh-Hans": "在输入框内部，前面添加一个内容"
      }
    },
    {
      "name": "progress",
      "source": "v-text-field",
      "description": {
        "en": "Slot for custom progress linear (displayed when **loading** prop is not equal to Boolean False)",
        "ja": "カスタムprogress linearのスロット（**loading** プロパティが真偽値 false でない場合に表示されます）",
        "zh-Hans": "自定义进度线的插槽（**loading**属性不等于布尔值False时显示)"
      }
    },
    {
      "name": "selection",
      "props": {
        "file": "File",
        "index": "number",
        "multiple": "boolean",
        "text": "string"
      },
      "source": "v-file-input",
      "description": {
        "en": "Slot for defining a custom appearance for selected item(s). Provides the current **index**, **text** (truncated) and [file](https://developer.mozilla.org/en-US/docs/Web/API/File).",
        "ja": "選択したアイテムのカスタム外観を定義するスロット。現在の **index**, **text** (truncated) および [file](https://developer.mozilla.org/ja/docs/Web/API/File) を提供します。",
        "zh-Hans": "为选定的项目定义自定义外观的插槽。提供当前 **索引**、**文本**（已截断）和 [file](https://developer.mozilla.org/en-US/docs/Web/API/File)。"
      }
    }
  ],
  "events": [
    {
      "name": "blur",
      "source": "v-text-field",
      "value": "Event",
      "description": {
        "en": "Emitted when the input is blurred",
        "ja": "入力を抜けたときに発生します",
        "zh-Hans": "当失去焦点时发出"
      }
    },
    {
      "name": "change",
      "source": "v-text-field",
      "value": "File[]",
      "description": {
        "en": "Emitted when the input is changed by user interaction",
        "ja": "ユーザーの操作によって値が変更されたときに発生します。",
        "zh-Hans": "当用户交互更改输入时发出"
      }
    },
    {
      "name": "click",
      "source": "v-input",
      "value": "MouseEvent",
      "description": {
        "en": "Emitted when input is clicked",
        "ja": "inputをクリックしたときに発生します",
        "zh-Hans": "在单击输入时发出"
      }
    },
    {
      "name": "click:append",
      "source": "v-input",
      "value": "Event",
      "description": {
        "en": "Emitted when appended icon is clicked",
        "ja": "アペンドアイコンがクリックされたときに発生します",
        "zh-Hans": "单击附加图标时发出"
      }
    },
    {
      "name": "click:append-outer",
      "source": "v-text-field",
      "value": "Event",
      "description": {
        "en": "Emitted when appended outer icon is clicked",
        "ja": "Emitted when appended outer icon is clicked",
        "zh-Hans": "单击附加的外部图标时发出"
      }
    },
    {
      "name": "click:clear",
      "source": "v-text-field",
      "value": "Event",
      "description": {
        "en": "Emitted when clearable icon clicked",
        "ja": "クリアアイコンをクリックしたときに発生します。",
        "zh-Hans": "单击可清除图标时发出"
      }
    },
    {
      "name": "click:prepend",
      "source": "v-input",
      "value": "Event",
      "description": {
        "en": "Emitted when prepended icon is clicked",
        "ja": "プリペンドアイコンがクリックされたときに発生します",
        "zh-Hans": "单击前置图标时发出"
      }
    },
    {
      "name": "click:prepend-inner",
      "source": "v-text-field",
      "value": "Event",
      "description": {
        "en": "Emitted when prepended inner icon is clicked",
        "ja": "Emitted when prepended inner icon is clicked",
        "zh-Hans": "单击前置的内部图标时发出"
      }
    },
    {
      "name": "focus",
      "source": "v-text-field",
      "value": "Event",
      "description": {
        "en": "Emitted when component is focused",
        "ja": "コンポーネントがフォーカスされている場合に発生します。",
        "zh-Hans": "组件聚焦时发出"
      }
    },
    {
      "name": "input",
      "source": "v-text-field",
      "value": "any",
      "description": {
        "en": "The updated bound model",
        "ja": "更新されたバウンドモデル",
        "zh-Hans": "被绑定模型的更新"
      }
    },
    {
      "name": "keydown",
      "source": "v-text-field",
      "value": "KeyboardEvent",
      "description": {
        "en": "Emitted when **any** key is pressed",
        "ja": "**any** キーが押されたときに発生します。",
        "zh-Hans": "按下 **任何** 键时发送"
      }
    },
    {
      "name": "mousedown",
      "source": "v-input",
      "value": "MouseEvent",
      "description": {
        "en": "Emitted when click is pressed",
        "ja": "クリック時、押された（pressed）ときに発生します",
        "zh-Hans": "单击时发出"
      }
    },
    {
      "name": "mouseup",
      "source": "v-input",
      "value": "MouseEvent",
      "description": {
        "en": "Emitted when click is released",
        "ja": "クリック後、放された（released）ときに発生します",
        "zh-Hans": "当单击释放时，发出"
      }
    },
    {
      "name": "update:error",
      "source": "validatable",
      "value": "boolean",
      "description": {
        "en": "The `error.sync` event",
        "ja": "`error.sync` イベント",
        "zh-Hans": "`error.sync` 事件"
      }
    }
  ],
  "functions": [],
  "exclude": {
    "props": [
      "type",
      "hide-spin-buttons"
    ]
  },
  "name": "v-file-input",
  "sass": [
    {
      "name": "$file-input-filled-padding-top",
      "default": "22px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$file-input-chip-margin",
      "default": "4px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$file-input-outlined-padding",
      "default": "6px 0 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$file-input-outlined-dense-padding",
      "default": "3px 0 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$file-input-slot-min-height",
      "default": "32px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$file-input-slot-dense-min-height",
      "default": "26px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "component": true
}