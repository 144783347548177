module.exports = {
  "props": [
    {
      "name": "absolute",
      "type": "boolean",
      "default": "false",
      "source": "positionable",
      "description": {
        "en": "Applies **position: absolute** to the component.",
        "ja": "コンポーネントに **position: absolute** を適用します。",
        "zh-Hans": "给组件应用 **position: absolute**"
      }
    },
    {
      "name": "active",
      "type": "boolean",
      "default": "true",
      "source": "v-progress-linear",
      "description": {
        "en": "Reduce the height to 0, hiding component",
        "ja": "高さを0に縮小して、コンポーネントを非表示にします。",
        "zh-Hans": "将高度降低到 0，隐藏组件"
      }
    },
    {
      "name": "background-color",
      "type": "string",
      "default": "undefined",
      "source": "v-progress-linear",
      "description": {
        "en": "Background color, set to component's color if null",
        "ja": "背景色。nullの場合はコンポーネントの色に設定されます。",
        "zh-Hans": "背景色，如果为 null，则设置为组件的颜色"
      }
    },
    {
      "name": "background-opacity",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "v-progress-linear",
      "description": {
        "en": "Background opacity, if null it defaults to 0.3 if background color is not specified or 1 otherwise",
        "ja": "背景の不透明度。背景色が指定されていない場合はデフォルトで0.3、そうでない場合は1になります。",
        "zh-Hans": "背景不透明度，如果为 null，默认为 0.3，如果没有指定背景颜色，则为 1"
      }
    },
    {
      "name": "bottom",
      "type": "boolean",
      "default": "false",
      "source": "positionable",
      "description": {
        "en": "Aligns the component towards the bottom.",
        "ja": "コンポーネントを下部に配置します。",
        "zh-Hans": "将组件向底部对齐。"
      }
    },
    {
      "name": "buffer-value",
      "type": [
        "number",
        "string"
      ],
      "default": 100,
      "source": "v-progress-linear",
      "description": {
        "en": "The percentage value for the buffer",
        "ja": "バッファのパーセンテージ値",
        "zh-Hans": "缓冲区的百分比值"
      }
    },
    {
      "name": "color",
      "type": "string",
      "default": "'primary'",
      "source": "colorable",
      "description": {
        "en": "Applies specified color to the control - it can be the name of material color (for example `success` or `purple`) or css color (`#033` or `rgba(255, 0, 0, 0.5)`). You can find a list of built-in classes on the [colors page](/styles/colors#material-colors).",
        "ja": "指定された色をコントロールに適用します。利用できるのはマテリアルカラーの名前 (たとえば、`success` または ` purple`) または css 表記の色 (`#033`または` rgba(255, 0, 0, 0.5) `) です。[colors のページ](/styles/colors#material-colors) で、組み込みクラスのリストが確認できます。",
        "zh-Hans": "指定控件颜色，颜色值可以是 Material 颜色（例如 <code>success</code>或者 <code>purple</code> ）也可以是 Css 颜色 （例如 <code>#033</code> 或者 <code>rgba(255, 0, 0, 0.5)</code>）。在 [colors page](/zh-Hans/styles/colors#material-colors)中可以找到内置颜色类列表。"
      }
    },
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにダークテーマを適応します。詳しく知りたい場合は、Material Design documentationの<a href=\"https://material.io/design/color/dark-theme.html\">dark themes</a>を参照してください。",
        "zh-Hans": "将暗色主题变量应用到组件。你可以在 [dark themes](https://material.io/design/color/dark-theme.html) 的 Material Design 文档中找到更多有关信息。"
      }
    },
    {
      "name": "fixed",
      "type": "boolean",
      "default": "false",
      "source": "positionable",
      "description": {
        "en": "Applies **position: fixed** to the component.",
        "ja": "コンポーネントに **position: fixed** を適用します。",
        "zh-Hans": "给组件应用 **position: fixed**"
      }
    },
    {
      "name": "height",
      "type": [
        "number",
        "string"
      ],
      "default": 4,
      "source": "v-progress-linear",
      "description": {
        "en": "Sets the height for the component",
        "ja": "コンポーネントの高さを設定します",
        "zh-Hans": "设置组件高度"
      }
    },
    {
      "name": "indeterminate",
      "type": "boolean",
      "default": "false",
      "source": "v-progress-linear",
      "description": {
        "en": "Constantly animates, use when loading progress is unknown.",
        "ja": "常にアニメーションします。読み込みの進行状況が不明な場合に使用します。",
        "zh-Hans": "恒定动画，加载进度未知时使用。"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    },
    {
      "name": "query",
      "type": "boolean",
      "default": "false",
      "source": "v-progress-linear",
      "description": {
        "en": "Animates like **indeterminate** prop but inverse",
        "ja": "**indeterminate** prop のようなアニメーションを作成しますが、逆になります。",
        "zh-Hans": "像 **indeterminate** 属性但反转的动画效果"
      }
    },
    {
      "name": "reverse",
      "type": "boolean",
      "default": "false",
      "source": "v-progress-linear",
      "description": {
        "en": "Displays reversed progress (right to left in LTR mode and left to right in RTL)",
        "ja": "進行状況を逆に表示します（右から左へ、RTLでは左から右へ）",
        "zh-Hans": "显示反向进度 (在LTR模式下从右到左，在RTL模式下从左到右)"
      }
    },
    {
      "name": "rounded",
      "type": "boolean",
      "default": "false",
      "source": "v-progress-linear",
      "description": {
        "en": "Adds a border radius to the progress component",
        "ja": "コンポーネントにborder-radiusを追加します。",
        "zh-Hans": "向进度组件添加边界半径"
      }
    },
    {
      "name": "stream",
      "type": "boolean",
      "default": "false",
      "source": "v-progress-linear",
      "description": {
        "en": "An alternative style for portraying loading that works in tandem with **buffer-value**",
        "ja": "**buffer-value** と連携して動作するローディングを描写するための代替スタイル",
        "zh-Hans": "描绘加载的另一种样式与 **buffer-value** 协同工作"
      }
    },
    {
      "name": "striped",
      "type": "boolean",
      "default": "false",
      "source": "v-progress-linear",
      "description": {
        "en": "Adds a stripe background to the filled portion of the progress component",
        "ja": "コンポーネントのバーにストライプの背景を追加します。",
        "zh-Hans": "将条纹背景添加到进度组件的填充部分"
      }
    },
    {
      "name": "top",
      "type": "boolean",
      "default": "false",
      "source": "positionable",
      "description": {
        "en": "Aligns the content towards the top.",
        "ja": "コンポーネントを上部に配置します。",
        "zh-Hans": "将组件向顶部对齐。"
      }
    },
    {
      "name": "value",
      "type": [
        "number",
        "string"
      ],
      "default": 0,
      "source": "proxyable",
      "description": {
        "en": "The designated model value for the component.",
        "ja": "コンポーネントの指定されたモデルの値。",
        "zh-Hans": "组件的指定 model 值。"
      }
    }
  ],
  "mixins": [
    "colorable",
    "positionable",
    "proxyable",
    "themeable"
  ],
  "slots": [
    {
      "name": "default",
      "props": {
        "value": "number"
      },
      "description": {
        "en": "Provides the current value of the component",
        "ja": "Provides the current value of the component",
        "zh-Hans": "提供组件的当前值"
      }
    }
  ],
  "events": [
    {
      "name": "change",
      "value": "number",
      "description": {
        "en": "Emitted when the component value is changed by user interaction",
        "ja": "コンポーネントの値がユーザー操作によって変更されたときに発生します。",
        "zh-Hans": "当组件值被用户交互改变时发出。"
      }
    }
  ],
  "functions": [],
  "name": "v-progress-linear",
  "sass": [
    {
      "name": "$progress-linear-border-radius",
      "default": "$border-radius-root !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$progress-linear-stream-opacity",
      "default": "0.3 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$progress-linear-stream-border-width",
      "default": "4px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$progress-linear-stripe-gradient",
      "default": "linear-gradient(\n  135deg,\n  hsla(0, 0%, 100%, .25) 25%,\n  transparent 0,\n  transparent 50%,\n  hsla(0, 0%, 100%, .25) 0,\n  hsla(0, 0%, 100%, .25) 75%,\n  transparent 0,\n  transparent\n) !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$progress-linear-stripe-background-size",
      "default": "40px 40px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "component": true
}