module.exports = {
  "props": [
    {
      "name": "group",
      "type": "boolean",
      "default": "false",
      "source": "transitions",
      "description": {
        "en": "Creates a `transition-group` component. [vue docs](https://vuejs.org/v2/api/#transition-group)",
        "ja": "`transition-group` コンポーネントを作成します。 [vue docs](https://jp.vuejs.org/v2/api/index.html#transition-group)",
        "zh-Hans": "创建 `transition-group` 组件 [vue docs](https://vuejs.org/v2/api/#transition-group)。"
      }
    },
    {
      "name": "hide-on-leave",
      "type": "boolean",
      "default": "false",
      "source": "transitions",
      "description": {
        "en": "Hides the leaving element (no exit animation)",
        "ja": "leave時の要素を非表示にします(終了アニメーションなし)",
        "zh-Hans": "隐藏离开元素 （无退出动画）"
      }
    },
    {
      "name": "leave-absolute",
      "type": "boolean",
      "default": "false",
      "source": "transitions",
      "description": {
        "en": "Absolutely positions the leaving element (useful for [FLIP](https://aerotwist.com/blog/flip-your-animations/))",
        "ja": "leaving要素を絶対的に配置する（[FLIP](https://aerotwist.com/blog/flip-your-animations/) の時に便利です。）",
        "zh-Hans": "离开元素的绝对位置 (useful for [FLIP](https://aerotwist.com/blog/flip-your-animations/))"
      }
    },
    {
      "name": "mode",
      "type": "string",
      "default": "undefined",
      "source": "transitions",
      "description": {
        "en": "Sets the transition mode (does not apply to transition-group). You can find more information on the Vue documentation [for transition modes](https://vuejs.org/v2/api/#transition).",
        "ja": "トランジション モードを設定します(transition-groupには適用されません)。詳細については、Vue のドキュメント [トランジションモード](https://jp.vuejs.org/v2/api/#transition) を参照してください。",
        "zh-Hans": "设置转换模式（不应用于转换组）。你可以在 Vue 文档浏览更多 [for transition modes](https://vuejs.org/v2/api/#transition)。"
      }
    },
    {
      "name": "origin",
      "type": "string",
      "default": "'top center 0'",
      "source": "transitions",
      "description": {
        "en": "Sets the transition origin on the element. You can find more information on the MDN documentation [for transition origin](https://developer.mozilla.org/en-US/docs/Web/CSS/transform-origin).",
        "ja": "要素にトランジションの起点を設定します。 MDNドキュメントで [for transition origin](https://developer.mozilla.org/ja/docs/Web/CSS/transform-origin) の詳細を参照できます。",
        "zh-Hans": "设置元素上的动画原点。您可以在 MDN 文档上浏览更多 [for transition origin](https://developer.mozilla.org/en-US/docs/Web/CSS/transform-origin)"
      }
    }
  ],
  "mixins": [],
  "slots": [],
  "events": [],
  "functions": [],
  "name": "v-scroll-x-transition",
  "sass": [],
  "component": true
}