module.exports = {
  "props": [
    {
      "name": "align",
      "type": "string",
      "default": "undefined",
      "source": "v-row",
      "description": {
        "en": "Applies the [align-items](https://developer.mozilla.org/en-US/docs/Web/CSS/align-items) css property. Available options are **start**, **center**, **end**, **baseline** and **stretch**.",
        "ja": "[align-items](https://developer.mozilla.org/ja/docs/Web/CSS/align-items) cssプロパティを適用します。使用可能なオプションは、**start**、**center**、**end**、**baseline**および**stretch**です。",
        "zh-Hans": "应用 [align-items](https://developer.mozilla.org/en-US/docs/Web/CSS/align-items) css 属性。可用的选项是 **start**, **center**, **end**, **baseline** 和 **stretch**。"
      }
    },
    {
      "name": "align-content",
      "type": "string",
      "default": "undefined",
      "source": "v-row",
      "description": {
        "en": "Applies the [align-content](https://developer.mozilla.org/en-US/docs/Web/CSS/align-content) css property. Available options are **start**, **center**, **end**, **space-between**, **space-around** and **stretch**.",
        "ja": "[align-content](https://developer.mozilla.org/ja/docs/Web/CSS/align-content) cssプロパティを適用します。利用可能なオプションは **start** 、 **center** 、 **end** 、 **space-between** 、 **space-around** および **stretch** です。",
        "zh-Hans": "应用 [align-content](https://developer.mozilla.org/en-US/docs/Web/CSS/align-content) 属性。可用的选项是： **start**, **center**, **end**, **space-between**, **space-around** 和 **stretch**。"
      }
    },
    {
      "name": "align-content-lg",
      "type": "string",
      "default": "undefined",
      "source": "v-row",
      "description": {
        "en": "Changes the **align-content** property on large and greater breakpoints.",
        "ja": "Large以上のブレークポイントの **align-content** プロパティを変更します。",
        "zh-Hans": "在更大和更大的断点上更改 **align-content** 属性。"
      }
    },
    {
      "name": "align-content-md",
      "type": "string",
      "default": "undefined",
      "source": "v-row",
      "description": {
        "en": "Changes the **align-content** property on medium and greater breakpoints.",
        "ja": "Medium以上のブレークポイントの **align-content** プロパティを変更します。",
        "zh-Hans": "在中等和较大的断点上更改 **align-content** 属性。"
      }
    },
    {
      "name": "align-content-sm",
      "type": "string",
      "default": "undefined",
      "source": "v-row",
      "description": {
        "en": "Changes the **align-content** property on small and greater breakpoints.",
        "ja": "Small以上のブレークポイントの **align-content** プロパティを変更します。",
        "zh-Hans": "在较小和较大的断点处更改 **align-content** 属性。"
      }
    },
    {
      "name": "align-content-xl",
      "type": "string",
      "default": "undefined",
      "source": "v-row",
      "description": {
        "en": "Changes the **align-content** property on extra large and greater breakpoints.",
        "ja": "Extra large以上のブレークポイントの **align-content** プロパティを変更します。",
        "zh-Hans": "在更大和更大的断点上更改 **align-content** 属性。"
      }
    },
    {
      "name": "align-lg",
      "type": "string",
      "default": "undefined",
      "source": "v-row",
      "description": {
        "en": "Changes the **align-items** property on large and greater breakpoints.",
        "ja": "Changes the **align-items** property on large and greater breakpoints.",
        "zh-Hans": "在更大和更大的断点上更改 **align-items** 属性。"
      }
    },
    {
      "name": "align-md",
      "type": "string",
      "default": "undefined",
      "source": "v-row",
      "description": {
        "en": "Changes the **align-items** property on medium and greater breakpoints.",
        "ja": "Changes the **align-items** property on medium and greater breakpoints.",
        "zh-Hans": "在中等和更高的断点上更改 **align-items** 属性。"
      }
    },
    {
      "name": "align-sm",
      "type": "string",
      "default": "undefined",
      "source": "v-row",
      "description": {
        "en": "Changes the **align-items** property on small and greater breakpoints.",
        "ja": "Small以上のブレークポイントの **align-items** プロパティを変更します。",
        "zh-Hans": "在较小和较大的断点处更改 **align-items** 属性。"
      }
    },
    {
      "name": "align-xl",
      "type": "string",
      "default": "undefined",
      "source": "v-row",
      "description": {
        "en": "Changes the **align-items** property on extra large and greater breakpoints.",
        "ja": "Changes the **align-items** property on extra large and greater breakpoints.",
        "zh-Hans": "在更大和更大的断点上更改 **align-items** 属性。"
      }
    },
    {
      "name": "dense",
      "type": "boolean",
      "default": "false",
      "source": "v-row",
      "description": {
        "en": "Reduces the gutter between `v-col`s.",
        "ja": "`v-col`の間の gutter を減らします。",
        "zh-Hans": "减少 `v-col` 之间的距离。"
      }
    },
    {
      "name": "justify",
      "type": "string",
      "default": "undefined",
      "source": "v-row",
      "description": {
        "en": "Applies the [justify-content](https://developer.mozilla.org/en-US/docs/Web/CSS/justify-content) css property. Available options are **start**, **center**, **end**, **space-between** and **space-around**.",
        "ja": "[justify-content](https://developer.mozilla.org/ja/docs/Web/CSS/justify-content) cssプロパティを適用します。使用可能なオプションは、 **start** 、 **center** 、 **end** 、 **space-between** および **space-around** です。",
        "zh-Hans": "应用 [justify-content](https://developer.mozilla.org/en-US/docs/Web/CSS/justify-content) css 属性。可用选项是 **start**, **center**, **end**, **space-between** 和 **space-around**。"
      }
    },
    {
      "name": "justify-lg",
      "type": "string",
      "default": "undefined",
      "source": "v-row",
      "description": {
        "en": "Changes the **justify-content** property on large and greater breakpoints.",
        "ja": "Large以上のブレークポイントの **justify-content** プロパティを変更します。",
        "zh-Hans": "在更大和更大的断点上更改 **justify-content** 属性。"
      }
    },
    {
      "name": "justify-md",
      "type": "string",
      "default": "undefined",
      "source": "v-row",
      "description": {
        "en": "Changes the **justify-content** property on medium and greater breakpoints.",
        "ja": "Medium以上のブレークポイントの **justify-content** プロパティを変更します。",
        "zh-Hans": "在中等和更高的断点上更改 **justify-content** 属性。"
      }
    },
    {
      "name": "justify-sm",
      "type": "string",
      "default": "undefined",
      "source": "v-row",
      "description": {
        "en": "Changes the **justify-content** property on small and greater breakpoints.",
        "ja": "Small以上のブレークポイントの **justify-content** プロパティを変更します。",
        "zh-Hans": "在较小和较大的断点上更改 **justify-content** 属性。"
      }
    },
    {
      "name": "justify-xl",
      "type": "string",
      "default": "undefined",
      "source": "v-row",
      "description": {
        "en": "Changes the **justify-content** property on extra large and greater breakpoints.",
        "ja": "Extra large以上のブレークポイントの **justify-content** プロパティを変更します。",
        "zh-Hans": "在更大和更大的断点上更改 **justify-content** 属性。"
      }
    },
    {
      "name": "no-gutters",
      "type": "boolean",
      "default": "false",
      "source": "v-row",
      "description": {
        "en": "Removes the gutter between `v-col`s.",
        "ja": "`v-col`間のガターを削除します。",
        "zh-Hans": "移除 `v-col` 之间的距离。"
      }
    },
    {
      "name": "tag",
      "type": "string",
      "default": "'div'",
      "source": "v-row",
      "description": {
        "en": "Specify a custom tag used on the root element.",
        "ja": "コンポーネント内で使用するカスタムタグを指定します。",
        "zh-Hans": "指定在根元素上使用的自定义标签。"
      }
    }
  ],
  "mixins": [],
  "slots": [
    {
      "name": "default",
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    }
  ],
  "events": [],
  "functions": [],
  "name": "v-row",
  "sass": [],
  "component": true
}