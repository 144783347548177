module.exports = {
  "props": [
    {
      "name": "active-class",
      "type": "string",
      "default": "undefined",
      "source": "routable",
      "description": {
        "en": "Configure the active CSS class applied when the link is active. You can find more information about the [**active-class** prop](https://router.vuejs.org/api/#active-class) on the vue-router documentation.",
        "ja": "リンクがアクティブなときに適用されるアクティブなCSSクラスを構成します。vue-routerのドキュメントで [**active-class** prop](https://router.vuejs.org/ja/api/#active-class) の詳細をご覧ください。",
        "zh-Hans": "配置在链接激活时应用的 CSS 类。你能够在 vue-router 文档浏览更多 [**active-class** prop](https://router.vuejs.org/api/#active-class)。"
      }
    },
    {
      "name": "append",
      "type": "boolean",
      "default": "false",
      "source": "routable",
      "description": {
        "en": "Setting **append** prop always appends the relative path to the current path. You can find more information about the [**append** prop](https://router.vuejs.org/api/#append) on the vue-router documentation.",
        "ja": "**append** propを設定すると、常に現在のパスに相対パスが追加されます。vue-router のドキュメントで [**append** prop](https://router.vuejs.org/ja/api/#append) の詳細についてをご覧ください。",
        "zh-Hans": "设置 **append** 属性总是会附加到当前路径的相对路径上。你能够在 vue-router 文档浏览更多 [**append** prop](https://router.vuejs.org/api/#append)。"
      }
    },
    {
      "name": "color",
      "type": "string",
      "default": "undefined",
      "source": "colorable",
      "description": {
        "en": "Applies specified color to the control - it can be the name of material color (for example `success` or `purple`) or css color (`#033` or `rgba(255, 0, 0, 0.5)`). You can find a list of built-in classes on the [colors page](/styles/colors#material-colors).",
        "ja": "指定された色をコントロールに適用します。利用できるのはマテリアルカラーの名前 (たとえば、`success` または ` purple`) または css 表記の色 (`#033`または` rgba(255, 0, 0, 0.5) `) です。[colors のページ](/styles/colors#material-colors) で、組み込みクラスのリストが確認できます。",
        "zh-Hans": "指定控件颜色，颜色值可以是 Material 颜色（例如 <code>success</code>或者 <code>purple</code> ）也可以是 Css 颜色 （例如 <code>#033</code> 或者 <code>rgba(255, 0, 0, 0.5)</code>）。在 [colors page](/zh-Hans/styles/colors#material-colors)中可以找到内置颜色类列表。"
      }
    },
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにダークテーマを適応します。詳しく知りたい場合は、Material Design documentationの<a href=\"https://material.io/design/color/dark-theme.html\">dark themes</a>を参照してください。",
        "zh-Hans": "将暗色主题变量应用到组件。你可以在 [dark themes](https://material.io/design/color/dark-theme.html) 的 Material Design 文档中找到更多有关信息。"
      }
    },
    {
      "name": "disabled",
      "type": "boolean",
      "default": "false",
      "source": "routable",
      "description": {
        "en": "Removes the ability to click or target the component.",
        "ja": "コンポーネントをクリックまたはターゲットにできなくします。",
        "zh-Hans": "移除组件的单击或 target 功能。"
      }
    },
    {
      "name": "elevation",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "elevatable",
      "description": {
        "en": "Designates an elevation applied to the component between 0 and 24. You can find more information on the [elevation page](/styles/elevation).",
        "ja": "コンポーネントに適用される高さを0〜24の範囲で指定します。詳細は、[elevation のページ](/styles/elevation)で確認できます。",
        "zh-Hans": "组件的海拔可接受 0 到 24 之间的值。你可以在 [elevation page](/styles/elevation) 浏览更多信息。"
      }
    },
    {
      "name": "exact",
      "type": "boolean",
      "default": "false",
      "source": "routable",
      "description": {
        "en": "Exactly match the link. Without this, '/' will match every route. You can find more information about the [**exact** prop](https://router.vuejs.org/api/#exact) on the vue-router documentation.",
        "ja": "リンクと完全に一致します。これがなければ、'/' はすべてのルートにマッチします。[**exact** prop](https://router.vuejs.org/ja/api/#exact) の詳細については、vue-router のドキュメントを参照してください。",
        "zh-Hans": "完全匹配链接。如果没有这个链接，'/' 将匹配每个路由。你能够在 vue-router 文档浏览更多 [**exact** prop](https://router.vuejs.org/api/#exact)。"
      }
    },
    {
      "name": "exact-active-class",
      "type": "string",
      "default": "undefined",
      "source": "routable",
      "description": {
        "en": "Configure the active CSS class applied when the link is active with exact match. You can find more information about the [**exact-active-class** prop](https://router.vuejs.org/api/#exact-active-class) on the vue-router documentation.",
        "ja": "完全一致のリンクがアクティブな場合に適用されるアクティブな CSS クラスを設定します。[**exact-active-class** prop](https://router.vuejs.org/ja/api/#exact-active-class) の詳細については、vue-router のドキュメントをご覧ください。",
        "zh-Hans": "配置在精准链接激活时应用的 CSS 类。你能够在 vue-router 文档浏览更多[**exact-active-class** prop](https://router.vuejs.org/api/#exact-active-class)。"
      }
    },
    {
      "name": "exact-path",
      "type": "boolean",
      "default": "false",
      "source": "routable",
      "description": {
        "en": "Exactly match the link, ignoring the `query` and the `hash` sections. You can find more information about the [**exact-path** prop](https://router.vuejs.org/api/#exact-path) on the vue-router documentation.",
        "ja": "`query` セクションと `hash` セクションを無視して、リンクと完全に一致します。[**exact-path** prop](https://router.vuejs.org/api/#exact-path) の詳細については、vue-routerのドキュメントをご覧ください。",
        "zh-Hans": "精确匹配链接, 忽略`query`和`hash`部分. 您可以在vue-router文档的[**精确路径** prop](https://router.vuejs.org/api/#exact-path)找到相关的信息."
      }
    },
    {
      "name": "flat",
      "type": "boolean",
      "default": "false",
      "source": "v-card",
      "description": {
        "en": "Removes the card's elevation.",
        "ja": "カードのElevationを削除します。",
        "zh-Hans": "移除卡片的海拔。"
      }
    },
    {
      "name": "height",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the height for the component.",
        "ja": "コンポーネントに高さを設定します。",
        "zh-Hans": "设定组件的高度。"
      }
    },
    {
      "name": "hover",
      "type": "boolean",
      "default": "false",
      "source": "v-card",
      "description": {
        "en": "Will apply an elevation of 4dp when hovered (default 2dp). You can find more information on the [elevation page](/styles/elevation).",
        "ja": "ホバー時に 4dp のElevationを適用します (デフォルト 2dp)。詳細は [elevationのページ](/styles/elevation) をご覧ください。",
        "zh-Hans": "悬停时将应用 4dp 的海拔（默认值为 2dp）。您可以在 [elevation page](/styles/elevation) 找到更多信息。"
      }
    },
    {
      "name": "href",
      "type": [
        "string",
        "object"
      ],
      "default": "undefined",
      "source": "routable",
      "description": {
        "en": "Designates the component as anchor and applies the **href** attribute.",
        "ja": "コンポーネントをアンカーとして指定し、**href** 属性を適用します。",
        "zh-Hans": "指定组件为锚点并应用 **href** 属性。"
      }
    },
    {
      "name": "img",
      "type": "string",
      "default": "undefined",
      "source": "v-card",
      "description": {
        "en": "Specifies an image background for the card. For more advanced implementations, it is recommended that you use the [v-img](/components/images) component. You can find a [v-img example here](/components/cards/#media-with-text).",
        "ja": "カードの背景画像を指定します。より高度な実装を行う場合は、 [v-img](/components/images) コンポーネントを使用することをお勧めします。 [v-imgの例はこちら](/components/cards/#media-with-text) 。",
        "zh-Hans": "指定卡片的背景图。对于更高级的实现，建议您使用 [v-img](/components/images) 组件。您可以在此处找到 [v-img example here](#media-with-text)。"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    },
    {
      "name": "link",
      "type": "boolean",
      "default": "false",
      "source": "routable",
      "description": {
        "en": "Designates that the component is a link. This is automatic when using the **href** or **to** prop.",
        "ja": "コンポーネントがリンクであることを指定します。これは **href** または **to** propを使用する場合は自動的に行われます。",
        "zh-Hans": "指定组件为链接。当使用 **href** 或 **to ** 属性时，这是自动的设置的。"
      }
    },
    {
      "name": "loader-height",
      "type": [
        "number",
        "string"
      ],
      "default": 4,
      "source": "loadable",
      "description": {
        "en": "Specifies the height of the loader",
        "ja": "loader の高さを指定します。",
        "zh-Hans": "指定加载器的高度"
      }
    },
    {
      "name": "loading",
      "type": [
        "boolean",
        "string"
      ],
      "default": false,
      "source": "loadable",
      "description": {
        "en": "Displays linear progress bar. Can either be a String which specifies which color is applied to the progress bar (any material color or theme color - **primary**, **secondary**, **success**, **info**, **warning**, **error**) or a Boolean which uses the component **color** (set by color prop - if it's supported by the component) or the primary color",
        "ja": "線形プログレス バーを表示します。 進行状況バー (任意のマテリアルカラーまたはテーマカラー - **primary**, **secondary**, **success**, **info**, **warning**, **error**) に適用する色を指定する文字列、あるいはコンポーネント **color** (color propで設定 - コンポーネントでサポートされている場合) またはprimary色を使用する真偽値を指定できます。",
        "zh-Hans": "显示线性进度条。可以是指定将哪种颜色应用于进度条的字符串（任何 material 色彩——主要（primary）, 次要（secondary）, 成功（success）, 信息（info），警告（warning），错误（error）），或者使用组件的布尔值 **color**（由色彩属性设置——如果它被组件支持的话）还可以是原色。"
      }
    },
    {
      "name": "max-height",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the maximum height for the component.",
        "ja": "コンテンツの高さの最大値を設定します。",
        "zh-Hans": "设定组件的最大高度。"
      }
    },
    {
      "name": "max-width",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the maximum width for the component.",
        "ja": "コンテンツの横幅の最大値を設定します。",
        "zh-Hans": "设定组件的最大宽度。"
      }
    },
    {
      "name": "min-height",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the minimum height for the component.",
        "ja": "コンテンツの高さの最小値を設定します。",
        "zh-Hans": "设定组件的最小高度。"
      }
    },
    {
      "name": "min-width",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the minimum width for the component.",
        "ja": "コンテンツの横幅の最小値を設定します。",
        "zh-Hans": "设定组件的最小宽度。"
      }
    },
    {
      "name": "nuxt",
      "type": "boolean",
      "default": "false",
      "source": "routable",
      "description": {
        "en": "Specifies the link is a `nuxt-link`. For use with the [nuxt framework](https://nuxtjs.org/api/components-nuxt-link/).",
        "ja": "リンクが`nuxt-link`であることを指定します。 [nuxtフレームワーク](https://ja.nuxtjs.org/api/components-nuxt-link/) で使用します。",
        "zh-Hans": "指定链接是 `nuxt-link` 。用处参考 [nuxt framework](https://nuxtjs.org/api/components-nuxt-link/)."
      }
    },
    {
      "name": "outlined",
      "type": "boolean",
      "default": "false",
      "source": "v-sheet",
      "description": {
        "en": "Removes elevation (box-shadow) and adds a *thin* border.",
        "ja": "Elevationの影(box-shadow) を削除し、*細い* 枠線を追加します。",
        "zh-Hans": "去除卡片的实心颜色并添加细边框。"
      }
    },
    {
      "name": "raised",
      "type": "boolean",
      "default": "false",
      "source": "v-card",
      "description": {
        "en": "Specifies a higher default elevation (8dp). You can find more information on the [elevation page](/styles/elevation).",
        "ja": "より高いデフォルトElevation (8dp) を指定します。詳細については、[elevationページ](/styles/elevation) をご覧ください。",
        "zh-Hans": "指定较高的默认海拔（8dp）。您可以在 [elevation page](/styles/elevation) 找到更多信息。"
      }
    },
    {
      "name": "replace",
      "type": "boolean",
      "default": "false",
      "source": "routable",
      "description": {
        "en": "Setting **replace** prop will call `router.replace()` instead of `router.push()` when clicked, so the navigation will not leave a history record. You can find more information about the [**replace** prop](https://router.vuejs.org/api/#replace) on the vue-router documentation.",
        "ja": "**replace** propを設定すると、クリックされたときに `router.push()` の代わりに `router.replace()` が呼び出されるため、ナビゲーションは history レコードを残しません。[**replace** prop](https://router.vuejs.org/ja/api/#replace) の詳細については、vue-routerのドキュメントをご覧ください。",
        "zh-Hans": "设置 **replace** 属性会在点击时调用 `router.replace()` 而不是 `router.push()`，这样导航就不会留下历史记录。您可以在 vue-router 文档中浏览更多 [**replace** prop](https://router.vuejs.org/api/#replace)。"
      }
    },
    {
      "name": "ripple",
      "type": [
        "boolean",
        "object"
      ],
      "default": "undefined",
      "source": "routable",
      "description": {
        "en": "Applies the [v-ripple](/directives/ripple) directive.",
        "ja": "[v-ripple](/directives/ripple) ディレクティブを適用します。",
        "zh-Hans": "应用 [v-ripple](/directives/ripples) 指令。"
      }
    },
    {
      "name": "rounded",
      "type": [
        "boolean",
        "string"
      ],
      "default": "undefined",
      "source": "roundable",
      "description": {
        "en": "Designates the **border-radius** applied to the component. You can find more information on the [Border Radius page](/styles/border-radius).",
        "ja": "コンポーネントに適用された **border-radius** を指定します。[Border Radius ページ](/styles/border-radius) で詳細を確認できます。",
        "zh-Hans": "对指定的组件应用 **border-radius** 样式。您可以在 [边框的圆角半径页面](/styles/border-radius) 获取更多有关信息"
      }
    },
    {
      "name": "shaped",
      "type": "boolean",
      "default": "false",
      "source": "v-sheet",
      "description": {
        "en": "Applies a large border radius on the top left and bottom right of the card.",
        "ja": "カードの左上と右下の角を丸くします。",
        "zh-Hans": "在卡片的左上角和右下角应用较大的边框半径。"
      }
    },
    {
      "name": "tag",
      "type": "string",
      "default": "'div'",
      "source": "routable",
      "description": {
        "en": "Specify a custom tag used on the root element.",
        "ja": "コンポーネント内で使用するカスタムタグを指定します。",
        "zh-Hans": "指定在根元素上使用的自定义标签。"
      }
    },
    {
      "name": "target",
      "type": "string",
      "default": "undefined",
      "source": "routable",
      "description": {
        "en": "Designates the target attribute. This should only be applied when using the **href** prop.",
        "ja": "ターゲット属性を指定します。これは **href** propを使用する場合にのみ適用する必要があります。",
        "zh-Hans": "指定 target 属性。只在使用 **href** 属性时应用。"
      }
    },
    {
      "name": "tile",
      "type": "boolean",
      "default": "false",
      "source": "roundable",
      "description": {
        "en": "Removes the component's **border-radius**.",
        "ja": "コンポーネントの **border-radius** を削除します。",
        "zh-Hans": "删除组件的**border-radius**样式"
      }
    },
    {
      "name": "to",
      "type": [
        "string",
        "object"
      ],
      "default": "undefined",
      "source": "routable",
      "description": {
        "en": "Denotes the target route of the link. You can find more information about the [**to** prop](https://router.vuejs.org/api/#to) on the vue-router documentation.",
        "ja": "リンクのターゲットルートを示します。[**to** prop](https://router.vuejs.org/ja/api/#to) の詳細については、vue-routerのドキュメントをご覧ください。",
        "zh-Hans": "表示链接的目标路由。你可以在 vue-router 文档浏览更多 [**to** prop](https://router.vuejs.org/api/#to)。"
      }
    },
    {
      "name": "width",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the width for the component.",
        "ja": "コンポーネントに横幅を設定します。",
        "zh-Hans": "设定组件的宽度。"
      }
    }
  ],
  "mixins": [
    "colorable",
    "elevatable",
    "loadable",
    "measurable",
    "roundable",
    "routable",
    "themeable",
    "v-sheet"
  ],
  "slots": [
    {
      "name": "default",
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    },
    {
      "name": "progress",
      "description": {
        "en": "Slot for custom progress linear (displayed when **loading** prop is not equal to Boolean False)",
        "ja": "カスタムprogress linearのスロット（**loading** プロパティが真偽値 false でない場合に表示されます）",
        "zh-Hans": "自定义进度线的插槽（**loading**属性不等于布尔值False时显示)"
      }
    }
  ],
  "events": [
    {
      "name": "click",
      "value": "void",
      "description": {
        "en": "Emitted when component is clicked - Will trigger component to ripple when clicked unless the `.native` modifier is used",
        "ja": "コンポーネントがクリックされたときに発生 - `.native`修飾子を使用しない限り、クリックするとコンポーネントにリップル効果が適用されます。",
        "zh-Hans": "单击组件时发出 - 不使用 `.native` 修饰符，否则单击时将触发组件产生涟漪"
      }
    }
  ],
  "functions": [],
  "name": "v-card",
  "sass": [
    {
      "name": "$card-actions-padding",
      "default": "8px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$card-adjacent-sibling-text-padding-top",
      "default": "0 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$card-border-radius",
      "default": "$border-radius-root !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$card-btn-margin-x",
      "default": "8px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$card-btn-padding",
      "default": "0 8px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$card-btn-small-margin-x",
      "default": "4px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$card-disabled-opacity",
      "default": "0.6 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$card-elevation",
      "default": "2 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$card-hover-elevation",
      "default": "8 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$card-link-focus-opacity",
      "default": "0.08 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$card-outlined-border-width",
      "default": "thin !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$card-overflow-wrap",
      "default": "break-word !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$card-raised-elevation",
      "default": "8 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$card-shaped-border-radius",
      "default": "map-get($rounded, 'xl') $card-border-radius !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$card-subtitle-padding",
      "default": "16px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$card-text-font-size",
      "default": "map-deep-get($headings, 'subtitle-2', 'size') !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$card-text-font-weight",
      "default": "400 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$card-text-letter-spacing",
      "default": "map-deep-get($headings, 'subtitle-2', 'letter-spacing') !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$card-text-line-height",
      "default": "map-deep-get($headings, 'subtitle-2', 'line-height') !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$card-title-adjacent-sibling-subtitle-margin-top",
      "default": "-16px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$card-title-adjacent-sibling-subtitle-text-padding-top",
      "default": "0 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$card-title-font-size",
      "default": "map-deep-get($headings, 'h6', 'size') !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$card-title-font-weight",
      "default": "map-deep-get($headings, 'h6', 'weight') !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$card-title-letter-spacing",
      "default": "map-deep-get($headings, 'h6', 'letter-spacing') !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$card-title-line-height",
      "default": "map-deep-get($headings, 'h6', 'line-height') !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$card-title-word-break",
      "default": "break-all !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$card-white-space",
      "default": "normal !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "component": true
}