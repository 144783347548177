module.exports = {
  "argument": [
    {
      "name": "argument",
      "type": [
        "Function"
      ],
      "description": {
        "en": "The function to invoke on target scroll",
        "ja": "ターゲットスクロール時に呼び出す関数",
        "zh-Hans": "目标滚动时调用的函数"
      }
    }
  ],
  "modifiers": [
    {
      "name": "#target",
      "default": "window",
      "type": [
        "string"
      ],
      "description": {
        "en": "The target watched for scroll changes. Defaults to window but can be changed to any valid id selector.",
        "ja": "スクロールの変更を監視するターゲット。デフォルトはウィンドウですが、任意の有効なIDセレクターに変更できます。",
        "zh-Hans": "监视的滚动变化目标。默认为“window”，但可以更改为任何有效的id选择器。"
      }
    },
    {
      "name": "self",
      "default": false,
      "type": [
        "boolean"
      ],
      "description": {
        "en": "Binds to the element that the the directive is attached.",
        "ja": "ディレクティブが添付されている要素をバインドします。",
        "zh-Hans": "绑定到指令附加的元素。"
      }
    }
  ],
  "props": [],
  "name": "v-scroll",
  "directive": true
}