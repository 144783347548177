module.exports = {
  "props": [
    {
      "name": "active-class",
      "type": "string",
      "source": "groupable",
      "description": {
        "en": "Configure the active CSS class applied when the link is active. You can find more information about the [**active-class** prop](https://router.vuejs.org/api/#active-class) on the vue-router documentation.",
        "ja": "リンクがアクティブなときに適用されるアクティブなCSSクラスを構成します。vue-routerのドキュメントで [**active-class** prop](https://router.vuejs.org/ja/api/#active-class) の詳細をご覧ください。",
        "zh-Hans": "配置在链接激活时应用的 CSS 类。你能够在 vue-router 文档浏览更多 [**active-class** prop](https://router.vuejs.org/api/#active-class)。"
      }
    },
    {
      "name": "disabled",
      "type": "boolean",
      "default": "false",
      "source": "groupable",
      "description": {
        "en": "Removes the ability to click or target the component.",
        "ja": "コンポーネントをクリックまたはターゲットにできなくします。",
        "zh-Hans": "移除组件的单击或 target 功能。"
      }
    },
    {
      "name": "value",
      "type": "any",
      "default": "undefined",
      "source": "groupable",
      "description": {
        "en": "The value used when the component is selected in a group. If not provided, the index will be used.",
        "ja": "コンポーネントがグループ内で選択されたときに使われる値です。もし指定しなかった場合、index が使用されます。",
        "zh-Hans": "在组中选择组件时使用的值。如果没有提供，则使用 index。"
      }
    }
  ],
  "mixins": [
    "groupable"
  ],
  "slots": [
    {
      "name": "default",
      "props": {
        "active": "boolean",
        "toggle": "Function"
      },
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    }
  ],
  "events": [],
  "functions": [],
  "name": "v-item",
  "sass": [],
  "component": true
}