<template>
  <v-icon
    class="hidden-sm-and-down"
    size="14"
    v-text="'$mdiChevronDown'"
  />
</template>

<script>
  export default { name: 'AppChevronDown' }
</script>
