module.exports = {
  "props": [
    {
      "name": "absolute",
      "type": "boolean",
      "default": "false",
      "source": "positionable",
      "description": {
        "en": "Applies **position: absolute** to the component.",
        "ja": "コンポーネントに **position: absolute** を適用します。",
        "zh-Hans": "给组件应用 **position: absolute**"
      }
    },
    {
      "name": "activator",
      "type": "any",
      "default": "undefined",
      "source": "activatable",
      "description": {
        "en": "Designate a custom activator when the `activator` slot is not used. String can be any valid querySelector and Object can be any valid Node.",
        "ja": "`activator` slot が使われていない場合、カスタムの activator を指定します。String には任意の有効な querySelector が、Object には任意の有効な Node が指定できます。",
        "zh-Hans": "当未使用 `activator` 插槽时，指定自定义激活器。String 可以是任何有效的 querySelector，对象可以是任何有效的 Node。"
      }
    },
    {
      "name": "allow-overflow",
      "type": "boolean",
      "default": "false",
      "source": "menuable",
      "description": {
        "en": "Removes overflow re-positioning for the content",
        "ja": "コンテンツのオーバーフローの再配置を削除します",
        "zh-Hans": "移除内容的溢出重新定位"
      }
    },
    {
      "name": "attach",
      "type": "any",
      "default": false,
      "source": "detachable",
      "description": {
        "en": "Specifies which DOM element that this component should detach to. String can be any valid querySelector and Object can be any valid Node. This will attach to the root `v-app` component by default.",
        "ja": "このコンポーネントが切り離すべき DOM 要素を指定します。String には任意の有効な querySelector、Object には任意の有効な Node を指定します。デフォルトではルートの `v-app` コンポーネントにアタッチされます。",
        "zh-Hans": "使组件脱离当前节点并且指向一个新的DOM元素。可以是任何document. querySelector 有效的字符串或者任何有效的节点。将默认指定并附加到根节点`v-app`下。"
      }
    },
    {
      "name": "auto",
      "type": "boolean",
      "default": "false",
      "source": "v-menu",
      "description": {
        "en": "Centers list on selected element",
        "ja": "選択した要素の中心リスト",
        "zh-Hans": "在所选择的元素上居中列表"
      }
    },
    {
      "name": "bottom",
      "type": "boolean",
      "default": "false",
      "source": "positionable",
      "description": {
        "en": "Aligns the component towards the bottom.",
        "ja": "コンポーネントを下部に配置します。",
        "zh-Hans": "将组件向底部对齐。"
      }
    },
    {
      "name": "close-delay",
      "type": [
        "number",
        "string"
      ],
      "default": 0,
      "source": "delayable",
      "description": {
        "en": "Milliseconds to wait before closing component. Only works with the **open-on-hover** prop",
        "ja": "コンポーネントが閉じられるまでのミリ秒を指定します。**open-on-hover** propがあるときのみ動作します。",
        "zh-Hans": "关闭组件之前等待的毫秒数. 只在使用 **open-on-hover** prop 时有效."
      }
    },
    {
      "name": "close-on-click",
      "type": "boolean",
      "default": "true",
      "source": "v-menu",
      "description": {
        "en": "Designates if menu should close on outside-activator click",
        "ja": "外側アクティベータークリック時にメニューを閉じるかどうかを指定します。",
        "zh-Hans": "指定菜应该在外部激活器被点击时关闭。"
      }
    },
    {
      "name": "close-on-content-click",
      "type": "boolean",
      "default": "true",
      "source": "v-menu",
      "description": {
        "en": "Designates if menu should close when its content is clicked",
        "ja": "コンテンツがクリックされたときにメニューを閉じるかどうかを指定します。",
        "zh-Hans": "指定点击内容时菜单是否应该关闭。"
      }
    },
    {
      "name": "content-class",
      "type": "string",
      "default": "undefined",
      "source": "detachable",
      "description": {
        "en": "Applies a custom class to the detached element. This is useful because the content is moved to the beginning of the `v-app` component (unless the **attach** prop is provided) and is not targetable by classes passed directly on the component.",
        "ja": "デタッチされた要素にカスタムクラスを適用します。コンテンツが `v-app`コンポーネントの先頭に移動され(**attach** propが与えられない限り)、コンポーネントに直接渡されたクラスが対象とならないので便利です。",
        "zh-Hans": "将自定义的class应用于被分离的此元素。由于此元素的内容将被附加到`v-app`组件开头(除非你设置了**attach**属性)，并且class属性不会将css类直接传递给组件，所以这个实行非常有用。"
      }
    },
    {
      "name": "content-props",
      "type": "object",
      "default": {},
      "source": "v-menu",
      "description": {
        "en": "Applies props/attributes to the detached menu. This is useful because the content is moved to the beginning of the `v-app` component (unless the attach prop is provided) and is not targetable by classes passed directly on the component. You could use this for example for applying a `data-cy` for cypress testing purposes.",
        "ja": "Applies props/attributes to the detached menu. This is useful because the content is moved to the beginning of the `v-app` component (unless the attach prop is provided) and is not targetable by classes passed directly on the component. You could use this for example for applying a `data-cy` for cypress testing purposes.",
        "zh-Hans": "Applies props/attributes to the detached menu. This is useful because the content is moved to the beginning of the `v-app` component (unless the attach prop is provided) and is not targetable by classes passed directly on the component. You could use this for example for applying a `data-cy` for cypress testing purposes."
      }
    },
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにダークテーマを適応します。詳しく知りたい場合は、Material Design documentationの<a href=\"https://material.io/design/color/dark-theme.html\">dark themes</a>を参照してください。",
        "zh-Hans": "将暗色主题变量应用到组件。你可以在 [dark themes](https://material.io/design/color/dark-theme.html) 的 Material Design 文档中找到更多有关信息。"
      }
    },
    {
      "name": "disable-keys",
      "type": "boolean",
      "default": "false",
      "source": "v-menu",
      "description": {
        "en": "Removes all keyboard interaction",
        "ja": "すべてのキーボード操作を取り除きます",
        "zh-Hans": "移除所有键盘交互功能"
      }
    },
    {
      "name": "disabled",
      "type": "boolean",
      "default": "false",
      "source": "activatable",
      "description": {
        "en": "Disables the menu",
        "ja": "メニューを無効にします。",
        "zh-Hans": "禁用菜单"
      }
    },
    {
      "name": "eager",
      "type": "boolean",
      "default": "false",
      "source": "bootable",
      "description": {
        "en": "Will force the components content to render on mounted. This is useful if you have content that will not be rendered in the DOM that you want crawled for SEO.",
        "ja": "コンポーネントのコンテンツを mounted 時に render することを強制します。この機能は、SEO のために crawl させたい DOM の中に render されないコンテンツを持っているときに役に立ちます。",
        "zh-Hans": "将强制组件内容在加载时呈现。如果存在内容的话，则不会在 DOM 中渲染，如果你想优化 SEO，这是非常有用的。"
      }
    },
    {
      "name": "internal-activator",
      "type": "boolean",
      "default": "false",
      "source": "activatable",
      "description": {
        "en": "Detaches the menu content inside of the component as opposed to the document.",
        "ja": "ドキュメントではなく、コンポーネント内のメニューコンテンツを切り離します。",
        "zh-Hans": "与文档相反，分离组件内部的菜单内容。"
      }
    },
    {
      "name": "left",
      "type": "boolean",
      "default": "false",
      "source": "positionable",
      "description": {
        "en": "Aligns the component towards the left.",
        "ja": "コンポーネントを左に配置します。",
        "zh-Hans": "将组件向左边对齐。"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    },
    {
      "name": "max-height",
      "type": [
        "number",
        "string"
      ],
      "default": "auto",
      "source": "v-menu",
      "description": {
        "en": "Sets the max height of the menu content",
        "ja": "メニューコンテンツの最大高さを設定します。",
        "zh-Hans": "设置菜单内容的最大高度"
      }
    },
    {
      "name": "max-width",
      "type": [
        "number",
        "string"
      ],
      "default": "auto",
      "source": "menuable",
      "description": {
        "en": "Sets the maximum width for the content",
        "ja": "コンテンツの横幅の最大値を設定します。",
        "zh-Hans": "设置内容的最大宽度"
      }
    },
    {
      "name": "min-width",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "menuable",
      "description": {
        "en": "Sets the minimum width for the content",
        "ja": "コンテンツの横幅の最小値を設定します。",
        "zh-Hans": "设置内容的最小宽度"
      }
    },
    {
      "name": "nudge-bottom",
      "type": [
        "number",
        "string"
      ],
      "default": 0,
      "source": "menuable",
      "description": {
        "en": "Nudge the content to the bottom",
        "ja": "コンテンツをbottomに移動します",
        "zh-Hans": "向底部微调内容"
      }
    },
    {
      "name": "nudge-left",
      "type": [
        "number",
        "string"
      ],
      "default": 0,
      "source": "menuable",
      "description": {
        "en": "Nudge the content to the left",
        "ja": "コンテンツをleftに移動します",
        "zh-Hans": "向左侧微调内容"
      }
    },
    {
      "name": "nudge-right",
      "type": [
        "number",
        "string"
      ],
      "default": 0,
      "source": "menuable",
      "description": {
        "en": "Nudge the content to the right",
        "ja": "コンテンツをrightに移動します",
        "zh-Hans": "向右侧微调内容"
      }
    },
    {
      "name": "nudge-top",
      "type": [
        "number",
        "string"
      ],
      "default": 0,
      "source": "menuable",
      "description": {
        "en": "Nudge the content to the top",
        "ja": "コンテンツをtopに移動します",
        "zh-Hans": "向顶部微调内容"
      }
    },
    {
      "name": "nudge-width",
      "type": [
        "number",
        "string"
      ],
      "default": 0,
      "source": "menuable",
      "description": {
        "en": "Nudge the content width",
        "ja": "コンテンツの幅を微調整します",
        "zh-Hans": "微调内容宽度"
      }
    },
    {
      "name": "offset-overflow",
      "type": "boolean",
      "default": "false",
      "source": "menuable",
      "description": {
        "en": "Causes the component to flip to the opposite side when repositioned due to overflow",
        "ja": "オーバーフローによる再配置の際、コンポーネントが反対側に反転します",
        "zh-Hans": "当由于溢出而重新定位时，导致组件翻转到另一侧"
      }
    },
    {
      "name": "offset-x",
      "type": "boolean",
      "default": "false",
      "source": "v-menu",
      "description": {
        "en": "Offset the menu on the x-axis. Works in conjunction with direction left/right",
        "ja": "X軸のメニューのオフセット。left/right方向と連動します。",
        "zh-Hans": "在x轴上偏移菜单，与左/右方向一起工作"
      }
    },
    {
      "name": "offset-y",
      "type": "boolean",
      "default": "false",
      "source": "v-menu",
      "description": {
        "en": "Offset the menu on the y-axis. Works in conjunction with direction top/bottom",
        "ja": "Y軸のメニューのオフセット。top/bottom方向と連動します。",
        "zh-Hans": "在y轴上偏移菜单，与上/下方向一起工作"
      }
    },
    {
      "name": "open-delay",
      "type": [
        "number",
        "string"
      ],
      "default": 0,
      "source": "delayable",
      "description": {
        "en": "Milliseconds to wait before opening component. Only works with the **open-on-hover** prop",
        "ja": "コンポーネントが開かれるまでのミリ秒を指定します。**open-on-hover** propがあるときのみ動作します。",
        "zh-Hans": "开启组件之前等待的毫秒数. 只在使用 **open-on-hover** prop 时有效."
      }
    },
    {
      "name": "open-on-click",
      "type": "boolean",
      "default": "true",
      "source": "activatable",
      "description": {
        "en": "Designates whether menu should open on activator click",
        "ja": "アクティベーターのクリック時にメニューを開くかどうかを指定します。",
        "zh-Hans": "指定是否在激活器点击时打开菜单"
      }
    },
    {
      "name": "open-on-focus",
      "type": "boolean",
      "default": "false",
      "source": "activatable",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "open-on-hover",
      "type": "boolean",
      "default": "false",
      "source": "activatable",
      "description": {
        "en": "Designates whether menu should open on activator hover",
        "ja": "アクティベーターのホバー時にメニューを開くかどうかを指定します",
        "zh-Hans": "指定是否在激活器悬停时打开菜单"
      }
    },
    {
      "name": "origin",
      "type": "string",
      "default": "'top left'",
      "source": "v-menu",
      "description": {
        "en": "Sets the transition origin on the element. You can find more information on the MDN documentation [for transition origin](https://developer.mozilla.org/en-US/docs/Web/CSS/transform-origin).",
        "ja": "要素にトランジションの起点を設定します。 MDNドキュメントで [for transition origin](https://developer.mozilla.org/ja/docs/Web/CSS/transform-origin) の詳細を参照できます。",
        "zh-Hans": "设置元素上的动画原点。您可以在 MDN 文档上浏览更多 [for transition origin](https://developer.mozilla.org/en-US/docs/Web/CSS/transform-origin)"
      }
    },
    {
      "name": "position-x",
      "type": "number",
      "default": "undefined",
      "source": "menuable",
      "description": {
        "en": "Used to position the content when not using an activator slot",
        "ja": "アクティベータースロットを使用しない場合にコンテンツを配置するために使用します",
        "zh-Hans": "用于在不使用激活器插槽时定位内容"
      }
    },
    {
      "name": "position-y",
      "type": "number",
      "default": "undefined",
      "source": "menuable",
      "description": {
        "en": "Used to position the content when not using an activator slot",
        "ja": "アクティベータースロットを使用しない場合にコンテンツを配置するために使用します",
        "zh-Hans": "用于在不使用激活器插槽时定位内容"
      }
    },
    {
      "name": "return-value",
      "type": "any",
      "default": "undefined",
      "source": "returnable",
      "description": {
        "en": "The value that is updated when the menu is closed - must be primitive. Dot notation is supported",
        "ja": "メニューが閉じられたときに更新される値は、プリミティブでなければなりません。ドット表記がサポートされています",
        "zh-Hans": "关闭菜单时更新的值 - 必须为原始值。 支持点表示法"
      }
    },
    {
      "name": "right",
      "type": "boolean",
      "default": "false",
      "source": "positionable",
      "description": {
        "en": "Aligns the component towards the right.",
        "ja": "コンポーネントを右に配置します。",
        "zh-Hans": "将组件向右边对齐。"
      }
    },
    {
      "name": "rounded",
      "type": [
        "boolean",
        "string"
      ],
      "default": "undefined",
      "source": "roundable",
      "description": {
        "en": "Designates the **border-radius** applied to the component. You can find more information on the [Border Radius page](/styles/border-radius).",
        "ja": "コンポーネントに適用された **border-radius** を指定します。[Border Radius ページ](/styles/border-radius) で詳細を確認できます。",
        "zh-Hans": "对指定的组件应用 **border-radius** 样式。您可以在 [边框的圆角半径页面](/styles/border-radius) 获取更多有关信息"
      }
    },
    {
      "name": "tile",
      "type": "boolean",
      "default": "false",
      "source": "roundable",
      "description": {
        "en": "Removes the component's **border-radius**.",
        "ja": "コンポーネントの **border-radius** を削除します。",
        "zh-Hans": "删除组件的**border-radius**样式"
      }
    },
    {
      "name": "top",
      "type": "boolean",
      "default": "false",
      "source": "positionable",
      "description": {
        "en": "Aligns the content towards the top.",
        "ja": "コンポーネントを上部に配置します。",
        "zh-Hans": "将组件向顶部对齐。"
      }
    },
    {
      "name": "transition",
      "type": [
        "boolean",
        "string"
      ],
      "default": "v-menu-transition",
      "source": "v-menu",
      "description": {
        "en": "Sets the component transition. Can be one of the [built in transitions](/styles/transitions) or one your own.",
        "ja": "コンポーネントのトランジションを設定します。 [組み込みトランジション](/styles/transitions) のいずれか、または独自のものを使用できます。",
        "zh-Hans": "设置组件转换。可以是一个 [built in transitions](/styles/transitions) 或者是自己自定义的。"
      }
    },
    {
      "name": "value",
      "type": "any",
      "default": "undefined",
      "source": "toggleable",
      "description": {
        "en": "Controls whether the component is visible or hidden.",
        "ja": "コンポーネントを表示するか非表示にするかを制御します。",
        "zh-Hans": "控制组件可见还是隐藏。"
      }
    },
    {
      "name": "z-index",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "menuable",
      "description": {
        "en": "The z-index used for the component",
        "ja": "コンポーネントに使用されるz-index",
        "zh-Hans": "用于组件的 z-index"
      }
    }
  ],
  "mixins": [
    "activatable",
    "bootable",
    "delayable",
    "delayable",
    "dependent",
    "detachable",
    "menuable",
    "positionable",
    "returnable",
    "roundable",
    "stackable",
    "themeable",
    "toggleable"
  ],
  "slots": [
    {
      "name": "activator",
      "props": {
        "attrs": "{ role: string, aria-haspopup: boolean, aria-expanded: string }",
        "on": "{ [eventName]: eventHandler }",
        "value": "boolean"
      },
      "description": {
        "en": "When used, will activate the component when clicked (or hover for specific components). This manually stops the event propagation. Without this slot, if you open the component through its model, you will need to manually stop the event propagation",
        "ja": "使用すると、クリック時 (または特定のコンポーネントではホバー時) にコンポーネントを有効にします。これにより手動でイベントの propagation を stop されます。この slot がない場合、モデル経由でコンポーネントを開いたときには、自分でイベントの propagation を stop する必要があります。",
        "zh-Hans": "使用时，将在点击后激活组件（或悬停特定组件），它手动阻止事件传播。如果通过模型打开组件而没有此插槽，则需要手动阻止事件传播。"
      }
    },
    {
      "name": "default",
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    }
  ],
  "events": [
    {
      "name": "input",
      "value": "boolean",
      "description": {
        "en": "The updated bound model",
        "ja": "更新されたバウンドモデル",
        "zh-Hans": "被绑定模型的更新"
      }
    }
  ],
  "functions": [],
  "name": "v-menu",
  "sass": [
    {
      "name": "$menu-content-border-radius",
      "default": "$border-radius-root !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$menu-content-elevation",
      "default": "8 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "component": true
}