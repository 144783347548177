module.exports = {
  "argument": [
    {
      "name": "argument",
      "type": [
        "object"
      ],
      "example": {
        "center": "boolean",
        "class": "string"
      },
      "description": {
        "en": "An object containing options for the ripple effect. `class` applies a custom class to the ripple, and can be used for changing color. `center` forces the ripple to originate from the center of the target",
        "ja": "リップル効果のオプションを含むオブジェクト。`class` はリップルにカスタムクラスを適用し、色を変更するために使用できます。 `center` は、リップルをターゲットの中心から強制的に作成します。",
        "zh-Hans": "包含波纹效果选项的对象。`class`属性将自定义类应用于波纹效果，并可用于更改颜色。`center`属性强制波纹效果从目标中心开始"
      }
    }
  ],
  "props": [],
  "name": "v-ripple",
  "directive": true
}