var render = function render(){var _vm=this,_c=_vm._self._c;return _c('app-tooltip-btn',{staticClass:"jobs-link",attrs:{"to":{
    name: 'Documentation',
    params: {
      category: 'resources',
      page: 'jobs-for-vue'
    }
  },"path":"jobs"},on:{"click":_vm.onClick},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-badge',{attrs:{"value":_vm.showBadge,"color":"#ED561B","dot":"","left":"","overlap":""}},[_c('v-icon',{staticClass:"mx-1",domProps:{"textContent":_vm._s(_vm.icon)}})],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }