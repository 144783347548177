module.exports = {
  "props": [
    {
      "name": "active-picker",
      "type": "string",
      "default": "undefined",
      "source": "v-date-picker",
      "description": {
        "en": "Determines which picker in the date or month picker is being displayed. Allowed values: `'DATE'`, `'MONTH'`, `'YEAR'`",
        "ja": "Determines which picker in the date or month picker is being displayed. Allowed values: `'DATE'`, `'MONTH'`, `'YEAR'`",
        "zh-Hans": "确定在日期或月份选择器中显示哪个选择器。允许值：`DATE'`, `'MONTH'`, `'YEAR'`"
      }
    },
    {
      "name": "allowed-dates",
      "type": "function",
      "default": "null",
      "source": "v-date-picker",
      "description": {
        "en": "Restricts which dates can be selected",
        "ja": "選択できる日付を制限します",
        "zh-Hans": "限制可以选择的日期"
      }
    },
    {
      "name": "color",
      "type": "string",
      "default": "undefined",
      "source": "colorable",
      "description": {
        "en": "Applies specified color to the control - it can be the name of material color (for example `success` or `purple`) or css color (`#033` or `rgba(255, 0, 0, 0.5)`). You can find a list of built-in classes on the [colors page](/styles/colors#material-colors).",
        "ja": "指定された色をコントロールに適用します。利用できるのはマテリアルカラーの名前 (たとえば、`success` または ` purple`) または css 表記の色 (`#033`または` rgba(255, 0, 0, 0.5) `) です。[colors のページ](/styles/colors#material-colors) で、組み込みクラスのリストが確認できます。",
        "zh-Hans": "指定控件颜色，颜色值可以是 Material 颜色（例如 <code>success</code>或者 <code>purple</code> ）也可以是 Css 颜色 （例如 <code>#033</code> 或者 <code>rgba(255, 0, 0, 0.5)</code>）。在 [colors page](/zh-Hans/styles/colors#material-colors)中可以找到内置颜色类列表。"
      }
    },
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにダークテーマを適応します。詳しく知りたい場合は、Material Design documentationの<a href=\"https://material.io/design/color/dark-theme.html\">dark themes</a>を参照してください。",
        "zh-Hans": "将暗色主题变量应用到组件。你可以在 [dark themes](https://material.io/design/color/dark-theme.html) 的 Material Design 文档中找到更多有关信息。"
      }
    },
    {
      "name": "day-format",
      "type": "function",
      "default": "null",
      "source": "v-date-picker",
      "description": {
        "en": "Allows you to customize the format of the day string that appears in the date table. Called with date (ISO 8601 **date** string) arguments.",
        "ja": "日付テーブルに表示される曜日文字列の形式をカスタマイズできます。date (ISO 8601 **date** string) 引数で呼び出されます。",
        "zh-Hans": "允许您自定义呈现在日期表中的日期字符串的格式. 使用日期 (ISO 8601 **日期** 字符串) 作为参数调用."
      }
    },
    {
      "name": "disabled",
      "type": "boolean",
      "default": "false",
      "source": "v-date-picker",
      "description": {
        "en": "Disables interaction with the picker",
        "ja": "Disables interaction with the picker",
        "zh-Hans": "禁用与选择器的交互"
      }
    },
    {
      "name": "elevation",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "elevatable",
      "description": {
        "en": "Designates an elevation applied to the component between 0 and 24. You can find more information on the [elevation page](/styles/elevation).",
        "ja": "コンポーネントに適用される高さを0〜24の範囲で指定します。詳細は、[elevation のページ](/styles/elevation)で確認できます。",
        "zh-Hans": "组件的海拔可接受 0 到 24 之间的值。你可以在 [elevation page](/styles/elevation) 浏览更多信息。"
      }
    },
    {
      "name": "event-color",
      "type": [
        "array",
        "function",
        "object",
        "string"
      ],
      "default": "warning",
      "source": "v-date-picker",
      "description": {
        "en": "Sets the color for event dot. It can be string (all events will have the same color) or `object` where attribute is the event date and value is boolean/color/array of colors for specified date or `function` taking date as a parameter and returning boolean/color/array of colors for that date",
        "ja": "イベントのドットの色を設定します。文字列(すべてのイベントは同じ色になります)あるいは `object` で、属性はイベントの日付, 値は指定された日付の色の真偽値/色/配列、`function` は日付をパラメータとして、その日付の色の真偽値/色/配列を返します。",
        "zh-Hans": "设置事件点的颜色。 它可以是字符串（所有事件将具有相同的颜色）或 `object`，其中属性是事件的日期，值是 布尔值/颜色/指定日期 的颜色数组，或者是 `function`，将日期作为参数并返回 布尔值/颜色/该日期 的颜色数组"
      }
    },
    {
      "name": "events",
      "type": [
        "array",
        "function",
        "object"
      ],
      "default": null,
      "source": "v-date-picker",
      "description": {
        "en": "Array of dates or object defining events or colors or function returning boolean/color/array of colors",
        "ja": "日付またはイベントまたは色を定義するオブジェクトの配列、または色の 真偽値/色/配列 を返す関数",
        "zh-Hans": "日期或对象定义事件或颜色的数组，或者返回 布尔值/颜色/数组 的函数"
      }
    },
    {
      "name": "first-day-of-week",
      "type": [
        "string",
        "number"
      ],
      "default": 0,
      "source": "v-date-picker",
      "description": {
        "en": "Sets the first day of the week, starting with 0 for Sunday.",
        "ja": "週の最初の日を設定します。日曜日の0から始まります。",
        "zh-Hans": "设置一周的第一天，从周日的 0 开始。"
      }
    },
    {
      "name": "flat",
      "type": "boolean",
      "default": "false",
      "source": "picker",
      "description": {
        "en": "Removes  elevation",
        "ja": "Elevationを削除します。",
        "zh-Hans": "移除提升"
      }
    },
    {
      "name": "full-width",
      "type": "boolean",
      "default": "false",
      "source": "picker",
      "description": {
        "en": "Forces 100% width",
        "ja": "幅を強制的に 100% にする",
        "zh-Hans": "强制 100% 宽度"
      }
    },
    {
      "name": "header-color",
      "type": "string",
      "default": "undefined",
      "source": "picker",
      "description": {
        "en": "Defines the header color. If not specified it will use the color defined by <code>color</code> prop or the default picker color",
        "ja": "ヘッダーの色を定義します。 指定されていない場合は、<code> color </ code> propで定義された色またはデフォルトのピッカーカラーを使用します",
        "zh-Hans": "定义标题颜色。如果未指定，将使用由 <code>color</code> 属性或默认选择器颜色定义的颜色。"
      }
    },
    {
      "name": "header-date-format",
      "type": "function",
      "default": "null",
      "source": "v-date-picker",
      "description": {
        "en": "Allows you to customize the format of the month string that appears in the header of the calendar. Called with date (ISO 8601 **date** string) arguments.",
        "ja": "カレンダーのヘッダーに表示される月の文字列の書式をカスタマイズできます。 日付引数(ISO 8601 **date** 文字列) で呼び出されます。",
        "zh-Hans": "允许您自定义呈现在日历表头中的月份字符串的格式. 使用日期 (ISO 8601 **日期** 字符串) 作为参数调用."
      }
    },
    {
      "name": "landscape",
      "type": "boolean",
      "default": "false",
      "source": "picker",
      "description": {
        "en": "Orients picker horizontal",
        "ja": "ピッカーを水平方向に向けます",
        "zh-Hans": "水平方向选择器"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    },
    {
      "name": "locale",
      "type": "string",
      "default": "undefined",
      "source": "localable",
      "description": {
        "en": "Sets the locale. Accepts a string with a BCP 47 language tag.",
        "ja": "ロケールを設定します。 BCP 47言語タグを含む文字列を受け入れます。",
        "zh-Hans": "设置语言环境。 接受带有 BCP 47 语言标记的字符串。"
      }
    },
    {
      "name": "locale-first-day-of-year",
      "type": [
        "string",
        "number"
      ],
      "default": 0,
      "source": "v-date-picker",
      "description": {
        "en": "Sets the day that determines the first week of the year, starting with 0 for **Sunday**. For ISO 8601 this should be 4.",
        "ja": "年の最初の週を決定する日を設定します。**日曜日** の 0 から始まります。ISO 8601 の場合は、4 にする必要があります。",
        "zh-Hans": "设置决定一年中第一周的日期，从 0 开始，**星期日**。对于 ISO 8601，应该是 4。"
      }
    },
    {
      "name": "max",
      "type": "string",
      "default": "undefined",
      "source": "v-date-picker",
      "description": {
        "en": "Maximum allowed date/month (ISO 8601 format)",
        "ja": "Maximum allowed date/month (ISO 8601 format)",
        "zh-Hans": "允许的最大 日期/月份（ISO 8601格式）"
      }
    },
    {
      "name": "min",
      "type": "string",
      "default": "undefined",
      "source": "v-date-picker",
      "description": {
        "en": "Minimum allowed date/month (ISO 8601 format)",
        "ja": "Minimum allowed date/month (ISO 8601 format)",
        "zh-Hans": "允许的最小 日期/月份（ISO 8601格式）"
      }
    },
    {
      "name": "month-format",
      "type": "function",
      "default": "null",
      "source": "v-date-picker",
      "description": {
        "en": "Formatting function used for displaying months in the months table. Called with date (ISO 8601 **date** string) arguments.",
        "ja": "Formatting function used for displaying months in the months table. Called with date (ISO 8601 **date** string) arguments.",
        "zh-Hans": "用于在月份表中格式化显示的月份字符串的函数. 使用日期 (ISO 8601 **日期** 字符串) 作为参数调用."
      }
    },
    {
      "name": "multiple",
      "type": "boolean",
      "default": "false",
      "source": "v-date-picker",
      "description": {
        "en": "Allow the selection of multiple dates",
        "ja": "複数の日付の選択を許可します",
        "zh-Hans": "允许选择多个日期"
      }
    },
    {
      "name": "next-icon",
      "type": "string",
      "default": "'$next'",
      "source": "v-date-picker",
      "description": {
        "en": "Sets the icon for next month/year button",
        "ja": "翌月/年ボタンのアイコンを設定します。",
        "zh-Hans": "设置下个 月/年 按钮的图标"
      }
    },
    {
      "name": "next-month-aria-label",
      "type": "string",
      "default": "'$vuetify.datePicker.nextMonthAriaLabel'",
      "source": "v-date-picker",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "next-year-aria-label",
      "type": "string",
      "default": "'$vuetify.datePicker.nextYearAriaLabel'",
      "source": "v-date-picker",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "no-title",
      "type": "boolean",
      "default": "false",
      "source": "picker",
      "description": {
        "en": "Hide the picker title",
        "ja": "ピッカータイトルを隠す",
        "zh-Hans": "隐藏选择器标题"
      }
    },
    {
      "name": "picker-date",
      "type": "string",
      "default": "undefined",
      "source": "v-date-picker",
      "description": {
        "en": "Displayed year/month",
        "ja": "表示される年/月",
        "zh-Hans": "显示 年/月"
      }
    },
    {
      "name": "prev-icon",
      "type": "string",
      "default": "'$prev'",
      "source": "v-date-picker",
      "description": {
        "en": "Sets the icon for previous month/year button",
        "ja": "前月/年ボタンのアイコンを設定します。",
        "zh-Hans": "设置上一个 月/年 按钮的图标"
      }
    },
    {
      "name": "prev-month-aria-label",
      "type": "string",
      "default": "'$vuetify.datePicker.prevMonthAriaLabel'",
      "source": "v-date-picker",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "prev-year-aria-label",
      "type": "string",
      "default": "'$vuetify.datePicker.prevYearAriaLabel'",
      "source": "v-date-picker",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "range",
      "type": "boolean",
      "default": "false",
      "source": "v-date-picker",
      "description": {
        "en": "Allow the selection of date range",
        "ja": "日付範囲の選択を許可します",
        "zh-Hans": "允许选择日期范围"
      }
    },
    {
      "name": "reactive",
      "type": "boolean",
      "default": "false",
      "source": "v-date-picker",
      "description": {
        "en": "Updates the picker model when changing months/years automatically",
        "ja": "Updates the picker model when changing months/years automatically",
        "zh-Hans": "自动更改 月份/年份 时更新选择器模型"
      }
    },
    {
      "name": "readonly",
      "type": "boolean",
      "default": "false",
      "source": "v-date-picker",
      "description": {
        "en": "Makes the picker readonly (doesn't allow to select new date)",
        "ja": "ピッカーの読み取り専用を設定します (新しい日付を選択することはできません)",
        "zh-Hans": "使选择器变为只读状态（不允许选择新日期）"
      }
    },
    {
      "name": "scrollable",
      "type": "boolean",
      "default": "false",
      "source": "v-date-picker",
      "description": {
        "en": "Allows changing displayed month with mouse scroll",
        "ja": "マウススクロールで月表示を変更する",
        "zh-Hans": "允许通过鼠标滚动更改显示的月份"
      }
    },
    {
      "name": "selected-items-text",
      "type": "string",
      "default": "'$vuetify.datePicker.itemsSelected'",
      "source": "v-date-picker",
      "description": {
        "en": "Text used for translating the number of selected dates when using *multiple* prop. Can also be customizing globally in [Internationalization](/customization/internationalization).",
        "ja": "*multiple* prop を使用している場合に、選択した日付の数を翻訳するために使用されるテキスト。 [Internationalization](/customization/internationalization) でグローバルにカスタマイズすることもできます。",
        "zh-Hans": "使用 *multiple* 属性时用于翻译所选日期数的文本。 也可以在 [Internationalization](/customization/internationalization) 中进行全局定制。"
      }
    },
    {
      "name": "show-adjacent-months",
      "type": "boolean",
      "default": "false",
      "source": "v-date-picker",
      "description": {
        "en": "Toggles visibility of days from previous and next months",
        "ja": "Toggles visibility of days from previous and next months",
        "zh-Hans": "切换前几个月和后几个月的天数的可见性。"
      }
    },
    {
      "name": "show-current",
      "type": [
        "boolean",
        "string"
      ],
      "default": true,
      "source": "v-date-picker",
      "description": {
        "en": "Toggles visibility of the current date/month outline or shows the provided date/month as a current",
        "ja": "Toggles visibility of the current date/month outline or shows the provided date/month as a current",
        "zh-Hans": "切换当前 日期/月份 大纲的可见性或将提供的 日期/月份 显示为当前日期"
      }
    },
    {
      "name": "show-week",
      "type": "boolean",
      "default": "false",
      "source": "v-date-picker",
      "description": {
        "en": "Toggles visibility of the week numbers in the body of the calendar",
        "ja": "カレンダー本体の週番号の表示を切り替えます",
        "zh-Hans": "切换日历正文中星期几的可见性"
      }
    },
    {
      "name": "title-date-format",
      "type": "function",
      "default": "null",
      "source": "v-date-picker",
      "description": {
        "en": "Allows you to customize the format of the date string that appears in the title of the date picker. Called with date (ISO 8601 **date** string) arguments.",
        "ja": "日付ピッカーのタイトルに表示される日付文字列の書式をカスタマイズできます。 日付引数(ISO 8601 **date** 文字列) で呼び出されます。",
        "zh-Hans": "允许您自定义呈现在日期选择器标题中的日期字符串的格式. 使用日期 (ISO 8601 **日期** 字符串) 作为参数调用."
      }
    },
    {
      "name": "type",
      "type": "string",
      "default": "'date'",
      "source": "v-date-picker",
      "description": {
        "en": "Determines the type of the picker - `date` for date picker, `month` for month picker",
        "ja": "Determines the type of the picker - `date` for date picker, `month` for month picker",
        "zh-Hans": "确定选择器的类型 - 日期选择器的 `date` ，月选择器的 `month`"
      }
    },
    {
      "name": "value",
      "type": [
        "array",
        "string"
      ],
      "default": "undefined",
      "source": "v-date-picker",
      "description": {
        "en": "Date picker model (ISO 8601 format, YYYY-mm-dd or YYYY-mm)",
        "ja": "日付ピッカーモデル (ISO 8601形式, YYYY-mm-dd または YYYY-mm)",
        "zh-Hans": "日期选择器模型（ISO 8601 格式，YYYYY-mm-dd 或 YYYYY-mm）"
      }
    },
    {
      "name": "weekday-format",
      "type": "function",
      "default": "null",
      "source": "v-date-picker",
      "description": {
        "en": "Allows you to customize the format of the weekday string that appears in the body of the calendar. Called with date (ISO 8601 **date** string) arguments.",
        "ja": "カレンダーボディに表示される平日の文字列の書式をカスタマイズできます。 日付引数(ISO 8601 **date** 文字列) で呼び出されます。",
        "zh-Hans": "允许您自定义呈现在日历中的星期字符串的格式. 使用日期 (ISO 8601 **日期** 字符串) 作为参数调用."
      }
    },
    {
      "name": "width",
      "type": [
        "number",
        "string"
      ],
      "default": 290,
      "source": "picker",
      "description": {
        "en": "Width of the picker",
        "ja": "ピッカーの幅",
        "zh-Hans": "选择器宽度"
      }
    },
    {
      "name": "year-format",
      "type": "function",
      "default": "null",
      "source": "v-date-picker",
      "description": {
        "en": "Allows you to customize the format of the year string that appears in the header of the calendar. Called with date (ISO 8601 **date** string) arguments.",
        "ja": "カレンダーのヘッダーに表示される年の文字列の書式をカスタマイズできます。 日付引数(ISO 8601 **date** 文字列) で呼び出されます。",
        "zh-Hans": "允许您自定义呈现在日历表头中的年份字符串的格式. 使用日期 (ISO 8601 **日期** 字符串) 作为参数调用."
      }
    },
    {
      "name": "year-icon",
      "type": "string",
      "default": "undefined",
      "source": "v-date-picker",
      "description": {
        "en": "Sets the icon in the year selection button",
        "ja": "年選択ボタンにアイコンを設定します",
        "zh-Hans": "设置年份选择按钮中的图标"
      }
    }
  ],
  "mixins": [
    "colorable",
    "elevatable",
    "localable",
    "picker",
    "themeable"
  ],
  "slots": [
    {
      "name": "default",
      "description": {
        "en": "Displayed below the calendar, can be used for example for adding action button (`OK` and `Cancel`)",
        "ja": "カレンダーの下に表示されます。アクションボタン (`OK` と `キャンセル`) などに使用できます。",
        "zh-Hans": "显示在日历下方，可用于添加动作按钮（`OK` 和 `Cancel`）。"
      }
    }
  ],
  "events": [
    {
      "name": "<domevent>:date",
      "value": "string",
      "description": {
        "en": "Emitted when the specified DOM event occurs on the date button",
        "ja": "日付ボタンで指定した DOM イベントが発生したときに発生します。",
        "zh-Hans": "在日期按钮上发生指定的DOM事件时发送 (事件)"
      }
    },
    {
      "name": "<domevent>:month",
      "value": "string",
      "description": {
        "en": "Emitted when the specified DOM event occurs on the month button",
        "ja": "Emitted when the specified DOM event occurs on the month button",
        "zh-Hans": "在月份按钮上发生指定的DOM事件时发送 (事件)"
      }
    },
    {
      "name": "<domevent>:year",
      "value": "number",
      "description": {
        "en": "Emitted when the specified DOM event occurs on the year button",
        "ja": "Emitted when the specified DOM event occurs on the year button",
        "zh-Hans": "在年份按钮上发生指定的DOM事件时发送 (事件)"
      }
    },
    {
      "name": "change",
      "value": "string",
      "description": {
        "en": "Reactive date picker emits `input` even when any part of the date (year/month/day) changes, but `change` event is emitted only when the day (for date pickers) or month (for month pickers) changes. If `range` prop is set, date picker emits `change` when both [from, to] are selected.",
        "ja": "Reactive date picker emits `input` even when any part of the date (year/month/day) changes, but `change` event is emitted only when the day (for date pickers) or month (for month pickers) changes. If `range` prop is set, date picker emits `change` when both [from, to] are selected.",
        "zh-Hans": "响应式日期选择器即使在日期（年/月/日）的任何部分发生更改时也会发出 `input` 事件，但是 `change` 事件仅在日期（对于日期选择器）或月份（对于月份选择器）发生更改时才会发出。如果 `range` 属性被设置，日期选择器发出 `change` 时，两个 [from, to] 都被选中。"
      }
    },
    {
      "name": "input",
      "value": "string",
      "description": {
        "en": "The updated bound model",
        "ja": "更新されたバウンドモデル",
        "zh-Hans": "被绑定模型的更新"
      }
    },
    {
      "name": "update:active-picker",
      "value": "string",
      "description": {
        "en": "The `.sync` event for `active-picker` prop",
        "ja": "`active-picker` プロパティ の `.sync` イベント",
        "zh-Hans": "`active-picker` 属性的`.sync` 事件"
      }
    },
    {
      "name": "update:picker-date",
      "value": "string",
      "description": {
        "en": "The `.sync` event for `picker-date` prop",
        "ja": "`picker-date`プロパティの`.sync`イベント",
        "zh-Hans": "`picker-date` 属性的`.sync` 事件"
      }
    }
  ],
  "functions": [],
  "name": "v-date-picker",
  "sass": [
    {
      "name": "$date-picker-years-font-size",
      "default": "16px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$date-picker-years-font-weight",
      "default": "400 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$date-picker-years-portrait-height",
      "default": "290px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$date-picker-years-landscape-height",
      "default": "290px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$date-picker-years-item-padding",
      "default": "8px 0 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$date-picker-years-active-font-size",
      "default": "26px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$date-picker-years-active-font-weight",
      "default": "500 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$date-picker-years-active-padding",
      "default": "10px 0 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$date-picker-years-item-hover-background",
      "default": "rgba(0, 0, 0, 0.12) !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$date-picker-years-item-align",
      "default": "center !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$date-picker-title-year-font-size",
      "default": "14px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$date-picker-title-year-font-weight",
      "default": "500 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$date-picker-title-year-bottom-margin",
      "default": "8px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$date-picker-title-date-font-size",
      "default": "34px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$date-picker-title-date-font-weight",
      "default": "500 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$date-picker-header-padding",
      "default": "4px 16px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$date-picker-header-value-transition",
      "default": "$primary-transition !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$date-picker-header-button-font-weight",
      "default": "bold !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$date-picker-header-button-padding",
      "default": "0.5rem !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$date-picker-header-button-transition",
      "default": "$primary-transition !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$date-picker-table-padding",
      "default": "0 12px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$date-picker-table-height",
      "default": "242px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$date-picker-table-font-size",
      "default": "12px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$date-picker-table-date-button-width",
      "default": "32px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$date-picker-table-date-button-height",
      "default": "32px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$date-picker-table-active-date-color",
      "default": "map-get($shades, 'white') !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$date-picker-table-month-height",
      "default": "56px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$date-picker-table-month-min-width",
      "default": "40px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$date-picker-table-month-max-width",
      "default": "140px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$date-picker-table-date-padding",
      "default": "8px 0 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$date-picker-table-date-font-weight",
      "default": "600 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$date-picker-table-date-width",
      "default": "45px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$date-picker-event-size",
      "default": "8px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$date-picker-event-margin",
      "default": "0 1px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$date-picker-event-border-radius",
      "default": "50% !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$date-picker-event-month-bottom",
      "default": "6px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$date-picker-event-date-bottom",
      "default": "8px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "component": true
}