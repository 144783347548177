module.exports = {
  "props": [
    {
      "name": "absolute",
      "type": "boolean",
      "default": "false",
      "source": "positionable",
      "description": {
        "en": "Applies **position: absolute** to the component.",
        "ja": "コンポーネントに **position: absolute** を適用します。",
        "zh-Hans": "给组件应用 **position: absolute**"
      }
    },
    {
      "name": "active-class",
      "type": "string",
      "default": "'v-btn--active'",
      "source": "v-bottom-navigation",
      "description": {
        "en": "The class applied to a [v-btn](/components/buttons) when activated.",
        "ja": "アクティブ化すると [v-btn](/components/buttons) に適用されるクラス。",
        "zh-Hans": "该类在激活时会被应用到一个 [v-btn](/components/buttons)。"
      }
    },
    {
      "name": "app",
      "type": "boolean",
      "default": "false",
      "source": "applicationable",
      "description": {
        "en": "Designates the component as part of the application layout. Used for dynamically adjusting content sizing. Components using this prop should reside **outside** of `v-main` component to function properly. You can find more information about layouts on the [application page](/components/application). **Note:** this prop automatically applies **position: fixed** to the layout element. You can overwrite this functionality by using the `absolute` prop",
        "ja": "コンポーネントをアプリケーションレイアウトの一部として指定します。コンテンツのサイズを動的に調整するために使用します。 このプロパティを使用するコンポーネントは、 `v-main` コンポーネントの **外側** に正しく機能するようにしてください。 [application page](/components/application) でレイアウトの詳細を確認できます。 **注意:** このプロパティは自動的に **position: fixed** をレイアウト要素に適用します。`absolute` prop を使用すると、この機能を上書きできます。",
        "zh-Hans": "指定该组件作为应用程序布局的一部分。用于动态调整内容的大小。使用此属性的组件只有位于 `v-main `组件的 **外部**才能正常运行。您可以在 [应用程序页面](/components/application) 获取更多有关布局的信息。**注意：** 使用此属性会自动应用**position: fixed** 到布局元素上。您可以使用 `absolute` 属性 来覆盖这个功能。"
      }
    },
    {
      "name": "background-color",
      "type": "string",
      "default": "undefined",
      "source": "v-bottom-navigation",
      "description": {
        "en": "Changes the background-color for the component.",
        "ja": "コンポーネントの背景色を変更します。",
        "zh-Hans": "更改组件的背景颜色。"
      }
    },
    {
      "name": "color",
      "type": "string",
      "default": "undefined",
      "source": "colorable",
      "description": {
        "en": "Applies specified color to the control - it can be the name of material color (for example `success` or `purple`) or css color (`#033` or `rgba(255, 0, 0, 0.5)`). You can find a list of built-in classes on the [colors page](/styles/colors#material-colors).",
        "ja": "指定された色をコントロールに適用します。利用できるのはマテリアルカラーの名前 (たとえば、`success` または ` purple`) または css 表記の色 (`#033`または` rgba(255, 0, 0, 0.5) `) です。[colors のページ](/styles/colors#material-colors) で、組み込みクラスのリストが確認できます。",
        "zh-Hans": "指定控件颜色，颜色值可以是 Material 颜色（例如 <code>success</code>或者 <code>purple</code> ）也可以是 Css 颜色 （例如 <code>#033</code> 或者 <code>rgba(255, 0, 0, 0.5)</code>）。在 [colors page](/zh-Hans/styles/colors#material-colors)中可以找到内置颜色类列表。"
      }
    },
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにダークテーマを適応します。詳しく知りたい場合は、Material Design documentationの[dark themes](https://material.io/design/color/dark-theme.html)を参照してください。",
        "zh-Hans": "将暗色主题变量应用到组件。你可以在 [dark themes](https://material.io/design/color/dark-theme.html) 的 Material Design 文档中找到更多有关信息。"
      }
    },
    {
      "name": "fixed",
      "type": "boolean",
      "default": "false",
      "source": "positionable",
      "description": {
        "en": "Applies **position: fixed** to the component.",
        "ja": "コンポーネントに **position: fixed** を適用します。",
        "zh-Hans": "给组件应用 **position: fixed**"
      }
    },
    {
      "name": "grow",
      "type": "boolean",
      "default": "false",
      "source": "v-bottom-navigation",
      "description": {
        "en": "Force [v-btn](/components/buttons)s to take up all available space.",
        "ja": "[v-btn](/components/buttons) は強制的に利用可能なすべてのスペースを占有します。",
        "zh-Hans": "强制 [v-btn](/components/buttons) 占用所有可用空间。"
      }
    },
    {
      "name": "height",
      "type": [
        "number",
        "string"
      ],
      "default": 56,
      "source": "measurable",
      "description": {
        "en": "Sets the height for the component.",
        "ja": "コンポーネントに高さを設定します。",
        "zh-Hans": "设定组件的高度。"
      }
    },
    {
      "name": "hide-on-scroll",
      "type": "boolean",
      "default": "false",
      "source": "v-bottom-navigation",
      "description": {
        "en": "Will transition the navigation off screen when scrolling up.",
        "ja": "上にスクロールすると、ナビゲーションが画面外に遷移します。",
        "zh-Hans": "向上滚动时过渡到屏幕外。"
      }
    },
    {
      "name": "horizontal",
      "type": "boolean",
      "default": "false",
      "source": "v-bottom-navigation",
      "description": {
        "en": "Uses an alternative horizontal styling for [v-btn](/components/buttons).",
        "ja": "[v-btn](/components/buttons) に別の水平スタイルを使用します。",
        "zh-Hans": "使用 [v-btn](/components/buttons) 的替代横向样式。"
      }
    },
    {
      "name": "input-value",
      "type": "boolean",
      "default": "true",
      "source": "toggleable",
      "description": {
        "en": "Controls whether the component is visible or hidden. Supports the **.sync** modifier.",
        "ja": "コンポーネントを表示するか非表示にするかを制御します。**.sync** 修飾子をサポートします。",
        "zh-Hans": "控制组件是否可见。支持 **.sync** 修饰符。"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    },
    {
      "name": "mandatory",
      "type": "boolean",
      "default": "false",
      "source": "v-bottom-navigation",
      "description": {
        "en": "Forces a value to always be selected (if available).",
        "ja": "値が常に選択状態であることを強制します (可能な場合)。",
        "zh-Hans": "强制始终选择一个值（如果可用）。"
      }
    },
    {
      "name": "max-height",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the maximum height for the component.",
        "ja": "コンテンツの高さの最大値を設定します。",
        "zh-Hans": "设定组件的最大高度。"
      }
    },
    {
      "name": "max-width",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the maximum width for the component.",
        "ja": "コンテンツの横幅の最大値を設定します。",
        "zh-Hans": "设定组件的最大宽度。"
      }
    },
    {
      "name": "min-height",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the minimum height for the component.",
        "ja": "コンテンツの高さの最小値を設定します。",
        "zh-Hans": "设定组件的最小高度。"
      }
    },
    {
      "name": "min-width",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the minimum width for the component.",
        "ja": "コンテンツの横幅の最小値を設定します。",
        "zh-Hans": "设定组件的最小宽度。"
      }
    },
    {
      "name": "scroll-target",
      "type": "string",
      "default": "undefined",
      "source": "scrollable",
      "description": {
        "en": "Designates the element to target for scrolling events. Uses `window` by default.",
        "ja": "スクロール イベントの対象となる要素を指定します。デフォルトでは `window` を使用します。",
        "zh-Hans": "将组件指定为相对的滚动目标。默认为 `window`。"
      }
    },
    {
      "name": "scroll-threshold",
      "type": [
        "string",
        "number"
      ],
      "default": "undefined",
      "source": "scrollable",
      "description": {
        "en": "The amount of scroll distance down before **hide-on-scroll** activates.",
        "ja": "**hide-on-scroll** がアクティブになる前のスクロール距離",
        "zh-Hans": "在 **hide-on-scroll** 激活之前的滚动距离。"
      }
    },
    {
      "name": "shift",
      "type": "boolean",
      "default": "false",
      "source": "v-bottom-navigation",
      "description": {
        "en": "Hides text of [v-btn](/components/buttons)s when they are not active.",
        "ja": "[v-btn](/components/buttons) がアクティブでない場合は、テキストを非表示にします。",
        "zh-Hans": "当 [v-btn](/components/buttons) 未激活时隐藏它们的文本。"
      }
    },
    {
      "name": "tag",
      "type": "string",
      "default": "'div'",
      "source": "v-bottom-navigation",
      "description": {
        "en": "Specify a custom tag used on the root element.",
        "ja": "コンポーネント内で使用するカスタムタグを指定します。",
        "zh-Hans": "指定在根元素上使用的自定义标签。"
      }
    },
    {
      "name": "value",
      "type": "any",
      "default": "undefined",
      "source": "proxyable",
      "description": {
        "en": "Holds the value of the currently active [v-btn](/components/buttons). If the button has no value supplied, its index will be used instead..",
        "ja": "現在アクティブな [v-btn](/components/buttons) の値を保持します。ボタンに値が与えられていない場合、そのインデックスが代わりに使用されます。",
        "zh-Hans": "保存当前激活了的 [v-btn](/components/buttons) 的值。若按钮没有值，则使用其索引号。"
      }
    },
    {
      "name": "width",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the width for the component.",
        "ja": "コンポーネントに横幅を設定します。",
        "zh-Hans": "设定组件的宽度。"
      }
    }
  ],
  "mixins": [
    "applicationable",
    "colorable",
    "measurable",
    "positionable",
    "proxyable",
    "scrollable",
    "themeable",
    "toggleable"
  ],
  "slots": [
    {
      "name": "default",
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    }
  ],
  "events": [
    {
      "name": "change",
      "value": "any",
      "description": {
        "en": "The value of currently selected button. If no value is assigned, will be the current index of the button.",
        "ja": "現在選択されているボタンの値。値が割り当てられていない場合は、ボタンの現在のインデックスになります。",
        "zh-Hans": "当前被选定按钮的值。若未分配值，则为按钮的当前索引号。"
      }
    },
    {
      "name": "update:input-value",
      "value": "string | number",
      "description": {
        "en": "The event used for `input-value.sync`.",
        "ja": "`input-value.sync` 用のイベント",
        "zh-Hans": "用于 `input-value.sync` 的事件。"
      }
    }
  ],
  "functions": [],
  "name": "v-bottom-navigation",
  "sass": [
    {
      "name": "$bottom-nav-btn-font-size",
      "default": "map-deep-get($headings, 'caption', 'size') !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$bottom-nav-btn-min-width",
      "default": "80px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$bottom-nav-btn-max-width",
      "default": "168px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$bottom-nav-shift-btn-top",
      "default": "calc(100% - 12px) !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$bottom-nav-shift-btn-active-top",
      "default": "calc(100% - 22px) !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "component": true
}