module.exports = {
  "props": [
    {
      "name": "append-icon",
      "type": "string",
      "default": "undefined",
      "source": "v-input",
      "description": {
        "en": "Appends an icon to the component, uses the same syntax as `v-icon`",
        "ja": "`v-icon`と同じ構文を使用し、コンポーネントにアイコンを追加します",
        "zh-Hans": "在组件上附加一个图标，使用与 `v-icon` 相同的语法"
      }
    },
    {
      "name": "background-color",
      "type": "string",
      "default": "undefined",
      "source": "v-input",
      "description": {
        "en": "Changes the background-color of the input",
        "ja": "inputの背景色を変更します",
        "zh-Hans": "更改输入的背景颜色"
      }
    },
    {
      "name": "color",
      "type": "string",
      "default": "undefined",
      "source": "colorable",
      "description": {
        "en": "Applies specified color to the control - it can be the name of material color (for example `success` or `purple`) or css color (`#033` or `rgba(255, 0, 0, 0.5)`). You can find a list of built-in classes on the [colors page](/styles/colors#material-colors).",
        "ja": "指定された色をコントロールに適用します。利用できるのはマテリアルカラーの名前 (たとえば、`success` または ` purple`) または css 表記の色 (`#033`または` rgba(255, 0, 0, 0.5) `) です。[colors のページ](/styles/colors#material-colors) で、組み込みクラスのリストが確認できます。",
        "zh-Hans": "指定控件颜色，颜色值可以是 Material 颜色（例如 <code>success</code>或者 <code>purple</code> ）也可以是 Css 颜色 （例如 <code>#033</code> 或者 <code>rgba(255, 0, 0, 0.5)</code>）。在 [colors page](/zh-Hans/styles/colors#material-colors)中可以找到内置颜色类列表。"
      }
    },
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. This will default the components color to _white_ unless you've configured your [application theme](/customization/theme) to **dark** or if you are using the **color** prop on the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにdarkテーマのバリエーションを適用します。[アプリケーションのテーマ](/customization/theme) を **dark** に設定している場合や、コンポーネントに **color** propを使用している場合を除き、コンポーネントの色はデフォルトで _white_ になります。Material Design documentationの[ダークテーマ](https://material.io/design/color/dark-theme.html) に詳しい情報があります。",
        "zh-Hans": "将深色主题变量应用于组件。 除非您已将 [application theme](/customization/theme) 配置为 **dark** 或在组件上使用 **color** 属性，否则组件的颜色默认为 _white_。 您可以在材料设计文档中找到更多信息 [dark themes](https://material.io/design/color/dark-theme.html)。"
      }
    },
    {
      "name": "dense",
      "type": "boolean",
      "default": "false",
      "source": "v-input",
      "description": {
        "en": "Reduces the input height",
        "ja": "inputの高さを低くします",
        "zh-Hans": "降低输入高度"
      }
    },
    {
      "name": "disabled",
      "type": "boolean",
      "default": false,
      "source": "validatable",
      "description": {
        "en": "Disable the input",
        "ja": "入力を無効にする",
        "zh-Hans": "禁用输入"
      }
    },
    {
      "name": "error",
      "type": "boolean",
      "default": "false",
      "source": "validatable",
      "description": {
        "en": "Puts the input in a manual error state",
        "ja": "入力を手動でエラー状態にします。",
        "zh-Hans": "将输入框设置为手动错误状态。"
      }
    },
    {
      "name": "error-count",
      "type": [
        "number",
        "string"
      ],
      "default": 1,
      "source": "validatable",
      "description": {
        "en": "The total number of errors that should display at once",
        "ja": "一度に表示するエラーの数を設定します。",
        "zh-Hans": "一次性显示的错误总数"
      }
    },
    {
      "name": "error-messages",
      "type": [
        "string",
        "array"
      ],
      "default": [],
      "source": "validatable",
      "description": {
        "en": "Puts the input in an error state and passes through custom error messages. Will be combined with any validations that occur from the **rules** prop. This field will not trigger validation",
        "ja": "入力をerror状態にして、カスタムエラーメッセージをパススルーします。**rules**  propから発生するすべてのバリデーションと組み合わせられます。このフィールドはバリデーションのトリガーにはなりません。",
        "zh-Hans": "将输入框置于错误状态，并传入自定义的错误信息。将与来自 **rules** 属性的任何验证相结合。这个字段不会触发验证。"
      }
    },
    {
      "name": "height",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "v-input",
      "description": {
        "en": "Sets the height of the input",
        "ja": "高さを設定します",
        "zh-Hans": "设置输入的高度"
      }
    },
    {
      "name": "hide-details",
      "type": [
        "boolean",
        "string"
      ],
      "default": "undefined",
      "source": "v-input",
      "description": {
        "en": "Hides hint and validation errors. When set to `auto` messages will be rendered only if there's a message (hint, error message, counter value etc) to display",
        "ja": "ヒントやバリデーションエラーを非表示にします。 `auto` が設定されている場合、表示するメッセージ（ヒント、エラーメッセージ、カウンター値など）がある場合のみ表示します",
        "zh-Hans": "隐藏提示和验证错误。当设置为 `auto` 时，只有在有信息（提示、错误信息、计数器值等）要显示时，才会显示信息。"
      }
    },
    {
      "name": "hint",
      "type": "string",
      "default": "undefined",
      "source": "v-input",
      "description": {
        "en": "Hint text",
        "ja": "ヒントを設定します。",
        "zh-Hans": "提示文本"
      }
    },
    {
      "name": "id",
      "type": "string",
      "default": "undefined",
      "source": "v-input",
      "description": {
        "en": "Sets the DOM id on the component",
        "ja": "コンポーネントにDOM idを設定します",
        "zh-Hans": "设置组件上的 DOM id"
      }
    },
    {
      "name": "inverse-label",
      "type": "boolean",
      "default": "false",
      "source": "v-slider",
      "description": {
        "en": "Reverse the label position. Works with **rtl**.",
        "ja": "ラベルのポジションを逆にします。**rtl** の (右から左に書く) 言語で利用できます。",
        "zh-Hans": "使用 **rtl** 反转标签位置。"
      }
    },
    {
      "name": "label",
      "type": "string",
      "source": "v-input",
      "description": {
        "en": "Sets input label",
        "ja": "ラベルを設定します。",
        "zh-Hans": "设置输入标签"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    },
    {
      "name": "loader-height",
      "type": [
        "number",
        "string"
      ],
      "default": 2,
      "source": "loadable",
      "description": {
        "en": "Specifies the height of the loader",
        "ja": "loader の高さを指定します。",
        "zh-Hans": "指定加载器的高度"
      }
    },
    {
      "name": "loading",
      "type": [
        "boolean",
        "string"
      ],
      "default": false,
      "source": "v-input",
      "description": {
        "en": "Displays linear progress bar. Can either be a String which specifies which color is applied to the progress bar (any material color or theme color - **primary**, **secondary**, **success**, **info**, **warning**, **error**) or a Boolean which uses the component **color** (set by color prop - if it's supported by the component) or the primary color",
        "ja": "線形プログレス バーを表示します。 進行状況バー (任意のマテリアルカラーまたはテーマカラー - **primary**, **secondary**, **success**, **info**, **warning**, **error**) に適用する色を指定する文字列、あるいはコンポーネント **color** (color propで設定 - コンポーネントでサポートされている場合) またはprimary色を使用する真偽値を指定できます。",
        "zh-Hans": "显示线性进度条。可以是指定将哪种颜色应用于进度条的字符串（任何 material 色彩——主要（primary）, 次要（secondary）, 成功（success）, 信息（info），警告（warning），错误（error）），或者使用组件的布尔值 **color**（由色彩属性设置——如果它被组件支持的话）还可以是原色。"
      }
    },
    {
      "name": "max",
      "type": [
        "number",
        "string"
      ],
      "default": 100,
      "source": "v-slider",
      "description": {
        "en": "Sets the maximum allowed value",
        "ja": "入力の最大値を設定します",
        "zh-Hans": "设置允许的最大值"
      }
    },
    {
      "name": "messages",
      "type": [
        "string",
        "array"
      ],
      "default": [],
      "source": "validatable",
      "description": {
        "en": "Displays a list of messages or message if using a string",
        "ja": "文字列を使用している場合は、メッセージまたはメッセージの一覧を表示します。",
        "zh-Hans": "显示消息列表或消息（如果使用字符串）"
      }
    },
    {
      "name": "min",
      "type": [
        "number",
        "string"
      ],
      "default": 0,
      "source": "v-slider",
      "description": {
        "en": "Sets the minimum allowed value",
        "ja": "入力の最小値を設定します",
        "zh-Hans": "设置允许的最小值"
      }
    },
    {
      "name": "persistent-hint",
      "type": "boolean",
      "default": "false",
      "source": "v-input",
      "description": {
        "en": "Forces hint to always be visible",
        "ja": "ヒントを常に表示します",
        "zh-Hans": "强制提示总是可见的"
      }
    },
    {
      "name": "prepend-icon",
      "type": "string",
      "default": "undefined",
      "source": "v-input",
      "description": {
        "en": "Prepends an icon to the component, uses the same syntax as `v-icon`",
        "ja": "コンポーネントの先頭にアイコンが追加されます。`v-icon` と同じ構文を使用します。",
        "zh-Hans": "在组件前添加一个图标，使用与 `v-icon` 相同的语法"
      }
    },
    {
      "name": "readonly",
      "type": "boolean",
      "default": false,
      "source": "validatable",
      "description": {
        "en": "Puts input in readonly state",
        "ja": "読み取り専用状態に設定します。",
        "zh-Hans": "将输入设置为只读状态"
      }
    },
    {
      "name": "rules",
      "type": "array",
      "default": [],
      "source": "validatable",
      "description": {
        "en": "Accepts a mixed array of types `function`, `boolean` and `string`. Functions pass an input value as an argument and must return either `true` / `false` or a `string` containing an error message. The input field will enter an error state if a function returns (or any value in the array contains) `false` or is a `string`",
        "ja": "`function`, ` boolean`, `string`の混合配列を受け入れます。関数は入力値を引数として渡し、 `true` /`false`またはエラーメッセージを含む `string`のいずれかを返す必要があります。関数が `false`を返す（または配列内の任意の値に含まれる）場合、または`string`の場合、入力フィールドはエラー状態になります。",
        "zh-Hans": "接受不同类型的 `function`, `boolean` 和 `string` 。 函数传递输入值作为参数，必须返回 `true` / `false` 或包含错误消息的 `string` 。 如果函数返回 (或数组包含的任何值) false` 或 `string` ，输入字段将输入错误状态"
      }
    },
    {
      "name": "step",
      "type": [
        "number",
        "string"
      ],
      "default": 1,
      "source": "v-slider",
      "description": {
        "en": "If greater than 0, sets step interval for ticks",
        "ja": "0 より大きな数値を指定すると、tick に対して step のインターバルを設定します",
        "zh-Hans": "如果大于0，则为滑块上的点设置步骤间隔"
      }
    },
    {
      "name": "success",
      "type": "boolean",
      "default": "false",
      "source": "validatable",
      "description": {
        "en": "Puts the input in a manual success state",
        "ja": "入力を手動で成功状態にします。",
        "zh-Hans": "将输入设置为手动成功状态"
      }
    },
    {
      "name": "success-messages",
      "type": [
        "string",
        "array"
      ],
      "default": [],
      "source": "validatable",
      "description": {
        "en": "Puts the input in a success state and passes through custom success messages.",
        "ja": "入力をsuccess状態にして、カスタムの成功メッセージをパススルーさせます。",
        "zh-Hans": "将输入设置为成功状态，并传递自定义成功消息。"
      }
    },
    {
      "name": "thumb-color",
      "type": "string",
      "default": "undefined",
      "source": "v-slider",
      "description": {
        "en": "Sets the thumb and thumb label color",
        "ja": "thumb と thumb ラベルの色を設定します",
        "zh-Hans": "设置拇指和拇指标签颜色"
      }
    },
    {
      "name": "thumb-label",
      "type": [
        "boolean",
        "string"
      ],
      "default": "undefined",
      "source": "v-slider",
      "description": {
        "en": "Show thumb label. If `true` it shows label when using slider. If set to `'always'` it always shows label.",
        "ja": "thumb ラベルを表示します。`true` の場合、スライダの使用中にのみラベルを表示します。`'always'` の場合、常にラベルを表示します。",
        "zh-Hans": "显示拇指标签"
      }
    },
    {
      "name": "thumb-size",
      "type": [
        "number",
        "string"
      ],
      "default": 32,
      "source": "v-slider",
      "description": {
        "en": "Controls the size of the thumb label.",
        "ja": "thumb ラベルのサイズを調整します",
        "zh-Hans": "控制缩略图标签的大小。"
      }
    },
    {
      "name": "tick-labels",
      "type": "array",
      "default": [],
      "source": "v-slider",
      "description": {
        "en": "When provided with Array<string>, will attempt to map the labels to each step in index order",
        "ja": "Array<string> が与えられた場合、各ステップに対して index の順にラベルをマッピングするように試みます。",
        "zh-Hans": "与 Array<string> 一起提供时，将尝试按索引顺序将标签映射到每个步骤"
      }
    },
    {
      "name": "tick-size",
      "type": [
        "number",
        "string"
      ],
      "default": 2,
      "source": "v-slider",
      "description": {
        "en": "Controls the size of **ticks**",
        "ja": "**tick** のサイズをコントロールします",
        "zh-Hans": "控制 **ticks** 的大小"
      }
    },
    {
      "name": "ticks",
      "type": [
        "boolean",
        "string"
      ],
      "default": false,
      "source": "v-slider",
      "description": {
        "en": "Show track ticks. If `true` it shows ticks when using slider. If set to `'always'` it always shows ticks.",
        "ja": "track tick を表示します。`true` の場合、スライダの使用中にのみ tick を表示します。`'always'` の場合、常に tick を表示します。",
        "zh-Hans": "显示刻度线。如果 `true` ，使用滑块时将显示刻度线。如果设置为 `'always'` \n，它总是显示刻度线。"
      }
    },
    {
      "name": "track-color",
      "type": "string",
      "default": "undefined",
      "source": "v-slider",
      "description": {
        "en": "Sets the track's color",
        "ja": "track の色を設定します",
        "zh-Hans": "设置刻度线颜色"
      }
    },
    {
      "name": "track-fill-color",
      "type": "string",
      "default": "undefined",
      "source": "v-slider",
      "description": {
        "en": "Sets the track's fill color",
        "ja": "track の塗りつぶしの色を設定します",
        "zh-Hans": "设置刻度线填充颜色"
      }
    },
    {
      "name": "validate-on-blur",
      "type": "boolean",
      "default": "false",
      "source": "validatable",
      "description": {
        "en": "Delays validation until blur event",
        "ja": "blurイベントまでバリデーションを遅らせる",
        "zh-Hans": "延迟验证直到失去焦点的事件被触发"
      }
    },
    {
      "name": "value",
      "type": "any",
      "source": "v-input",
      "description": {
        "en": "The input's value",
        "ja": "入力の値",
        "zh-Hans": "输入的值"
      }
    },
    {
      "name": "vertical",
      "type": "boolean",
      "default": "false",
      "source": "v-slider",
      "description": {
        "en": "Changes slider direction to vertical",
        "ja": "スライダーの方向を縦に変更します",
        "zh-Hans": "将滑块方向切换为垂直方向"
      }
    }
  ],
  "mixins": [
    "loadable"
  ],
  "slots": [
    {
      "name": "append",
      "source": "v-input",
      "description": {
        "en": "Adds an item inside the input and after input content",
        "ja": "入力内容と入力内容の後に項目を追加します",
        "zh-Hans": "在输入框内部，文字之后添加一个内容"
      }
    },
    {
      "name": "default",
      "source": "v-input",
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    },
    {
      "name": "label",
      "source": "v-input",
      "description": {
        "en": "Replaces the default label",
        "ja": "デフォルトのラベルを置き換えます",
        "zh-Hans": "替换默认标签"
      }
    },
    {
      "name": "message",
      "props": {
        "key": "number, // the messages index",
        "message": "string, // the message"
      },
      "source": "v-input",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "prepend",
      "source": "v-input",
      "description": {
        "en": "Adds an item outside the input and before input content",
        "ja": "入力の外側と入力の内容の前にアイテムを追加します",
        "zh-Hans": "在输入框外部，前面添加一个内容"
      }
    },
    {
      "name": "progress",
      "source": "v-slider",
      "description": {
        "en": "Slot for custom progress linear (displayed when **loading** prop is not equal to Boolean False)",
        "ja": "カスタムprogress linearのスロット（**loading** プロパティが真偽値 false でない場合に表示されます）",
        "zh-Hans": "自定义进度线的插槽（**loading**属性不等于布尔值False时显示)"
      }
    },
    {
      "name": "thumb-label",
      "props": {
        "value": "number | string"
      },
      "source": "v-slider",
      "description": {
        "en": "Replaces the content inside the thumb label",
        "ja": "thumb ラベルの内側のコンテンツを置き換える",
        "zh-Hans": "替换缩略图标签内的内容"
      }
    }
  ],
  "events": [
    {
      "name": "change",
      "source": "v-slider",
      "value": "number",
      "description": {
        "en": "Emitted when the input is changed by user interaction",
        "ja": "ユーザーの操作によって値が変更されたときに発生します。",
        "zh-Hans": "当用户交互更改输入时发出"
      }
    },
    {
      "name": "click",
      "source": "v-input",
      "value": "MouseEvent",
      "description": {
        "en": "Emitted when input is clicked",
        "ja": "inputをクリックしたときに発生します",
        "zh-Hans": "在单击输入时发出"
      }
    },
    {
      "name": "click:append",
      "source": "v-input",
      "value": "Event",
      "description": {
        "en": "Emitted when appended icon is clicked",
        "ja": "アペンドアイコンがクリックされたときに発生します",
        "zh-Hans": "单击附加图标时发出"
      }
    },
    {
      "name": "click:prepend",
      "source": "v-input",
      "value": "Event",
      "description": {
        "en": "Emitted when prepended icon is clicked",
        "ja": "プリペンドアイコンがクリックされたときに発生します",
        "zh-Hans": "单击前置图标时发出"
      }
    },
    {
      "name": "end",
      "source": "v-slider",
      "value": "number",
      "description": {
        "en": "Slider value emitted at the end of slider movement",
        "ja": "スライダーの移動の最後に発生するスライダー値",
        "zh-Hans": "滑块移动结束时发出的滑块值"
      }
    },
    {
      "name": "input",
      "source": "v-slider",
      "value": "number",
      "description": {
        "en": "The updated bound model",
        "ja": "更新されたバウンドモデル",
        "zh-Hans": "被绑定模型的更新"
      }
    },
    {
      "name": "mousedown",
      "source": "v-input",
      "value": "MouseEvent",
      "description": {
        "en": "Emitted when click is pressed",
        "ja": "クリック時、押された（pressed）ときに発生します",
        "zh-Hans": "单击时发出"
      }
    },
    {
      "name": "mouseup",
      "source": "v-input",
      "value": "MouseEvent",
      "description": {
        "en": "Emitted when click is released",
        "ja": "クリック後、放された（released）ときに発生します",
        "zh-Hans": "当单击释放时，发出"
      }
    },
    {
      "name": "start",
      "source": "v-slider",
      "value": "number",
      "description": {
        "en": "Slider value emitted at start of slider movement",
        "ja": "スライダーの移動の最初に発生するスライダー値",
        "zh-Hans": "滑块移动开始时发出的滑块值"
      }
    },
    {
      "name": "update:error",
      "source": "validatable",
      "value": "boolean",
      "description": {
        "en": "The `error.sync` event",
        "ja": "`error.sync` イベント",
        "zh-Hans": "`error.sync` 事件"
      }
    }
  ],
  "functions": [],
  "exclude": {
    "props": [
      "type",
      "hide-spin-buttons"
    ]
  },
  "name": "v-slider",
  "sass": [
    {
      "name": "$chip-group-no-color-opacity",
      "default": ".22 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$chip-group-opacity",
      "default": ".32 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$slider-horizontal-left",
      "default": "8px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$slider-horizontal-min-height",
      "default": "32px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$slider-horizontal-right",
      "default": "8px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$slider-label-margin-end",
      "default": "12px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$slider-label-margin-start",
      "default": "12px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$slider-state-track-background-opacity",
      "default": "0.4 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$slider-thumb-before-opacity",
      "default": "0.3 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$slider-thumb-border-radius",
      "default": "50% !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$slider-thumb-focused-size-increase",
      "default": "24px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$slider-thumb-label-font-size",
      "default": "map-deep-get($headings, 'caption', 'size') !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$slider-thumb-label-height",
      "default": "32px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$slider-thumb-label-transition",
      "default": ".3s map-get($transition, 'fast-in-fast-out') !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$slider-thumb-label-width",
      "default": "32px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$slider-thumb-size",
      "default": "12px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$slider-thumb-touch-size",
      "default": "42px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$slider-tick-border-radius",
      "default": "0 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$slider-track-border-radius",
      "default": "0 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$slider-track-width",
      "default": "2px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$slider-transition",
      "default": ".3s map-get($transition, 'swing') !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$slider-vertical-margin-bottom",
      "default": "12px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$slider-vertical-margin-top",
      "default": "12px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$slider-vertical-min-height",
      "default": "150px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "component": true
}