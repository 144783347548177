module.exports = {
  "props": [
    {
      "name": "append-icon",
      "type": "string",
      "default": "undefined",
      "source": "v-input",
      "description": {
        "en": "Appends an icon to the component, uses the same syntax as `v-icon`",
        "ja": "`v-icon`と同じ構文を使用し、コンポーネントにアイコンを追加します",
        "zh-Hans": "在组件上附加一个图标，使用与 `v-icon` 相同的语法"
      }
    },
    {
      "name": "background-color",
      "type": "string",
      "default": "undefined",
      "source": "v-input",
      "description": {
        "en": "Changes the background-color of the input",
        "ja": "inputの背景色を変更します",
        "zh-Hans": "更改输入的背景颜色"
      }
    },
    {
      "name": "color",
      "type": "string",
      "default": "undefined",
      "source": "colorable",
      "description": {
        "en": "Applies specified color to the control - it can be the name of material color (for example `success` or `purple`) or css color (`#033` or `rgba(255, 0, 0, 0.5)`). You can find a list of built-in classes on the [colors page](/styles/colors#material-colors).",
        "ja": "指定された色をコントロールに適用します。利用できるのはマテリアルカラーの名前 (たとえば、`success` または ` purple`) または css 表記の色 (`#033`または` rgba(255, 0, 0, 0.5) `) です。[colors のページ](/styles/colors#material-colors) で、組み込みクラスのリストが確認できます。",
        "zh-Hans": "指定控件颜色，颜色值可以是 Material 颜色（例如 <code>success</code>或者 <code>purple</code> ）也可以是 Css 颜色 （例如 <code>#033</code> 或者 <code>rgba(255, 0, 0, 0.5)</code>）。在 [colors page](/zh-Hans/styles/colors#material-colors)中可以找到内置颜色类列表。"
      }
    },
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. This will default the components color to _white_ unless you've configured your [application theme](/customization/theme) to **dark** or if you are using the **color** prop on the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにdarkテーマのバリエーションを適用します。[アプリケーションのテーマ](/customization/theme) を **dark** に設定している場合や、コンポーネントに **color** propを使用している場合を除き、コンポーネントの色はデフォルトで _white_ になります。Material Design documentationの[ダークテーマ](https://material.io/design/color/dark-theme.html) に詳しい情報があります。",
        "zh-Hans": "将深色主题变量应用于组件。 除非您已将 [application theme](/customization/theme) 配置为 **dark** 或在组件上使用 **color** 属性，否则组件的颜色默认为 _white_。 您可以在材料设计文档中找到更多信息 [dark themes](https://material.io/design/color/dark-theme.html)。"
      }
    },
    {
      "name": "dense",
      "type": "boolean",
      "default": "false",
      "source": "v-input",
      "description": {
        "en": "Reduces the input height",
        "ja": "inputの高さを低くします",
        "zh-Hans": "降低输入高度"
      }
    },
    {
      "name": "disabled",
      "type": "boolean",
      "default": false,
      "source": "validatable",
      "description": {
        "en": "Disable the input",
        "ja": "入力を無効にする",
        "zh-Hans": "禁用输入"
      }
    },
    {
      "name": "error",
      "type": "boolean",
      "default": "false",
      "source": "validatable",
      "description": {
        "en": "Puts the input in a manual error state",
        "ja": "入力を手動でエラー状態にします。",
        "zh-Hans": "将输入框设置为手动错误状态。"
      }
    },
    {
      "name": "error-count",
      "type": [
        "number",
        "string"
      ],
      "default": 1,
      "source": "validatable",
      "description": {
        "en": "The total number of errors that should display at once",
        "ja": "一度に表示するエラーの数を設定します。",
        "zh-Hans": "一次性显示的错误总数"
      }
    },
    {
      "name": "error-messages",
      "type": [
        "string",
        "array"
      ],
      "default": [],
      "source": "validatable",
      "description": {
        "en": "Puts the input in an error state and passes through custom error messages. Will be combined with any validations that occur from the **rules** prop. This field will not trigger validation",
        "ja": "入力をerror状態にして、カスタムエラーメッセージをパススルーします。**rules**  propから発生するすべてのバリデーションと組み合わせられます。このフィールドはバリデーションのトリガーにはなりません。",
        "zh-Hans": "将输入框置于错误状态，并传入自定义的错误信息。将与来自 **rules** 属性的任何验证相结合。这个字段不会触发验证。"
      }
    },
    {
      "name": "false-value",
      "type": "any",
      "default": "undefined",
      "source": "selectable",
      "description": {
        "en": "Sets value for falsy state",
        "ja": "falseとみなされる状態の値を設定します。",
        "zh-Hans": "设置假值"
      }
    },
    {
      "name": "flat",
      "type": "boolean",
      "default": "false",
      "source": "v-switch",
      "description": {
        "en": "Display component without elevation. Default elevation for thumb is 4dp, `flat` resets it",
        "ja": "Elevationのないコンポーネントを表示します。デフォルトのElevationは 4dp で、`flat` はリセットします。",
        "zh-Hans": "显示没有海拔的组件。 拇指的默认海拔为 4dp，`flat` 重置它"
      }
    },
    {
      "name": "hide-details",
      "type": [
        "boolean",
        "string"
      ],
      "default": "undefined",
      "source": "v-input",
      "description": {
        "en": "Hides hint and validation errors. When set to `auto` messages will be rendered only if there's a message (hint, error message, counter value etc) to display",
        "ja": "ヒントやバリデーションエラーを非表示にします。 `auto` が設定されている場合、表示するメッセージ（ヒント、エラーメッセージ、カウンター値など）がある場合のみ表示します",
        "zh-Hans": "隐藏提示和验证错误。当设置为 `auto` 时，只有在有信息（提示、错误信息、计数器值等）要显示时，才会显示信息。"
      }
    },
    {
      "name": "hint",
      "type": "string",
      "default": "undefined",
      "source": "v-input",
      "description": {
        "en": "Hint text",
        "ja": "ヒントを設定します。",
        "zh-Hans": "提示文本"
      }
    },
    {
      "name": "id",
      "type": "string",
      "default": "undefined",
      "source": "v-input",
      "description": {
        "en": "Sets the DOM id on the component",
        "ja": "コンポーネントにDOM idを設定します",
        "zh-Hans": "设置组件上的 DOM id"
      }
    },
    {
      "name": "input-value",
      "type": "any",
      "default": "undefined",
      "source": "selectable",
      "description": {
        "en": "The **v-model** bound value",
        "ja": "**v-model** のバインド値",
        "zh-Hans": "**v-model** 的绑定值"
      }
    },
    {
      "name": "inset",
      "type": "boolean",
      "default": "false",
      "source": "v-switch",
      "description": {
        "en": "Enlarge the `v-switch` track to encompass the thumb",
        "ja": "Enlarge the `v-switch` track to encompass the thumb",
        "zh-Hans": "扩展 `v-switch` 轨迹以包含缩略图"
      }
    },
    {
      "name": "label",
      "type": "string",
      "source": "v-input",
      "description": {
        "en": "Sets input label",
        "ja": "ラベルを設定します。",
        "zh-Hans": "设置输入标签"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    },
    {
      "name": "loading",
      "type": [
        "boolean",
        "string"
      ],
      "default": false,
      "source": "v-input",
      "description": {
        "en": "Displays circular progress bar. Can either be a String which specifies which color is applied to the progress bar (any material color or theme color - primary, secondary, success, info, warning, error) or a Boolean which uses the component color (set by color prop - if it's supported by the component) or the primary color",
        "ja": "円形のプログレスバーを表示します。プログレスバーの色には、任意のマテリアルカラーとテーマカラー（primary、secondary、success、info、warning、error）か、Booleanのコンポーネントのprimary色（親コンポーネントがサポートしている場合）が指定できます。",
        "zh-Hans": "显示循环进度条。 可以是指定将哪种颜色应用于进度栏的字符串（任何 material color 或主题颜色――primary, secondary, success, info, warning, error），也可以是使用组件颜色的布尔值 （由颜色属性设置 - 如果是由组件支持） 或主色"
      }
    },
    {
      "name": "messages",
      "type": [
        "string",
        "array"
      ],
      "default": [],
      "source": "validatable",
      "description": {
        "en": "Displays a list of messages or message if using a string",
        "ja": "文字列を使用している場合は、メッセージまたはメッセージの一覧を表示します。",
        "zh-Hans": "显示消息列表或消息（如果使用字符串）"
      }
    },
    {
      "name": "multiple",
      "type": "boolean",
      "default": "false",
      "source": "selectable",
      "description": {
        "en": "Changes expected model to an array",
        "ja": "モデルを配列に変更します",
        "zh-Hans": "将预期模型更改为数组"
      }
    },
    {
      "name": "persistent-hint",
      "type": "boolean",
      "default": "false",
      "source": "v-input",
      "description": {
        "en": "Forces hint to always be visible",
        "ja": "ヒントを常に表示します",
        "zh-Hans": "强制提示总是可见的"
      }
    },
    {
      "name": "prepend-icon",
      "type": "string",
      "default": "undefined",
      "source": "v-input",
      "description": {
        "en": "Prepends an icon to the component, uses the same syntax as `v-icon`",
        "ja": "コンポーネントの先頭にアイコンが追加されます。`v-icon` と同じ構文を使用します。",
        "zh-Hans": "在组件前添加一个图标，使用与 `v-icon` 相同的语法"
      }
    },
    {
      "name": "readonly",
      "type": "boolean",
      "default": false,
      "source": "validatable",
      "description": {
        "en": "Puts input in readonly state",
        "ja": "読み取り専用状態に設定します。",
        "zh-Hans": "将输入设置为只读状态"
      }
    },
    {
      "name": "ripple",
      "type": [
        "boolean",
        "object"
      ],
      "default": true,
      "source": "rippleable",
      "description": {
        "en": "Applies the [v-ripple](/directives/ripple) directive.",
        "ja": "[v-ripple](/directives/ripple) ディレクティブを適用します。",
        "zh-Hans": "应用 [v-ripple](/directives/ripples) 指令。"
      }
    },
    {
      "name": "rules",
      "type": "array",
      "default": [],
      "source": "validatable",
      "description": {
        "en": "Accepts a mixed array of types `function`, `boolean` and `string`. Functions pass an input value as an argument and must return either `true` / `false` or a `string` containing an error message. The input field will enter an error state if a function returns (or any value in the array contains) `false` or is a `string`",
        "ja": "`function`, ` boolean`, `string`の混合配列を受け入れます。関数は入力値を引数として渡し、 `true` /`false`またはエラーメッセージを含む `string`のいずれかを返す必要があります。関数が `false`を返す（または配列内の任意の値に含まれる）場合、または`string`の場合、入力フィールドはエラー状態になります。",
        "zh-Hans": "接受不同类型的 `function`, `boolean` 和 `string` 。 函数传递输入值作为参数，必须返回 `true` / `false` 或包含错误消息的 `string` 。 如果函数返回 (或数组包含的任何值) false` 或 `string` ，输入字段将输入错误状态"
      }
    },
    {
      "name": "success",
      "type": "boolean",
      "default": "false",
      "source": "validatable",
      "description": {
        "en": "Puts the input in a manual success state",
        "ja": "入力を手動で成功状態にします。",
        "zh-Hans": "将输入设置为手动成功状态"
      }
    },
    {
      "name": "success-messages",
      "type": [
        "string",
        "array"
      ],
      "default": [],
      "source": "validatable",
      "description": {
        "en": "Puts the input in a success state and passes through custom success messages.",
        "ja": "入力をsuccess状態にして、カスタムの成功メッセージをパススルーさせます。",
        "zh-Hans": "将输入设置为成功状态，并传递自定义成功消息。"
      }
    },
    {
      "name": "true-value",
      "type": "any",
      "default": "undefined",
      "source": "selectable",
      "description": {
        "en": "Sets value for truthy state",
        "ja": "trueとみなされる状態の値を設定します。",
        "zh-Hans": "设置真值"
      }
    },
    {
      "name": "validate-on-blur",
      "type": "boolean",
      "default": "false",
      "source": "validatable",
      "description": {
        "en": "Delays validation until blur event",
        "ja": "blurイベントまでバリデーションを遅らせる",
        "zh-Hans": "延迟验证直到失去焦点的事件被触发"
      }
    },
    {
      "name": "value",
      "type": "any",
      "source": "v-input",
      "description": {
        "en": "The input's value",
        "ja": "入力の値",
        "zh-Hans": "输入的值"
      }
    },
    {
      "name": "value-comparator",
      "type": "function",
      "default": "null",
      "source": "comparable",
      "description": {
        "en": "Apply a custom value comparator function",
        "ja": "カスタムの値の比較関数を適用します",
        "zh-Hans": "应用自定义的 comparator function"
      }
    }
  ],
  "mixins": [
    "colorable",
    "comparable",
    "registrable-inject",
    "rippleable",
    "themeable",
    "v-input",
    "validatable"
  ],
  "slots": [
    {
      "name": "default",
      "source": "v-input",
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    },
    {
      "name": "label",
      "source": "v-input",
      "description": {
        "en": "Replaces the default label",
        "ja": "デフォルトのラベルを置き換えます",
        "zh-Hans": "替换默认标签"
      }
    },
    {
      "name": "message",
      "props": {
        "key": "number, // the messages index",
        "message": "string, // the message"
      },
      "source": "v-input",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "events": [
    {
      "name": "change",
      "value": "any",
      "description": {
        "en": "Emitted when the input is changed by user interaction",
        "ja": "ユーザーの操作によって値が変更されたときに発生します。",
        "zh-Hans": "当用户交互更改输入时发出"
      }
    },
    {
      "name": "click",
      "value": "MouseEvent",
      "description": {
        "en": "Emitted when input is clicked. **Note:** the **change** event should be used instead of **click** when monitoring state change",
        "ja": "クリックされたときに発生します。**注意:** 状態変化を監視する場合は **click** の代わりに **change** イベントを使用してください。",
        "zh-Hans": "单击输入时触发。**注意：** 监视状态变化时，应使用 **change** 事件，而不是 **click** 事件。"
      }
    },
    {
      "name": "click:append",
      "source": "v-input",
      "value": "Event",
      "description": {
        "en": "Emitted when appended icon is clicked",
        "ja": "アペンドアイコンがクリックされたときに発生します",
        "zh-Hans": "单击附加图标时发出"
      }
    },
    {
      "name": "click:prepend",
      "source": "v-input",
      "value": "Event",
      "description": {
        "en": "Emitted when prepended icon is clicked",
        "ja": "プリペンドアイコンがクリックされたときに発生します",
        "zh-Hans": "单击前置图标时发出"
      }
    },
    {
      "name": "mousedown",
      "source": "v-input",
      "value": "MouseEvent",
      "description": {
        "en": "Emitted when click is pressed",
        "ja": "クリック時、押された（pressed）ときに発生します",
        "zh-Hans": "单击时发出"
      }
    },
    {
      "name": "mouseup",
      "source": "v-input",
      "value": "MouseEvent",
      "description": {
        "en": "Emitted when click is released",
        "ja": "クリック後、放された（released）ときに発生します",
        "zh-Hans": "当单击释放时，发出"
      }
    },
    {
      "name": "update:error",
      "source": "validatable",
      "value": "boolean",
      "description": {
        "en": "The `error.sync` event",
        "ja": "`error.sync` イベント",
        "zh-Hans": "`error.sync` 事件"
      }
    }
  ],
  "functions": [],
  "exclude": {
    "props": [
      "type",
      "hide-spin-buttons"
    ]
  },
  "name": "v-switch",
  "sass": [
    {
      "name": "$switch-dirty-offset-x",
      "default": "20px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$switch-disabled-opacity",
      "default": ".6 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$switch-ripple-dense-top",
      "default": "calc(50% - 22px) !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$switch-ripple-dense-x",
      "default": "-12px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$switch-ripple-top",
      "default": "calc(50% - 24px) !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$switch-ripple-x",
      "default": "-14px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$switch-thumb-dense-height",
      "default": "18px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$switch-thumb-dense-width",
      "default": "18px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$switch-thumb-elevation",
      "default": "4 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$switch-thumb-height",
      "default": "20px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$switch-thumb-top",
      "default": "calc(50% - 10px) !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$switch-thumb-width",
      "default": "20px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$switch-track-border-radius",
      "default": "8px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$switch-track-dense-height",
      "default": "12px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$switch-track-dense-inset-height",
      "default": "22px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$switch-track-dense-inset-width",
      "default": "44px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$switch-track-dense-top",
      "default": "calc(50% - 12px) !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$switch-track-dense-width",
      "default": "32px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$switch-track-height",
      "default": "14px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$switch-track-inset-border-radius",
      "default": "14px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$switch-track-inset-height",
      "default": "28px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$switch-track-inset-opacity",
      "default": ".32 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$switch-track-inset-width",
      "default": "48px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$switch-track-opacity",
      "default": ".6 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$switch-track-top",
      "default": "calc(50% - 7px) !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$switch-track-width",
      "default": "36px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$switch-track-x",
      "default": "2px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$switch-width",
      "default": "38px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "component": true
}