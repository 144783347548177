module.exports = {
  "props": [
    {
      "name": "color",
      "type": "string",
      "default": "undefined",
      "source": "colorable",
      "description": {
        "en": "Applies specified color to the control - it can be the name of material color (for example `success` or `purple`) or css color (`#033` or `rgba(255, 0, 0, 0.5)`). You can find a list of built-in classes on the [colors page](/styles/colors#material-colors).",
        "ja": "指定された色をコントロールに適用します。利用できるのはマテリアルカラーの名前 (たとえば、`success` または ` purple`) または css 表記の色 (`#033`または` rgba(255, 0, 0, 0.5) `) です。[colors のページ](/styles/colors#material-colors) で、組み込みクラスのリストが確認できます。",
        "zh-Hans": "指定控件颜色，颜色值可以是 Material 颜色（例如 <code>success</code>或者 <code>purple</code> ）也可以是 Css 颜色 （例如 <code>#033</code> 或者 <code>rgba(255, 0, 0, 0.5)</code>）。在 [colors page](/zh-Hans/styles/colors#material-colors)中可以找到内置颜色类列表。"
      }
    },
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにダークテーマを適応します。詳しく知りたい場合は、Material Design documentationの<a href=\"https://material.io/design/color/dark-theme.html\">dark themes</a>を参照してください。",
        "zh-Hans": "将暗色主题变量应用到组件。你可以在 [dark themes](https://material.io/design/color/dark-theme.html) 的 Material Design 文档中找到更多有关信息。"
      }
    },
    {
      "name": "dense",
      "type": "boolean",
      "default": "false",
      "source": null,
      "description": {
        "en": "Makes icon smaller (20px)",
        "ja": "アイコンを小さくする(20px)",
        "zh-Hans": "使图标更小（20px）"
      }
    },
    {
      "name": "disabled",
      "type": "boolean",
      "default": "false",
      "source": "v-icon",
      "description": {
        "en": "Disable the input",
        "ja": "入力を無効にする",
        "zh-Hans": "禁用输入"
      }
    },
    {
      "name": "large",
      "type": "boolean",
      "default": "false",
      "source": "sizeable",
      "description": {
        "en": "Makes the component large.",
        "ja": "コンポーネントをlargeにします。",
        "zh-Hans": "使组件尺寸变的巨大。"
      }
    },
    {
      "name": "left",
      "type": "boolean",
      "default": "false",
      "source": "v-icon",
      "description": {
        "en": "Applies appropriate margins to the icon inside of a button when placed to the **left** of another element or text",
        "ja": "他の要素やテキストの**左**に配置された場合に、ボタンの内側のアイコンに適切なマージンを適用します。",
        "zh-Hans": "当按钮放置在另一个元素或文本的**左边**时，对按钮内的图标应用适当的间距。"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    },
    {
      "name": "right",
      "type": "boolean",
      "default": "false",
      "source": "v-icon",
      "description": {
        "en": "Applies appropriate margins to the icon inside of a button when placed to the **right** of another element or text",
        "ja": "他の要素やテキストの**右**に配置された場合に、ボタンの内側のアイコンに適切なマージンを適用します。",
        "zh-Hans": "当按钮放置在另一个元素或文本的**右边**时，对按钮内的图标应用适当的间距。"
      }
    },
    {
      "name": "size",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "v-icon",
      "description": {
        "en": "Specifies a custom font size for the icon",
        "ja": "アイコンのフォントサイズを指定します",
        "zh-Hans": "为图标指定自定义的字体大小"
      }
    },
    {
      "name": "small",
      "type": "boolean",
      "default": "false",
      "source": "sizeable",
      "description": {
        "en": "Makes the component small.",
        "ja": "コンポーネントをsmallにします。",
        "zh-Hans": "使组件尺寸变的小。"
      }
    },
    {
      "name": "tag",
      "type": "string",
      "default": "'i'",
      "source": "v-icon",
      "description": {
        "en": "Specifies a custom tag to be used",
        "ja": "使用するカスタム タグを指定します。",
        "zh-Hans": "指定要使用的自定义标签"
      }
    },
    {
      "name": "x-large",
      "type": "boolean",
      "default": "false",
      "source": "sizeable",
      "description": {
        "en": "Makes the component extra large.",
        "ja": "コンポーネントをextra largeにします。",
        "zh-Hans": "使组件尺寸变的无比巨大。"
      }
    },
    {
      "name": "x-small",
      "type": "boolean",
      "default": "false",
      "source": "sizeable",
      "description": {
        "en": "Makes the component extra small.",
        "ja": "コンポーネントをextra smallにします。",
        "zh-Hans": "使组件尺寸变的更小。"
      }
    }
  ],
  "mixins": [
    "colorable",
    "sizeable",
    "themeable"
  ],
  "slots": [
    {
      "name": "default",
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    }
  ],
  "events": [],
  "functions": [],
  "name": "v-icon",
  "sass": [
    {
      "name": "$icon-size",
      "default": "24px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$icon-size-dense",
      "default": "20px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "component": true
}