module.exports = {
  "props": [
    {
      "name": "button",
      "type": "boolean",
      "default": "false",
      "source": "v-progress-circular",
      "description": {
        "en": "Deprecated - Pending removal",
        "ja": "廃止予定 - 削除保留中",
        "zh-Hans": "已弃用 - 待删除"
      }
    },
    {
      "name": "color",
      "type": "string",
      "default": "undefined",
      "source": "colorable",
      "description": {
        "en": "Applies specified color to the control - it can be the name of material color (for example `success` or `purple`) or css color (`#033` or `rgba(255, 0, 0, 0.5)`). You can find a list of built-in classes on the [colors page](/styles/colors#material-colors).",
        "ja": "指定された色をコントロールに適用します。利用できるのはマテリアルカラーの名前 (たとえば、`success` または ` purple`) または css 表記の色 (`#033`または` rgba(255, 0, 0, 0.5) `) です。[colors のページ](/styles/colors#material-colors) で、組み込みクラスのリストが確認できます。",
        "zh-Hans": "指定控件颜色，颜色值可以是 Material 颜色（例如 <code>success</code>或者 <code>purple</code> ）也可以是 Css 颜色 （例如 <code>#033</code> 或者 <code>rgba(255, 0, 0, 0.5)</code>）。在 [colors page](/zh-Hans/styles/colors#material-colors)中可以找到内置颜色类列表。"
      }
    },
    {
      "name": "indeterminate",
      "type": "boolean",
      "default": "false",
      "source": "v-progress-circular",
      "description": {
        "en": "Constantly animates, use when loading progress is unknown.",
        "ja": "常にアニメーションします。読み込みの進行状況が不明な場合に使用します。",
        "zh-Hans": "恒定动画，加载进度未知时使用。"
      }
    },
    {
      "name": "rotate",
      "type": [
        "number",
        "string"
      ],
      "default": 0,
      "source": "v-progress-circular",
      "description": {
        "en": "Rotates the circle start point in deg",
        "ja": "円の始点を角度単位で指定します",
        "zh-Hans": "以度为单位旋转圆的起点"
      }
    },
    {
      "name": "size",
      "type": [
        "number",
        "string"
      ],
      "default": 32,
      "source": "v-progress-circular",
      "description": {
        "en": "Sets the diameter of the circle in pixels",
        "ja": "円の直径をピクセルで設定",
        "zh-Hans": "设置圆的直径（以像素为单位）"
      }
    },
    {
      "name": "value",
      "type": [
        "number",
        "string"
      ],
      "default": 0,
      "source": "v-progress-circular",
      "description": {
        "en": "The percentage value for current progress",
        "ja": "現在の進捗のパーセンテージ値",
        "zh-Hans": "当前进度的百分比值"
      }
    },
    {
      "name": "width",
      "type": [
        "number",
        "string"
      ],
      "default": 4,
      "source": "v-progress-circular",
      "description": {
        "en": "Sets the stroke of the circle in pixels",
        "ja": "円周の幅（太さ）をピクセルで設定",
        "zh-Hans": "以像素为单位设置圆的笔触"
      }
    }
  ],
  "mixins": [],
  "slots": [
    {
      "name": "default",
      "props": {
        "value": "number"
      },
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    }
  ],
  "events": [],
  "functions": [],
  "name": "v-progress-circular",
  "sass": [
    {
      "name": "$progress-circular-rotate-animation",
      "default": "progress-circular-rotate 1.4s linear infinite !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$progress-circular-rotate-dash",
      "default": "progress-circular-dash 1.4s ease-in-out infinite !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$process-circular-intermediate-svg-transition",
      "default": "all .2s ease-in-out !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$progress-circular-underlay-stroke",
      "default": "rgba(map-get($grey, 'base'), 0.4) !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$progress-circular-overlay-transition",
      "default": "all .6s ease-in-out !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "component": true
}