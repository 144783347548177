var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"min-width":_vm.btnWidth,"to":{
          name: 'Documentation',
          params: {
            category: 'getting-started',
            page: 'installation'
          }
        },"color":"primary","depressed":"","x-large":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" $mdiSpeedometer ")]),_vm._v(" "+_vm._s(_vm.$t('home.get-started'))+" ")],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"min-width":_vm.btnWidth,"to":{
          name: 'Documentation',
          params: {
            category: 'introduction',
            page: 'why-vuetify'
          }
        },"color":"primary","outlined":"","x-large":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" $mdiVuetify ")]),_vm._v(" "+_vm._s(_vm.$t('home.why-vuetify'))+" ")],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"min-width":_vm.btnWidth,"color":"#212121","dark":"","depressed":"","href":"https://github.com/vuetifyjs/vuetify","rel":"noopener","target":"_blank","x-large":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" $mdiGithub ")]),_vm._v(" GitHub ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }