module.exports = {
  "props": [
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにダークテーマを適応します。詳しく知りたい場合は、Material Design documentationの<a href=\"https://material.io/design/color/dark-theme.html\">dark themes</a>を参照してください。",
        "zh-Hans": "将暗色主题变量应用到组件。你可以在 [dark themes](https://material.io/design/color/dark-theme.html) 的 Material Design 文档中找到更多有关信息。"
      }
    },
    {
      "name": "inset",
      "type": "boolean",
      "default": "false",
      "source": "v-subheader",
      "description": {
        "en": "Adds indentation (72px)",
        "ja": "インデントを追加します (72px)",
        "zh-Hans": "添加缩进（72px）"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    }
  ],
  "mixins": [
    "themeable"
  ],
  "slots": [
    {
      "name": "default",
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    }
  ],
  "events": [],
  "functions": [],
  "name": "v-subheader",
  "sass": [
    {
      "name": "$subheader-inset-margin",
      "default": "56px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$subheader-item-single-height",
      "default": "48px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$subheader-left-padding",
      "default": "16px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$subheader-right-padding",
      "default": "16px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "component": true
}