module.exports = {
  "props": [
    {
      "name": "absolute",
      "type": "boolean",
      "default": "false",
      "source": "positionable",
      "description": {
        "en": "Applies **position: absolute** to the component.",
        "ja": "コンポーネントに **position: absolute** を適用します。",
        "zh-Hans": "给组件应用 **position: absolute**"
      }
    },
    {
      "name": "app",
      "type": "boolean",
      "default": "false",
      "source": "applicationable",
      "description": {
        "en": "Designates the component as part of the application layout. Used for dynamically adjusting content sizing. Components using this prop should reside **outside** of `v-main` component to function properly. You can find more information about layouts on the [application page](/components/application). **Note:** this prop automatically applies **position: fixed** to the layout element. You can overwrite this functionality by using the `absolute` prop",
        "ja": "コンポーネントをアプリケーションレイアウトの一部として指定します。コンテンツのサイズを動的に調整するために使用します。 このプロパティを使用するコンポーネントは、 `v-main` コンポーネントの **外側** に正しく機能するようにしてください。 [application page](/components/application) でレイアウトの詳細を確認できます。 **注意:** このプロパティは自動的に **position: fixed** をレイアウト要素に適用します。`absolute` prop を使用すると、この機能を上書きできます。",
        "zh-Hans": "指定该组件作为应用程序布局的一部分。用于动态调整内容的大小。使用此属性的组件只有位于 `v-main `组件的 **外部**才能正常运行。您可以在 [应用程序页面](/components/application) 获取更多有关布局的信息。**注意：** 使用此属性会自动应用**position: fixed** 到布局元素上。您可以使用 `absolute` 属性 来覆盖这个功能。"
      }
    },
    {
      "name": "bottom",
      "type": "boolean",
      "default": "false",
      "source": "v-navigation-drawer",
      "description": {
        "en": "Expands from the bottom of the screen on mobile devices",
        "ja": "モバイルデバイスでは、下から開くようになります。",
        "zh-Hans": "在移动设备上从屏幕底部展开"
      }
    },
    {
      "name": "clipped",
      "type": "boolean",
      "default": "false",
      "source": "v-navigation-drawer",
      "description": {
        "en": "A clipped drawer rests under the application toolbar. **Note:** requires the **clipped-left** or **clipped-right** prop on `v-app-bar` to work as intended",
        "ja": "A clipped drawer rests under the application toolbar. **Note:** requires the **clipped-left** or **clipped-right** prop on `v-app-bar` to work as intended",
        "zh-Hans": "A clipped drawer rests under the application toolbar. **Note:** requires the **clipped-left** or **clipped-right** prop on `v-app-bar` to work as intended"
      }
    },
    {
      "name": "color",
      "type": "string",
      "default": "undefined",
      "source": "colorable",
      "description": {
        "en": "Applies specified color to the control - it can be the name of material color (for example `success` or `purple`) or css color (`#033` or `rgba(255, 0, 0, 0.5)`). You can find a list of built-in classes on the [colors page](/styles/colors#material-colors).",
        "ja": "指定された色をコントロールに適用します。利用できるのはマテリアルカラーの名前 (たとえば、`success` または ` purple`) または css 表記の色 (`#033`または` rgba(255, 0, 0, 0.5) `) です。[colors のページ](/styles/colors#material-colors) で、組み込みクラスのリストが確認できます。",
        "zh-Hans": "指定控件颜色，颜色值可以是 Material 颜色（例如 <code>success</code>或者 <code>purple</code> ）也可以是 Css 颜色 （例如 <code>#033</code> 或者 <code>rgba(255, 0, 0, 0.5)</code>）。在 [colors page](/zh-Hans/styles/colors#material-colors)中可以找到内置颜色类列表。"
      }
    },
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにダークテーマを適応します。詳しく知りたい場合は、Material Design documentationの<a href=\"https://material.io/design/color/dark-theme.html\">dark themes</a>を参照してください。",
        "zh-Hans": "将暗色主题变量应用到组件。你可以在 [dark themes](https://material.io/design/color/dark-theme.html) 的 Material Design 文档中找到更多有关信息。"
      }
    },
    {
      "name": "disable-resize-watcher",
      "type": "boolean",
      "default": "false",
      "source": "v-navigation-drawer",
      "description": {
        "en": "Will automatically open/close drawer when resized depending if mobile or desktop.",
        "ja": "モバイルやデスクトップに応じてサイズを変更したとき、ドロワーを自動的に開いたり閉じたりします。",
        "zh-Hans": "调整大小时将自动打开/关闭抽屉，具体取决于手机还是桌面"
      }
    },
    {
      "name": "disable-route-watcher",
      "type": "boolean",
      "default": "false",
      "source": "v-navigation-drawer",
      "description": {
        "en": "Disables opening of navigation drawer when route changes",
        "ja": "ルート変更時にナビゲーションドロワーが開くのを無効にします",
        "zh-Hans": "当路由改变时禁止打开抽屉导航"
      }
    },
    {
      "name": "expand-on-hover",
      "type": "boolean",
      "default": "false",
      "source": "v-navigation-drawer",
      "description": {
        "en": "Collapses the drawer to a **mini-variant** until hovering with the mouse",
        "ja": "ドロワーをマウスでホバリングするまで**mini-variant**に折りたたみます。",
        "zh-Hans": "将抽屉折叠成 **mini-variant**，直到用鼠标悬停"
      }
    },
    {
      "name": "fixed",
      "type": "boolean",
      "default": "false",
      "source": "positionable",
      "description": {
        "en": "Applies **position: fixed** to the component.",
        "ja": "コンポーネントに **position: fixed** を適用します。",
        "zh-Hans": "给组件应用 **position: fixed**"
      }
    },
    {
      "name": "floating",
      "type": "boolean",
      "default": "false",
      "source": "v-navigation-drawer",
      "description": {
        "en": "A floating drawer has no visible container (no border-right)",
        "ja": "A floating drawer has no visible container (no border-right)",
        "zh-Hans": "浮动抽屉没有可见的容器（没有右边框）"
      }
    },
    {
      "name": "height",
      "type": [
        "number",
        "string"
      ],
      "default": "100%",
      "source": "v-navigation-drawer",
      "description": {
        "en": "Sets the height of the navigation drawer",
        "ja": "ナビゲーション ドロワーの高さを設定します。",
        "zh-Hans": "设置导航抽屉高度"
      }
    },
    {
      "name": "hide-overlay",
      "type": "boolean",
      "default": "false",
      "source": "overlayable",
      "description": {
        "en": "Hides the display of the overlay.",
        "ja": "オーバーレイを非表示にします。",
        "zh-Hans": "隐藏遮罩层的显示."
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    },
    {
      "name": "mini-variant",
      "type": "boolean",
      "default": "false",
      "source": "v-navigation-drawer",
      "description": {
        "en": "Condenses navigation drawer width, also accepts the **.sync** modifier. With this, the drawer will re-open when clicking it",
        "ja": "ナビゲーションドロワーの幅を縮小し、**.sync**修飾子も受け入れます。これにより、ドロワーはクリックすると再び開きます",
        "zh-Hans": "凝结导航抽屉宽度，也接受**.sync**修饰符。这样，抽屉在点击时会重新打开"
      }
    },
    {
      "name": "mini-variant-width",
      "type": [
        "number",
        "string"
      ],
      "default": 56,
      "source": "v-navigation-drawer",
      "description": {
        "en": "Designates the width assigned when the `mini` prop is turned on",
        "ja": "`mini` prop がオンのときに割り当てられる幅を指定します。",
        "zh-Hans": "指定`mini`属性开期时指定的宽度"
      }
    },
    {
      "name": "mobile-breakpoint",
      "type": [
        "number",
        "string"
      ],
      "source": "mobile",
      "description": {
        "en": "Sets the designated mobile breakpoint for the component. This will apply alternate styles for mobile devices such as the `temporary` prop, or activate the `bottom` prop when the breakpoint value is met. Setting the value to `0` will disable this functionality.",
        "ja": "コンポーネントに指定されたモバイルブレークポイントを設定します。 これにより、`temporary` プロパティなどのモバイルデバイスに別のスタイルを適用するか、ブレークポイント値が満たされた場合に `bottom` プロパティを有効にします。 値を `0` に設定すると、この機能は無効になります。",
        "zh-Hans": "为组件设置指定的移动断点。这将为移动设备应用替代样式，如 `temporary` prop，或在满足断点值时激活 `bottom` prop。将值设置为 `0` 将禁用此功能。"
      }
    },
    {
      "name": "overlay-color",
      "type": "string",
      "default": "undefined",
      "source": "overlayable",
      "description": {
        "en": "Sets the overlay color.",
        "ja": "オーバーレイの色を設定します。",
        "zh-Hans": "设置遮罩层的颜色。"
      }
    },
    {
      "name": "overlay-opacity",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "overlayable",
      "description": {
        "en": "Sets the overlay opacity.",
        "ja": "オーバーレイの不透明度を設定します。",
        "zh-Hans": "设置遮罩层的透明度。"
      }
    },
    {
      "name": "permanent",
      "type": "boolean",
      "default": "false",
      "source": "v-navigation-drawer",
      "description": {
        "en": "The drawer remains visible regardless of screen size",
        "ja": "ドロワーは画面サイズに関係なく表示されたままになります",
        "zh-Hans": "不管屏幕尺寸如何，抽屉都可以看到"
      }
    },
    {
      "name": "right",
      "type": "boolean",
      "default": "false",
      "source": "v-navigation-drawer",
      "description": {
        "en": "Places the navigation drawer on the right",
        "ja": "ナビゲーションドロワーを右側に配置します。",
        "zh-Hans": "将抽屉放置在右侧"
      }
    },
    {
      "name": "src",
      "type": [
        "string",
        "object"
      ],
      "default": "undefined",
      "source": "v-navigation-drawer",
      "description": {
        "en": "Specifies a [v-img](/components/images) as the component's background.",
        "ja": "コンポーネントの背景として [v-img](/components/images) を指定します。",
        "zh-Hans": "指定 [v-img](/components/images) 作为组件背景。"
      }
    },
    {
      "name": "stateless",
      "type": "boolean",
      "default": "false",
      "source": "v-navigation-drawer",
      "description": {
        "en": "Remove all automated state functionality (resize, mobile, route) and manually control the drawer state",
        "ja": "すべての自動状態機能（resize, mobile, route）を削除し、ドロワーの状態を手動で制御します",
        "zh-Hans": "删除所有自动状态功能（调整大小、移动、路由）并手动控制抽屉状态。"
      }
    },
    {
      "name": "tag",
      "type": "string",
      "default": "'aside'",
      "source": "v-navigation-drawer",
      "description": {
        "en": "Specify a custom tag used on the root element.",
        "ja": "コンポーネント内で使用するカスタムタグを指定します。",
        "zh-Hans": "指定在根元素上使用的自定义标签。"
      }
    },
    {
      "name": "temporary",
      "type": "boolean",
      "default": "false",
      "source": "v-navigation-drawer",
      "description": {
        "en": "A temporary drawer sits above its application and uses a scrim (overlay) to darken the background",
        "ja": "temporary は、ドロワーをアプリケーション画面の上に重なるように表示し、幕（overlay）を表示して背景を暗くします。",
        "zh-Hans": "临时抽屉位于其应用之上，并使用稀松布（叠加）来使背景变暗"
      }
    },
    {
      "name": "touchless",
      "type": "boolean",
      "default": "false",
      "source": "v-navigation-drawer",
      "description": {
        "en": "Disable mobile touch functionality",
        "ja": "モバイルのタッチ機能を無効にする",
        "zh-Hans": "禁用移动触摸功能"
      }
    },
    {
      "name": "value",
      "type": "any",
      "default": "undefined",
      "source": "v-navigation-drawer",
      "description": {
        "en": "Controls whether the component is visible or hidden.",
        "ja": "コンポーネントを表示するか非表示にするかを制御します。",
        "zh-Hans": "控制组件可见还是隐藏。"
      }
    },
    {
      "name": "width",
      "type": [
        "number",
        "string"
      ],
      "default": 256,
      "source": "v-navigation-drawer",
      "description": {
        "en": "Sets the width for the component.",
        "ja": "コンポーネントに横幅を設定します。",
        "zh-Hans": "设定组件的宽度。"
      }
    }
  ],
  "mixins": [
    "applicationable",
    "colorable",
    "dependent",
    "mobile",
    "overlayable",
    "positionable",
    "ssr-bootable",
    "themeable"
  ],
  "slots": [
    {
      "name": "append",
      "description": {
        "en": "A slot at the bottom of the drawer",
        "ja": "ドロワーの下部にあるスロット",
        "zh-Hans": "抽屉底部的插槽"
      }
    },
    {
      "name": "default",
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    },
    {
      "name": "img",
      "props": {
        "height": "string",
        "src": "string | srcObject"
      },
      "description": {
        "en": "Used to modify `v-img` properties when using the **src** prop",
        "ja": "**src** プロパティを指定した時、 `v-img` のプロパティを設定するために使用します。",
        "zh-Hans": "用于修改 `v-img` 属性时使用 **src** 属性"
      }
    },
    {
      "name": "prepend",
      "description": {
        "en": "A slot at the top of the drawer",
        "ja": "ドロワーの上部にあるスロット",
        "zh-Hans": "抽屉顶部的插槽"
      }
    }
  ],
  "events": [
    {
      "name": "input",
      "value": "boolean",
      "description": {
        "en": "The updated bound model",
        "ja": "更新されたバウンドモデル",
        "zh-Hans": "被绑定模型的更新"
      }
    },
    {
      "name": "transitionend",
      "value": "object",
      "description": {
        "en": "Emits event object when transition is complete.",
        "ja": "トランジションが完了したときにイベントオブジェクトを発生させます。",
        "zh-Hans": "过渡完成后，发出事件对象。"
      }
    },
    {
      "name": "update:mini-variant",
      "value": "boolean",
      "description": {
        "en": "The `mini-variant.sync` event",
        "ja": "`mini-variant.sync` イベント",
        "zh-Hans": "`mini-variant.sync` 事件"
      }
    }
  ],
  "functions": [],
  "name": "v-navigation-drawer",
  "sass": [
    {
      "name": "$navigation-drawer-border-width",
      "default": "1px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$navigation-drawer-mobile-temporary-elevation",
      "default": "16 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "component": true
}