module.exports = {
  "props": [
    {
      "name": "active-class",
      "type": "string",
      "default": "'v-slide-item--active'",
      "source": "base-item-group",
      "description": {
        "en": "The **active-class** applied to children when they are activated.",
        "ja": "子要素が有効な場合、子要素に **active-class** が適用されます。",
        "zh-Hans": "**active-class** 在子组件（children ）被激活时应用。"
      }
    },
    {
      "name": "center-active",
      "type": "boolean",
      "default": "false",
      "source": "base-slide-group",
      "description": {
        "en": "Forces the selected component to be centered",
        "ja": "選択したコンポーネントを強制的に中央に配置します",
        "zh-Hans": "强制将选中的组件居中"
      }
    },
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにダークテーマを適応します。詳しく知りたい場合は、Material Design documentationの<a href=\"https://material.io/design/color/dark-theme.html\">dark themes</a>を参照してください。",
        "zh-Hans": "将暗色主题变量应用到组件。你可以在 [dark themes](https://material.io/design/color/dark-theme.html) 的 Material Design 文档中找到更多有关信息。"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    },
    {
      "name": "mandatory",
      "type": "boolean",
      "default": "false",
      "source": "base-item-group",
      "description": {
        "en": "Forces a value to always be selected (if available).",
        "ja": "値が常に選択状態であることを強制します (可能な場合)。",
        "zh-Hans": "强制始终选择一个值（如果可用）。"
      }
    },
    {
      "name": "max",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "base-item-group",
      "description": {
        "en": "Sets a maximum number of selections that can be made.",
        "ja": "選択できる最大値を設定します。",
        "zh-Hans": "设置可以选择的最大数量。"
      }
    },
    {
      "name": "mobile-breakpoint",
      "type": [
        "number",
        "string"
      ],
      "source": "mobile",
      "description": {
        "en": "Sets the designated mobile breakpoint for the component.",
        "ja": "コンポーネントに指定されたモバイルのbreakpointを指定します。",
        "zh-Hans": "为组件设置和指定移动设备的屏幕尺寸断点"
      }
    },
    {
      "name": "multiple",
      "type": "boolean",
      "default": "false",
      "source": "base-item-group",
      "description": {
        "en": "Allow multiple selections. The **value** prop must be an _array_.",
        "ja": "複数の選択を許可します。この場合、**value** prop は _array_ でなければなりません。",
        "zh-Hans": "允许多个选择。**value** 属性必须是 _array_。"
      }
    },
    {
      "name": "next-icon",
      "type": "string",
      "default": "'$next'",
      "source": "base-slide-group",
      "description": {
        "en": "The appended slot when arrows are shown",
        "ja": "矢印が表示されているとき後ろに追加されるスロット",
        "zh-Hans": "当箭头显示时附加的插槽"
      }
    },
    {
      "name": "prev-icon",
      "type": "string",
      "default": "'$prev'",
      "source": "base-slide-group",
      "description": {
        "en": "The prepended slot when arrows are shown",
        "ja": "矢印が表示されているときの先頭のスロット",
        "zh-Hans": "当箭头显示时，预定的插槽"
      }
    },
    {
      "name": "show-arrows",
      "type": [
        "boolean",
        "string"
      ],
      "default": "undefined",
      "source": "base-slide-group",
      "description": {
        "en": "Change when the overflow arrow indicators are shown. By **default**, arrows *always* display on Desktop when the container is overflowing. When the container overflows on mobile, arrows are not shown by default. A **show-arrows** value of `true` allows these arrows to show on Mobile if the container overflowing. A value of `desktop` *always* displays arrows on Desktop while a value of `mobile` always displays arrows on Mobile. A value of `always` always displays arrows on Desktop *and* Mobile. A value of `never` always hides the arrows. Find more information on how to customize breakpoint thresholds on the [breakpoints page](/customizing/breakpoints).",
        "ja": "Change when the overflow arrow indicators are shown. By **default**, arrows *always* display on Desktop when the container is overflowing. When the container overflows on mobile, arrows are not shown by default. A **show-arrows** value of `true` allows these arrows to show on Mobile if the container overflowing. A value of `desktop` *always* displays arrows on Desktop while a value of `mobile` always displays arrows on Mobile. A value of `always` always displays arrows on Desktop *and* Mobile. A value of `never` always hides the arrows. Find more information on how to customize breakpoint thresholds on the [breakpoints page](/customizing/breakpoints).",
        "zh-Hans": "Change when the overflow arrow indicators are shown. By **default**, arrows *always* display on Desktop when the container is overflowing. When the container overflows on mobile, arrows are not shown by default. A **show-arrows** value of `true` allows these arrows to show on Mobile if the container overflowing. A value of `desktop` *always* displays arrows on Desktop while a value of `mobile` always displays arrows on Mobile. A value of `always` always displays arrows on Desktop *and* Mobile. A value of `never` always hides the arrows. Find more information on how to customize breakpoint thresholds on the [breakpoints page](/customizing/breakpoints)."
      }
    },
    {
      "name": "tag",
      "type": "string",
      "default": "'div'",
      "source": "base-item-group",
      "description": {
        "en": "Specify a custom tag used on the root element.",
        "ja": "コンポーネント内で使用するカスタムタグを指定します。",
        "zh-Hans": "指定在根元素上使用的自定义标签。"
      }
    },
    {
      "name": "value",
      "type": "any",
      "default": "undefined",
      "source": "proxyable",
      "description": {
        "en": "The designated model value for the component.",
        "ja": "コンポーネントの指定されたモデルの値。",
        "zh-Hans": "组件的指定 model 值。"
      }
    },
    {
      "name": "value-comparator",
      "type": "function",
      "default": "null",
      "source": "comparable",
      "description": {
        "en": "Apply a custom value comparator function",
        "ja": "カスタムの値の比較関数を適用します",
        "zh-Hans": "应用自定义的 comparator function"
      }
    }
  ],
  "mixins": [
    "base-item-group",
    "comparable",
    "mobile",
    "proxyable",
    "themeable"
  ],
  "slots": [
    {
      "name": "default",
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    },
    {
      "name": "next",
      "description": {
        "en": "The next slot",
        "ja": "次のslot",
        "zh-Hans": "下一个插槽"
      }
    },
    {
      "name": "prev",
      "description": {
        "en": "The prev slot",
        "ja": "前のslot",
        "zh-Hans": "前一个插槽"
      }
    }
  ],
  "events": [
    {
      "name": "change",
      "value": "any[] | any",
      "description": {
        "en": "Emitted when the component value is changed by user interaction",
        "ja": "コンポーネントの値がユーザー操作によって変更されたときに発生します。",
        "zh-Hans": "当组件值被用户交互改变时发出。"
      }
    },
    {
      "name": "click:next",
      "value": "void",
      "description": {
        "en": "Emitted when the next is clicked",
        "ja": "nextがクリックされたときに発生します。",
        "zh-Hans": "Emitted when the next is clicked"
      }
    },
    {
      "name": "click:prev",
      "value": "void",
      "description": {
        "en": "Emitted when the prev is clicked",
        "ja": "prevがクリックされたときに発生します。",
        "zh-Hans": "Emitted when the prev is clicked"
      }
    }
  ],
  "functions": [],
  "name": "v-slide-group",
  "sass": [
    {
      "name": "$slide-group-prev-basis",
      "default": "52px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "component": true
}