module.exports = {
  "props": [
    {
      "name": "close-delay",
      "type": [
        "number",
        "string"
      ],
      "default": 0,
      "source": "delayable",
      "description": {
        "en": "Milliseconds to wait before closing component. Only applies to hover and focus events.",
        "ja": "Milliseconds to wait before closing component. Only applies to hover and focus events.",
        "zh-Hans": "组件关闭前等待的毫秒数。仅应用于悬停和焦点事件。"
      }
    },
    {
      "name": "disabled",
      "type": "boolean",
      "default": "false",
      "source": "v-hover",
      "description": {
        "en": "Turns off hover functionality",
        "ja": "ホバー機能をオフにします",
        "zh-Hans": "关闭悬停功能"
      }
    },
    {
      "name": "open-delay",
      "type": [
        "number",
        "string"
      ],
      "default": 0,
      "source": "delayable",
      "description": {
        "en": "Milliseconds to wait before opening component. Only applies to hover and focus events.",
        "ja": "コンポーネントを開くまでのミリ秒を指定。hoverとfocusイベントのみに反映します。",
        "zh-Hans": "组件打开前等待的毫秒数。仅应用于悬停和焦点事件。"
      }
    },
    {
      "name": "value",
      "type": "boolean",
      "default": "false",
      "source": "toggleable",
      "description": {
        "en": "Controls whether the component is visible or hidden.",
        "ja": "コンポーネントを表示するか非表示にするかを制御します。",
        "zh-Hans": "控制组件可见还是隐藏。"
      }
    }
  ],
  "mixins": [
    "delayable",
    "toggleable"
  ],
  "slots": [
    {
      "name": "default",
      "props": {
        "hover": "boolean"
      },
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    }
  ],
  "events": [],
  "functions": [],
  "name": "v-hover",
  "sass": [],
  "component": true
}