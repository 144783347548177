/* eslint-disable camelcase */
import {
  mdiAccount,
  mdiAlertCircleOutline,
  mdiAlertOutline,
  mdiAlphaA,
  mdiAlphaB,
  mdiAlphaC,
  mdiAlphaD,
  mdiAlphaE,
  mdiAlphaF,
  mdiAlphaG,
  mdiAlphaH,
  mdiAlphaI,
  mdiAlphaJ,
  mdiAlphaK,
  mdiAlphaL,
  mdiAlphaM,
  mdiAlphaN,
  mdiAlphaO,
  mdiAlphaP,
  mdiAlphaQ,
  mdiAlphaR,
  mdiAlphaS,
  mdiAlphaT,
  mdiAlphaU,
  mdiAlphaV,
  mdiAlphaW,
  mdiAlphaX,
  mdiAlphaY,
  mdiAlphaZ,
  mdiConsole,
  mdiFormatWrapInline,
  mdiFlaskEmptyOutline,
  mdiFlaskOutline,
  mdiLink,
  mdiBell,
  mdiBellOutline,
  mdiBellRingOutline,
  mdiScriptText,
  mdiScriptTextOutline,
  mdiSchool,
  mdiSchoolOutline,
  mdiBriefcaseSearchOutline,
  mdiBriefcaseVariant,
  mdiBriefcaseVariantOutline,
  mdiWeatherNight,
  mdiWhiteBalanceSunny,
  mdiCellphone,
  mdiRecord,
  mdiChevronDown,
  mdiChevronUp,
  mdiClockFast,
  mdiCodepen,
  mdiCodeTags,
  mdiCogOutline,
  mdiImageEdit,
  mdiImageEditOutline,
  mdiContentCopy,
  mdiDatabaseSearch,
  mdiDesktopTowerMonitor,
  mdiDiscord,
  mdiDotsVertical,
  mdiEmail,
  mdiEmailMarkAsUnread,
  mdiEmailOpen,
  mdiEmailOpenMultiple,
  mdiEye,
  mdiHuman,
  mdiMonitorDashboard,
  mdiFormatTextdirectionLToR,
  mdiFormatTextdirectionRToL,
  mdiFunction,
  mdiGithub,
  mdiGrid,
  mdiHeart,
  mdiHeartHalfFull,
  mdiHeartOutline,
  mdiHomeCircle,
  mdiInvertColors,
  mdiLaptop,
  mdiLinkedin,
  mdiMaterialDesign,
  mdiMagnify,
  mdiMapMarkerOutline,
  mdiMenuDown,
  mdiMonitor,
  mdiNewBox,
  mdiNumeric,
  mdiAlertOctagonOutline,
  mdiOpenInNew,
  mdiOrderAlphabeticalAscending,
  mdiOrderBoolAscending,
  mdiPageNext,
  mdiPalette,
  mdiPaletteOutline,
  mdiPatreon,
  mdiPlayCircle,
  mdiPlusCircle,
  mdiPound,
  mdiReddit,
  mdiRefreshCircle,
  mdiRocket,
  mdiShoppingOutline,
  mdiSpeedometer,
  mdiSpeedometerMedium,
  mdiStar,
  mdiStarHalfFull,
  mdiStarOutline,
  mdiTablet,
  mdiHumanMaleBoard,
  mdiTagOutline,
  mdiTelevision,
  mdiTextBoxSearch,
  mdiTextBoxSearchOutline,
  mdiThemeLightDark,
  mdiTranslate,
  mdiTwitter,
  mdiWrench,
  mdiViewDashboard,
  mdiViewDashboardOutline,
  mdiViewStream,
  mdiVuetify,
  mdiInformationOutline,
} from '@mdi/js'

export const icons = {
  iconfont: 'mdiSvg',
  values: {
    mdiAccount,
    mdiAlertCircleOutline,
    mdiAlertOutline,
    mdiAlphaA,
    mdiAlphaB,
    mdiAlphaC,
    mdiAlphaD,
    mdiAlphaE,
    mdiAlphaF,
    mdiAlphaG,
    mdiAlphaH,
    mdiAlphaI,
    mdiAlphaJ,
    mdiAlphaK,
    mdiAlphaL,
    mdiAlphaM,
    mdiAlphaN,
    mdiAlphaO,
    mdiAlphaP,
    mdiAlphaQ,
    mdiAlphaR,
    mdiAlphaS,
    mdiAlphaT,
    mdiAlphaU,
    mdiAlphaV,
    mdiAlphaW,
    mdiAlphaX,
    mdiAlphaY,
    mdiAlphaZ,
    mdiConsole,
    mdiFlaskEmptyOutline,
    mdiFlaskOutline,
    mdiFormatWrapInline,
    mdiLink,
    mdiBell,
    mdiBellOutline,
    mdiBellRingOutline,
    mdiScriptText,
    mdiScriptTextOutline,
    mdiSchool,
    mdiSchoolOutline,
    mdiBriefcaseSearchOutline,
    mdiBriefcaseVariant,
    mdiBriefcaseVariantOutline,
    mdiWeatherNight,
    mdiWhiteBalanceSunny,
    mdiCellphone,
    mdiRecord,
    mdiChevronDown,
    mdiChevronUp,
    mdiClockFast,
    mdiCodepen,
    mdiCodeTags,
    mdiCogOutline,
    mdiImageEdit,
    mdiImageEditOutline,
    mdiContentCopy,
    mdiDatabaseSearch,
    mdiDesktopTowerMonitor,
    mdiDiscord,
    mdiDotsVertical,
    mdiEmail,
    mdiEmailMarkAsUnread,
    mdiEmailOpen,
    mdiEmailOpenMultiple,
    mdiEye,
    mdiHuman,
    mdiMonitorDashboard,
    mdiFormatTextdirectionLToR,
    mdiFormatTextdirectionRToL,
    mdiFunction,
    mdiGrid,
    mdiGithub,
    mdiHeart,
    mdiHeartHalfFull,
    mdiHeartOutline,
    mdiHomeCircle,
    mdiInvertColors,
    mdiLaptop,
    mdiLinkedin,
    mdiMaterialDesign,
    mdiMagnify,
    mdiMapMarkerOutline,
    mdiMenuDown,
    mdiMonitor,
    mdiNewBox,
    mdiNumeric,
    mdiAlertOctagonOutline,
    mdiOpenInNew,
    mdiOrderAlphabeticalAscending,
    mdiOrderBoolAscending,
    mdiPageNext,
    mdiPalette,
    mdiPaletteOutline,
    mdiPatreon,
    mdiPlayCircle,
    mdiPlusCircle,
    mdiPound,
    mdiReddit,
    mdiRefreshCircle,
    mdiRocket,
    mdiShoppingOutline,
    mdiSpeedometer,
    mdiSpeedometerMedium,
    mdiStar,
    mdiStarHalfFull,
    mdiStarOutline,
    mdiTablet,
    mdiHumanMaleBoard,
    mdiTagOutline,
    mdiTelevision,
    mdiTextBoxSearch,
    mdiTextBoxSearchOutline,
    mdiThemeLightDark,
    mdiTranslate,
    mdiTwitter,
    mdiWrench,
    mdiViewDashboard,
    mdiViewDashboardOutline,
    mdiViewStream,
    mdiVuetify,
    error: mdiAlertOctagonOutline,
    info: mdiInformationOutline,
    warning: mdiAlertOutline,
  },
}
