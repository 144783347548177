module.exports = {
  "props": [
    {
      "name": "active-class",
      "type": "string",
      "default": "undefined",
      "source": "groupable",
      "description": {
        "en": "Configure the active CSS class applied when the link is active. You can find more information about the [**active-class** prop](https://router.vuejs.org/api/#active-class) on the vue-router documentation.",
        "ja": "リンクがアクティブなときに適用されるアクティブなCSSクラスを構成します。vue-routerのドキュメントで [**active-class** prop](https://router.vuejs.org/ja/api/#active-class) の詳細をご覧ください。",
        "zh-Hans": "配置在链接激活时应用的 CSS 类。你能够在 vue-router 文档浏览更多 [**active-class** prop](https://router.vuejs.org/api/#active-class)。"
      }
    },
    {
      "name": "append",
      "type": "boolean",
      "default": "false",
      "source": "routable",
      "description": {
        "en": "Setting **append** prop always appends the relative path to the current path. You can find more information about the [**append** prop](https://router.vuejs.org/api/#append) on the vue-router documentation.",
        "ja": "**append** propを設定すると、常に現在のパスに相対パスが追加されます。vue-router のドキュメントで [**append** prop](https://router.vuejs.org/ja/api/#append) の詳細についてをご覧ください。",
        "zh-Hans": "设置 **append** 属性总是会附加到当前路径的相对路径上。你能够在 vue-router 文档浏览更多 [**append** prop](https://router.vuejs.org/api/#append)。"
      }
    },
    {
      "name": "disabled",
      "type": "boolean",
      "default": "false",
      "source": "groupable",
      "description": {
        "en": "Removes the ability to click or target the component.",
        "ja": "コンポーネントをクリックまたはターゲットにできなくします。",
        "zh-Hans": "移除组件的单击或 target 功能。"
      }
    },
    {
      "name": "eager",
      "type": "boolean",
      "default": "false",
      "source": "bootable",
      "description": {
        "en": "Will force the components content to render on mounted. This is useful if you have content that will not be rendered in the DOM that you want crawled for SEO.",
        "ja": "コンポーネントのコンテンツを mounted 時に render することを強制します。この機能は、SEO のために crawl させたい DOM の中に render されないコンテンツを持っているときに役に立ちます。",
        "zh-Hans": "将强制组件内容在加载时呈现。如果存在内容的话，则不会在 DOM 中渲染，如果你想优化 SEO，这是非常有用的。"
      }
    },
    {
      "name": "exact",
      "type": "boolean",
      "default": "false",
      "source": "routable",
      "description": {
        "en": "Exactly match the link. Without this, '/' will match every route. You can find more information about the [**exact** prop](https://router.vuejs.org/api/#exact) on the vue-router documentation.",
        "ja": "リンクと完全に一致します。これがなければ、'/' はすべてのルートにマッチします。[**exact** prop](https://router.vuejs.org/ja/api/#exact) の詳細については、vue-router のドキュメントを参照してください。",
        "zh-Hans": "完全匹配链接。如果没有这个链接，'/' 将匹配每个路由。你能够在 vue-router 文档浏览更多 [**exact** prop](https://router.vuejs.org/api/#exact)。"
      }
    },
    {
      "name": "exact-active-class",
      "type": "string",
      "default": "undefined",
      "source": "routable",
      "description": {
        "en": "Configure the active CSS class applied when the link is active with exact match. You can find more information about the [**exact-active-class** prop](https://router.vuejs.org/api/#exact-active-class) on the vue-router documentation.",
        "ja": "完全一致のリンクがアクティブな場合に適用されるアクティブな CSS クラスを設定します。[**exact-active-class** prop](https://router.vuejs.org/ja/api/#exact-active-class) の詳細については、vue-router のドキュメントをご覧ください。",
        "zh-Hans": "配置在精准链接激活时应用的 CSS 类。你能够在 vue-router 文档浏览更多[**exact-active-class** prop](https://router.vuejs.org/api/#exact-active-class)。"
      }
    },
    {
      "name": "exact-path",
      "type": "boolean",
      "default": "false",
      "source": "routable",
      "description": {
        "en": "Exactly match the link, ignoring the `query` and the `hash` sections. You can find more information about the [**exact-path** prop](https://router.vuejs.org/api/#exact-path) on the vue-router documentation.",
        "ja": "`query` セクションと `hash` セクションを無視して、リンクと完全に一致します。[**exact-path** prop](https://router.vuejs.org/api/#exact-path) の詳細については、vue-routerのドキュメントをご覧ください。",
        "zh-Hans": "精确匹配链接, 忽略`query`和`hash`部分. 您可以在vue-router文档的[**精确路径** prop](https://router.vuejs.org/api/#exact-path)找到相关的信息."
      }
    },
    {
      "name": "href",
      "type": [
        "string",
        "object"
      ],
      "default": "undefined",
      "source": "routable",
      "description": {
        "en": "Designates the component as anchor and applies the **href** attribute.",
        "ja": "コンポーネントをアンカーとして指定し、**href** 属性を適用します。",
        "zh-Hans": "指定组件为锚点并应用 **href** 属性。"
      }
    },
    {
      "name": "link",
      "type": "boolean",
      "default": "false",
      "source": "routable",
      "description": {
        "en": "Designates that the component is a link. This is automatic when using the **href** or **to** prop.",
        "ja": "コンポーネントがリンクであることを指定します。これは **href** または **to** propを使用する場合は自動的に行われます。",
        "zh-Hans": "指定组件为链接。当使用 **href** 或 **to ** 属性时，这是自动的设置的。"
      }
    },
    {
      "name": "nuxt",
      "type": "boolean",
      "default": "false",
      "source": "routable",
      "description": {
        "en": "Specifies the link is a `nuxt-link`. For use with the [nuxt framework](https://nuxtjs.org/api/components-nuxt-link/).",
        "ja": "リンクが`nuxt-link`であることを指定します。 [nuxtフレームワーク](https://ja.nuxtjs.org/api/components-nuxt-link/) で使用します。",
        "zh-Hans": "指定链接是 `nuxt-link` 。用处参考 [nuxt framework](https://nuxtjs.org/api/components-nuxt-link/)."
      }
    },
    {
      "name": "replace",
      "type": "boolean",
      "default": "false",
      "source": "routable",
      "description": {
        "en": "Setting **replace** prop will call `router.replace()` instead of `router.push()` when clicked, so the navigation will not leave a history record. You can find more information about the [**replace** prop](https://router.vuejs.org/api/#replace) on the vue-router documentation.",
        "ja": "**replace** propを設定すると、クリックされたときに `router.push()` の代わりに `router.replace()` が呼び出されるため、ナビゲーションは history レコードを残しません。[**replace** prop](https://router.vuejs.org/ja/api/#replace) の詳細については、vue-routerのドキュメントをご覧ください。",
        "zh-Hans": "设置 **replace** 属性会在点击时调用 `router.replace()` 而不是 `router.push()`，这样导航就不会留下历史记录。您可以在 vue-router 文档中浏览更多 [**replace** prop](https://router.vuejs.org/api/#replace)。"
      }
    },
    {
      "name": "reverse-transition",
      "type": [
        "boolean",
        "string"
      ],
      "default": "undefined",
      "source": "v-window-item",
      "description": {
        "en": "Sets the reverse transition",
        "ja": "戻す際のトランジションを設定します。",
        "zh-Hans": "设置反转过渡"
      }
    },
    {
      "name": "ripple",
      "type": [
        "boolean",
        "object"
      ],
      "default": "undefined",
      "source": "routable",
      "description": {
        "en": "Applies the [v-ripple](/directives/ripple) directive.",
        "ja": "[v-ripple](/directives/ripple) ディレクティブを適用します。",
        "zh-Hans": "应用 [v-ripple](/directives/ripples) 指令。"
      }
    },
    {
      "name": "tag",
      "type": "string",
      "default": "undefined",
      "source": "routable",
      "description": {
        "en": "Specify a custom tag used on the root element.",
        "ja": "コンポーネント内で使用するカスタムタグを指定します。",
        "zh-Hans": "指定在根元素上使用的自定义标签。"
      }
    },
    {
      "name": "target",
      "type": "string",
      "default": "undefined",
      "source": "routable",
      "description": {
        "en": "Designates the target attribute. This should only be applied when using the **href** prop.",
        "ja": "ターゲット属性を指定します。これは **href** propを使用する場合にのみ適用する必要があります。",
        "zh-Hans": "指定 target 属性。只在使用 **href** 属性时应用。"
      }
    },
    {
      "name": "to",
      "type": [
        "string",
        "object"
      ],
      "default": "undefined",
      "source": "routable",
      "description": {
        "en": "Denotes the target route of the link. You can find more information about the [**to** prop](https://router.vuejs.org/api/#to) on the vue-router documentation.",
        "ja": "リンクのターゲットルートを示します。[**to** prop](https://router.vuejs.org/ja/api/#to) の詳細については、vue-routerのドキュメントをご覧ください。",
        "zh-Hans": "表示链接的目标路由。你可以在 vue-router 文档浏览更多 [**to** prop](https://router.vuejs.org/api/#to)。"
      }
    },
    {
      "name": "transition",
      "type": [
        "boolean",
        "string"
      ],
      "default": "undefined",
      "source": "v-window-item",
      "description": {
        "en": "The transition used when the component progressing through items. Can be one of the [built in transitions](/styles/transitions) or one your own.",
        "ja": "コンポーネントが項目を進行するときに使用するときに使用されるトランジション。 [組み込みトランジション](/styles/transitions) のいずれか、または独自のものを使用できます。",
        "zh-Hans": "组件在逐项浏览时使用的过渡。 可以是 [built in transitions](/styles/transitions) 之一，或是你自己创建的过渡。"
      }
    },
    {
      "name": "value",
      "type": "any",
      "default": "undefined",
      "source": "v-window-item",
      "description": {
        "en": "The value used when the component is selected in a group. If not provided, the index will be used.",
        "ja": "コンポーネントがグループ内で選択されたときに使われる値です。もし指定しなかった場合、index が使用されます。",
        "zh-Hans": "在组中选择组件时使用的值。如果没有提供，则使用 index。"
      }
    }
  ],
  "mixins": [
    "bootable",
    "groupable",
    "routable",
    "v-window-item"
  ],
  "slots": [
    {
      "name": "default",
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    }
  ],
  "events": [],
  "functions": [],
  "name": "v-carousel-item",
  "sass": [],
  "component": true
}