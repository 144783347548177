module.exports = {
  "props": [
    {
      "name": "auto-draw",
      "type": "boolean",
      "default": "false",
      "source": "v-sparkline",
      "description": {
        "en": "Trace the length of the line when first rendered",
        "ja": "Trace the length of the line when first rendered",
        "zh-Hans": "首次渲染时跟踪线的长度"
      }
    },
    {
      "name": "auto-draw-duration",
      "type": "number",
      "default": 2000,
      "source": "v-sparkline",
      "description": {
        "en": "Amount of time (in ms) to run the trace animation",
        "ja": "トレースアニメーションの実行時間 (ミリ秒)",
        "zh-Hans": "运行跟踪动画的时间（以毫秒为单位）"
      }
    },
    {
      "name": "auto-draw-easing",
      "type": "string",
      "default": "'ease'",
      "source": "v-sparkline",
      "description": {
        "en": "The easing function to use for the trace animation",
        "ja": "トレースアニメーションに使用するイージング関数",
        "zh-Hans": "用于跟踪动画的缓动功能"
      }
    },
    {
      "name": "auto-line-width",
      "type": "boolean",
      "default": "false",
      "source": "v-sparkline",
      "description": {
        "en": "Automatically expand bars to use space efficiently",
        "ja": "Automatically expand bars to use space efficiently",
        "zh-Hans": "自动展开栏以有效利用空间"
      }
    },
    {
      "name": "color",
      "type": "string",
      "default": "'primary'",
      "source": "colorable",
      "description": {
        "en": "Applies specified color to the control - it can be the name of material color (for example `success` or `purple`) or css color (`#033` or `rgba(255, 0, 0, 0.5)`). You can find a list of built-in classes on the [colors page](/styles/colors#material-colors).",
        "ja": "指定された色をコントロールに適用します。利用できるのはマテリアルカラーの名前 (たとえば、`success` または ` purple`) または css 表記の色 (`#033`または` rgba(255, 0, 0, 0.5) `) です。[colors のページ](/styles/colors#material-colors) で、組み込みクラスのリストが確認できます。",
        "zh-Hans": "指定控件颜色，颜色值可以是 Material 颜色（例如 <code>success</code>或者 <code>purple</code> ）也可以是 Css 颜色 （例如 <code>#033</code> 或者 <code>rgba(255, 0, 0, 0.5)</code>）。在 [colors page](/zh-Hans/styles/colors#material-colors)中可以找到内置颜色类列表。"
      }
    },
    {
      "name": "fill",
      "type": "boolean",
      "default": "false",
      "source": "v-sparkline",
      "description": {
        "en": "Using the **fill** property allows you to better customize the look and feel of your sparkline.",
        "ja": "**fill** プロパティを使用することで、スパークラインの外観をより良くカスタマイズすることができます。",
        "zh-Hans": "使用 **fill** 属性可以让您更好地自定义您的迷你图表的外观。"
      }
    },
    {
      "name": "gradient",
      "type": "array",
      "default": [],
      "source": "v-sparkline",
      "description": {
        "en": "An array of colors to use as a linear-gradient",
        "ja": "線形グラデーション（linear-gradient）として使用する色の配列。",
        "zh-Hans": "用作线性渐变的颜色数组"
      }
    },
    {
      "name": "gradient-direction",
      "type": "string",
      "default": "'top'",
      "source": "v-sparkline",
      "description": {
        "en": "The direction the gradient should run",
        "ja": "グラデーションを実行する方向",
        "zh-Hans": "渐变应运行的方向"
      }
    },
    {
      "name": "height",
      "type": [
        "string",
        "number"
      ],
      "default": 75,
      "source": "v-sparkline",
      "description": {
        "en": "Height of the SVG trendline or bars",
        "ja": "SVGトレンドラインまたはバーの高さ",
        "zh-Hans": "SVG 趋势线或条的高度"
      }
    },
    {
      "name": "label-size",
      "type": [
        "number",
        "string"
      ],
      "default": 7,
      "source": "v-sparkline",
      "description": {
        "en": "The label font size",
        "ja": "ラベルフォントサイズ",
        "zh-Hans": "标签字体大小"
      }
    },
    {
      "name": "labels",
      "type": "array",
      "default": [],
      "source": "v-sparkline",
      "description": {
        "en": "An array of string labels that correspond to the same index as its data counterpart",
        "ja": "データと同じインデックスに対応する文字列ラベルの配列",
        "zh-Hans": "与数据对应的索引对应的字符串标签数组"
      }
    },
    {
      "name": "line-width",
      "type": [
        "string",
        "number"
      ],
      "default": 4,
      "source": "v-sparkline",
      "description": {
        "en": "The thickness of the line, in px",
        "ja": "線の太さ（px）",
        "zh-Hans": "线的粗细，以 px 为单位"
      }
    },
    {
      "name": "padding",
      "type": [
        "string",
        "number"
      ],
      "default": 8,
      "source": "v-sparkline",
      "description": {
        "en": "Low `smooth` or high `line-width` values may result in cropping, increase padding to compensate",
        "ja": "Low `smooth` or high `line-width` values may result in cropping, increase padding to compensate",
        "zh-Hans": "较低的 `smooth` 值或较高的 `line-width` 值可能会导致裁切，请增加填充以补偿"
      }
    },
    {
      "name": "show-labels",
      "type": "boolean",
      "default": "false",
      "source": "v-sparkline",
      "description": {
        "en": "Show labels below each data point",
        "ja": "各データ ポイントの下にラベルを表示する",
        "zh-Hans": "在每个数据点下方显示标签"
      }
    },
    {
      "name": "smooth",
      "type": [
        "boolean",
        "number",
        "string"
      ],
      "default": false,
      "source": "v-sparkline",
      "description": {
        "en": "Number of px to use as a corner radius. `true` defaults to 8, `false` is 0",
        "ja": "Number of px to use as a corner radius. `true` defaults to 8, `false` is 0",
        "zh-Hans": "用作角半径的 px 数。 `true` 为默认值 8，`false` 为 0"
      }
    },
    {
      "name": "type",
      "type": "string",
      "default": "'trend'",
      "source": "v-sparkline",
      "description": {
        "en": "Choose between a trendline or bars",
        "ja": "トレンドラインとバーのどちらかを選択",
        "zh-Hans": "在趋势线和条线之间选择"
      }
    },
    {
      "name": "value",
      "type": "array",
      "default": [],
      "source": "v-sparkline",
      "description": {
        "en": "An array of numbers.",
        "ja": "数値の配列",
        "zh-Hans": "数字数组。"
      }
    },
    {
      "name": "width",
      "type": [
        "number",
        "string"
      ],
      "default": 300,
      "source": "v-sparkline",
      "description": {
        "en": "Width of the SVG trendline or bars",
        "ja": "SVGトレンドラインまたはバーの幅",
        "zh-Hans": "SVG 趋势线或条的宽度"
      }
    }
  ],
  "mixins": [
    "colorable"
  ],
  "slots": [
    {
      "name": "label",
      "description": {
        "en": "Replaces the default label",
        "ja": "デフォルトのラベルを置き換えます",
        "zh-Hans": "替换默认标签"
      }
    }
  ],
  "events": [],
  "functions": [],
  "name": "v-sparkline",
  "sass": [],
  "component": true
}