module.exports = {
  "props": [
    {
      "name": "fluid",
      "type": "boolean",
      "default": "false",
      "source": "v-container",
      "description": {
        "en": "Removes viewport maximum-width size breakpoints",
        "ja": "ビューポートの最大幅のブレークポイントを削除します",
        "zh-Hans": "删除视图最大宽度大小的断点"
      }
    },
    {
      "name": "id",
      "type": "string",
      "default": "undefined",
      "source": "v-container",
      "description": {
        "en": "Sets the DOM id on the component",
        "ja": "コンポーネントにDOM idを設定します",
        "zh-Hans": "设置组件上的 DOM id"
      }
    },
    {
      "name": "tag",
      "type": "string",
      "default": "'div'",
      "source": "v-container",
      "description": {
        "en": "Specify a custom tag used on the root element.",
        "ja": "コンポーネント内で使用するカスタムタグを指定します。",
        "zh-Hans": "指定在根元素上使用的自定义标签。"
      }
    }
  ],
  "mixins": [],
  "slots": [
    {
      "name": "default",
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    }
  ],
  "events": [],
  "functions": [],
  "name": "v-container",
  "sass": [],
  "component": true
}