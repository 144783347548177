module.exports = {
  "props": [
    {
      "name": "active-class",
      "type": "string",
      "default": "'v-item--active'",
      "source": "button-group",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "background-color",
      "type": "string",
      "default": "undefined",
      "source": "v-btn-toggle",
      "description": {
        "en": "Changes the background-color for the component.",
        "ja": "コンポーネントの背景色を変更します。",
        "zh-Hans": "更改组件的背景颜色。"
      }
    },
    {
      "name": "borderless",
      "type": "boolean",
      "default": "false",
      "source": "v-btn-toggle",
      "description": {
        "en": "Removes the group's border.",
        "ja": "グループの枠線を削除します。",
        "zh-Hans": "移除按钮组的边框。"
      }
    },
    {
      "name": "color",
      "type": "string",
      "default": "undefined",
      "source": "colorable",
      "description": {
        "en": "Applies specified color to the control - it can be the name of material color (for example `success` or `purple`) or css color (`#033` or `rgba(255, 0, 0, 0.5)`). You can find a list of built-in classes on the [colors page](/styles/colors#material-colors).",
        "ja": "指定された色をコントロールに適用します。利用できるのはマテリアルカラーの名前 (たとえば、`success` または ` purple`) または css 表記の色 (`#033`または` rgba(255, 0, 0, 0.5) `) です。[colors のページ](/styles/colors#material-colors) で、組み込みクラスのリストが確認できます。",
        "zh-Hans": "指定控件颜色，颜色值可以是 Material 颜色（例如 <code>success</code>或者 <code>purple</code> ）也可以是 Css 颜色 （例如 <code>#033</code> 或者 <code>rgba(255, 0, 0, 0.5)</code>）。在 [colors page](/zh-Hans/styles/colors#material-colors)中可以找到内置颜色类列表。"
      }
    },
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにダークテーマを適応します。詳しく知りたい場合は、Material Design documentationの<a href=\"https://material.io/design/color/dark-theme.html\">dark themes</a>を参照してください。",
        "zh-Hans": "将暗色主题变量应用到组件。你可以在 [dark themes](https://material.io/design/color/dark-theme.html) 的 Material Design 文档中找到更多有关信息。"
      }
    },
    {
      "name": "dense",
      "type": "boolean",
      "default": "false",
      "source": "v-btn-toggle",
      "description": {
        "en": "Reduces the button size and padding.",
        "ja": "ボタンのサイズとパディングを縮小します。",
        "zh-Hans": "减少按钮大小和间距。"
      }
    },
    {
      "name": "group",
      "type": "boolean",
      "default": "false",
      "source": "v-btn-toggle",
      "description": {
        "en": "Generally used in [v-toolbar](/components/toolbars) and [v-app-bar](/components/app-bars). Removes background color, border and increases space between the buttons",
        "ja": "一般的に [v-toolbar](/components/toolbars) および [v-app-bar](/components/app-bars) で使用されます。背景色、境界線を削除し、ボタン間のスペースを増やします",
        "zh-Hans": "通常用于 [v-toolbar](/components/toolbars) 和 [v-app-bar](/components/app-bars)。删除背景颜色、边框并增加按钮的间距"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    },
    {
      "name": "mandatory",
      "type": "boolean",
      "default": "false",
      "source": "button-group",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "max",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "button-group",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "multiple",
      "type": "boolean",
      "default": "false",
      "source": "button-group",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "rounded",
      "type": "boolean",
      "default": "false",
      "source": "v-btn-toggle",
      "description": {
        "en": "Round edge buttons",
        "ja": "両端が丸いボタンにする",
        "zh-Hans": "在边缘上的圆角按钮"
      }
    },
    {
      "name": "shaped",
      "type": "boolean",
      "default": "false",
      "source": "v-btn-toggle",
      "description": {
        "en": "Applies a large border radius on the top left and bottom right of the card.",
        "ja": "カードの左上と右下の角を丸くします。",
        "zh-Hans": "在卡片的左上角和右下角应用较大的边框半径。"
      }
    },
    {
      "name": "tag",
      "type": "string",
      "default": "'div'",
      "source": "button-group",
      "description": {
        "en": "Specify a custom tag used on the root element.",
        "ja": "コンポーネント内で使用するカスタムタグを指定します。",
        "zh-Hans": "指定在根元素上使用的自定义标签。"
      }
    },
    {
      "name": "tile",
      "type": "boolean",
      "default": "false",
      "source": "v-btn-toggle",
      "description": {
        "en": "Removes the component's border-radius.",
        "ja": "コンポーネントの角丸を除去します。",
        "zh-Hans": "删除组件的 border-radius。"
      }
    },
    {
      "name": "value",
      "type": "any",
      "default": "undefined",
      "source": "proxyable",
      "description": {
        "en": "The designated model value for the component.",
        "ja": "コンポーネントの指定されたモデルの値。",
        "zh-Hans": "组件的指定 model 值。"
      }
    },
    {
      "name": "value-comparator",
      "type": "function",
      "default": "null",
      "source": "comparable",
      "description": {
        "en": "Apply a custom value comparator function",
        "ja": "カスタムの値の比較関数を適用します",
        "zh-Hans": "应用自定义的 comparator function"
      }
    }
  ],
  "mixins": [
    "button-group",
    "colorable",
    "comparable",
    "proxyable",
    "themeable"
  ],
  "slots": [
    {
      "name": "default",
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    }
  ],
  "events": [
    {
      "name": "change",
      "value": "any[] | any",
      "description": {
        "en": "Emitted when the input is changed by user interaction",
        "ja": "ユーザーの操作によって値が変更されたときに発生します。",
        "zh-Hans": "当用户交互更改输入时发出"
      }
    }
  ],
  "functions": [],
  "name": "v-btn-toggle",
  "sass": [
    {
      "name": "$btn-toggle-border-radius",
      "default": "$border-radius-root !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$btn-toggle-shaped-border-radius",
      "default": "24px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$btn-toggle-btn-height",
      "default": "48px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$btn-toggle-btn-padding",
      "default": "0 12px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$btn-toggle-btn-width",
      "default": "48px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$btn-toggle-btn-opacity",
      "default": "0.8 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$btn-toggle-round-border-radius",
      "default": "24px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$btn-toggle-dense-btn-padding",
      "default": "0 8px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$btn-toggle-group-btn-margin",
      "default": "4px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "component": true
}