module.exports = {
  "props": [
    {
      "name": "boilerplate",
      "type": "boolean",
      "default": "false",
      "source": "v-skeleton-loader",
      "description": {
        "en": "Remove the loading animation from the skeleton",
        "ja": "スケルトンから読み込みアニメーションを削除します",
        "zh-Hans": "从骨架中移除加载动画"
      }
    },
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにダークテーマを適応します。詳しく知りたい場合は、Material Design documentationの<a href=\"https://material.io/design/color/dark-theme.html\">dark themes</a>を参照してください。",
        "zh-Hans": "将暗色主题变量应用到组件。你可以在 [dark themes](https://material.io/design/color/dark-theme.html) 的 Material Design 文档中找到更多有关信息。"
      }
    },
    {
      "name": "elevation",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "elevatable",
      "description": {
        "en": "Designates an elevation applied to the component between 0 and 24. You can find more information on the [elevation page](/styles/elevation).",
        "ja": "コンポーネントに適用される高さを0〜24の範囲で指定します。詳細は、[elevation のページ](/styles/elevation)で確認できます。",
        "zh-Hans": "组件的海拔可接受 0 到 24 之间的值。你可以在 [elevation page](/styles/elevation) 浏览更多信息。"
      }
    },
    {
      "name": "height",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the height for the component.",
        "ja": "コンポーネントに高さを設定します。",
        "zh-Hans": "设定组件的高度。"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    },
    {
      "name": "loading",
      "type": "boolean",
      "default": "false",
      "source": "v-skeleton-loader",
      "description": {
        "en": "Applies a loading animation with a on-hover loading cursor. A value of **false** will only work when there is content in the `default` slot.",
        "ja": "カーソルをon-hoverでロードするアニメーションを適用します。**false**の値は、`default`スロットにコンテンツがある場合にのみ動作します。",
        "zh-Hans": "应用一个加载动画，加载光标在悬停时加载。**false** 的值只有在 `default` 槽中有内容时才会工作。"
      }
    },
    {
      "name": "loading-text",
      "type": "string",
      "default": "'$vuetify.loading'",
      "source": "v-skeleton-loader",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "max-height",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the maximum height for the component.",
        "ja": "コンテンツの高さの最大値を設定します。",
        "zh-Hans": "设定组件的最大高度。"
      }
    },
    {
      "name": "max-width",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the maximum width for the component.",
        "ja": "コンテンツの横幅の最大値を設定します。",
        "zh-Hans": "设定组件的最大宽度。"
      }
    },
    {
      "name": "min-height",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the minimum height for the component.",
        "ja": "コンテンツの高さの最小値を設定します。",
        "zh-Hans": "设定组件的最小高度。"
      }
    },
    {
      "name": "min-width",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the minimum width for the component.",
        "ja": "コンテンツの横幅の最小値を設定します。",
        "zh-Hans": "设定组件的最小宽度。"
      }
    },
    {
      "name": "tile",
      "type": "boolean",
      "default": "false",
      "source": "v-skeleton-loader",
      "description": {
        "en": "Removes the component's border-radius",
        "ja": "コンポーネントの border-radius を削除します",
        "zh-Hans": "删除组件的**border-radius**样式"
      }
    },
    {
      "name": "transition",
      "type": "string",
      "default": "undefined",
      "source": "v-skeleton-loader",
      "description": {
        "en": "Sets the component transition. Can be one of the [built in transitions](/styles/transitions) or one your own.",
        "ja": "コンポーネントのトランジションを設定します。 [組み込みトランジション](/styles/transitions) のいずれか、または独自のものを使用できます。",
        "zh-Hans": "设置组件转换。可以是一个 [built in transitions](/styles/transitions) 或者是自己自定义的。"
      }
    },
    {
      "name": "type",
      "type": "string",
      "default": "undefined",
      "source": "v-skeleton-loader",
      "example": {
        "actions": "button@2",
        "article": "heading, paragraph",
        "avatar": "avatar",
        "button": "button",
        "card": "image, card-heading",
        "card-avatar": "image, list-item-avatar",
        "card-heading": "heading",
        "chip": "chip",
        "date-picker": "list-item, card-heading, divider, date-picker-options, date-picker-days, actions",
        "date-picker-options": "text, avatar@2",
        "date-picker-days": "avatar@28",
        "heading": "heading",
        "image": "image",
        "list-item": "text",
        "list-item-avatar": "avatar, text",
        "list-item-two-line": "sentences",
        "list-item-avatar-two-line": "avatar, sentences",
        "list-item-three-line": "paragraph",
        "list-item-avatar-three-line": "avatar, paragraph",
        "paragraph": "text@3",
        "sentences": "text@2",
        "table": "table-heading, table-thead, table-tbody, table-tfoot",
        "table-heading": "heading, text",
        "table-thead": "heading@6",
        "table-tbody": "table-row-divider@6",
        "table-row-divider": "table-row, divider",
        "table-row": "table-cell@6",
        "table-cell": "text",
        "table-tfoot": "text@2, avatar@2",
        "text": "text"
      },
      "description": {
        "en": "A string delimited list of skeleton components to create such as `type=\"text@3\"` or `type=\"card, list-item\"`. Will recursively generate a corresponding skeleton from the provided string. Also supports short-hand for multiple elements such as **article@3** and **paragraph@2** which will generate 3 _article_ skeletons and 2 _paragraph_ skeletons. Please see below for a list of available pre-defined options.",
        "ja": "`type=\"text@3\"`や`type=\"card, list-item\"`などの作成するスケルトンコンポーネントの文字列区切りリスト。提供された文字列から対応するスケルトンを再帰的に生成します。 また **article@3** や **paragraph @2** のような複数の要素に対して短絡をサポートし、3 つの _article_ スケルトンと 2 つの _paragraph スケルトンを生成します。 利用可能な事前定義オプションのリストは以下を参照してください。",
        "zh-Hans": "字符串分隔要创建的骨架组件列表，例如 `type=\"text@3\"` 和 `type=\"card, list-item\"`。将根据提供的字符串递归生成相应的框架。 还支持多个元素的简写，例如 **article@3** 和 **paragraph@2** ，它们将生成 3 _article_ skeletons and 2 _paragraph_ 骨架。 请参阅下面的可用预定义选项列表。"
      }
    },
    {
      "name": "types",
      "type": "object",
      "default": {},
      "source": "v-skeleton-loader",
      "description": {
        "en": "A custom types object that will be combined with the pre-defined options. For a list of available pre-defined options, see the **type** prop.",
        "ja": "あらかじめ定義されたオプションと組み合わせるカスタム型オブジェクト。利用可能な事前定義オプションのリストについては、**type** プロパティを参照してください。",
        "zh-Hans": "将与预定义选项结合使用的自定义类型对象。 有关可用的预定义选项的列表，请参见 **type** 属性。"
      }
    },
    {
      "name": "width",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the width for the component.",
        "ja": "コンポーネントに横幅を設定します。",
        "zh-Hans": "设定组件的宽度。"
      }
    }
  ],
  "mixins": [
    "elevatable",
    "measurable",
    "themeable"
  ],
  "slots": [
    {
      "name": "default",
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    }
  ],
  "events": [],
  "functions": [],
  "name": "v-skeleton-loader",
  "sass": [
    {
      "name": "$skeleton-loader-actions-button-margin",
      "default": "12px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-actions-padding",
      "default": "16px 16px 8px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-article-heading-margin-top-left",
      "default": "16px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-article-heading-margin-x",
      "default": "16px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-article-paragraph-padding",
      "default": "16px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-avatar-height",
      "default": "56px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-avatar-width",
      "default": "48px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-border-radius",
      "default": "$border-radius-root !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-button-border-radius",
      "default": "$border-radius-root !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-button-height",
      "default": "36px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-button-width",
      "default": "64px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-card-heading-loader-heading-margin",
      "default": "16px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-card-text-padding",
      "default": "16px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-chip-border-radius",
      "default": "16px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-chip-height",
      "default": "32px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-chip-width",
      "default": "96px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-date-picker-border-radius",
      "default": "inherit !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-date-picker-days-height",
      "default": "40px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-date-picker-days-margin",
      "default": "4px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-date-picker-days-padding",
      "default": "0 12px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-date-picker-days-width",
      "default": "40px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-date-picker-options-avatar-child-margin",
      "default": "8px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-date-picker-options-avatar-height",
      "default": "40px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-date-picker-options-avatar-width",
      "default": "40px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-date-picker-options-padding",
      "default": "16px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-divider-border-radius",
      "default": "1px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-divider-height",
      "default": "2px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-heading-border-radius",
      "default": "12px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-heading-height",
      "default": "24px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-image-height",
      "default": "200px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-item-avatar-height",
      "default": "40px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-item-avatar-margin",
      "default": "16px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-item-avatar-width",
      "default": "40px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-item-padding",
      "default": "0 16px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-item-three-line-height",
      "default": "88px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-item-two-line-height",
      "default": "72px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-list-item-height",
      "default": "48px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-loading-animation",
      "default": "loading 1.5s infinite !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-loading-transform",
      "default": "translateX(-100%) !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-table-cell-height",
      "default": "48px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-table-cell-width",
      "default": "88px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-table-heading-padding",
      "default": "16px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-table-tbody-padding",
      "default": "16px 16px 0 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-table-tfoot-avatar-height",
      "default": "40px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-table-tfoot-avatar-width",
      "default": "40px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-table-tfoot-children-margin",
      "default": "8px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-table-tfoot-padding",
      "default": "16px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-table-thead-heading-padding",
      "default": "16px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-table-thead-padding",
      "default": "16px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-text-border-radius",
      "default": "6px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$skeleton-loader-text-height",
      "default": "12px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "component": true
}