module.exports = {
  "props": [
    {
      "name": "absolute",
      "type": "boolean",
      "default": "false",
      "source": "v-toolbar",
      "description": {
        "en": "Applies position: absolute to the component.",
        "ja": "コンポーネントに position: absolute を適用します。",
        "zh-Hans": "应用 **position: absolute** 样式到组件."
      }
    },
    {
      "name": "bottom",
      "type": "boolean",
      "default": "false",
      "source": "v-toolbar",
      "description": {
        "en": "Aligns the component towards the bottom.",
        "ja": "コンポーネントを下部に配置します。",
        "zh-Hans": "将组件向底部对齐。"
      }
    },
    {
      "name": "collapse",
      "type": "boolean",
      "default": "false",
      "source": "v-toolbar",
      "description": {
        "en": "Puts the toolbar into a collapsed state reducing its maximum width.",
        "ja": "ツールバーを折りたたんだ状態に配置し、最大幅を減らします。",
        "zh-Hans": "将工具栏置于折叠状态，以减小其最大宽度。"
      }
    },
    {
      "name": "color",
      "type": "string",
      "default": "undefined",
      "source": "colorable",
      "description": {
        "en": "Applies specified color to the control - it can be the name of material color (for example `success` or `purple`) or css color (`#033` or `rgba(255, 0, 0, 0.5)`). You can find a list of built-in classes on the [colors page](/styles/colors#material-colors).",
        "ja": "指定された色をコントロールに適用します。利用できるのはマテリアルカラーの名前 (たとえば、`success` または ` purple`) または css 表記の色 (`#033`または` rgba(255, 0, 0, 0.5) `) です。[colors のページ](/styles/colors#material-colors) で、組み込みクラスのリストが確認できます。",
        "zh-Hans": "指定控件颜色，颜色值可以是 Material 颜色（例如 <code>success</code>或者 <code>purple</code> ）也可以是 Css 颜色 （例如 <code>#033</code> 或者 <code>rgba(255, 0, 0, 0.5)</code>）。在 [colors page](/zh-Hans/styles/colors#material-colors)中可以找到内置颜色类列表。"
      }
    },
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにダークテーマを適応します。詳しく知りたい場合は、Material Design documentationの<a href=\"https://material.io/design/color/dark-theme.html\">dark themes</a>を参照してください。",
        "zh-Hans": "将暗色主题变量应用到组件。你可以在 [dark themes](https://material.io/design/color/dark-theme.html) 的 Material Design 文档中找到更多有关信息。"
      }
    },
    {
      "name": "dense",
      "type": "boolean",
      "default": "false",
      "source": "v-toolbar",
      "description": {
        "en": "Reduces the height of the toolbar content to 48px (96px when using the **prominent** prop).",
        "ja": "ツールバーのコンテンツの高さを48px（** prominent ** propを使用する場合は96px）に減らします。",
        "zh-Hans": "将工具栏内容的高度降低到 48px（使用 **prominent** 属性时为 96px）。"
      }
    },
    {
      "name": "elevation",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "elevatable",
      "description": {
        "en": "Designates an elevation applied to the component between 0 and 24. You can find more information on the [elevation page](/styles/elevation).",
        "ja": "コンポーネントに適用される高さを0〜24の範囲で指定します。詳細は、[elevation のページ](/styles/elevation)で確認できます。",
        "zh-Hans": "组件的海拔可接受 0 到 24 之间的值。你可以在 [elevation page](/styles/elevation) 浏览更多信息。"
      }
    },
    {
      "name": "extended",
      "type": "boolean",
      "default": "false",
      "source": "v-toolbar",
      "description": {
        "en": "Use this prop to increase the height of the toolbar _without_ using the `extension` slot for adding content. May be used in conjunction with the **extension-height** prop, and any of the other props that affect the height of the toolbar, e.g. **prominent**, **dense**, etc., **WITH THE EXCEPTION** of **height**.",
        "ja": "このプロパティを使用すると、コンテンツを追加するための`extension`スロットを _使用せずに_ ツールバーの高さを増加させます。 **extension-height** プロップや、ツールバーの高さに影響する他のプロップ(**prominent**、**dense** など)と組み合わせて使用​​できますが、**height** と組み合わせると **例外が発生** します。",
        "zh-Hans": "使用此道具可以增加工具栏的高度，而 _不需要_ 使用 `extension` 插槽来添加内容。可以与 **extension-height** 属性，以及其他会影响工具栏高度的其他属性，例如 **prominent**, **dense** 等配合使用，**height** **除外**。"
      }
    },
    {
      "name": "extension-height",
      "type": [
        "number",
        "string"
      ],
      "default": 48,
      "source": "v-toolbar",
      "description": {
        "en": "Specify an explicit height for the `extension` slot. ",
        "ja": "`extension`slotの高さ。 ",
        "zh-Hans": "为 `extension` 插槽指定一个明确的高度。 "
      }
    },
    {
      "name": "flat",
      "type": "boolean",
      "default": "false",
      "source": "v-toolbar",
      "description": {
        "en": "Removes the toolbar's box-shadow.",
        "ja": "このツールバーの影を取り除きます。",
        "zh-Hans": "删除工具栏的框阴影。"
      }
    },
    {
      "name": "floating",
      "type": "boolean",
      "default": "false",
      "source": "v-toolbar",
      "description": {
        "en": "Applies **display: inline-flex** to the component.",
        "ja": "**display: inline-flex**を適用します。",
        "zh-Hans": "将 **display: inline-flex** 应用于组件。"
      }
    },
    {
      "name": "height",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Designates a specific height for the toolbar. Overrides the heights imposed by other props, e.g. **prominent**, **dense**, **extended**, etc.",
        "ja": "ツールバーの高さを指定します。他のprop（**prominent**、**dense**、**extended**など）によって課される高さを上書きします。",
        "zh-Hans": "指定工具栏的特定高度。覆盖其他 props 所施加的高度，例如 **prominent**, **dense**, **extended**。"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    },
    {
      "name": "max-height",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the maximum height for the component.",
        "ja": "コンテンツの高さの最大値を設定します。",
        "zh-Hans": "设定组件的最大高度。"
      }
    },
    {
      "name": "max-width",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the maximum width for the component.",
        "ja": "コンテンツの横幅の最大値を設定します。",
        "zh-Hans": "设定组件的最大宽度。"
      }
    },
    {
      "name": "min-height",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the minimum height for the component.",
        "ja": "コンテンツの高さの最小値を設定します。",
        "zh-Hans": "设定组件的最小高度。"
      }
    },
    {
      "name": "min-width",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the minimum width for the component.",
        "ja": "コンテンツの横幅の最小値を設定します。",
        "zh-Hans": "设定组件的最小宽度。"
      }
    },
    {
      "name": "outlined",
      "type": "boolean",
      "default": "false",
      "source": "v-sheet",
      "description": {
        "en": "Removes elevation (box-shadow) and adds a *thin* border.",
        "ja": "Elevationの影(box-shadow) を削除し、*細い* 枠線を追加します。",
        "zh-Hans": "去除卡片的实心颜色并添加细边框。"
      }
    },
    {
      "name": "prominent",
      "type": "boolean",
      "default": "false",
      "source": "v-toolbar",
      "description": {
        "en": "Increases the height of the toolbar content to 128px.",
        "ja": "ツールバーの高さを128pxにまで増やします。",
        "zh-Hans": "将工具栏内容的高度增加到 128px。"
      }
    },
    {
      "name": "rounded",
      "type": [
        "boolean",
        "string"
      ],
      "default": "undefined",
      "source": "roundable",
      "description": {
        "en": "Designates the **border-radius** applied to the component. You can find more information on the [Border Radius page](/styles/border-radius).",
        "ja": "コンポーネントに適用された **border-radius** を指定します。[Border Radius ページ](/styles/border-radius) で詳細を確認できます。",
        "zh-Hans": "对指定的组件应用 **border-radius** 样式。您可以在 [边框的圆角半径页面](/styles/border-radius) 获取更多有关信息"
      }
    },
    {
      "name": "shaped",
      "type": "boolean",
      "default": "false",
      "source": "v-sheet",
      "description": {
        "en": "Applies a large border radius on the top left and bottom right of the card.",
        "ja": "カードの左上と右下の角を丸くします。",
        "zh-Hans": "在卡片的左上角和右下角应用较大的边框半径。"
      }
    },
    {
      "name": "short",
      "type": "boolean",
      "default": "false",
      "source": "v-toolbar",
      "description": {
        "en": "Reduce the height of the toolbar content to 56px (112px when using the **prominent** prop).",
        "ja": "ツールバーのコンテンツの高さを56px(** prominent ** propを使用する場合は112px) に減らします。",
        "zh-Hans": "将工具栏内容的高度降低到 56px（使用 **prominent** 属性时为 112px）。"
      }
    },
    {
      "name": "src",
      "type": [
        "string",
        "object"
      ],
      "default": "undefined",
      "source": "v-toolbar",
      "description": {
        "en": "Specifies a [v-img](/components/images) as the component's background.",
        "ja": "コンポーネントの背景として [v-img](/components/images) を指定します。",
        "zh-Hans": "指定 [v-img](/components/images) 作为组件背景。"
      }
    },
    {
      "name": "tag",
      "type": "string",
      "default": "'header'",
      "source": "v-sheet",
      "description": {
        "en": "Specify a custom tag used on the root element.",
        "ja": "コンポーネント内で使用するカスタムタグを指定します。",
        "zh-Hans": "指定在根元素上使用的自定义标签。"
      }
    },
    {
      "name": "tile",
      "type": "boolean",
      "default": "false",
      "source": "roundable",
      "description": {
        "en": "Removes the component's **border-radius**.",
        "ja": "コンポーネントの **border-radius** を削除します。",
        "zh-Hans": "删除组件的**border-radius**样式"
      }
    },
    {
      "name": "width",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the width for the component.",
        "ja": "コンポーネントに横幅を設定します。",
        "zh-Hans": "设定组件的宽度。"
      }
    }
  ],
  "mixins": [
    "colorable",
    "elevatable",
    "measurable",
    "roundable",
    "themeable"
  ],
  "slots": [
    {
      "name": "default",
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    },
    {
      "name": "extension",
      "description": {
        "en": "Slot positioned directly under the main content of the toolbar. Height of this slot can be set explicitly with the **extension-height** prop. If this slot has no content, the **extended** prop may be used instead.",
        "ja": "スロットはツールバーのメインコンテンツの下に配置されます。高さは**extension-height**プロパティで明示的に設定できます。 このスロットにコンテンツがない場合、**extended** プロパティが代わりに使用されます。",
        "zh-Hans": "槽位直接位于工具栏的主要内容下。可以使用 **extension-height** 属性显式设置高度。如果该插槽没有内容，可以使用 **extended** 属性代替。"
      }
    },
    {
      "name": "img",
      "props": {
        "props": "{ height: string, src: string | srcObject }"
      },
      "description": {
        "en": "Expects the [v-img](/components/images) component. Scoped **props** should be applied with `v-bind=\"props\"`.",
        "ja": "[v-img](/components/images) コンポーネントが必要です。スコープ付きの **props** は `v-bind=\"props\"` で適用する必要があります。",
        "zh-Hans": "需要 [v-img](/components/images) 组件。范围内的 **props** 应该与 `v-bind=\"props\"` 一起使用。"
      }
    }
  ],
  "events": [],
  "functions": [],
  "name": "v-toolbar",
  "sass": [
    {
      "name": "$toolbar-btn-icon-size",
      "default": "48px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$toolbar-content-padding-y",
      "default": "4px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$toolbar-content-padding-x",
      "default": "16px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$toolbar-elevation",
      "default": "4 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$toolbar-border-radius",
      "default": "0 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$toolbar-shaped-border-radius",
      "default": "map-get($rounded, 'xl') $toolbar-border-radius !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$toolbar-title-padding",
      "default": "20px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$toolbar-transition",
      "default": "0.2s map-get($transition, 'fast-out-slow-in') transform,\n                     0.2s map-get($transition, 'fast-out-slow-in') background-color,\n                     0.2s map-get($transition, 'fast-out-slow-in') left,\n                     0.2s map-get($transition, 'fast-out-slow-in') right,\n                     280ms map-get($transition, 'fast-out-slow-in') box-shadow,\n                     0.25s map-get($transition, 'fast-out-slow-in') max-width,\n                     0.25s map-get($transition, 'fast-out-slow-in') width !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$toolbar-collapsed-max-width",
      "default": "112px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$toolbar-collapsed-border-radius",
      "default": "24px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$toolbar-prominent-padding",
      "default": "6px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "component": true
}