module.exports = {
  "props": [
    {
      "name": "absolute",
      "type": "boolean",
      "default": "false",
      "source": "positionable",
      "description": {
        "en": "Applies **position: absolute** to the component.",
        "ja": "コンポーネントに **position: absolute** を適用します。",
        "zh-Hans": "给组件应用 **position: absolute**"
      }
    },
    {
      "name": "app",
      "type": "boolean",
      "default": "false",
      "source": "applicationable",
      "description": {
        "en": "Designates the component as part of the application layout. Used for dynamically adjusting content sizing. Components using this prop should reside **outside** of `v-main` component to function properly. You can find more information about layouts on the [application page](/components/application). **Note:** this prop automatically applies **position: fixed** to the layout element. You can overwrite this functionality by using the `absolute` prop",
        "ja": "コンポーネントをアプリケーションレイアウトの一部として指定します。コンテンツのサイズを動的に調整するために使用します。 このプロパティを使用するコンポーネントは、 `v-main` コンポーネントの **外側** に正しく機能するようにしてください。 [application page](/components/application) でレイアウトの詳細を確認できます。 **注意:** このプロパティは自動的に **position: fixed** をレイアウト要素に適用します。`absolute` prop を使用すると、この機能を上書きできます。",
        "zh-Hans": "指定该组件作为应用程序布局的一部分。用于动态调整内容的大小。使用此属性的组件只有位于 `v-main `组件的 **外部**才能正常运行。您可以在 [应用程序页面](/components/application) 获取更多有关布局的信息。**注意：** 使用此属性会自动应用**position: fixed** 到布局元素上。您可以使用 `absolute` 属性 来覆盖这个功能。"
      }
    },
    {
      "name": "color",
      "type": "string",
      "default": "undefined",
      "source": "colorable",
      "description": {
        "en": "Applies specified color to the control - it can be the name of material color (for example `success` or `purple`) or css color (`#033` or `rgba(255, 0, 0, 0.5)`). You can find a list of built-in classes on the [colors page](/styles/colors#material-colors).",
        "ja": "指定された色をコントロールに適用します。利用できるのはマテリアルカラーの名前 (たとえば、`success` または ` purple`) または css 表記の色 (`#033`または` rgba(255, 0, 0, 0.5) `) です。[colors のページ](/styles/colors#material-colors) で、組み込みクラスのリストが確認できます。",
        "zh-Hans": "指定控件颜色，颜色值可以是 Material 颜色（例如 <code>success</code>或者 <code>purple</code> ）也可以是 Css 颜色 （例如 <code>#033</code> 或者 <code>rgba(255, 0, 0, 0.5)</code>）。在 [colors page](/zh-Hans/styles/colors#material-colors)中可以找到内置颜色类列表。"
      }
    },
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにダークテーマを適応します。詳しく知りたい場合は、Material Design documentationの<a href=\"https://material.io/design/color/dark-theme.html\">dark themes</a>を参照してください。",
        "zh-Hans": "将暗色主题变量应用到组件。你可以在 [dark themes](https://material.io/design/color/dark-theme.html) 的 Material Design 文档中找到更多有关信息。"
      }
    },
    {
      "name": "elevation",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "elevatable",
      "description": {
        "en": "Designates an elevation applied to the component between 0 and 24. You can find more information on the [elevation page](/styles/elevation).",
        "ja": "コンポーネントに適用される高さを0〜24の範囲で指定します。詳細は、[elevation のページ](/styles/elevation)で確認できます。",
        "zh-Hans": "组件的海拔可接受 0 到 24 之间的值。你可以在 [elevation page](/styles/elevation) 浏览更多信息。"
      }
    },
    {
      "name": "fixed",
      "type": "boolean",
      "default": "false",
      "source": "positionable",
      "description": {
        "en": "Applies **position: fixed** to the component.",
        "ja": "コンポーネントに **position: fixed** を適用します。",
        "zh-Hans": "给组件应用 **position: fixed**"
      }
    },
    {
      "name": "height",
      "type": [
        "number",
        "string"
      ],
      "default": "auto",
      "source": "measurable",
      "description": {
        "en": "Sets the height for the component.",
        "ja": "コンポーネントに高さを設定します。",
        "zh-Hans": "设定组件的高度。"
      }
    },
    {
      "name": "inset",
      "type": "boolean",
      "default": "false",
      "source": "v-footer",
      "description": {
        "en": "Positions the toolbar offset from an application `v-navigation-drawer`",
        "ja": "アプリケーションからのツールバーの位置を `v-navigation-drawer`で指定します。",
        "zh-Hans": "定位工具栏偏离应用程序的`v-navigation-drawer`的位置"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    },
    {
      "name": "max-height",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the maximum height for the component.",
        "ja": "コンテンツの高さの最大値を設定します。",
        "zh-Hans": "设定组件的最大高度。"
      }
    },
    {
      "name": "max-width",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the maximum width for the component.",
        "ja": "コンテンツの横幅の最大値を設定します。",
        "zh-Hans": "设定组件的最大宽度。"
      }
    },
    {
      "name": "min-height",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the minimum height for the component.",
        "ja": "コンテンツの高さの最小値を設定します。",
        "zh-Hans": "设定组件的最小高度。"
      }
    },
    {
      "name": "min-width",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the minimum width for the component.",
        "ja": "コンテンツの横幅の最小値を設定します。",
        "zh-Hans": "设定组件的最小宽度。"
      }
    },
    {
      "name": "outlined",
      "type": "boolean",
      "default": "false",
      "source": "v-sheet",
      "description": {
        "en": "Removes elevation (box-shadow) and adds a *thin* border.",
        "ja": "Elevationの影(box-shadow) を削除し、*細い* 枠線を追加します。",
        "zh-Hans": "去除卡片的实心颜色并添加细边框。"
      }
    },
    {
      "name": "padless",
      "type": "boolean",
      "default": "false",
      "source": "v-footer",
      "description": {
        "en": "Remove all padding from the footer",
        "ja": "フッターからすべてのpaddingを削除する",
        "zh-Hans": "删除页脚中的所有边距"
      }
    },
    {
      "name": "rounded",
      "type": [
        "boolean",
        "string"
      ],
      "default": "undefined",
      "source": "roundable",
      "description": {
        "en": "Designates the **border-radius** applied to the component. You can find more information on the [Border Radius page](/styles/border-radius).",
        "ja": "コンポーネントに適用された **border-radius** を指定します。[Border Radius ページ](/styles/border-radius) で詳細を確認できます。",
        "zh-Hans": "对指定的组件应用 **border-radius** 样式。您可以在 [边框的圆角半径页面](/styles/border-radius) 获取更多有关信息"
      }
    },
    {
      "name": "shaped",
      "type": "boolean",
      "default": "false",
      "source": "v-sheet",
      "description": {
        "en": "Applies a large border radius on the top left and bottom right of the card.",
        "ja": "カードの左上と右下の角を丸くします。",
        "zh-Hans": "在卡片的左上角和右下角应用较大的边框半径。"
      }
    },
    {
      "name": "tag",
      "type": "string",
      "default": "'footer'",
      "source": "v-sheet",
      "description": {
        "en": "Specify a custom tag used on the root element.",
        "ja": "コンポーネント内で使用するカスタムタグを指定します。",
        "zh-Hans": "指定在根元素上使用的自定义标签。"
      }
    },
    {
      "name": "tile",
      "type": "boolean",
      "default": "false",
      "source": "roundable",
      "description": {
        "en": "Removes the component's **border-radius**.",
        "ja": "コンポーネントの **border-radius** を削除します。",
        "zh-Hans": "删除组件的**border-radius**样式"
      }
    },
    {
      "name": "width",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the width for the component.",
        "ja": "コンポーネントに横幅を設定します。",
        "zh-Hans": "设定组件的宽度。"
      }
    }
  ],
  "mixins": [
    "applicationable",
    "colorable",
    "elevatable",
    "measurable",
    "positionable",
    "roundable",
    "ssr-bootable",
    "themeable",
    "v-sheet"
  ],
  "slots": [
    {
      "name": "default",
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    }
  ],
  "events": [],
  "functions": [],
  "name": "v-footer",
  "sass": [
    {
      "name": "$footer-border-radius",
      "default": "0 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$footer-elevation",
      "default": "0 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$footer-padding",
      "default": "6px 16px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$footer-padless-padding",
      "default": "0px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$footer-shaped-border-radius",
      "default": "map-get($rounded, 'xl') $footer-border-radius !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$footer-transition-duration",
      "default": "0.2s !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$footer-transition-property",
      "default": "background-color, left, right !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$footer-transition-timing-function",
      "default": "map-get($transition, 'fast-out-slow-in') !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "component": true
}