module.exports = {
  "props": [
    {
      "name": "active-class",
      "type": "string",
      "default": "'v-window-item--active'",
      "source": "v-window",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "continuous",
      "type": "boolean",
      "default": "false",
      "source": "v-window",
      "description": {
        "en": "If `true`, window will \"wrap around\" from the last item to the first, and from the first item to the last",
        "ja": "If `true`, window will \"wrap around\" from the last item to the first, and from the first item to the last",
        "zh-Hans": "如果为 `true`，则窗口将从最后一项到第一项，以及从第一项到最后 \"wrap around\""
      }
    },
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにダークテーマを適応します。詳しく知りたい場合は、Material Design documentationの<a href=\"https://material.io/design/color/dark-theme.html\">dark themes</a>を参照してください。",
        "zh-Hans": "将暗色主题变量应用到组件。你可以在 [dark themes](https://material.io/design/color/dark-theme.html) 的 Material Design 文档中找到更多有关信息。"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    },
    {
      "name": "mandatory",
      "type": "boolean",
      "default": "false",
      "source": "v-window",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "max",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "v-window",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "multiple",
      "type": "boolean",
      "default": "false",
      "source": "v-window",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "next-icon",
      "type": [
        "boolean",
        "string"
      ],
      "default": "$next",
      "source": "v-window",
      "description": {
        "en": "Icon used for the \"next\" button if `show-arrows` is `true`",
        "ja": "`show-arrows` が `true` の場合、\"next\" ボタンに使用されるアイコン",
        "zh-Hans": "如果 `show-arrows` 为 `true`，则显示 \"next\" 按钮的图标"
      }
    },
    {
      "name": "prev-icon",
      "type": [
        "boolean",
        "string"
      ],
      "default": "$prev",
      "source": "v-window",
      "description": {
        "en": "Icon used for the \"prev\" button if `show-arrows` is `true`",
        "ja": "`show-arrows` が `true` の場合、\"prev\" ボタンに使用されるアイコン",
        "zh-Hans": "如果 `show-arrows` 为 `true`，则显示 \"prev\" 按钮的图标"
      }
    },
    {
      "name": "reverse",
      "type": "boolean",
      "default": "false",
      "source": "v-window",
      "description": {
        "en": "Reverse the normal transition direction.",
        "ja": "トランジションの方向を逆にします。",
        "zh-Hans": "反转正常的过渡方向。"
      }
    },
    {
      "name": "show-arrows",
      "type": "boolean",
      "default": "false",
      "source": "v-window",
      "description": {
        "en": "Display the \"next\" and \"prev\" buttons",
        "ja": "\"prev\" ボタンと \"next\" ボタンを表示する",
        "zh-Hans": "显示 \"next\" 和 \"prev\" 按钮"
      }
    },
    {
      "name": "show-arrows-on-hover",
      "type": "boolean",
      "default": "false",
      "source": "v-window",
      "description": {
        "en": "Display the \"next\" and \"prev\" buttons on hover. `show-arrows` MUST ALSO be set.",
        "ja": "ホバー時に \"next\" ボタンと \"prev\" ボタンを表示します。`show-arrows` も設定する **必要が** あります。",
        "zh-Hans": "悬停时显示 \"next\" 和 \"prev\" 按钮。必须设置 `show-arrows` 。"
      }
    },
    {
      "name": "tag",
      "type": "string",
      "default": "'div'",
      "source": "v-window",
      "description": {
        "en": "Specify a custom tag used on the root element.",
        "ja": "コンポーネント内で使用するカスタムタグを指定します。",
        "zh-Hans": "指定在根元素上使用的自定义标签。"
      }
    },
    {
      "name": "touch",
      "type": "object",
      "default": "undefined",
      "source": "v-window",
      "description": {
        "en": "Provide a custom **left** and **right** function when swiped left or right.",
        "ja": "左または右にスワイプされたときの、カスタムの**left**および**right**関数を提供します。",
        "zh-Hans": "左右滑动时提供自定义的 **left** 和 **right** 函数。"
      }
    },
    {
      "name": "touchless",
      "type": "boolean",
      "default": "false",
      "source": "v-window",
      "description": {
        "en": "Disable touch support.",
        "ja": "タッチ サポートを無効にします。",
        "zh-Hans": "禁用触摸支持."
      }
    },
    {
      "name": "value",
      "type": "any",
      "default": "undefined",
      "source": "proxyable",
      "description": {
        "en": "The designated model value for the component.",
        "ja": "コンポーネントの指定されたモデルの値。",
        "zh-Hans": "组件的指定 model 值。"
      }
    },
    {
      "name": "value-comparator",
      "type": "function",
      "default": "null",
      "source": "comparable",
      "description": {
        "en": "Apply a custom value comparator function",
        "ja": "カスタムの値の比較関数を適用します",
        "zh-Hans": "应用自定义的 comparator function"
      }
    },
    {
      "name": "vertical",
      "type": "boolean",
      "default": "false",
      "source": "v-window",
      "description": {
        "en": "Uses a vertical transition when changing windows.",
        "ja": "Uses a vertical transition when changing windows.",
        "zh-Hans": "改变窗口时使用垂直转换."
      }
    }
  ],
  "mixins": [
    "comparable",
    "proxyable",
    "themeable"
  ],
  "slots": [
    {
      "name": "default",
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    }
  ],
  "events": [
    {
      "name": "change",
      "value": "string",
      "description": {
        "en": "Emitted when user swipes between tabs.",
        "ja": "ユーザーがタブ間をスワイプすると発生します。",
        "zh-Hans": "当用户在选项卡之间滑动时发出。"
      }
    }
  ],
  "functions": [],
  "name": "v-tabs-items",
  "sass": [],
  "component": true
}