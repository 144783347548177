module.exports = {
  "props": [
    {
      "name": "bench",
      "type": [
        "number",
        "string"
      ],
      "default": 0,
      "source": "v-virtual-scroll",
      "description": {
        "en": "The number of items **outside** the user view that are rendered (even if they are **not** viewable); to help prevent empty white space when scrolling *fast*.",
        "ja": "ユーザービューの**外側**にあるアイテムのうち、レンダリングされるものの数（表示**されない**場合も含む）。*高速*でスクロールしたときに空のホワイトスペースができないようにするためです。",
        "zh-Hans": "在**用户视图之外**呈现的项目数（即使它们是**不可见**的）；以帮助防止*快速*滚动时出现空白。"
      }
    },
    {
      "name": "height",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Height of the component as a css value",
        "ja": "CSS値としてのコンポーネントの高さ",
        "zh-Hans": "作为css值的组件高度"
      }
    },
    {
      "name": "item-height",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "v-virtual-scroll",
      "description": {
        "en": "Height in pixels of the items to display",
        "ja": "表示するアイテムのピクセル単位の高さ",
        "zh-Hans": "要显示的项目的像素高度"
      }
    },
    {
      "name": "items",
      "type": "array",
      "default": [],
      "source": "v-virtual-scroll",
      "description": {
        "en": "The array of items to display",
        "ja": "表示されるitemsのarray。",
        "zh-Hans": "要显示的项目数组"
      }
    },
    {
      "name": "max-height",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the maximum height for the component.",
        "ja": "コンテンツの高さの最大値を設定します。",
        "zh-Hans": "设定组件的最大高度。"
      }
    },
    {
      "name": "max-width",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the maximum width for the component.",
        "ja": "コンテンツの横幅の最大値を設定します。",
        "zh-Hans": "设定组件的最大宽度。"
      }
    },
    {
      "name": "min-height",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the minimum height for the component.",
        "ja": "コンテンツの高さの最小値を設定します。",
        "zh-Hans": "设定组件的最小高度。"
      }
    },
    {
      "name": "min-width",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the minimum width for the component.",
        "ja": "コンテンツの横幅の最小値を設定します。",
        "zh-Hans": "设定组件的最小宽度。"
      }
    },
    {
      "name": "width",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the width for the component.",
        "ja": "コンポーネントに横幅を設定します。",
        "zh-Hans": "设定组件的宽度。"
      }
    }
  ],
  "mixins": [],
  "slots": [
    {
      "name": "default",
      "props": {
        "index": "number",
        "item": "any"
      },
      "description": {
        "en": "Default slot to customize items appearance",
        "ja": "アイテムの外観をカスタマイズするためのデフォルトのスロット",
        "zh-Hans": "自定义项目外观的默认插槽"
      }
    }
  ],
  "events": [],
  "functions": [],
  "name": "v-virtual-scroll",
  "sass": [],
  "component": true
}