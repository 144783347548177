module.exports = {
  "props": [
    {
      "name": "border",
      "type": "string",
      "default": "undefined",
      "source": "v-alert",
      "description": {
        "en": "Puts a border on the alert. Accepts **top** \\| **right** \\| **bottom** \\| **left**.",
        "ja": "アラートのボーダーを指定します。**top** | **right** | **bottom** | **left** のいずれかの値が使用可能です。",
        "zh-Hans": "在Alert组件内设置边框，可用的值有**top** \\| **right** \\| **bottom** \\| **left**"
      }
    },
    {
      "name": "close-icon",
      "type": "string",
      "default": "'$cancel'",
      "source": "v-alert",
      "description": {
        "en": "Change the default icon used for **dismissible** alerts.",
        "ja": "**dismissible**アラートのデフォルトのアイコンを変更します。",
        "zh-Hans": "改变用于**dismissible**属性（是否可关闭alert组件）的默认图标"
      }
    },
    {
      "name": "close-label",
      "type": "string",
      "default": "'$vuetify.close'",
      "source": "v-alert",
      "description": {
        "en": "Text used for *aria-label* on **dismissible** alerts. Can also be customizing globally in [Internationalization](/customization/internationalization).",
        "ja": "**dismissible**アラートの *aria-label* に使用されるテキストです。 [Internationalization](/customization/internationalization) でグローバルにカスタマイズすることもできます。",
        "zh-Hans": "在 **dismissible** 提示框上的 *aria-label* 使用文本。也可以全局自定义 [Internationalization](/customization/internationalization)。"
      }
    },
    {
      "name": "color",
      "type": "string",
      "default": "undefined",
      "source": "colorable",
      "description": {
        "en": "Applies specified color to the control - it can be the name of material color (for example `success` or `purple`) or css color (`#033` or `rgba(255, 0, 0, 0.5)`). You can find a list of built-in classes on the [colors page](/styles/colors#material-colors).",
        "ja": "指定された色をコントロールに適用します。利用できるのはマテリアルカラーの名前 (たとえば、`success` または ` purple`) または css 表記の色 (`#033`または` rgba(255, 0, 0, 0.5) `) です。[colors のページ](/styles/colors#material-colors) で、組み込みクラスのリストが確認できます。",
        "zh-Hans": "指定控件颜色，颜色值可以是 Material 颜色（例如 `success` 或者 `purple` ），也可以是 Css 颜色 （例如 `#033` 或者 `rgba(255, 0, 0, 0.5)`）。在 [colors page](/zh-Hans/styles/colors#material-colors) 中可以找到内置颜色类列表。"
      }
    },
    {
      "name": "colored-border",
      "type": "boolean",
      "default": "false",
      "source": "v-alert",
      "description": {
        "en": "Applies the defined **color** to the alert's border.",
        "ja": "定義された **color** をAlertの境界線に適用します。",
        "zh-Hans": "将自定义的 **color** 应用于提示框的边框"
      }
    },
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにダークテーマを適応します。詳しく知りたい場合は、Material Design documentationの[dark themes](https://material.io/design/color/dark-theme.html)を参照してください。",
        "zh-Hans": "将暗色主题变量应用到组件。你可以在 [dark themes](https://material.io/design/color/dark-theme.html) 的 Material Design 文档中找到更多有关信息。"
      }
    },
    {
      "name": "dense",
      "type": "boolean",
      "default": "false",
      "source": "v-alert",
      "description": {
        "en": "Decreases component's height.",
        "ja": "コンポーネントの高さを下げます。",
        "zh-Hans": "降低组件的高度。"
      }
    },
    {
      "name": "dismissible",
      "type": "boolean",
      "default": "false",
      "source": "v-alert",
      "description": {
        "en": "Adds a close icon that can hide the alert.",
        "ja": "アラートを非表示にする、閉じるアイコンを表示します。",
        "zh-Hans": "添加一个可以关闭警报的图标。"
      }
    },
    {
      "name": "elevation",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "elevatable",
      "description": {
        "en": "Designates an elevation applied to the component between 0 and 24. You can find more information on the [elevation page](/styles/elevation).",
        "ja": "コンポーネントに適用される高さを0〜24の範囲で指定します。詳細は、[elevation のページ](/styles/elevation)で確認できます。",
        "zh-Hans": "组件的海拔可接受 0 到 24 之间的值。你可以在 [elevation page](/styles/elevation) 浏览更多信息。"
      }
    },
    {
      "name": "height",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the height for the component.",
        "ja": "コンポーネントに高さを設定します。",
        "zh-Hans": "设定组件的高度。"
      }
    },
    {
      "name": "icon",
      "type": [
        "boolean",
        "string"
      ],
      "default": "undefined",
      "source": "v-alert",
      "description": {
        "en": "Designates a specific icon.",
        "ja": "アイコンを指定します。",
        "zh-Hans": "指定特定的图标。"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    },
    {
      "name": "max-height",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the maximum height for the component.",
        "ja": "コンテンツの高さの最大値を設定します。",
        "zh-Hans": "设定组件的最大高度。"
      }
    },
    {
      "name": "max-width",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the maximum width for the component.",
        "ja": "コンテンツの横幅の最大値を設定します。",
        "zh-Hans": "设定组件的最大宽度。"
      }
    },
    {
      "name": "min-height",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the minimum height for the component.",
        "ja": "コンテンツの高さの最小値を設定します。",
        "zh-Hans": "设定组件的最小高度。"
      }
    },
    {
      "name": "min-width",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the minimum width for the component.",
        "ja": "コンテンツの横幅の最小値を設定します。",
        "zh-Hans": "设定组件的最小宽度。"
      }
    },
    {
      "name": "mode",
      "type": "string",
      "default": "undefined",
      "source": "transitionable",
      "description": {
        "en": "Sets the transition mode (does not apply to transition-group). You can find more information on the Vue documentation [for transition modes](https://vuejs.org/v2/api/#transition).",
        "ja": "トランジション モードを設定します(transition-groupには適用されません)。詳細については、Vue のドキュメント [トランジションモード](https://jp.vuejs.org/v2/api/#transition) を参照してください。",
        "zh-Hans": "设置转换模式（不应用于转换组）。你可以在 Vue 文档浏览更多 [for transition modes](https://vuejs.org/v2/api/#transition)。"
      }
    },
    {
      "name": "origin",
      "type": "string",
      "default": "undefined",
      "source": "transitionable",
      "description": {
        "en": "Sets the transition origin on the element. You can find more information on the MDN documentation [for transition origin](https://developer.mozilla.org/en-US/docs/Web/CSS/transform-origin).",
        "ja": "要素にトランジションの起点を設定します。 MDNドキュメントで [for transition origin](https://developer.mozilla.org/ja/docs/Web/CSS/transform-origin) の詳細を参照できます。",
        "zh-Hans": "设置元素上的动画原点。您可以在 MDN 文档上浏览更多 [for transition origin](https://developer.mozilla.org/en-US/docs/Web/CSS/transform-origin)"
      }
    },
    {
      "name": "outlined",
      "type": "boolean",
      "default": "false",
      "source": "v-sheet",
      "description": {
        "en": "Makes the background transparent and applies a thin border.",
        "ja": "背景を透明にし、細い境界線を適用します。",
        "zh-Hans": "使背景透明并使用薄边框。"
      }
    },
    {
      "name": "prominent",
      "type": "boolean",
      "default": "false",
      "source": "v-alert",
      "description": {
        "en": "Displays a larger vertically centered icon to draw more attention.",
        "ja": "縦方向中央に大きなアイコンを表示して、より注目を集めます。",
        "zh-Hans": "显示较大的垂直居中图标，以引起更多注意。"
      }
    },
    {
      "name": "rounded",
      "type": [
        "boolean",
        "string"
      ],
      "default": "undefined",
      "source": "roundable",
      "description": {
        "en": "Designates the **border-radius** applied to the component. You can find more information on the [Border Radius page](/styles/border-radius).",
        "ja": "コンポーネントに適用された **border-radius** を指定します。[Border Radius ページ](/styles/border-radius) で詳細を確認できます。",
        "zh-Hans": "对指定的组件应用 **border-radius** 样式。您可以在 [边框的圆角半径页面](/styles/border-radius) 获取更多有关信息"
      }
    },
    {
      "name": "shaped",
      "type": "boolean",
      "default": "false",
      "source": "v-sheet",
      "description": {
        "en": "Applies a large border radius on the top left and bottom right of the card.",
        "ja": "カードの左上と右下の角を丸くします。",
        "zh-Hans": "在卡片的左上角和右下角应用较大的边框半径。"
      }
    },
    {
      "name": "tag",
      "type": "string",
      "default": "'div'",
      "source": "v-sheet",
      "description": {
        "en": "Specify a custom tag used on the root element.",
        "ja": "コンポーネント内で使用するカスタムタグを指定します。",
        "zh-Hans": "指定在根元素上使用的自定义标签。"
      }
    },
    {
      "name": "text",
      "type": "boolean",
      "default": "false",
      "source": "v-alert",
      "description": {
        "en": "Applies the defined **color** to text and a low opacity background of the same.",
        "ja": "定義された**color**をテキストに適用し、同じ色の低い不透明度の背景を適用します。",
        "zh-Hans": "将定义的 **color** 应用于文本和同样的低透明度背景。"
      }
    },
    {
      "name": "tile",
      "type": "boolean",
      "default": "false",
      "source": "roundable",
      "description": {
        "en": "Removes the component's border-radius.",
        "ja": "コンポーネントの角丸を除去します。",
        "zh-Hans": "删除组件的 border-radius。"
      }
    },
    {
      "name": "transition",
      "type": "string",
      "default": "undefined",
      "source": "transitionable",
      "description": {
        "en": "Sets the component transition. Can be one of the [built in transitions](/styles/transitions) or one your own.",
        "ja": "コンポーネントのトランジションを設定します。 [組み込みトランジション](/styles/transitions) のいずれか、または独自のものを使用できます。",
        "zh-Hans": "设置组件转换。可以是一个 [built in transitions](/styles/transitions) 或者是自己自定义的。"
      }
    },
    {
      "name": "type",
      "type": "string",
      "default": "undefined",
      "source": "v-alert",
      "description": {
        "en": "Specify a **success**, **info**, **warning** or **error** alert. Uses the contextual color and has a pre-defined icon.",
        "ja": "**success**、**info**、**warning** もしくは **error** のアラートであることを設定します。コンテキストカラーと事前定義されたアイコンが使用されます。",
        "zh-Hans": "指定一个 **success**, **info**, **warning** 或 **error** 提示。使用上下文颜色并具有预定义的图标。"
      }
    },
    {
      "name": "value",
      "type": "boolean",
      "default": "true",
      "source": "toggleable",
      "description": {
        "en": "Controls whether the component is visible or hidden.",
        "ja": "コンポーネントを表示するか非表示にするかを制御します。",
        "zh-Hans": "控制组件可见还是隐藏。"
      }
    },
    {
      "name": "width",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the width for the component.",
        "ja": "コンポーネントに横幅を設定します。",
        "zh-Hans": "设定组件的宽度。"
      }
    }
  ],
  "mixins": [
    "colorable",
    "elevatable",
    "measurable",
    "roundable",
    "themeable",
    "toggleable",
    "transitionable",
    "v-sheet"
  ],
  "slots": [
    {
      "name": "append",
      "description": {
        "en": "Slot for icon at end of alert.",
        "ja": "アラート終了時のアイコン用スロット。",
        "zh-Hans": "提示框末尾的图标插槽。"
      }
    },
    {
      "name": "close",
      "props": {
        "toggle": "Function"
      },
      "description": {
        "en": "Slot for icon used in **dismissible** prop.",
        "ja": "**dismissible** prop で使用されるアイコンのスロットです。",
        "zh-Hans": "**dismissible** 属性中的图标插槽"
      }
    },
    {
      "name": "default",
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    },
    {
      "name": "prepend",
      "description": {
        "en": "Slot for icon at beginning of alert.",
        "ja": "アラート開始時のアイコン用スロット。",
        "zh-Hans": "提示框开始的图标插槽。"
      }
    }
  ],
  "events": [
    {
      "name": "input",
      "value": "boolean",
      "description": {
        "en": "The updated bound model",
        "ja": "更新されたバウンドモデル",
        "zh-Hans": "被绑定模型的更新"
      }
    }
  ],
  "functions": [
    {
      "name": "toggle",
      "signature": "(): void",
      "description": {
        "en": "Toggles the alert's active state. Available in the close slot and used as the click action in **dismissible**.",
        "ja": "アラートのアクティブ状態を切り替えます。閉じたスロットで使用可能で、**disissible**’（無効）のクリックアクションとして使用されます。",
        "zh-Hans": "切换提示框的活动状态。可在关闭插槽中使用，并在 **dismissible** 中用作单击操作。"
      }
    }
  ],
  "name": "v-alert",
  "sass": [
    {
      "name": "$alert-elevation",
      "default": "0 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$alert-border-opacity",
      "default": "0.26 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$alert-border-radius",
      "default": "$border-radius-root !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$alert-shaped-border-radius",
      "default": "map-get($rounded, 'xl') $alert-border-radius !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$alert-border-width",
      "default": "4px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$alert-dense-border-width",
      "default": "medium !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$alert-font-size",
      "default": "16px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$alert-icon-size",
      "default": "24px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$alert-margin",
      "default": "16px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$alert-outline",
      "default": "thin solid currentColor !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$alert-padding",
      "default": "16px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$alert-prominent-icon-font-size",
      "default": "32px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$alert-prominent-icon-size",
      "default": "48px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$alert-border-icon-margin",
      "default": "8px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "component": true
}