<template>
  <app-img
    alt="Vuetify + Tidelift"
    class="mb-8 mx-auto"
    contain
    height="210"
    src="https://cdn.vuetifyjs.com/images/affiliates/vuetify-tidelift.png"
  />
</template>

<script>
  export default { name: 'TideliftImg' }
</script>
