module.exports = {
  "props": [
    {
      "name": "color",
      "type": "string",
      "default": "'primary'",
      "source": "colorable",
      "description": {
        "en": "Applies specified color to the control - it can be the name of material color (for example `success` or `purple`) or css color (`#033` or `rgba(255, 0, 0, 0.5)`). You can find a list of built-in classes on the [colors page](/styles/colors#material-colors).",
        "ja": "指定された色をコントロールに適用します。利用できるのはマテリアルカラーの名前 (たとえば、`success` または ` purple`) または css 表記の色 (`#033`または` rgba(255, 0, 0, 0.5) `) です。[colors のページ](/styles/colors#material-colors) で、組み込みクラスのリストが確認できます。",
        "zh-Hans": "指定控件颜色，颜色值可以是 Material 颜色（例如 <code>success</code>或者 <code>purple</code> ）也可以是 Css 颜色 （例如 <code>#033</code> 或者 <code>rgba(255, 0, 0, 0.5)</code>）。在 [colors page](/zh-Hans/styles/colors#material-colors)中可以找到内置颜色类列表。"
      }
    },
    {
      "name": "complete",
      "type": "boolean",
      "default": "false",
      "source": "v-stepper-step",
      "description": {
        "en": "Marks step as complete",
        "ja": "ステップを完了としてマークします",
        "zh-Hans": "标记为已完成"
      }
    },
    {
      "name": "complete-icon",
      "type": "string",
      "default": "'$complete'",
      "source": "v-stepper-step",
      "description": {
        "en": "Icon to display when step is marked as completed",
        "ja": "ステップが完了したときに表示するアイコン",
        "zh-Hans": "步骤标记为已完成时显示的图标"
      }
    },
    {
      "name": "edit-icon",
      "type": "string",
      "default": "'$edit'",
      "source": "v-stepper-step",
      "description": {
        "en": "Icon to display when step is editable",
        "ja": "ステップが編集可能なときに表示するアイコン",
        "zh-Hans": "步骤可编辑时显示的图标"
      }
    },
    {
      "name": "editable",
      "type": "boolean",
      "default": "false",
      "source": "v-stepper-step",
      "description": {
        "en": "Marks step as editable",
        "ja": "編集可能としてマーク",
        "zh-Hans": "标记为可编辑"
      }
    },
    {
      "name": "error-icon",
      "type": "string",
      "default": "'$error'",
      "source": "v-stepper-step",
      "description": {
        "en": "Icon to display when step has an error",
        "ja": "ステップにエラーがある場合に表示するアイコン",
        "zh-Hans": "步骤错误时显示的图标"
      }
    },
    {
      "name": "rules",
      "type": "array",
      "default": [],
      "source": "v-stepper-step",
      "description": {
        "en": "Accepts a mixed array of types `function`, `boolean` and `string`. Functions pass an input value as an argument and must return either `true` / `false` or a `string` containing an error message. The input field will enter an error state if a function returns (or any value in the array contains) `false` or is a `string`",
        "ja": "`function`, ` boolean`, `string`の混合配列を受け入れます。関数は入力値を引数として渡し、 `true` /`false`またはエラーメッセージを含む `string`のいずれかを返す必要があります。関数が `false`を返す（または配列内の任意の値に含まれる）場合、または`string`の場合、入力フィールドはエラー状態になります。",
        "zh-Hans": "接受“function”、“boolean”和“string”类型的混合数组。函数将输入值作为参数传递，并且必须返回“true”/“false”或包含错误消息的“string”。如果函数返回（或数组中包含的任何值）`false`或是`string，则输入字段将进入错误状态`"
      }
    },
    {
      "name": "step",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "v-stepper-step",
      "description": {
        "en": "Content to display inside step circle",
        "ja": "ステップサークル内に表示するコンテンツ",
        "zh-Hans": "步骤圈内显示的内容"
      }
    }
  ],
  "mixins": [
    "colorable",
    "registrable-inject"
  ],
  "slots": [
    {
      "name": "default",
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    }
  ],
  "events": [
    {
      "name": "click",
      "value": "MouseEvent",
      "description": {
        "en": "Emitted when component is clicked",
        "ja": "コンポーネントがクリックされたときに発生します",
        "zh-Hans": "单击组件时发出"
      }
    }
  ],
  "functions": [],
  "name": "v-stepper-step",
  "sass": [],
  "component": true
}