module.exports = {
  "props": [
    {
      "name": "step",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "v-stepper-content",
      "description": {
        "en": "Sets step to associate the content to",
        "ja": "コンテンツを関連付けるステップを設定します",
        "zh-Hans": "设置步骤关联的内容"
      }
    }
  ],
  "mixins": [
    "registrable-inject"
  ],
  "slots": [
    {
      "name": "default",
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    }
  ],
  "events": [],
  "functions": [],
  "name": "v-stepper-content",
  "sass": [],
  "component": true
}