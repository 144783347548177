module.exports = {
  "props": [
    {
      "name": "active-class",
      "type": "string",
      "default": "'v-slide-item--active'",
      "source": "base-item-group",
      "description": {
        "en": "The **active-class** applied to children when they are activated.",
        "ja": "子要素が有効な場合、子要素に **active-class** が適用されます。",
        "zh-Hans": "**active-class** 在子组件（children ）被激活时应用。"
      }
    },
    {
      "name": "center-active",
      "type": "boolean",
      "default": "false",
      "source": "base-slide-group",
      "description": {
        "en": "Forces the selected chip to be centered",
        "ja": "選択したチップを強制的に中央に配置します",
        "zh-Hans": "强制选择的纸片居中"
      }
    },
    {
      "name": "color",
      "type": "string",
      "default": "undefined",
      "source": "colorable",
      "description": {
        "en": "Applies specified color to the control - it can be the name of material color (for example `success` or `purple`) or css color (`#033` or `rgba(255, 0, 0, 0.5)`). You can find a list of built-in classes on the [colors page](/styles/colors#material-colors).",
        "ja": "指定された色をコントロールに適用します。利用できるのはマテリアルカラーの名前 (たとえば、`success` または ` purple`) または css 表記の色 (`#033`または` rgba(255, 0, 0, 0.5) `) です。[colors のページ](/styles/colors#material-colors) で、組み込みクラスのリストが確認できます。",
        "zh-Hans": "指定控件颜色，颜色值可以是 Material 颜色（例如 <code>success</code>或者 <code>purple</code> ）也可以是 Css 颜色 （例如 <code>#033</code> 或者 <code>rgba(255, 0, 0, 0.5)</code>）。在 [colors page](/zh-Hans/styles/colors#material-colors)中可以找到内置颜色类列表。"
      }
    },
    {
      "name": "column",
      "type": "boolean",
      "default": "false",
      "source": "v-chip-group",
      "description": {
        "en": "Remove horizontal pagination and wrap items as needed",
        "ja": "必要に応じて、水平ページネーションを削除し、アイテムを折り返します。",
        "zh-Hans": "删除水平分页并根据需要包装项目"
      }
    },
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにダークテーマを適応します。詳しく知りたい場合は、Material Design documentationの<a href=\"https://material.io/design/color/dark-theme.html\">dark themes</a>を参照してください。",
        "zh-Hans": "将暗色主题变量应用到组件。你可以在 [dark themes](https://material.io/design/color/dark-theme.html) 的 Material Design 文档中找到更多有关信息。"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    },
    {
      "name": "mandatory",
      "type": "boolean",
      "default": "false",
      "source": "base-item-group",
      "description": {
        "en": "Forces a value to always be selected (if available).",
        "ja": "値が常に選択状態であることを強制します (可能な場合)。",
        "zh-Hans": "强制始终选择一个值（如果可用）。"
      }
    },
    {
      "name": "max",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "base-item-group",
      "description": {
        "en": "Sets a maximum number of selections that can be made.",
        "ja": "選択できる最大値を設定します。",
        "zh-Hans": "设置可以选择的最大数量。"
      }
    },
    {
      "name": "mobile-breakpoint",
      "type": [
        "number",
        "string"
      ],
      "source": "mobile",
      "description": {
        "en": "Sets the designated mobile breakpoint for the component.",
        "ja": "コンポーネントに指定されたモバイルのbreakpointを指定します。",
        "zh-Hans": "为组件设置和指定移动设备的屏幕尺寸断点"
      }
    },
    {
      "name": "multiple",
      "type": "boolean",
      "default": "false",
      "source": "base-item-group",
      "description": {
        "en": "Allow multiple selections. The **value** prop must be an _array_.",
        "ja": "複数の選択を許可します。この場合、**value** prop は _array_ でなければなりません。",
        "zh-Hans": "允许多个选择。**value** 属性必须是 _array_。"
      }
    },
    {
      "name": "next-icon",
      "type": "string",
      "default": "'$next'",
      "source": "base-slide-group",
      "description": {
        "en": "Specify the icon to use for the next icon",
        "ja": "「次」アイコンを指定します。",
        "zh-Hans": "指定用于下一个图标的图标"
      }
    },
    {
      "name": "prev-icon",
      "type": "string",
      "default": "'$prev'",
      "source": "base-slide-group",
      "description": {
        "en": "Specify the icon to use for the prev icon",
        "ja": "「前」アイコンを指定します。",
        "zh-Hans": "指定用于上一个图标的图标"
      }
    },
    {
      "name": "show-arrows",
      "type": [
        "boolean",
        "string"
      ],
      "default": "undefined",
      "source": "base-slide-group",
      "description": {
        "en": "Force the display of the pagination arrows",
        "ja": "ページネーションの矢印を強制的に表示する",
        "zh-Hans": "强制显示分页箭头"
      }
    },
    {
      "name": "tag",
      "type": "string",
      "default": "'div'",
      "source": "base-item-group",
      "description": {
        "en": "Specify a custom tag used on the root element.",
        "ja": "コンポーネント内で使用するカスタムタグを指定します。",
        "zh-Hans": "指定在根元素上使用的自定义标签。"
      }
    },
    {
      "name": "value",
      "type": "any",
      "default": "undefined",
      "source": "proxyable",
      "description": {
        "en": "The designated model value for the component.",
        "ja": "コンポーネントの指定されたモデルの値。",
        "zh-Hans": "组件的指定 model 值。"
      }
    },
    {
      "name": "value-comparator",
      "type": "function",
      "default": "null",
      "source": "comparable",
      "description": {
        "en": "Apply a custom value comparator function",
        "ja": "カスタムの値の比較関数を適用します",
        "zh-Hans": "应用自定义的 comparator function"
      }
    }
  ],
  "mixins": [
    "base-item-group",
    "base-slide-group",
    "colorable",
    "comparable",
    "mobile",
    "proxyable",
    "themeable"
  ],
  "slots": [
    {
      "name": "default",
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    }
  ],
  "events": [
    {
      "name": "change",
      "value": "any[] | any",
      "description": {
        "en": "Emitted when the component value is changed by user interaction",
        "ja": "コンポーネントの値がユーザー操作によって変更されたときに発生します。",
        "zh-Hans": "当组件值被用户交互改变时发出。"
      }
    }
  ],
  "functions": [],
  "name": "v-chip-group",
  "sass": [
    {
      "name": "$chip-group-content-padding",
      "default": "4px 0 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$chip-group-margin",
      "default": "4px 8px 4px 0 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$chip-group-no-color-focus-opacity",
      "default": ".32 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$chip-group-no-color-opacity",
      "default": ".22 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "component": true
}