import { render, staticRenderFns } from "./Usage.vue?vue&type=template&id=274f8a57&"
import script from "./Usage.vue?vue&type=script&lang=js&"
export * from "./Usage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VLazy } from 'vuetify/lib/components/VLazy';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VThemeProvider } from 'vuetify/lib/components/VThemeProvider';
installComponents(component, {VBtn,VBtnToggle,VCheckbox,VCol,VDivider,VLazy,VRadio,VRadioGroup,VResponsive,VRow,VSelect,VSheet,VSlideGroup,VSlideItem,VSlider,VThemeProvider})
