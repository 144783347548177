module.exports = {
  "props": [
    {
      "name": "active",
      "type": "boolean",
      "default": "true",
      "source": "v-chip",
      "description": {
        "en": "Determines whether the chip is visible or not.",
        "ja": "チップが見えるかどうかを決定します。",
        "zh-Hans": "确定纸片是否可见。"
      }
    },
    {
      "name": "active-class",
      "type": "string",
      "default": "",
      "source": "routable",
      "description": {
        "en": "Configure the active CSS class applied when the link is active. You can find more information about the [**active-class** prop](https://router.vuejs.org/api/#active-class) on the vue-router documentation.",
        "ja": "リンクがアクティブなときに適用されるアクティブなCSSクラスを構成します。vue-routerのドキュメントで [**active-class** prop](https://router.vuejs.org/ja/api/#active-class) の詳細をご覧ください。",
        "zh-Hans": "配置在链接激活时应用的 CSS 类。你能够在 vue-router 文档浏览更多 [**active-class** prop](https://router.vuejs.org/api/#active-class)。"
      }
    },
    {
      "name": "append",
      "type": "boolean",
      "default": "false",
      "source": "routable",
      "description": {
        "en": "Setting **append** prop always appends the relative path to the current path. You can find more information about the [**append** prop](https://router.vuejs.org/api/#append) on the vue-router documentation.",
        "ja": "**append** propを設定すると、常に現在のパスに相対パスが追加されます。vue-router のドキュメントで [**append** prop](https://router.vuejs.org/ja/api/#append) の詳細についてをご覧ください。",
        "zh-Hans": "设置 **append** 属性总是会附加到当前路径的相对路径上。你能够在 vue-router 文档浏览更多 [**append** prop](https://router.vuejs.org/api/#append)。"
      }
    },
    {
      "name": "close",
      "type": "boolean",
      "default": "false",
      "source": "v-chip",
      "description": {
        "en": "Adds remove button",
        "ja": "閉じるボタンを追加します。",
        "zh-Hans": "添加删除按钮"
      }
    },
    {
      "name": "close-icon",
      "type": "string",
      "default": "'$delete'",
      "source": "v-chip",
      "description": {
        "en": "Change the default icon used for **close** chips",
        "ja": "**close** チップに使用されるデフォルトのアイコンを変更する",
        "zh-Hans": "更改用于 **close** 纸片的默认图标"
      }
    },
    {
      "name": "close-label",
      "type": "string",
      "default": "'$vuetify.close'",
      "source": "v-chip",
      "description": {
        "en": "Text used for *aria-label* on the close button in **close** chips. Can also be customized globally in [Internationalization](/customization/internationalization).",
        "ja": "**close** チップのクローズボタンの *aria-label* に使用されるテキスト。 [Internationalization](/customization/internationalization) でグローバルにカスタマイズすることもできます。",
        "zh-Hans": "在 **关闭** 碎片中用于关闭按钮的 *aria-label* 的文字。也可以在 [国际化](/customization/international) 中进行全局定制化。"
      }
    },
    {
      "name": "color",
      "type": "string",
      "default": "undefined",
      "source": "colorable",
      "description": {
        "en": "Applies specified color to the control - it can be the name of material color (for example `success` or `purple`) or css color (`#033` or `rgba(255, 0, 0, 0.5)`). You can find a list of built-in classes on the [colors page](/styles/colors#material-colors).",
        "ja": "指定された色をコントロールに適用します。利用できるのはマテリアルカラーの名前 (たとえば、`success` または ` purple`) または css 表記の色 (`#033`または` rgba(255, 0, 0, 0.5) `) です。[colors のページ](/styles/colors#material-colors) で、組み込みクラスのリストが確認できます。",
        "zh-Hans": "指定控件颜色，颜色值可以是 Material 颜色（例如 <code>success</code>或者 <code>purple</code> ）也可以是 Css 颜色 （例如 <code>#033</code> 或者 <code>rgba(255, 0, 0, 0.5)</code>）。在 [colors page](/zh-Hans/styles/colors#material-colors)中可以找到内置颜色类列表。"
      }
    },
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにダークテーマを適応します。詳しく知りたい場合は、Material Design documentationの<a href=\"https://material.io/design/color/dark-theme.html\">dark themes</a>を参照してください。",
        "zh-Hans": "将暗色主题变量应用到组件。你可以在 [dark themes](https://material.io/design/color/dark-theme.html) 的 Material Design 文档中找到更多有关信息。"
      }
    },
    {
      "name": "disabled",
      "type": "boolean",
      "default": "false",
      "source": "routable",
      "description": {
        "en": "Disables the chip, making it un-selectable",
        "ja": "Chip を無効化し、選択できないようにします。",
        "zh-Hans": "禁用纸片，使其不可选择"
      }
    },
    {
      "name": "draggable",
      "type": "boolean",
      "default": "false",
      "source": "v-chip",
      "description": {
        "en": "Makes the chip draggable",
        "ja": "チップをドラッグ可能にする",
        "zh-Hans": "使纸片可拖动"
      }
    },
    {
      "name": "exact",
      "type": "boolean",
      "default": "false",
      "source": "routable",
      "description": {
        "en": "Exactly match the link. Without this, '/' will match every route. You can find more information about the [**exact** prop](https://router.vuejs.org/api/#exact) on the vue-router documentation.",
        "ja": "リンクと完全に一致します。これがなければ、'/' はすべてのルートにマッチします。[**exact** prop](https://router.vuejs.org/ja/api/#exact) の詳細については、vue-router のドキュメントを参照してください。",
        "zh-Hans": "完全匹配链接。如果没有这个链接，'/' 将匹配每个路由。你能够在 vue-router 文档浏览更多 [**exact** prop](https://router.vuejs.org/api/#exact)。"
      }
    },
    {
      "name": "exact-active-class",
      "type": "string",
      "default": "undefined",
      "source": "routable",
      "description": {
        "en": "Configure the active CSS class applied when the link is active with exact match. You can find more information about the [**exact-active-class** prop](https://router.vuejs.org/api/#exact-active-class) on the vue-router documentation.",
        "ja": "完全一致のリンクがアクティブな場合に適用されるアクティブな CSS クラスを設定します。[**exact-active-class** prop](https://router.vuejs.org/ja/api/#exact-active-class) の詳細については、vue-router のドキュメントをご覧ください。",
        "zh-Hans": "配置在精准链接激活时应用的 CSS 类。你能够在 vue-router 文档浏览更多[**exact-active-class** prop](https://router.vuejs.org/api/#exact-active-class)。"
      }
    },
    {
      "name": "exact-path",
      "type": "boolean",
      "default": "false",
      "source": "routable",
      "description": {
        "en": "Exactly match the link, ignoring the `query` and the `hash` sections. You can find more information about the [**exact-path** prop](https://router.vuejs.org/api/#exact-path) on the vue-router documentation.",
        "ja": "`query` セクションと `hash` セクションを無視して、リンクと完全に一致します。[**exact-path** prop](https://router.vuejs.org/api/#exact-path) の詳細については、vue-routerのドキュメントをご覧ください。",
        "zh-Hans": "精确匹配链接, 忽略`query`和`hash`部分. 您可以在vue-router文档的[**精确路径** prop](https://router.vuejs.org/api/#exact-path)找到相关的信息."
      }
    },
    {
      "name": "filter",
      "type": "boolean",
      "default": "false",
      "source": "v-chip",
      "description": {
        "en": "Displays a selection icon when selected",
        "ja": "選択すると選択アイコンを表示します。",
        "zh-Hans": "选中时显示选择图标"
      }
    },
    {
      "name": "filter-icon",
      "type": "string",
      "default": "'$complete'",
      "source": "v-chip",
      "description": {
        "en": "Change the default icon used for **filter** chips",
        "ja": "**filter** チップに使用されるデフォルトのアイコンを変更する",
        "zh-Hans": "更改用于 **filter** 纸片的默认图标"
      }
    },
    {
      "name": "href",
      "type": [
        "string",
        "object"
      ],
      "default": "undefined",
      "source": "routable",
      "description": {
        "en": "Designates the component as anchor and applies the **href** attribute.",
        "ja": "コンポーネントをアンカーとして指定し、**href** 属性を適用します。",
        "zh-Hans": "指定组件为锚点并应用 **href** 属性。"
      }
    },
    {
      "name": "input-value",
      "type": "any",
      "default": "undefined",
      "source": "toggleable",
      "description": {
        "en": "Controls the **active** state of the item. This is typically used to highlight the component.",
        "ja": "アイテムの **active** 状態を制御します。これは通常、コンポーネントをハイライトするために使用されます。",
        "zh-Hans": "控制项的 **active** 状态。这通常用于高亮显示组件。"
      }
    },
    {
      "name": "label",
      "type": "boolean",
      "default": "false",
      "source": "v-chip",
      "description": {
        "en": "Removes circle edges",
        "ja": "丸みを削除します。",
        "zh-Hans": "移除圆形边缘"
      }
    },
    {
      "name": "large",
      "type": "boolean",
      "default": "false",
      "source": "sizeable",
      "description": {
        "en": "Makes the component large.",
        "ja": "コンポーネントをlargeにします。",
        "zh-Hans": "使组件尺寸变的巨大。"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    },
    {
      "name": "link",
      "type": "boolean",
      "default": "false",
      "source": "routable",
      "description": {
        "en": "Explicitly define the chip as a link",
        "ja": "明示的にリンクとしてチップを定義します",
        "zh-Hans": "明确地将纸片定义为链接"
      }
    },
    {
      "name": "nuxt",
      "type": "boolean",
      "default": "false",
      "source": "routable",
      "description": {
        "en": "Specifies the link is a `nuxt-link`. For use with the [nuxt framework](https://nuxtjs.org/api/components-nuxt-link/).",
        "ja": "リンクが`nuxt-link`であることを指定します。 [nuxtフレームワーク](https://ja.nuxtjs.org/api/components-nuxt-link/) で使用します。",
        "zh-Hans": "指定链接是 `nuxt-link` 。用处参考 [nuxt framework](https://nuxtjs.org/api/components-nuxt-link/)."
      }
    },
    {
      "name": "outlined",
      "type": "boolean",
      "default": "false",
      "source": "v-chip",
      "description": {
        "en": "Removes background and applies border and text color",
        "ja": "背景を削除しテキストカラーとボーダーカラーを適用します。",
        "zh-Hans": "删除背景并应用边框和文本颜色"
      }
    },
    {
      "name": "pill",
      "type": "boolean",
      "default": "false",
      "source": "v-chip",
      "description": {
        "en": "Remove `v-avatar` padding",
        "ja": "`v-avatar` パディングを削除",
        "zh-Hans": "移除 `v-avatar` 的边距"
      }
    },
    {
      "name": "replace",
      "type": "boolean",
      "default": "false",
      "source": "routable",
      "description": {
        "en": "Setting **replace** prop will call `router.replace()` instead of `router.push()` when clicked, so the navigation will not leave a history record. You can find more information about the [**replace** prop](https://router.vuejs.org/api/#replace) on the vue-router documentation.",
        "ja": "**replace** propを設定すると、クリックされたときに `router.push()` の代わりに `router.replace()` が呼び出されるため、ナビゲーションは history レコードを残しません。[**replace** prop](https://router.vuejs.org/ja/api/#replace) の詳細については、vue-routerのドキュメントをご覧ください。",
        "zh-Hans": "设置 **replace** 属性会在点击时调用 `router.replace()` 而不是 `router.push()`，这样导航就不会留下历史记录。您可以在 vue-router 文档中浏览更多 [**replace** prop](https://router.vuejs.org/api/#replace)。"
      }
    },
    {
      "name": "ripple",
      "type": [
        "boolean",
        "object"
      ],
      "default": "undefined",
      "source": "routable",
      "description": {
        "en": "Applies the [v-ripple](/directives/ripple) directive.",
        "ja": "[v-ripple](/directives/ripple) ディレクティブを適用します。",
        "zh-Hans": "应用 [v-ripple](/directives/ripples) 指令。"
      }
    },
    {
      "name": "small",
      "type": "boolean",
      "default": "false",
      "source": "sizeable",
      "description": {
        "en": "Makes the component small.",
        "ja": "コンポーネントをsmallにします。",
        "zh-Hans": "使组件尺寸变的小。"
      }
    },
    {
      "name": "tag",
      "type": "string",
      "default": "'span'",
      "source": "routable",
      "description": {
        "en": "Specify a custom tag used on the root element.",
        "ja": "コンポーネント内で使用するカスタムタグを指定します。",
        "zh-Hans": "指定在根元素上使用的自定义标签。"
      }
    },
    {
      "name": "target",
      "type": "string",
      "default": "undefined",
      "source": "routable",
      "description": {
        "en": "Designates the target attribute. This should only be applied when using the **href** prop.",
        "ja": "ターゲット属性を指定します。これは **href** propを使用する場合にのみ適用する必要があります。",
        "zh-Hans": "指定 target 属性。只在使用 **href** 属性时应用。"
      }
    },
    {
      "name": "text-color",
      "type": "string",
      "default": "undefined",
      "source": "v-chip",
      "description": {
        "en": "Applies a specified color to the control text",
        "ja": "コントロール テキストに指定した色を適用します。",
        "zh-Hans": "将指定的颜色应用于控件文本"
      }
    },
    {
      "name": "to",
      "type": [
        "string",
        "object"
      ],
      "default": "undefined",
      "source": "routable",
      "description": {
        "en": "Denotes the target route of the link. You can find more information about the [**to** prop](https://router.vuejs.org/api/#to) on the vue-router documentation.",
        "ja": "リンクのターゲットルートを示します。[**to** prop](https://router.vuejs.org/ja/api/#to) の詳細については、vue-routerのドキュメントをご覧ください。",
        "zh-Hans": "表示链接的目标路由。你可以在 vue-router 文档浏览更多 [**to** prop](https://router.vuejs.org/api/#to)。"
      }
    },
    {
      "name": "value",
      "type": "any",
      "default": "undefined",
      "source": "v-chip",
      "description": {
        "en": "The value used when a child of a [v-chip-group](/components/chip-groups).",
        "ja": "[v-chip-group](/components/chip-groups) の子（child）の場合に使用される値。",
        "zh-Hans": "[v-chip-group](/components/chip-groups) 的子组时使用的值。"
      }
    },
    {
      "name": "x-large",
      "type": "boolean",
      "default": "false",
      "source": "sizeable",
      "description": {
        "en": "Makes the component extra large.",
        "ja": "コンポーネントをextra largeにします。",
        "zh-Hans": "使组件尺寸变的无比巨大。"
      }
    },
    {
      "name": "x-small",
      "type": "boolean",
      "default": "false",
      "source": "sizeable",
      "description": {
        "en": "Makes the component extra small.",
        "ja": "コンポーネントをextra smallにします。",
        "zh-Hans": "使组件尺寸变的更小。"
      }
    }
  ],
  "mixins": [
    "colorable",
    "groupable",
    "routable",
    "sizeable",
    "themeable",
    "toggleable"
  ],
  "slots": [
    {
      "name": "default",
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    }
  ],
  "events": [
    {
      "name": "click",
      "value": "MouseEvent",
      "description": {
        "en": "Emitted when component is clicked, toggles chip if contained in a chip group - Will trigger component to ripple when clicked unless the `.native` modifier is used",
        "ja": "コンポーネントがクリックされたときに発生し、チップグループに含まれる場合はチップを切り替えます - `.native`修飾子を使用しない限り、クリックするとコンポーネントにリップル効果が適用されます。",
        "zh-Hans": "单击组件时发出，切换芯片（如果包含在芯片组中） - 除非使用 `.native` 修饰符，否则单击时将触发组件产生涟漪"
      }
    },
    {
      "name": "click:close",
      "value": "void",
      "description": {
        "en": "Emitted when close icon is clicked",
        "ja": "Closeアイコンがクリックされたときに発生します",
        "zh-Hans": "单击关闭图标时发出"
      }
    },
    {
      "name": "input",
      "value": "boolean",
      "description": {
        "en": "The updated bound model",
        "ja": "更新されたバウンドモデル",
        "zh-Hans": "被绑定模型的更新"
      }
    },
    {
      "name": "update:active",
      "value": "boolean",
      "description": {
        "en": "Emitted when close icon is clicked, sets active to `false`",
        "ja": "閉じるアイコンがクリックされたときに発生し、activeを `false` に設定します。",
        "zh-Hans": "单击关闭图标时发出，将 active 设置为 `false`"
      }
    }
  ],
  "functions": [],
  "name": "v-chip",
  "sass": [
    {
      "name": "$chip-avatar-size",
      "default": "24px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$chip-close-size",
      "default": "18px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$chip-icon-margin-after",
      "default": "6px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$chip-icon-margin-before",
      "default": "-6px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$chip-icon-right-margin-after",
      "default": "-4px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$chip-icon-right-margin-before",
      "default": "8px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$chip-icon-size",
      "default": "24px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$chip-label-border-radius",
      "default": "$border-radius-root !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$chip-link-focus-opacity",
      "default": "0.32 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$chip-pill-avatar-margin-after",
      "default": "8px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$chip-pill-avatar-margin-before",
      "default": "-12px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$chip-pill-avatar-size",
      "default": "32px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$chip-pill-filter-margin",
      "default": "0 16px 0 0 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$chip-transition-duration",
      "default": "0.28s !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$chip-transition-fn",
      "default": "map-get($transition, 'fast-out-slow-in') !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$icon-outlined-border-width",
      "default": "thin !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$chip-line-height",
      "default": "20px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$chip-padding",
      "default": "0 12px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$chip-white-space",
      "default": "nowrap !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$chip-disabled-opacity",
      "default": "0.4 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$chip-filter-max-width",
      "default": "24px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$chip-outlined-active-opacity",
      "default": "0.08 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$chip-selected-opacity",
      "default": "0.28 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$icon-sizes",
      "default": "map-deep-merge(\n  (\n    'x-small': (\n      'font-size': 10,\n      'height': 16\n    ),\n    'small': (\n      'font-size': 12,\n      'height': 24\n    ),\n    'default': (\n      'font-size': 14,\n      'height': 32\n    ),\n    'large': (\n      'font-size': 16,\n      'height': 54\n    ),\n    'x-large': (\n      'font-size': 18,\n      'height': 66\n    )\n  ),\n  $icon-sizes\n);",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "component": true
}