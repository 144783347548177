module.exports = {
  "props": [
    {
      "name": "absolute",
      "type": "boolean",
      "default": "false",
      "source": "positionable",
      "description": {
        "en": "Applies **position: absolute** to the component.",
        "ja": "コンポーネントに **position: absolute** を適用します。",
        "zh-Hans": "给组件应用 **position: absolute**"
      }
    },
    {
      "name": "active-class",
      "type": "string",
      "default": "",
      "source": "routable",
      "description": {
        "en": "Configure the active CSS class applied when the link is active. You can find more information about the [**active-class** prop](https://router.vuejs.org/api/#active-class) on the vue-router documentation.",
        "ja": "リンクがアクティブなときに適用されるアクティブなCSSクラスを構成します。vue-routerのドキュメントで [**active-class** prop](https://router.vuejs.org/ja/api/#active-class) の詳細をご覧ください。",
        "zh-Hans": "配置在链接激活时应用的 CSS 类。你能够在 vue-router 文档浏览更多 [**active-class** prop](https://router.vuejs.org/api/#active-class)。"
      }
    },
    {
      "name": "append",
      "type": "boolean",
      "default": "false",
      "source": "routable",
      "description": {
        "en": "Setting **append** prop always appends the relative path to the current path. You can find more information about the [**append** prop](https://router.vuejs.org/api/#append) on the vue-router documentation.",
        "ja": "**append** propを設定すると、常に現在のパスに相対パスが追加されます。vue-router のドキュメントで [**append** prop](https://router.vuejs.org/ja/api/#append) の詳細についてをご覧ください。",
        "zh-Hans": "设置 **append** 属性总是会附加到当前路径的相对路径上。你能够在 vue-router 文档浏览更多 [**append** prop](https://router.vuejs.org/api/#append)。"
      }
    },
    {
      "name": "block",
      "type": "boolean",
      "default": "false",
      "source": "v-btn",
      "description": {
        "en": "Expands the button to 100% of available space.",
        "ja": "ボタンを使用可能なスペースの最大限まで広げます。",
        "zh-Hans": "将按钮扩大到可用空间的 100％。"
      }
    },
    {
      "name": "bottom",
      "type": "boolean",
      "default": "false",
      "source": "positionable",
      "description": {
        "en": "Aligns the component towards the bottom.",
        "ja": "コンポーネントを下部に配置します。",
        "zh-Hans": "将组件向底部对齐。"
      }
    },
    {
      "name": "color",
      "type": "string",
      "default": "undefined",
      "source": "colorable",
      "description": {
        "en": "Applies specified color to the control - it can be the name of material color (for example `success` or `purple`) or css color (`#033` or `rgba(255, 0, 0, 0.5)`). You can find a list of built-in classes on the [colors page](/styles/colors#material-colors).",
        "ja": "指定された色をコントロールに適用します。利用できるのはマテリアルカラーの名前 (たとえば、`success` または ` purple`) または css 表記の色 (`#033`または` rgba(255, 0, 0, 0.5) `) です。[colors のページ](/styles/colors#material-colors) で、組み込みクラスのリストが確認できます。",
        "zh-Hans": "指定控件颜色，颜色值可以是 Material 颜色（例如 <code>success</code>或者 <code>purple</code> ）也可以是 Css 颜色 （例如 <code>#033</code> 或者 <code>rgba(255, 0, 0, 0.5)</code>）。在 [colors page](/zh-Hans/styles/colors#material-colors)中可以找到内置颜色类列表。"
      }
    },
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにダークテーマを適応します。詳しく知りたい場合は、Material Design documentationの<a href=\"https://material.io/design/color/dark-theme.html\">dark themes</a>を参照してください。",
        "zh-Hans": "将暗色主题变量应用到组件。你可以在 [dark themes](https://material.io/design/color/dark-theme.html) 的 Material Design 文档中找到更多有关信息。"
      }
    },
    {
      "name": "depressed",
      "type": "boolean",
      "default": "false",
      "source": "v-btn",
      "description": {
        "en": "Removes the button box shadow.",
        "ja": "ボタンの影を削除します。",
        "zh-Hans": "移除按钮的阴影效果。"
      }
    },
    {
      "name": "disabled",
      "type": "boolean",
      "default": "false",
      "source": "routable",
      "description": {
        "en": "Removes the ability to click or target the component.",
        "ja": "コンポーネントをクリックまたはターゲットにできなくします。",
        "zh-Hans": "移除组件的单击或 target 功能。"
      }
    },
    {
      "name": "elevation",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "elevatable",
      "description": {
        "en": "Designates an elevation applied to the component between 0 and 24. You can find more information on the [elevation page](/styles/elevation).",
        "ja": "コンポーネントに適用される高さを0〜24の範囲で指定します。詳細は、[elevation のページ](/styles/elevation)で確認できます。",
        "zh-Hans": "组件的海拔可接受 0 到 24 之间的值。你可以在 [elevation page](/styles/elevation) 浏览更多信息。"
      }
    },
    {
      "name": "exact",
      "type": "boolean",
      "default": "false",
      "source": "routable",
      "description": {
        "en": "Exactly match the link. Without this, '/' will match every route. You can find more information about the [**exact** prop](https://router.vuejs.org/api/#exact) on the vue-router documentation.",
        "ja": "リンクと完全に一致します。これがなければ、'/' はすべてのルートにマッチします。[**exact** prop](https://router.vuejs.org/ja/api/#exact) の詳細については、vue-router のドキュメントを参照してください。",
        "zh-Hans": "完全匹配链接。如果没有这个链接，'/' 将匹配每个路由。你能够在 vue-router 文档浏览更多 [**exact** prop](https://router.vuejs.org/api/#exact)。"
      }
    },
    {
      "name": "exact-active-class",
      "type": "string",
      "default": "undefined",
      "source": "routable",
      "description": {
        "en": "Configure the active CSS class applied when the link is active with exact match. You can find more information about the [**exact-active-class** prop](https://router.vuejs.org/api/#exact-active-class) on the vue-router documentation.",
        "ja": "完全一致のリンクがアクティブな場合に適用されるアクティブな CSS クラスを設定します。[**exact-active-class** prop](https://router.vuejs.org/ja/api/#exact-active-class) の詳細については、vue-router のドキュメントをご覧ください。",
        "zh-Hans": "配置在精准链接激活时应用的 CSS 类。你能够在 vue-router 文档浏览更多[**exact-active-class** prop](https://router.vuejs.org/api/#exact-active-class)。"
      }
    },
    {
      "name": "exact-path",
      "type": "boolean",
      "default": "false",
      "source": "routable",
      "description": {
        "en": "Exactly match the link, ignoring the `query` and the `hash` sections. You can find more information about the [**exact-path** prop](https://router.vuejs.org/api/#exact-path) on the vue-router documentation.",
        "ja": "`query` セクションと `hash` セクションを無視して、リンクと完全に一致します。[**exact-path** prop](https://router.vuejs.org/api/#exact-path) の詳細については、vue-routerのドキュメントをご覧ください。",
        "zh-Hans": "精确匹配链接, 忽略`query`和`hash`部分. 您可以在vue-router文档的[**精确路径** prop](https://router.vuejs.org/api/#exact-path)找到相关的信息."
      }
    },
    {
      "name": "fab",
      "type": "boolean",
      "default": "false",
      "source": "v-btn",
      "description": {
        "en": "Designates the button as a floating-action-button. Button will become _round_.",
        "ja": "ボタンをfloating-action-buttonとして指定します。ボタンは _round_ になります。",
        "zh-Hans": "指定按钮为浮动的动作按钮. 按钮将变成圆形按钮."
      }
    },
    {
      "name": "fixed",
      "type": "boolean",
      "default": "false",
      "source": "positionable",
      "description": {
        "en": "Applies **position: fixed** to the component.",
        "ja": "コンポーネントに **position: fixed** を適用します。",
        "zh-Hans": "给组件应用 **position: fixed**"
      }
    },
    {
      "name": "height",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the height for the component.",
        "ja": "コンポーネントに高さを設定します。",
        "zh-Hans": "设定组件的高度。"
      }
    },
    {
      "name": "href",
      "type": [
        "string",
        "object"
      ],
      "default": "undefined",
      "source": "routable",
      "description": {
        "en": "Designates the component as anchor and applies the **href** attribute.",
        "ja": "コンポーネントをアンカーとして指定し、**href** 属性を適用します。",
        "zh-Hans": "指定组件为锚点并应用 **href** 属性。"
      }
    },
    {
      "name": "icon",
      "type": "boolean",
      "default": "false",
      "source": "v-btn",
      "description": {
        "en": "Designates the button as icon. Button will become _round_ and applies the **text** prop.",
        "ja": "ボタンをiconとして指定します。ボタンは _round_ になり、**text** プロパティを適用します。",
        "zh-Hans": "指定按钮为图标按钮. 按钮将变成圆形按钮并使用**text** prop."
      }
    },
    {
      "name": "input-value",
      "type": "any",
      "default": "undefined",
      "source": "toggleable",
      "description": {
        "en": "Controls the button's active state.",
        "ja": "ボタンの有効状態を制御します。",
        "zh-Hans": "控制按钮的活动状态。"
      }
    },
    {
      "name": "large",
      "type": "boolean",
      "default": "false",
      "source": "sizeable",
      "description": {
        "en": "Makes the component large.",
        "ja": "コンポーネントをlargeにします。",
        "zh-Hans": "使组件尺寸变的巨大。"
      }
    },
    {
      "name": "left",
      "type": "boolean",
      "default": "false",
      "source": "positionable",
      "description": {
        "en": "Aligns the component towards the left. This should be used with the **absolute** or **fixed** props.",
        "ja": "コンポーネントを左に揃えます。これは **absolute** もしくは **fixed** props と共に使うべきです。",
        "zh-Hans": "将组件向左对齐。 这应该与 **absolute** 或 **fixed** 属性一起使用。"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    },
    {
      "name": "link",
      "type": "boolean",
      "default": "false",
      "source": "routable",
      "description": {
        "en": "Designates that the component is a link. This is automatic when using the **href** or **to** prop.",
        "ja": "コンポーネントがリンクであることを指定します。これは **href** または **to** propを使用する場合は自動的に行われます。",
        "zh-Hans": "指定组件为链接。当使用 **href** 或 **to ** 属性时，这是自动的设置的。"
      }
    },
    {
      "name": "loading",
      "type": "boolean",
      "default": "false",
      "source": "v-btn",
      "description": {
        "en": "Adds a loading icon animation.",
        "ja": "ローディングアイコンアニメーションを追加します。",
        "zh-Hans": "添加一个加载中的图标动画."
      }
    },
    {
      "name": "max-height",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the maximum height for the component.",
        "ja": "コンテンツの高さの最大値を設定します。",
        "zh-Hans": "设定组件的最大高度。"
      }
    },
    {
      "name": "max-width",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the maximum width for the component.",
        "ja": "コンテンツの横幅の最大値を設定します。",
        "zh-Hans": "设定组件的最大宽度。"
      }
    },
    {
      "name": "min-height",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the minimum height for the component.",
        "ja": "コンテンツの高さの最小値を設定します。",
        "zh-Hans": "设定组件的最小高度。"
      }
    },
    {
      "name": "min-width",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the minimum width for the component.",
        "ja": "コンテンツの横幅の最小値を設定します。",
        "zh-Hans": "设定组件的最小宽度。"
      }
    },
    {
      "name": "nuxt",
      "type": "boolean",
      "default": "false",
      "source": "routable",
      "description": {
        "en": "Specifies the link is a `nuxt-link`. For use with the [nuxt framework](https://nuxtjs.org/api/components-nuxt-link/).",
        "ja": "リンクが`nuxt-link`であることを指定します。 [nuxtフレームワーク](https://ja.nuxtjs.org/api/components-nuxt-link/) で使用します。",
        "zh-Hans": "指定链接是 `nuxt-link` 。用处参考 [nuxt framework](https://nuxtjs.org/api/components-nuxt-link/)."
      }
    },
    {
      "name": "outlined",
      "type": "boolean",
      "default": "false",
      "source": "v-sheet",
      "description": {
        "en": "Makes the background transparent and applies a thin border.",
        "ja": "背景を透明にし、細い境界線を適用します。",
        "zh-Hans": "使背景透明并使用薄边框。"
      }
    },
    {
      "name": "plain",
      "type": "boolean",
      "default": "false",
      "source": "v-btn",
      "description": {
        "en": "Removes the default background change applied when hovering over the button.",
        "ja": "ボタンにカーソルを合わせたときに適用されるデフォルトの背景の変化を削除します。",
        "zh-Hans": "移除悬停在按钮上时应用的默认背景变化。"
      }
    },
    {
      "name": "replace",
      "type": "boolean",
      "default": "false",
      "source": "routable",
      "description": {
        "en": "Setting **replace** prop will call `router.replace()` instead of `router.push()` when clicked, so the navigation will not leave a history record. You can find more information about the [**replace** prop](https://router.vuejs.org/api/#replace) on the vue-router documentation.",
        "ja": "**replace** propを設定すると、クリックされたときに `router.push()` の代わりに `router.replace()` が呼び出されるため、ナビゲーションは history レコードを残しません。[**replace** prop](https://router.vuejs.org/ja/api/#replace) の詳細については、vue-routerのドキュメントをご覧ください。",
        "zh-Hans": "设置 **replace** 属性会在点击时调用 `router.replace()` 而不是 `router.push()`，这样导航就不会留下历史记录。您可以在 vue-router 文档中浏览更多 [**replace** prop](https://router.vuejs.org/api/#replace)。"
      }
    },
    {
      "name": "retain-focus-on-click",
      "type": "boolean",
      "default": "false",
      "source": "v-btn",
      "description": {
        "en": "Don't blur on click.",
        "ja": "クリック時のぼかしを無効化します。",
        "zh-Hans": "点击时不失去焦点。"
      }
    },
    {
      "name": "right",
      "type": "boolean",
      "default": "false",
      "source": "positionable",
      "description": {
        "en": "Aligns the component towards the right. This should be used with the **absolute** or **fixed** props.",
        "ja": "コンポーネントを右に揃えます。これは **absolute** もしくは **fixed** props と共に使うべきです。",
        "zh-Hans": "将组件向右对齐。这应该与 **absolute** 或 **fixed** 属性一起使用。"
      }
    },
    {
      "name": "ripple",
      "type": [
        "boolean",
        "object"
      ],
      "default": "undefined",
      "source": "routable",
      "description": {
        "en": "Applies the [v-ripple](/directives/ripple) directive.",
        "ja": "[v-ripple](/directives/ripple) ディレクティブを適用します。",
        "zh-Hans": "应用 [v-ripple](/directives/ripples) 指令。"
      }
    },
    {
      "name": "rounded",
      "type": "boolean",
      "default": "false",
      "source": "roundable",
      "description": {
        "en": "Applies a large border radius on the button.",
        "ja": "ボタンの境界線に大きな半径を適用します。",
        "zh-Hans": "在按钮上应用较大的边框半径。"
      }
    },
    {
      "name": "shaped",
      "type": "boolean",
      "default": "false",
      "source": "v-sheet",
      "description": {
        "en": "Applies a large border radius on the top left and bottom right of the card.",
        "ja": "カードの左上と右下の角を丸くします。",
        "zh-Hans": "在卡片的左上角和右下角应用较大的边框半径。"
      }
    },
    {
      "name": "small",
      "type": "boolean",
      "default": "false",
      "source": "sizeable",
      "description": {
        "en": "Makes the component small.",
        "ja": "コンポーネントをsmallにします。",
        "zh-Hans": "使组件尺寸变的小。"
      }
    },
    {
      "name": "tag",
      "type": "string",
      "default": "'button'",
      "source": "v-sheet",
      "description": {
        "en": "Specify a custom tag used on the root element.",
        "ja": "コンポーネント内で使用するカスタムタグを指定します。",
        "zh-Hans": "指定在根元素上使用的自定义标签。"
      }
    },
    {
      "name": "target",
      "type": "string",
      "default": "undefined",
      "source": "routable",
      "description": {
        "en": "Designates the target attribute. This should only be applied when using the **href** prop.",
        "ja": "ターゲット属性を指定します。これは **href** propを使用する場合にのみ適用する必要があります。",
        "zh-Hans": "指定 target 属性。只在使用 **href** 属性时应用。"
      }
    },
    {
      "name": "text",
      "type": "boolean",
      "default": "false",
      "source": "v-btn",
      "description": {
        "en": "Makes the background transparent. When using the **color** prop, the color will be applied to the button text instead of the background.",
        "ja": "背景を透明にします。**color** プロパティを使用すると、背景の代わりにボタンのテキストに色が適用されます。",
        "zh-Hans": "使背景变为透明. 当使用 **color** prop时, 颜色将应用于按钮文字而不是背景色."
      }
    },
    {
      "name": "tile",
      "type": "boolean",
      "default": "false",
      "source": "roundable",
      "description": {
        "en": "Removes the component's **border-radius**.",
        "ja": "コンポーネントの **border-radius** を削除します。",
        "zh-Hans": "删除组件的**border-radius**样式"
      }
    },
    {
      "name": "to",
      "type": [
        "string",
        "object"
      ],
      "default": "undefined",
      "source": "routable",
      "description": {
        "en": "Denotes the target route of the link. You can find more information about the [**to** prop](https://router.vuejs.org/api/#to) on the vue-router documentation.",
        "ja": "リンクのターゲットルートを示します。[**to** prop](https://router.vuejs.org/ja/api/#to) の詳細については、vue-routerのドキュメントをご覧ください。",
        "zh-Hans": "表示链接的目标路由。你可以在 vue-router 文档浏览更多 [**to** prop](https://router.vuejs.org/api/#to)。"
      }
    },
    {
      "name": "top",
      "type": "boolean",
      "default": "false",
      "source": "positionable",
      "description": {
        "en": "Aligns the content towards the top.",
        "ja": "コンポーネントを上部に配置します。",
        "zh-Hans": "将组件向顶部对齐。"
      }
    },
    {
      "name": "type",
      "type": "string",
      "default": "'button'",
      "source": "v-btn",
      "description": {
        "en": "Set the button's **type** attribute.",
        "ja": "ボタンの **type** 属性を設定します。",
        "zh-Hans": "设置按钮的 **type** 属性."
      }
    },
    {
      "name": "value",
      "type": "any",
      "default": "undefined",
      "source": "v-btn",
      "description": {
        "en": "Controls whether the component is visible or hidden.",
        "ja": "コンポーネントを表示するか非表示にするかを制御します。",
        "zh-Hans": "控制组件可见还是隐藏。"
      }
    },
    {
      "name": "width",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "measurable",
      "description": {
        "en": "Sets the width for the component.",
        "ja": "コンポーネントに横幅を設定します。",
        "zh-Hans": "设定组件的宽度。"
      }
    },
    {
      "name": "x-large",
      "type": "boolean",
      "default": "false",
      "source": "sizeable",
      "description": {
        "en": "Makes the component extra large.",
        "ja": "コンポーネントをextra largeにします。",
        "zh-Hans": "使组件尺寸变的无比巨大。"
      }
    },
    {
      "name": "x-small",
      "type": "boolean",
      "default": "false",
      "source": "sizeable",
      "description": {
        "en": "Makes the component extra small.",
        "ja": "コンポーネントをextra smallにします。",
        "zh-Hans": "使组件尺寸变的更小。"
      }
    }
  ],
  "mixins": [
    "colorable",
    "elevatable",
    "groupable",
    "measurable",
    "positionable",
    "roundable",
    "routable",
    "sizeable",
    "themeable",
    "toggleable",
    "v-sheet"
  ],
  "slots": [
    {
      "name": "default",
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    },
    {
      "name": "loader",
      "description": {
        "en": "Custom loader.",
        "ja": "カスタムローダー。",
        "zh-Hans": "自定义加载器."
      }
    }
  ],
  "events": [
    {
      "name": "click",
      "value": "Event",
      "description": {
        "en": "Event that is emitted when the component is clicked",
        "ja": "コンポーネントがクリックされたときに発生するイベント",
        "zh-Hans": "单击组件时发出的事件"
      }
    }
  ],
  "functions": [],
  "name": "v-btn",
  "sass": [
    {
      "name": "$btn-active-opacity",
      "default": "0.18 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$btn-border-radius",
      "default": "$border-radius-root !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$btn-focus-opacity",
      "default": "0.24 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$btn-font-weight",
      "default": "500 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$btn-hover-opacity",
      "default": "0.08 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$btn-icon-font-size",
      "default": "18px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$btn-icon-padding",
      "default": "12px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$btn-letter-spacing",
      "default": ".0892857143em !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$btn-outline-border-width",
      "default": "thin !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$btn-round-border-radius",
      "default": "50% !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$btn-rounded-border-radius",
      "default": "28px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$btn-text-transform",
      "default": "uppercase !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$btn-tile-border-radius",
      "default": "0 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$btn-transition-duration",
      "default": "0.28s !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$btn-transition-fn",
      "default": "map-get($transition, 'fast-out-slow-in') !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$btn-transition",
      "default": "opacity 0.2s map-get($transition, 'ease-in-out') !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$btn-states",
      "default": "(\n  'states': (\n    'activated': $btn-active-opacity,\n    'focus': $btn-focus-opacity,\n    'hover': $btn-hover-opacity\n  )\n);",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$btn-sizes",
      "default": "map-deep-merge(\n  (\n    'x-small': 20,\n    'small': 28,\n    'default': 36,\n    'large': 44,\n    'x-large': 52\n  ),\n  $btn-sizes\n);",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$btn-font-sizes",
      "default": "map-deep-merge(\n  (\n    'x-small': .625rem,\n    'small': .75rem,\n    'default': .875rem,\n    'large': .875rem,\n    'x-large': 1rem\n  ),\n  $btn-font-sizes\n);",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$fab-sizes",
      "default": "map-deep-merge(\n  (\n    'x-small': 32,\n    'small': 40,\n    'default': 56,\n    'large': 64,\n    'x-large': 72\n  ),\n  $fab-sizes\n);",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$fab-icon-sizes",
      "default": "map-deep-merge(\n  (\n    'x-small': 18,\n    'small': 24,\n    'default': 24,\n    'large': 28,\n    'x-large': 32\n  ),\n  $fab-icon-sizes\n);",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "component": true
}