var render = function render(){var _vm=this,_c=_vm._self._c;return _c('app-btn',{attrs:{"to":{
    name: 'Documentation',
    params: {
      category: 'introduction',
      page: 'why-vuetify'
    }
  },"path":"guide"},on:{"click":_vm.onClick}})
}
var staticRenderFns = []

export { render, staticRenderFns }