module.exports = {
  "props": [
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. This will default the components color to _white_ unless you've configured your [application theme](/customization/theme) to **dark** or if you are using the **color** prop on the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにdarkテーマのバリエーションを適用します。[アプリケーションのテーマ](/customization/theme) を **dark** に設定している場合や、コンポーネントに **color** propを使用している場合を除き、コンポーネントの色はデフォルトで _white_ になります。Material Design documentationの[ダークテーマ](https://material.io/design/color/dark-theme.html) に詳しい情報があります。",
        "zh-Hans": "将深色主题变量应用于组件。 除非您已将 [application theme](/customization/theme) 配置为 **dark** 或在组件上使用 **color** 属性，否则组件的颜色默认为 _white_。 您可以在材料设计文档中找到更多信息 [dark themes](https://material.io/design/color/dark-theme.html)。"
      }
    },
    {
      "name": "disabled",
      "type": "boolean",
      "default": "false",
      "source": "validatable",
      "description": {
        "en": "Disable the input",
        "ja": "入力を無効にする",
        "zh-Hans": "禁用输入"
      }
    },
    {
      "name": "id",
      "type": "string",
      "default": "undefined",
      "source": "v-input",
      "description": {
        "en": "Sets the DOM id on the component",
        "ja": "コンポーネントにDOM idを設定します",
        "zh-Hans": "设置组件上的 DOM id"
      }
    },
    {
      "name": "length",
      "type": [
        "number",
        "string"
      ],
      "default": 6,
      "source": "v-otp-input",
      "description": {
        "en": "The OTP field's length",
        "ja": "OTPフィールドの長さ",
        "zh-Hans": "The OTP field's length"
      }
    },
    {
      "name": "plain",
      "type": "boolean",
      "default": "false",
      "source": "v-otp-input",
      "description": {
        "en": "Outlined style applied by default to the input, set to `true` to apply plain style",
        "ja": "Outlined style applied by default to the input, set to `true` to apply plain style",
        "zh-Hans": "Outlined style applied by default to the input, set to `true` to apply plain style"
      }
    },
    {
      "name": "readonly",
      "type": "boolean",
      "default": "false",
      "source": "validatable",
      "description": {
        "en": "Puts input in readonly state",
        "ja": "読み取り専用状態に設定します。",
        "zh-Hans": "将输入设置为只读状态"
      }
    },
    {
      "name": "type",
      "type": "string",
      "default": "'text'",
      "source": "v-otp-input",
      "description": {
        "en": "Supported types: `text`, `password`, `number`",
        "ja": "サポートされている型: `text`, `password`, `number`",
        "zh-Hans": "Supported types: `text`, `password`, `number`"
      }
    },
    {
      "name": "value",
      "type": "any",
      "default": "undefined",
      "source": "validatable",
      "description": {
        "en": "The input's value",
        "ja": "入力の値",
        "zh-Hans": "输入的值"
      }
    }
  ],
  "mixins": [
    "colorable",
    "registrable-inject",
    "themeable",
    "v-input",
    "validatable"
  ],
  "slots": [],
  "events": [
    {
      "name": "change",
      "value": "string",
      "description": {
        "en": "Emitted when the input is changed by user interaction",
        "ja": "ユーザーの操作によって値が変更されたときに発生します。",
        "zh-Hans": "当用户交互更改输入时发出"
      }
    },
    {
      "name": "finish",
      "value": "string",
      "description": {
        "en": "Emitted when the input is filled completely and cursor is blurred",
        "ja": "入力欄が完全に埋められ、かつフォーカスが外れたときに発行されます。",
        "zh-Hans": "Emitted when the input is filled completely and cursor is blurred"
      }
    },
    {
      "name": "input",
      "value": "string",
      "description": {
        "en": "The updated bound model",
        "ja": "更新されたバウンドモデル",
        "zh-Hans": "被绑定模型的更新"
      }
    }
  ],
  "functions": [],
  "exclude": {
    "props": [
      "hide-spin-buttons"
    ]
  },
  "name": "v-otp-input",
  "sass": [
    {
      "name": "$otp-gutter",
      "default": "4px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$otp-width",
      "default": "24px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "component": true
}