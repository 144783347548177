module.exports = {
  "props": [
    {
      "name": "active-picker",
      "type": "string",
      "default": "undefined",
      "source": "v-time-picker",
      "description": {
        "en": "Determines which picker is being displayed. Allowed values: `'HOUR'`, `'MINUTE'`, `'SECOND'`",
        "ja": "Determines which picker is being displayed. Allowed values: `'HOUR'`, `'MINUTE'`, `'SECOND'`",
        "zh-Hans": "Determines which picker is being displayed. Allowed values: `'HOUR'`, `'MINUTE'`, `'SECOND'`"
      }
    },
    {
      "name": "allowed-hours",
      "type": [
        "function",
        "array"
      ],
      "default": "undefined",
      "source": "v-time-picker",
      "description": {
        "en": "Restricts which hours can be selected",
        "ja": "選択できる時間を制限します",
        "zh-Hans": "限制可以选择的小时"
      }
    },
    {
      "name": "allowed-minutes",
      "type": [
        "function",
        "array"
      ],
      "default": "undefined",
      "source": "v-time-picker",
      "description": {
        "en": "Restricts which minutes can be selected",
        "ja": "選択できる分数を制限します",
        "zh-Hans": "限制可以选择的分钟"
      }
    },
    {
      "name": "allowed-seconds",
      "type": [
        "function",
        "array"
      ],
      "default": "undefined",
      "source": "v-time-picker",
      "description": {
        "en": "Restricts which seconds can be selected",
        "ja": "選択できる秒数を制限します",
        "zh-Hans": "限制可以选择的秒数"
      }
    },
    {
      "name": "ampm-in-title",
      "type": "boolean",
      "default": "false",
      "source": "v-time-picker",
      "description": {
        "en": "Place AM/PM switch in title, not near the clock.",
        "ja": "時計の近くではなく、タイトルにAM/PMスイッチを入れます。",
        "zh-Hans": "将 AM/PM 开关放置在标题中，不在时钟附近。"
      }
    },
    {
      "name": "color",
      "type": "string",
      "default": "undefined",
      "source": "colorable",
      "description": {
        "en": "Applies specified color to the control - it can be the name of material color (for example `success` or `purple`) or css color (`#033` or `rgba(255, 0, 0, 0.5)`). You can find a list of built-in classes on the [colors page](/styles/colors#material-colors).",
        "ja": "指定された色をコントロールに適用します。利用できるのはマテリアルカラーの名前 (たとえば、`success` または ` purple`) または css 表記の色 (`#033`または` rgba(255, 0, 0, 0.5) `) です。[colors のページ](/styles/colors#material-colors) で、組み込みクラスのリストが確認できます。",
        "zh-Hans": "指定控件颜色，颜色值可以是 Material 颜色（例如 <code>success</code>或者 <code>purple</code> ）也可以是 Css 颜色 （例如 <code>#033</code> 或者 <code>rgba(255, 0, 0, 0.5)</code>）。在 [colors page](/zh-Hans/styles/colors#material-colors)中可以找到内置颜色类列表。"
      }
    },
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにダークテーマを適応します。詳しく知りたい場合は、Material Design documentationの<a href=\"https://material.io/design/color/dark-theme.html\">dark themes</a>を参照してください。",
        "zh-Hans": "将暗色主题变量应用到组件。你可以在 [dark themes](https://material.io/design/color/dark-theme.html) 的 Material Design 文档中找到更多有关信息。"
      }
    },
    {
      "name": "disabled",
      "type": "boolean",
      "default": "false",
      "source": "v-time-picker",
      "description": {
        "en": "disables picker",
        "ja": "ピッカーを無効にします",
        "zh-Hans": "禁用选择器"
      }
    },
    {
      "name": "elevation",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "elevatable",
      "description": {
        "en": "Designates an elevation applied to the component between 0 and 24. You can find more information on the [elevation page](/styles/elevation).",
        "ja": "コンポーネントに適用される高さを0〜24の範囲で指定します。詳細は、[elevation のページ](/styles/elevation)で確認できます。",
        "zh-Hans": "组件的海拔可接受 0 到 24 之间的值。你可以在 [elevation page](/styles/elevation) 浏览更多信息。"
      }
    },
    {
      "name": "flat",
      "type": "boolean",
      "default": "false",
      "source": "picker",
      "description": {
        "en": "Removes  elevation",
        "ja": "Elevationを削除します。",
        "zh-Hans": "移除提升"
      }
    },
    {
      "name": "format",
      "type": "string",
      "default": "'ampm'",
      "source": "v-time-picker",
      "description": {
        "en": "Defines the format of a time displayed in picker. Available options are `ampm` and `24hr`.",
        "ja": "ピッカーに表示される時間の形式を定義します。利用可能なオプションは `ampm` と `24hr` です。",
        "zh-Hans": "定义在选择器中显示的时间格式。可用的选项是 `ampm` 和 `24hr`。"
      }
    },
    {
      "name": "full-width",
      "type": "boolean",
      "default": "false",
      "source": "picker",
      "description": {
        "en": "Forces 100% width",
        "ja": "幅を強制的に 100% にする",
        "zh-Hans": "强制 100% 宽度"
      }
    },
    {
      "name": "header-color",
      "type": "string",
      "default": "undefined",
      "source": "picker",
      "description": {
        "en": "Defines the header color. If not specified it will use the color defined by <code>color</code> prop or the default picker color",
        "ja": "ヘッダーの色を定義します。 指定されていない場合は、<code> color </ code> propで定義された色またはデフォルトのピッカーカラーを使用します",
        "zh-Hans": "定义标题颜色。如果未指定，将使用由 <code>color</code> 属性或默认选择器颜色定义的颜色。"
      }
    },
    {
      "name": "landscape",
      "type": "boolean",
      "default": "false",
      "source": "picker",
      "description": {
        "en": "Orients picker horizontal",
        "ja": "ピッカーを水平方向に向けます",
        "zh-Hans": "水平方向选择器"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    },
    {
      "name": "max",
      "type": "string",
      "default": "undefined",
      "source": "v-time-picker",
      "description": {
        "en": "Maximum allowed time",
        "ja": "最大時間",
        "zh-Hans": "允许的最大时间"
      }
    },
    {
      "name": "min",
      "type": "string",
      "default": "undefined",
      "source": "v-time-picker",
      "description": {
        "en": "Minimum allowed time",
        "ja": "最小時間",
        "zh-Hans": "允许的最小时间"
      }
    },
    {
      "name": "no-title",
      "type": "boolean",
      "default": "false",
      "source": "picker",
      "description": {
        "en": "Hide the picker title",
        "ja": "ピッカータイトルを隠す",
        "zh-Hans": "隐藏选择器标题"
      }
    },
    {
      "name": "readonly",
      "type": "boolean",
      "default": "false",
      "source": "v-time-picker",
      "description": {
        "en": "Puts picker in readonly state",
        "ja": "読み取り専用状態に設定します。",
        "zh-Hans": "将选择器置于只读状态"
      }
    },
    {
      "name": "scrollable",
      "type": "boolean",
      "default": "false",
      "source": "v-time-picker",
      "description": {
        "en": "Allows changing hour/minute with mouse scroll",
        "ja": "マウスのスクロールで時間/分を変更できます",
        "zh-Hans": "允许通过鼠标滚动更改 小时/分钟"
      }
    },
    {
      "name": "use-seconds",
      "type": "boolean",
      "default": "false",
      "source": "v-time-picker",
      "description": {
        "en": "Toggles the use of seconds in picker",
        "ja": "ピッカーでの秒数の使用を切り替えます",
        "zh-Hans": "切换选择器中秒的使用"
      }
    },
    {
      "name": "value",
      "type": "any",
      "default": "undefined",
      "source": "v-time-picker",
      "description": {
        "en": "Time picker model (ISO 8601 format, 24hr hh:mm)",
        "ja": "Time picker model (ISO 8601 format, 24hr hh:mm)",
        "zh-Hans": "时间选择器型号（ISO 8601 格式，24hr hh:mm）"
      }
    },
    {
      "name": "width",
      "type": [
        "number",
        "string"
      ],
      "default": 290,
      "source": "picker",
      "description": {
        "en": "Width of the picker",
        "ja": "ピッカーの幅",
        "zh-Hans": "选择器宽度"
      }
    }
  ],
  "mixins": [
    "colorable",
    "colorable",
    "colorable",
    "elevatable",
    "picker",
    "themeable"
  ],
  "slots": [
    {
      "name": "default",
      "description": {
        "en": "Displayed below the clock, can be used for example for adding action button (`OK` and `Cancel`)",
        "ja": "時計の下に表示されます。アクションボタン (`OK` と `キャンセル`) などに使用できます。",
        "zh-Hans": "显示在时钟下方，可用于添加动作按钮（`OK` 和 `Cancel`）。"
      }
    }
  ],
  "events": [
    {
      "name": "change",
      "value": "string",
      "description": {
        "en": "Emitted when the time selection is done (when user changes the minute for HH:MM picker and the second for HH:MM:SS picker",
        "ja": "時間の選択が完了したときに発生します (HH:MM ピッカーの場合は「分」を、HH:MM:SS ピッカーの場合は 「秒」を変更したとき)。",
        "zh-Hans": "当时间选择完成时发出通知（当用户更改 HH:MM 选择器的分钟数时, HH:MM:SS 选择器的秒数"
      }
    },
    {
      "name": "click:hour",
      "value": "string",
      "description": {
        "en": "Emitted when user selects the hour",
        "ja": "ユーザーが「時」を選択したときに発生します。",
        "zh-Hans": "当用户选择小时时发出"
      }
    },
    {
      "name": "click:minute",
      "value": "string",
      "description": {
        "en": "Emitted when user selects the minute",
        "ja": "ユーザーが「分」を選択したときに発生します。",
        "zh-Hans": "当用户选择分钟时发出"
      }
    },
    {
      "name": "click:second",
      "value": "string",
      "description": {
        "en": "Emitted when user selects the second",
        "ja": "ユーザーが「秒」を選択したときに発生します。",
        "zh-Hans": "当用户选择秒时发出"
      }
    },
    {
      "name": "input",
      "value": "string",
      "description": {
        "en": "The updated bound model",
        "ja": "更新されたバウンドモデル",
        "zh-Hans": "被绑定模型的更新"
      }
    },
    {
      "name": "update:active-picker",
      "value": "string",
      "description": {
        "en": "The `.sync` event for `active-picker` prop",
        "ja": "`active-picker` プロパティ の `.sync` イベント",
        "zh-Hans": "`active-picker` 属性的`.sync` 事件"
      }
    },
    {
      "name": "update:period",
      "value": "string",
      "description": {
        "en": "Emitted when user clicks the AM/PM button",
        "ja": "ユーザーが AM/PM ボタンをクリックすると発生します。",
        "zh-Hans": "当用户点击 AM/PM 按钮时发出。"
      }
    }
  ],
  "functions": [],
  "name": "v-time-picker",
  "sass": [
    {
      "name": "$time-picker-title-color",
      "default": "map-get($shades, 'white') !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$time-picker-title-btn-height",
      "default": "70px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$time-picker-landscape-title-btn-height",
      "default": "55px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$time-picker-ampm-title-margin-start",
      "default": "8px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$time-picker-ampm-title-margin-bottom",
      "default": "6px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$time-picker-ampm-title-margin",
      "default": "0 0 $time-picker-ampm-title-margin-bottom $time-picker-ampm-title-margin-start !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$time-picker-ampm-title-margin-rtl",
      "default": "0 $time-picker-ampm-title-margin-start $time-picker-ampm-title-margin-bottom 0 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$time-picker-ampm-title-font-size",
      "default": "16px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$time-picker-landscape-ampm-title-margin",
      "default": "16px 0 0 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$time-picker-number-font-size",
      "default": "16px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$time-picker-indicator-size",
      "default": "40px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$time-picker-clock-padding",
      "default": "10px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$time-picker-clock-max-width",
      "default": "290px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$time-picker-clock-hand-height",
      "default": "calc(50% - 4px) !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$time-picker-clock-hand-width",
      "default": "2px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$time-picker-clock-hand-left",
      "default": "calc(50% - 1px) !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$time-picker-clock-center-size",
      "default": "8px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$time-picker-clock-end-size",
      "default": "10px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$time-picker-clock-end-top",
      "default": "-4px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$time-picker-clock-inner-hand-height",
      "default": "14px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$time-picker-clock-inner-offset",
      "default": "27px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$time-picker-ampm-padding",
      "default": "10px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$time-picker-clock-end-border-width",
      "default": "2px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$time-picker-clock-end-border-style",
      "default": "solid !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$time-picker-clock-end-border-color",
      "default": "inherit !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "component": true
}