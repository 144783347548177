module.exports = {
  "props": [
    {
      "name": "cancel-text",
      "type": "any",
      "default": "Cancel",
      "source": "v-edit-dialog",
      "description": {
        "en": "Sets the default text for the cancel button when using the **large** prop",
        "ja": "**large** prop を使用した際に表示されるキャンセルボタンのデフォルトテキストを設定します。",
        "zh-Hans": "为取消按钮设置默认文本，当使用了 `large` 属性时（即有提交和取消按钮的对话框）。"
      }
    },
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにダークテーマを適応します。詳しく知りたい場合は、Material Design documentationの<a href=\"https://material.io/design/color/dark-theme.html\">dark themes</a>を参照してください。",
        "zh-Hans": "将暗色主题变量应用到组件。你可以在 [dark themes](https://material.io/design/color/dark-theme.html) 的 Material Design 文档中找到更多有关信息。"
      }
    },
    {
      "name": "eager",
      "type": "boolean",
      "default": "false",
      "source": "v-edit-dialog",
      "description": {
        "en": "Will force the components content to render on mounted. This is useful if you have content that will not be rendered in the DOM that you want crawled for SEO.",
        "ja": "コンポーネントのコンテンツを mounted 時に render することを強制します。この機能は、SEO のために crawl させたい DOM の中に render されないコンテンツを持っているときに役に立ちます。",
        "zh-Hans": "将强制组件内容在加载时呈现。如果存在内容的话，则不会在 DOM 中渲染，如果你想优化 SEO，这是非常有用的。"
      }
    },
    {
      "name": "large",
      "type": "boolean",
      "default": "false",
      "source": "v-edit-dialog",
      "description": {
        "en": "Attaches a submit and cancel button to the dialog",
        "ja": "ダイアログにsubmitボタンとキャンセルボタンをアタッチします",
        "zh-Hans": "将提交和取消按钮附加到对话框"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    },
    {
      "name": "persistent",
      "type": "boolean",
      "default": "false",
      "source": "v-edit-dialog",
      "description": {
        "en": "Clicking outside or pressing **esc** key will not dismiss the dialog",
        "ja": "外側をクリックしても、**esc**キーを押しても、ダイアログは閉じられません。",
        "zh-Hans": "点击外部或按下 **esc** 键将不会关闭对话框"
      }
    },
    {
      "name": "return-value",
      "type": "any",
      "default": "undefined",
      "source": "returnable",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "save-text",
      "type": "any",
      "default": "Save",
      "source": "v-edit-dialog",
      "description": {
        "en": "Sets the default text for the save button when using the **large** prop",
        "ja": "**large** prop を使用した際に表示される保存ボタンのデフォルトテキストを設定します。",
        "zh-Hans": "为保存按钮设置默认文本，当使用了 `large` 属性时。"
      }
    },
    {
      "name": "transition",
      "type": "string",
      "default": "'slide-x-reverse-transition'",
      "source": "v-edit-dialog",
      "description": {
        "en": "Sets the component transition. Can be one of the [built in transitions](/styles/transitions) or one your own.",
        "ja": "コンポーネントのトランジションを設定します。 [組み込みトランジション](/styles/transitions) のいずれか、または独自のものを使用できます。",
        "zh-Hans": "设置组件转换。可以是一个 [built in transitions](/styles/transitions) 或者是自己自定义的。"
      }
    }
  ],
  "mixins": [
    "returnable",
    "themeable"
  ],
  "slots": [
    {
      "name": "default",
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    },
    {
      "name": "input",
      "description": {
        "en": "Slot used to denote input component for v-edit-dialog",
        "ja": "v-edit-dialogの入力コンポーネントを示すために使用されるスロット",
        "zh-Hans": "用于表示 v-edit-dialog 输入组件的插槽"
      }
    }
  ],
  "events": [
    {
      "name": "cancel",
      "value": "void",
      "description": {
        "en": "Emits when editing is canceled",
        "ja": "編集がキャンセルされたときに発生します",
        "zh-Hans": "在编辑被取消时发出"
      }
    },
    {
      "name": "close",
      "value": "void",
      "description": {
        "en": "Emits when edit-dialog close button is pressed",
        "ja": "編集ダイアログで、閉じるボタンが押されたときに発生します",
        "zh-Hans": "按下编辑对话框关闭按钮时发出"
      }
    },
    {
      "name": "open",
      "value": "void",
      "description": {
        "en": "Emits when editing is opened",
        "ja": "編集を開いたときに発生します",
        "zh-Hans": "在打开编辑时发出"
      }
    },
    {
      "name": "save",
      "value": "void",
      "description": {
        "en": "Emits when edit-dialog save button is pressed",
        "ja": "編集ダイアログ保存ボタンが押されたときに発生します",
        "zh-Hans": "按下编辑对话框保存按钮时退出"
      }
    }
  ],
  "functions": [],
  "name": "v-edit-dialog",
  "sass": [],
  "component": true
}