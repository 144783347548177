module.exports = {
  "props": [
    {
      "name": "append-icon",
      "type": "string",
      "default": "'$dropdown'",
      "source": "v-input",
      "description": {
        "en": "Appends an icon to the component, uses the same syntax as `v-icon`",
        "ja": "`v-icon`と同じ構文を使用し、コンポーネントにアイコンを追加します",
        "zh-Hans": "在组件上附加一个图标，使用与 `v-icon` 相同的语法"
      }
    },
    {
      "name": "append-outer-icon",
      "type": "string",
      "default": "undefined",
      "source": "v-text-field",
      "description": {
        "en": "Appends an icon to the outside the component's input, uses same syntax as `v-icon`",
        "ja": "`v-icon`と同じ構文を使用して、入力コンポーネント外側の後方にアイコンを追加します",
        "zh-Hans": "在组件的外部添加一个图标，使用与 `v-icon` 相同的语法"
      }
    },
    {
      "name": "attach",
      "type": "any",
      "default": false,
      "source": "v-autocomplete",
      "description": {
        "en": "Specifies which DOM element that this component should detach to. String can be any valid querySelector and Object can be any valid Node. This will attach to the root `v-app` component by default.",
        "ja": "このコンポーネントが切り離すべき DOM 要素を指定します。String には任意の有効な querySelector、Object には任意の有効な Node を指定します。デフォルトではルートの `v-app` コンポーネントにアタッチされます。",
        "zh-Hans": "使组件脱离当前节点并且指向一个新的DOM元素。可以是任何document. querySelector 有效的字符串或者任何有效的节点。将默认指定并附加到根节点`v-app`下。"
      }
    },
    {
      "name": "auto-select-first",
      "type": "boolean",
      "default": "false",
      "source": "v-autocomplete",
      "description": {
        "en": "When searching, will always highlight the first option",
        "ja": "検索時には、常に最初のオプションを強調表示します",
        "zh-Hans": "搜索时，总是选中第一个选项"
      }
    },
    {
      "name": "autofocus",
      "type": "boolean",
      "default": "false",
      "source": "v-text-field",
      "description": {
        "en": "Enables autofocus",
        "ja": "オートフォーカスを有効にします。",
        "zh-Hans": "启用自动聚焦"
      }
    },
    {
      "name": "background-color",
      "type": "string",
      "default": "undefined",
      "source": "v-input",
      "description": {
        "en": "Changes the background-color of the input",
        "ja": "inputの背景色を変更します",
        "zh-Hans": "更改输入的背景颜色"
      }
    },
    {
      "name": "cache-items",
      "type": "boolean",
      "default": "false",
      "source": "v-autocomplete",
      "description": {
        "en": "Keeps a local _unique_ copy of all items that have been passed through the **items** prop.",
        "ja": "Keeps a local _unique_ copy of all items that have been passed through the **items** prop.",
        "zh-Hans": "保留已经通过 **items** 属性的项在本地的唯一副本"
      }
    },
    {
      "name": "chips",
      "type": "boolean",
      "default": "false",
      "source": "v-autocomplete",
      "description": {
        "en": "Changes display of selections to chips",
        "ja": "選択項目の表示をチップに変更",
        "zh-Hans": "改变一个已选择项为小纸片（chips）的显示方式"
      }
    },
    {
      "name": "clear-icon",
      "type": "string",
      "default": "'$clear'",
      "source": "v-text-field",
      "description": {
        "en": "Applied when using **clearable** and the input is dirty",
        "ja": "Applied when using **clearable** and the input is dirty",
        "zh-Hans": "当使用 **clearable** 且有输入值时应用"
      }
    },
    {
      "name": "clearable",
      "type": "boolean",
      "default": "false",
      "source": "v-text-field",
      "description": {
        "en": "Add input clear functionality, default icon is Material Design Icons **mdi-clear**",
        "ja": "入力クリア機能を追加します（デフォルトのアイコンは、Material Icons の **mdi-clear** ）",
        "zh-Hans": "添加清除已输入内容功能，默认图标是Material Design Icons **mdi-clear**"
      }
    },
    {
      "name": "color",
      "type": "string",
      "default": "undefined",
      "source": "colorable",
      "description": {
        "en": "Applies specified color to the control - it can be the name of material color (for example `success` or `purple`) or css color (`#033` or `rgba(255, 0, 0, 0.5)`). You can find a list of built-in classes on the [colors page](/styles/colors#material-colors).",
        "ja": "指定された色をコントロールに適用します。利用できるのはマテリアルカラーの名前 (たとえば、`success` または ` purple`) または css 表記の色 (`#033`または` rgba(255, 0, 0, 0.5) `) です。[colors のページ](/styles/colors#material-colors) で、組み込みクラスのリストが確認できます。",
        "zh-Hans": "指定控件颜色，颜色值可以是 Material 颜色（例如 <code>success</code>或者 <code>purple</code> ）也可以是 Css 颜色 （例如 <code>#033</code> 或者 <code>rgba(255, 0, 0, 0.5)</code>）。在 [colors page](/zh-Hans/styles/colors#material-colors)中可以找到内置颜色类列表。"
      }
    },
    {
      "name": "counter",
      "type": [
        "boolean",
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "v-text-field",
      "description": {
        "en": "Creates counter for input length; if no number is specified, it defaults to 25. Does not apply any validation.",
        "ja": "入力長のカウンタを作成します。数値が指定されていない場合、デフォルトは 25 になります。バリデーションは適用しません。",
        "zh-Hans": "为输入长度创建一个计数器，如果未指定数字，则默认为25，不会应用任何验证。"
      }
    },
    {
      "name": "counter-value",
      "type": "function",
      "default": "null",
      "source": "v-text-field",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. This will default the components color to _white_ unless you've configured your [application theme](/customization/theme) to **dark** or if you are using the **color** prop on the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにdarkテーマのバリエーションを適用します。[アプリケーションのテーマ](/customization/theme) を **dark** に設定している場合や、コンポーネントに **color** propを使用している場合を除き、コンポーネントの色はデフォルトで _white_ になります。Material Design documentationの[ダークテーマ](https://material.io/design/color/dark-theme.html) に詳しい情報があります。",
        "zh-Hans": "将深色主题变量应用于组件。 除非您已将 [application theme](/customization/theme) 配置为 **dark** 或在组件上使用 **color** 属性，否则组件的颜色默认为 _white_。 您可以在材料设计文档中找到更多信息 [dark themes](https://material.io/design/color/dark-theme.html)。"
      }
    },
    {
      "name": "deletable-chips",
      "type": "boolean",
      "default": "false",
      "source": "v-autocomplete",
      "description": {
        "en": "Adds a remove icon to selected chips",
        "ja": "選択中のチップに削除アイコンを追加します（chipsオプションと併用）",
        "zh-Hans": "添加一个去除图标的到选定的小纸片（chips）"
      }
    },
    {
      "name": "dense",
      "type": "boolean",
      "default": "false",
      "source": "v-input",
      "description": {
        "en": "Reduces the input height",
        "ja": "inputの高さを低くします",
        "zh-Hans": "降低输入高度"
      }
    },
    {
      "name": "disable-lookup",
      "type": "boolean",
      "default": "false",
      "source": "v-autocomplete",
      "description": {
        "en": "Disables keyboard lookup",
        "ja": "キーボードの検索を無効にします",
        "zh-Hans": "禁用键盘查询"
      }
    },
    {
      "name": "disabled",
      "type": "boolean",
      "default": false,
      "source": "validatable",
      "description": {
        "en": "Disable the input",
        "ja": "入力を無効にする",
        "zh-Hans": "禁用输入"
      }
    },
    {
      "name": "eager",
      "type": "boolean",
      "default": "false",
      "source": "v-autocomplete",
      "description": {
        "en": "Will force the components content to render on mounted. This is useful if you have content that will not be rendered in the DOM that you want crawled for SEO.",
        "ja": "コンポーネントのコンテンツを mounted 時に render することを強制します。この機能は、SEO のために crawl させたい DOM の中に render されないコンテンツを持っているときに役に立ちます。",
        "zh-Hans": "将强制组件内容在加载时呈现。如果存在内容的话，则不会在 DOM 中渲染，如果你想优化 SEO，这是非常有用的。"
      }
    },
    {
      "name": "editable",
      "type": "boolean",
      "default": "false",
      "source": "v-overflow-btn",
      "description": {
        "en": "Creates an editable button",
        "ja": "編集可能なボタンを作成します",
        "zh-Hans": "创建一个可编辑按钮"
      }
    },
    {
      "name": "error",
      "type": "boolean",
      "default": "false",
      "source": "validatable",
      "description": {
        "en": "Puts the input in a manual error state",
        "ja": "入力を手動でエラー状態にします。",
        "zh-Hans": "将输入框设置为手动错误状态。"
      }
    },
    {
      "name": "error-count",
      "type": [
        "number",
        "string"
      ],
      "default": 1,
      "source": "validatable",
      "description": {
        "en": "The total number of errors that should display at once",
        "ja": "一度に表示するエラーの数を設定します。",
        "zh-Hans": "一次性显示的错误总数"
      }
    },
    {
      "name": "error-messages",
      "type": [
        "string",
        "array"
      ],
      "default": [],
      "source": "validatable",
      "description": {
        "en": "Puts the input in an error state and passes through custom error messages. Will be combined with any validations that occur from the **rules** prop. This field will not trigger validation",
        "ja": "入力をerror状態にして、カスタムエラーメッセージをパススルーします。**rules**  propから発生するすべてのバリデーションと組み合わせられます。このフィールドはバリデーションのトリガーにはなりません。",
        "zh-Hans": "将输入框置于错误状态，并传入自定义的错误信息。将与来自 **rules** 属性的任何验证相结合。这个字段不会触发验证。"
      }
    },
    {
      "name": "filled",
      "type": "boolean",
      "default": "false",
      "source": "v-text-field",
      "description": {
        "en": "Applies the alternate filled input style",
        "ja": "代替のfilled入力スタイルを適用します",
        "zh-Hans": "应用替代填充输入样式"
      }
    },
    {
      "name": "filter",
      "type": "function",
      "default": "(item, queryText, itemText) => {}",
      "source": "v-autocomplete",
      "description": {
        "en": "The filtering algorithm used when searching. [example](https://github.com/vuetifyjs/vuetify/blob/v2-stable/packages/vuetify/src/components/VAutocomplete/VAutocomplete.ts#L40)",
        "ja": "The filtering algorithm used when searching. [example](https://github.com/vuetifyjs/vuetify/blob/v2-stable/packages/vuetify/src/components/VAutocomplete/VAutocomplete.ts#L40)",
        "zh-Hans": "搜索时使用的过滤算法。 [example](https://github.com/vuetifyjs/vuetify/blob/v2-stable/packages/vuetify/src/components/VAutocomplete/VAutocomplete.ts#L40)"
      }
    },
    {
      "name": "flat",
      "type": "boolean",
      "default": "false",
      "source": "v-text-field",
      "description": {
        "en": "Removes elevation (shadow) added to element when using the **solo** or **solo-inverted** props",
        "ja": "**solo**または**solo-inverted** propを使用するときに要素に追加されたエレベーション（影）を削除します。",
        "zh-Hans": "当使用**solo**或者**solo-inverted**属性时，移除添加到元素的标高（阴影）"
      }
    },
    {
      "name": "full-width",
      "type": "boolean",
      "default": "false",
      "source": "v-text-field",
      "description": {
        "en": "Designates input type as full-width",
        "ja": "Designates input type as full-width",
        "zh-Hans": "指定输入类型为全宽度"
      }
    },
    {
      "name": "height",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "v-input",
      "description": {
        "en": "Sets the height of the input",
        "ja": "高さを設定します",
        "zh-Hans": "设置输入的高度"
      }
    },
    {
      "name": "hide-details",
      "type": [
        "boolean",
        "string"
      ],
      "default": "undefined",
      "source": "v-input",
      "description": {
        "en": "Hides hint and validation errors. When set to `auto` messages will be rendered only if there's a message (hint, error message, counter value etc) to display",
        "ja": "ヒントやバリデーションエラーを非表示にします。 `auto` が設定されている場合、表示するメッセージ（ヒント、エラーメッセージ、カウンター値など）がある場合のみ表示します",
        "zh-Hans": "隐藏提示和验证错误。当设置为 `auto` 时，只有在有信息（提示、错误信息、计数器值等）要显示时，才会显示信息。"
      }
    },
    {
      "name": "hide-no-data",
      "type": "boolean",
      "default": "false",
      "source": "v-autocomplete",
      "description": {
        "en": "Hides the menu when there are no options to show.  Useful for preventing the menu from opening before results are fetched asynchronously.  Also has the effect of opening the menu when the `items` array changes if not already open.",
        "ja": "表示するオプションがない場合、メニューを非表示にします。結果が非同期でフェッチされる前にメニューが開かないようにするのに役立ちます。 `items`配列がまだ開いていない場合は、変化したときにメニューを開く効果もあります。",
        "zh-Hans": "当没有要显示的选项时，隐藏菜单。用于防止在异步获取结果之前打开菜单。也有打开菜单的效果，当“ `items` 数组改变，如果还没有打开。"
      }
    },
    {
      "name": "hide-selected",
      "type": "boolean",
      "default": "false",
      "source": "v-autocomplete",
      "description": {
        "en": "Do not display in the select menu items that are already selected. Also removes checkboxes from the list when multiple",
        "ja": "Do not display in the select menu items that are already selected. Also removes checkboxes from the list when multiple",
        "zh-Hans": "Do not display in the select menu items that are already selected. Also removes checkboxes from the list when multiple"
      }
    },
    {
      "name": "hint",
      "type": "string",
      "default": "undefined",
      "source": "v-input",
      "description": {
        "en": "Hint text",
        "ja": "ヒントを設定します。",
        "zh-Hans": "提示文本"
      }
    },
    {
      "name": "id",
      "type": "string",
      "default": "undefined",
      "source": "v-input",
      "description": {
        "en": "Sets the DOM id on the component",
        "ja": "コンポーネントにDOM idを設定します",
        "zh-Hans": "设置组件上的 DOM id"
      }
    },
    {
      "name": "item-color",
      "type": "string",
      "default": "'primary'",
      "source": "v-autocomplete",
      "description": {
        "en": "Sets color of selected items",
        "ja": "選択した項目の色を設定します",
        "zh-Hans": "设置选中项目的颜色"
      }
    },
    {
      "name": "item-disabled",
      "type": [
        "string",
        "array",
        "function"
      ],
      "default": "disabled",
      "source": "v-autocomplete",
      "description": {
        "en": "Set property of **items**'s disabled value",
        "ja": "Set property of **items**'s disabled value",
        "zh-Hans": "禁用 **items** 的属性值"
      }
    },
    {
      "name": "item-text",
      "type": [
        "string",
        "array",
        "function"
      ],
      "default": "text",
      "source": "v-autocomplete",
      "description": {
        "en": "Set property of **items**'s text value",
        "ja": "**items** のテキスト値のプロパティを設定します",
        "zh-Hans": "设置**items**'属性的文本值"
      }
    },
    {
      "name": "item-value",
      "type": [
        "string",
        "array",
        "function"
      ],
      "default": "value",
      "source": "v-autocomplete",
      "description": {
        "en": "Set property of **items**'s value - **must be primitive**. Dot notation is supported. **Note:** This is currently not supported with `v-combobox` [GitHub Issue](https://github.com/vuetifyjs/vuetify/issues/5479)",
        "ja": "Set property of **items**'s value - **must be primitive**. Dot notation is supported. **Note:** This is currently not supported with `v-combobox` [GitHub Issue](https://github.com/vuetifyjs/vuetify/issues/5479)",
        "zh-Hans": "设置 **items** 的值的属性 - **必须是 primitive**。支持点符号。**注意：** 目前不支持 `v-combobox` [GitHub问题](https://github.com/vuetifyjs/vuetify/issues/5479)。"
      }
    },
    {
      "name": "items",
      "type": "array",
      "default": [],
      "source": "v-select",
      "example": {
        "text": "string | number | object",
        "value": "string | number | object",
        "disabled": "boolean",
        "divider": "boolean",
        "header": "string"
      },
      "description": {
        "en": "Can be an array of objects or array of strings. When using objects, will look for a text, value and disabled keys. This can be changed using the **item-text**, **item-value** and **item-disabled** props.  Objects that have a **header** or **divider** property are considered special cases and generate a list header or divider; these items are not selectable.",
        "ja": "Can be an array of objects or array of strings. When using objects, will look for a text, value and disabled keys. This can be changed using the **item-text**, **item-value** and **item-disabled** props.  Objects that have a **header** or **divider** property are considered special cases and generate a list header or divider; these items are not selectable.",
        "zh-Hans": "可以是对象数组或字符串数组。当使用对象时，将寻找文本和值字段。 这可以使用 **item-text** 和 **item-value** 属性来更改。 具有 **header** 或 **divider** 属性的对象被视为特殊情况并生成列表头或分隔符；这些是不可选择的。"
      }
    },
    {
      "name": "label",
      "type": "string",
      "source": "v-input",
      "description": {
        "en": "Sets input label",
        "ja": "ラベルを設定します。",
        "zh-Hans": "设置输入标签"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    },
    {
      "name": "loader-height",
      "type": [
        "number",
        "string"
      ],
      "default": 2,
      "source": "loadable",
      "description": {
        "en": "Specifies the height of the loader",
        "ja": "loader の高さを指定します。",
        "zh-Hans": "指定加载器的高度"
      }
    },
    {
      "name": "loading",
      "type": [
        "boolean",
        "string"
      ],
      "default": false,
      "source": "v-input",
      "description": {
        "en": "Displays linear progress bar. Can either be a String which specifies which color is applied to the progress bar (any material color or theme color - **primary**, **secondary**, **success**, **info**, **warning**, **error**) or a Boolean which uses the component **color** (set by color prop - if it's supported by the component) or the primary color",
        "ja": "線形プログレス バーを表示します。 進行状況バー (任意のマテリアルカラーまたはテーマカラー - **primary**, **secondary**, **success**, **info**, **warning**, **error**) に適用する色を指定する文字列、あるいはコンポーネント **color** (color propで設定 - コンポーネントでサポートされている場合) またはprimary色を使用する真偽値を指定できます。",
        "zh-Hans": "显示线性进度条。可以是指定将哪种颜色应用于进度条的字符串（任何 material 色彩——主要（primary）, 次要（secondary）, 成功（success）, 信息（info），警告（warning），错误（error）），或者使用组件的布尔值 **color**（由色彩属性设置——如果它被组件支持的话）还可以是原色。"
      }
    },
    {
      "name": "menu-props",
      "type": [
        "string",
        "array",
        "object"
      ],
      "default": "{\n  closeOnClick: false,\n  closeOnContentClick: false,\n  disableKeys: true,\n  openOnClick: false,\n  maxHeight: 304\n}",
      "source": "v-select",
      "description": {
        "en": "Pass props through to the `v-menu` component. Accepts either a string for boolean props `menu-props=\"auto, overflowY\"`, or an object `:menu-props=\"{ auto: true, overflowY: true }\"`",
        "ja": "Pass props through to the `v-menu` component. Accepts either a string for boolean props `menu-props=\"auto, overflowY\"`, or an object `:menu-props=\"{ auto: true, overflowY: true }\"`",
        "zh-Hans": "将属性传递到 `v-menu` 组件。接受布尔属性 `menu-props=\"auto, overflowY\"` ，或一个对象 `:menu-props=\"{ auto: true, overflowY: true }\"`"
      }
    },
    {
      "name": "messages",
      "type": [
        "string",
        "array"
      ],
      "default": [],
      "source": "validatable",
      "description": {
        "en": "Displays a list of messages or message if using a string",
        "ja": "文字列を使用している場合は、メッセージまたはメッセージの一覧を表示します。",
        "zh-Hans": "显示消息列表或消息（如果使用字符串）"
      }
    },
    {
      "name": "multiple",
      "type": "boolean",
      "default": "false",
      "source": "v-autocomplete",
      "description": {
        "en": "Changes select to multiple. Accepts array for value",
        "ja": "selectを複数選択に変更します。値には配列を受け付けます。",
        "zh-Hans": "多选，接受数组作为值"
      }
    },
    {
      "name": "no-data-text",
      "type": "string",
      "default": "'$vuetify.noDataText'",
      "source": "filterable",
      "description": {
        "en": "Display text when there is no data",
        "ja": "データが存在しない場合に表示するテキストです。",
        "zh-Hans": "当没有数据时显示的文本"
      }
    },
    {
      "name": "no-filter",
      "type": "boolean",
      "default": "false",
      "source": "v-autocomplete",
      "description": {
        "en": "Do not apply filtering when searching. Useful when data is being filtered server side",
        "ja": "検索時にフィルタリングを適用しません。データをサーバ側でフィルタリングしている場合に便利です。",
        "zh-Hans": "搜索时不要应用过滤。在过滤服务器端数据时非常有用"
      }
    },
    {
      "name": "open-on-clear",
      "type": "boolean",
      "default": "false",
      "source": "v-autocomplete",
      "description": {
        "en": "When using the **clearable** prop, once cleared, the select menu will either open or stay open, depending on the current state",
        "ja": "**clearable** propを使用する場合、クリアすると、選択メニューは現在の状態に応じて開くか、開いたままになります",
        "zh-Hans": "当使用 **clearable** 属性, 一旦清除，选择菜单将打开或保持打开，这个取决于当前状态"
      }
    },
    {
      "name": "outlined",
      "type": "boolean",
      "default": "false",
      "source": "v-text-field",
      "description": {
        "en": "Applies the outlined style to the input",
        "ja": "アウトラインスタイルを適用します。",
        "zh-Hans": "将轮廓样式应用于输入"
      }
    },
    {
      "name": "persistent-hint",
      "type": "boolean",
      "default": "false",
      "source": "v-input",
      "description": {
        "en": "Forces hint to always be visible",
        "ja": "ヒントを常に表示します",
        "zh-Hans": "强制提示总是可见的"
      }
    },
    {
      "name": "persistent-placeholder",
      "type": "boolean",
      "default": "false",
      "source": "v-text-field",
      "description": {
        "en": "Forces label to always be visible",
        "ja": "Forces label to always be visible",
        "zh-Hans": "Forces label to always be visible"
      }
    },
    {
      "name": "placeholder",
      "type": "string",
      "default": "undefined",
      "source": "v-text-field",
      "description": {
        "en": "Sets the input's placeholder text",
        "ja": "プレースホルダーを設定します",
        "zh-Hans": "设置输入的占位符文本"
      }
    },
    {
      "name": "prefix",
      "type": "string",
      "default": "undefined",
      "source": "v-text-field",
      "description": {
        "en": "Displays prefix text",
        "ja": "前につけるプレフィックステキストを表示します",
        "zh-Hans": "显示前缀"
      }
    },
    {
      "name": "prepend-icon",
      "type": "string",
      "default": "undefined",
      "source": "v-input",
      "description": {
        "en": "Prepends an icon to the component, uses the same syntax as `v-icon`",
        "ja": "コンポーネントの先頭にアイコンが追加されます。`v-icon` と同じ構文を使用します。",
        "zh-Hans": "在组件前添加一个图标，使用与 `v-icon` 相同的语法"
      }
    },
    {
      "name": "prepend-inner-icon",
      "type": "string",
      "default": "undefined",
      "source": "v-text-field",
      "description": {
        "en": "Prepends an icon inside the component's input, uses the same syntax as `v-icon`",
        "ja": "`v-icon`と同じ構文を使用して、入力コンポーネント内の先頭にアイコンを追加します",
        "zh-Hans": "在组件的输入中添加一个图标，使用与 `v-icon` 相同的语法"
      }
    },
    {
      "name": "readonly",
      "type": "boolean",
      "default": false,
      "source": "validatable",
      "description": {
        "en": "Puts input in readonly state",
        "ja": "読み取り専用状態に設定します。",
        "zh-Hans": "将输入设置为只读状态"
      }
    },
    {
      "name": "return-object",
      "type": "boolean",
      "default": "false",
      "source": "v-autocomplete",
      "description": {
        "en": "Changes the selection behavior to return the object directly rather than the value specified with **item-value**",
        "ja": "**item-value** で指定された値ではなく、オブジェクトを直接返すように選択動作を変更します。",
        "zh-Hans": "将选择器的行为更改为直接返回对象，而不是 item-value 指定的值"
      }
    },
    {
      "name": "reverse",
      "type": "boolean",
      "default": "false",
      "source": "v-text-field",
      "description": {
        "en": "Reverses the input orientation",
        "ja": "入力方向を反転します。",
        "zh-Hans": "反转输入方向"
      }
    },
    {
      "name": "rounded",
      "type": "boolean",
      "default": "false",
      "source": "v-text-field",
      "description": {
        "en": "Adds a border radius to the input",
        "ja": "Adds a border radius to the input",
        "zh-Hans": "向输入添加边框半径"
      }
    },
    {
      "name": "rules",
      "type": "array",
      "default": [],
      "source": "validatable",
      "description": {
        "en": "Accepts a mixed array of types `function`, `boolean` and `string`. Functions pass an input value as an argument and must return either `true` / `false` or a `string` containing an error message. The input field will enter an error state if a function returns (or any value in the array contains) `false` or is a `string`",
        "ja": "`function`, ` boolean`, `string`の混合配列を受け入れます。関数は入力値を引数として渡し、 `true` /`false`またはエラーメッセージを含む `string`のいずれかを返す必要があります。関数が `false`を返す（または配列内の任意の値に含まれる）場合、または`string`の場合、入力フィールドはエラー状態になります。",
        "zh-Hans": "接受不同类型的 `function`, `boolean` 和 `string` 。 函数传递输入值作为参数，必须返回 `true` / `false` 或包含错误消息的 `string` 。 如果函数返回 (或数组包含的任何值) false` 或 `string` ，输入字段将输入错误状态"
      }
    },
    {
      "name": "search-input",
      "type": "string",
      "default": "undefined",
      "source": "v-autocomplete",
      "description": {
        "en": "Search value. Can be used with `.sync` modifier.",
        "ja": "検索値。`.sync` 修飾子と共に使用できます。",
        "zh-Hans": "搜索值。可与 `.sync` 修改器一起使用。"
      }
    },
    {
      "name": "segmented",
      "type": "boolean",
      "default": "false",
      "source": "v-overflow-btn",
      "description": {
        "en": "Creates a segmented button",
        "ja": "区切り線つきのセグメント化されたボタンを作成します",
        "zh-Hans": "创建一个分段按钮"
      }
    },
    {
      "name": "shaped",
      "type": "boolean",
      "default": "false",
      "source": "v-text-field",
      "description": {
        "en": "Round if `outlined` and increase `border-radius` if `filled`. Must be used with either `outlined` or `filled`",
        "ja": "Round if `outlined` and increase `border-radius` if `filled`. Must be used with either `outlined` or `filled`",
        "zh-Hans": "如果 `outlined` 则为圆形，如果 `filled` 则增加 `border-radius`。必须与 `outlined` 或 `filled` 一起使用"
      }
    },
    {
      "name": "single-line",
      "type": "boolean",
      "default": "false",
      "source": "v-text-field",
      "description": {
        "en": "Label does not move on focus/dirty",
        "ja": "Label does not move on focus/dirty",
        "zh-Hans": "标签在 focus/dirty 上不移动"
      }
    },
    {
      "name": "small-chips",
      "type": "boolean",
      "default": "false",
      "source": "v-autocomplete",
      "description": {
        "en": "Changes display of selections to chips with the **small** property",
        "ja": "選択範囲の表示を **small** プロパティでchipに変更します",
        "zh-Hans": "使用 **small** 属性将选择的显示更改为纸片"
      }
    },
    {
      "name": "solo",
      "type": "boolean",
      "default": "false",
      "source": "v-text-field",
      "description": {
        "en": "Changes the style of the input",
        "ja": "入力のスタイルを変更",
        "zh-Hans": "改变输入框的样式"
      }
    },
    {
      "name": "solo-inverted",
      "type": "boolean",
      "default": "false",
      "source": "v-text-field",
      "description": {
        "en": "Reduces element opacity until focused",
        "ja": "フォーカスされるまで要素の不透明度を減らす",
        "zh-Hans": "减少元素的不透明度，直到获得焦点"
      }
    },
    {
      "name": "success",
      "type": "boolean",
      "default": "false",
      "source": "validatable",
      "description": {
        "en": "Puts the input in a manual success state",
        "ja": "入力を手動で成功状態にします。",
        "zh-Hans": "将输入设置为手动成功状态"
      }
    },
    {
      "name": "success-messages",
      "type": [
        "string",
        "array"
      ],
      "default": [],
      "source": "validatable",
      "description": {
        "en": "Puts the input in a success state and passes through custom success messages.",
        "ja": "入力をsuccess状態にして、カスタムの成功メッセージをパススルーさせます。",
        "zh-Hans": "将输入设置为成功状态，并传递自定义成功消息。"
      }
    },
    {
      "name": "suffix",
      "type": "string",
      "default": "undefined",
      "source": "v-text-field",
      "description": {
        "en": "Displays suffix text",
        "ja": "後につけるサフィックステキストを表示します",
        "zh-Hans": "显示后缀"
      }
    },
    {
      "name": "validate-on-blur",
      "type": "boolean",
      "default": "false",
      "source": "validatable",
      "description": {
        "en": "Delays validation until blur event",
        "ja": "blurイベントまでバリデーションを遅らせる",
        "zh-Hans": "延迟验证直到失去焦点的事件被触发"
      }
    },
    {
      "name": "value",
      "type": "any",
      "source": "v-input",
      "description": {
        "en": "The input's value",
        "ja": "入力の値",
        "zh-Hans": "输入的值"
      }
    },
    {
      "name": "value-comparator",
      "type": "function",
      "default": "(a: any, b: any): boolean",
      "source": "v-select",
      "description": {
        "en": "The comparison algorithm used for values. [More info](https://github.com/vuetifyjs/vuetify/blob/v2-stable/packages/vuetify/src/util/helpers.ts)",
        "ja": "The comparison algorithm used for values. [More info](https://github.com/vuetifyjs/vuetify/blob/v2-stable/packages/vuetify/src/util/helpers.ts)",
        "zh-Hans": "The comparison algorithm used for values. [More info](https://github.com/vuetifyjs/vuetify/blob/v2-stable/packages/vuetify/src/util/helpers.ts)"
      }
    }
  ],
  "mixins": [
    "colorable",
    "comparable",
    "dependent",
    "filterable",
    "intersectable",
    "loadable",
    "registrable-inject",
    "themeable",
    "v-input",
    "v-text-field",
    "validatable"
  ],
  "slots": [
    {
      "name": "append",
      "source": "v-input",
      "description": {
        "en": "Adds an item inside the input and after input content",
        "ja": "入力内容と入力内容の後に項目を追加します",
        "zh-Hans": "在输入框内部，文字之后添加一个内容"
      }
    },
    {
      "name": "append-item",
      "source": "v-select",
      "description": {
        "en": "Adds an item after menu content",
        "ja": "メニューコンテンツの後に項目を追加します。",
        "zh-Hans": "在菜单内容后添加项目"
      }
    },
    {
      "name": "append-outer",
      "source": "v-text-field",
      "description": {
        "en": "Adds an item outside the input and after input content",
        "ja": "Adds an item outside the input and after input content",
        "zh-Hans": "在输入框外部，文字之后添加一个内容"
      }
    },
    {
      "name": "counter",
      "source": "v-text-field",
      "props": {
        "props": {
          "dark": "boolean",
          "light": "boolean",
          "max": "string | number",
          "value": "string"
        }
      },
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "item",
      "props": {
        "parent": "VueComponent",
        "item": "object",
        "on": "object // Only needed when providing your own v-list-item",
        "attrs": "object // Only needed when providing your own v-list-item"
      },
      "source": "v-select",
      "description": {
        "en": "Define a custom item appearance",
        "ja": "カスタムアイテムの外観を定義します",
        "zh-Hans": "定义自定义项外观"
      }
    },
    {
      "name": "label",
      "source": "v-input",
      "description": {
        "en": "Replaces the default label",
        "ja": "デフォルトのラベルを置き換えます",
        "zh-Hans": "替换默认标签"
      }
    },
    {
      "name": "message",
      "props": {
        "key": "number, // the messages index",
        "message": "string, // the message"
      },
      "source": "v-input",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "no-data",
      "source": "v-select",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "prepend",
      "source": "v-input",
      "description": {
        "en": "Adds an item outside the input and before input content",
        "ja": "入力の外側と入力の内容の前にアイテムを追加します",
        "zh-Hans": "在输入框外部，前面添加一个内容"
      }
    },
    {
      "name": "prepend-inner",
      "source": "v-text-field",
      "description": {
        "en": "Adds an item inside the input and before input content",
        "ja": "Adds an item inside the input and before input content",
        "zh-Hans": "在输入框内部，前面添加一个内容"
      }
    },
    {
      "name": "prepend-item",
      "source": "v-select",
      "description": {
        "en": "Adds an item before menu content",
        "ja": "メニューコンテンツの前に項目を追加します。",
        "zh-Hans": "在菜单内容之前添加项目"
      }
    },
    {
      "name": "progress",
      "source": "v-text-field",
      "description": {
        "en": "Slot for custom progress linear (displayed when **loading** prop is not equal to Boolean False)",
        "ja": "カスタムprogress linearのスロット（**loading** プロパティが真偽値 false でない場合に表示されます）",
        "zh-Hans": "自定义进度线的插槽（**loading**属性不等于布尔值False时显示)"
      }
    },
    {
      "name": "selection",
      "props": {
        "parent": "VueComponent",
        "item": "object",
        "index": "number",
        "select": "function",
        "selected": "boolean",
        "disabled": "boolean"
      },
      "source": "v-select",
      "description": {
        "en": "Define a custom selection appearance",
        "ja": "Define a custom selection appearance",
        "zh-Hans": "定义自定义选择器外观"
      }
    }
  ],
  "events": [
    {
      "name": "blur",
      "source": "v-text-field",
      "value": "Event",
      "description": {
        "en": "Emitted when the input is blurred",
        "ja": "入力を抜けたときに発生します",
        "zh-Hans": "当失去焦点时发出"
      }
    },
    {
      "name": "change",
      "source": "v-text-field",
      "value": "any",
      "description": {
        "en": "Emitted when the input is changed by user interaction",
        "ja": "ユーザーの操作によって値が変更されたときに発生します。",
        "zh-Hans": "当用户交互更改输入时发出"
      }
    },
    {
      "name": "click",
      "source": "v-input",
      "value": "MouseEvent",
      "description": {
        "en": "Emitted when input is clicked",
        "ja": "inputをクリックしたときに発生します",
        "zh-Hans": "在单击输入时发出"
      }
    },
    {
      "name": "click:append",
      "source": "v-input",
      "value": "Event",
      "description": {
        "en": "Emitted when appended icon is clicked",
        "ja": "アペンドアイコンがクリックされたときに発生します",
        "zh-Hans": "单击附加图标时发出"
      }
    },
    {
      "name": "click:append-outer",
      "source": "v-text-field",
      "value": "Event",
      "description": {
        "en": "Emitted when appended outer icon is clicked",
        "ja": "Emitted when appended outer icon is clicked",
        "zh-Hans": "单击附加的外部图标时发出"
      }
    },
    {
      "name": "click:clear",
      "source": "v-text-field",
      "value": "Event",
      "description": {
        "en": "Emitted when clearable icon clicked",
        "ja": "クリアアイコンをクリックしたときに発生します。",
        "zh-Hans": "单击可清除图标时发出"
      }
    },
    {
      "name": "click:prepend",
      "source": "v-input",
      "value": "Event",
      "description": {
        "en": "Emitted when prepended icon is clicked",
        "ja": "プリペンドアイコンがクリックされたときに発生します",
        "zh-Hans": "单击前置图标时发出"
      }
    },
    {
      "name": "click:prepend-inner",
      "source": "v-text-field",
      "value": "Event",
      "description": {
        "en": "Emitted when prepended inner icon is clicked",
        "ja": "Emitted when prepended inner icon is clicked",
        "zh-Hans": "单击前置的内部图标时发出"
      }
    },
    {
      "name": "focus",
      "source": "v-text-field",
      "value": "Event",
      "description": {
        "en": "Emitted when component is focused",
        "ja": "コンポーネントがフォーカスされている場合に発生します。",
        "zh-Hans": "组件聚焦时发出"
      }
    },
    {
      "name": "input",
      "source": "v-text-field",
      "value": "any",
      "description": {
        "en": "The updated bound model",
        "ja": "更新されたバウンドモデル",
        "zh-Hans": "被绑定模型的更新"
      }
    },
    {
      "name": "keydown",
      "source": "v-text-field",
      "value": "KeyboardEvent",
      "description": {
        "en": "Emitted when **any** key is pressed",
        "ja": "**any** キーが押されたときに発生します。",
        "zh-Hans": "按下 **任何** 键时发送"
      }
    },
    {
      "name": "mousedown",
      "source": "v-input",
      "value": "MouseEvent",
      "description": {
        "en": "Emitted when click is pressed",
        "ja": "クリック時、押された（pressed）ときに発生します",
        "zh-Hans": "单击时发出"
      }
    },
    {
      "name": "mouseup",
      "source": "v-input",
      "value": "MouseEvent",
      "description": {
        "en": "Emitted when click is released",
        "ja": "クリック後、放された（released）ときに発生します",
        "zh-Hans": "当单击释放时，发出"
      }
    },
    {
      "name": "update:error",
      "source": "validatable",
      "value": "boolean",
      "description": {
        "en": "The `error.sync` event",
        "ja": "`error.sync` イベント",
        "zh-Hans": "`error.sync` 事件"
      }
    },
    {
      "name": "update:list-index",
      "source": "v-select",
      "value": "number",
      "description": {
        "en": "Emitted when menu item is selected using keyboard arrows",
        "ja": "キーボードの矢印を使用してメニュー項目を選択するときに発生します",
        "zh-Hans": "使用键盘箭头选择菜单项时发出"
      }
    },
    {
      "name": "update:search-input",
      "source": "v-select",
      "value": "string",
      "description": {
        "en": "The `search-input.sync` event",
        "ja": "`search-input.sync` イベント",
        "zh-Hans": "`search-input.sync` 事件"
      }
    }
  ],
  "functions": [],
  "exclude": {
    "props": [
      "type",
      "hide-spin-buttons"
    ]
  },
  "name": "v-overflow-btn",
  "sass": [
    {
      "name": "$overflow-active-slot-border-radius",
      "default": "$border-radius-root $border-radius-root 0 0 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$overflow-append-inner-height",
      "default": "48px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$overflow-append-inner-width",
      "default": "42px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$overflow-append-prepend-margin-bottom",
      "default": "12px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$overflow-append-prepend-margin-top",
      "default": "12px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$overflow-dense-input-margin-x",
      "default": "16px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$overflow-dense-slot-height",
      "default": "38px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$overflow-focused-active-border-radius",
      "default": "$border-radius-root !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$overflow-focused-active-slot-box-shadow",
      "default": "0 1px 6px 0 rgba(32,33,36,0.28) !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$overflow-focused-active-slot-elevation",
      "default": "2 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$overflow-input-slot-border-width",
      "default": "2px 0 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$overflow-label-margin-x",
      "default": "16px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$overflow-label-top",
      "default": "calc(50% - 10px) !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$overflow-margin-top",
      "default": "12px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$overflow-menu-content-box-shadow",
      "default": "0 4px 6px 0 rgba(32,33,36,0.28) !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$overflow-menu-content-select-list-border-radius",
      "default": "0 0 $border-radius-root $border-radius-root !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$overflow-segmented-input-slot-border-width",
      "default": "thin 0 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$overflow-segmented-selections-btn-height",
      "default": "48px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$overflow-segmented-selections-btn-margin-x",
      "default": "-16px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$overflow-selection-comma-margin-x",
      "default": "16px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$overflow-slot-height",
      "default": "48px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$overflow-editable-select-slot-padding",
      "default": "8px 16px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "component": true
}