module.exports = {
  "props": [
    {
      "name": "avatar",
      "type": "boolean",
      "default": "false",
      "source": "v-badge",
      "description": {
        "en": "Removes badge padding for the use of the `v-avatar` in the **badge** slot.",
        "ja": "**badge**スロットで `v-avatar` を使用するためのバッジパディングを削除します。",
        "zh-Hans": "去掉了 **badge** 插槽中使用 `v-avatar` 的徽章边距。"
      }
    },
    {
      "name": "bordered",
      "type": "boolean",
      "default": "false",
      "source": "v-badge",
      "description": {
        "en": "Applies a **2px** by default and **1.5px** border around the badge when using the **dot** property.",
        "ja": "**dot**プロパティを使用する場合、デフォルトで**2px**およびバッジの周囲に**1.5px**のボーダーを適用します。",
        "zh-Hans": "当使用 **dot** 属性时，默认情况下会在徽章上应用一个 **2px** 和它的周围应用一个 **1.5px** 的边框。"
      }
    },
    {
      "name": "bottom",
      "type": "boolean",
      "default": "false",
      "source": "positionable",
      "description": {
        "en": "Aligns the component towards the bottom.",
        "ja": "コンポーネントを下部に配置します。",
        "zh-Hans": "将组件向底部对齐。"
      }
    },
    {
      "name": "color",
      "type": "string",
      "default": "'primary'",
      "source": "colorable",
      "description": {
        "en": "Applies specified color to the control - it can be the name of material color (for example `success` or `purple`) or css color (`#033` or `rgba(255, 0, 0, 0.5)`). You can find a list of built-in classes on the [colors page](/styles/colors#material-colors).",
        "ja": "指定された色をコントロールに適用します。利用できるのはマテリアルカラーの名前 (たとえば、`success` または ` purple`) または css 表記の色 (`#033`または` rgba(255, 0, 0, 0.5) `) です。[colors のページ](/styles/colors#material-colors) で、組み込みクラスのリストが確認できます。",
        "zh-Hans": "指定控件颜色，颜色值可以是 Material 颜色（例如 <code>success</code>或者 <code>purple</code> ）也可以是 Css 颜色 （例如 <code>#033</code> 或者 <code>rgba(255, 0, 0, 0.5)</code>）。在 [colors page](/zh-Hans/styles/colors#material-colors)中可以找到内置颜色类列表。"
      }
    },
    {
      "name": "content",
      "type": "any",
      "default": "undefined",
      "source": "v-badge",
      "description": {
        "en": "Any content you want injected as text into the badge.",
        "ja": "バッジにテキストとして挿入するコンテンツ。",
        "zh-Hans": "任何你想要的内容都可以作为文字注入到徽章中。"
      }
    },
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにダークテーマを適応します。詳しく知りたい場合は、Material Design documentationの<a href=\"https://material.io/design/color/dark-theme.html\">dark themes</a>を参照してください。",
        "zh-Hans": "将暗色主题变量应用到组件。你可以在 [dark themes](https://material.io/design/color/dark-theme.html) 的 Material Design 文档中找到更多有关信息。"
      }
    },
    {
      "name": "dot",
      "type": "boolean",
      "default": "false",
      "source": "v-badge",
      "description": {
        "en": "Reduce the size of the badge and hide its contents",
        "ja": "バッジのサイズを縮小し、その内容を非表示にします",
        "zh-Hans": "缩小徽章的尺寸并隐藏其内容"
      }
    },
    {
      "name": "icon",
      "type": "string",
      "default": "undefined",
      "source": "v-badge",
      "description": {
        "en": "Designates a specific icon used in the badge.",
        "ja": "バッジで使用される特定のアイコンを指定します。",
        "zh-Hans": "指定徽章中使用的具体图标。"
      }
    },
    {
      "name": "inline",
      "type": "boolean",
      "default": "false",
      "source": "v-badge",
      "description": {
        "en": "Moves the badge to be inline with the wrapping element. Supports the usage of the **left** prop.",
        "ja": "ラッピング要素とインラインになるようにバッジを移動します。 **left** prop の使用をサポートします。",
        "zh-Hans": "移动徽章，使其与包装元素保持一致。支持使用 **left** prop。"
      }
    },
    {
      "name": "label",
      "type": "string",
      "default": "'$vuetify.badge'",
      "source": "v-badge",
      "description": {
        "en": "The **aria-label** used for the badge",
        "ja": "バッジに使用される **aria-label** ",
        "zh-Hans": "用于徽章的 **aria-label**"
      }
    },
    {
      "name": "left",
      "type": "boolean",
      "default": "false",
      "source": "positionable",
      "description": {
        "en": "Aligns the component towards the left.",
        "ja": "コンポーネントを左に配置します。",
        "zh-Hans": "将组件向左边对齐。"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    },
    {
      "name": "mode",
      "type": "string",
      "default": "undefined",
      "source": "transitionable",
      "description": {
        "en": "Sets the transition mode (does not apply to transition-group). You can find more information on the Vue documentation [for transition modes](https://vuejs.org/v2/api/#transition).",
        "ja": "トランジション モードを設定します(transition-groupには適用されません)。詳細については、Vue のドキュメント [トランジションモード](https://jp.vuejs.org/v2/api/#transition) を参照してください。",
        "zh-Hans": "设置转换模式（不应用于转换组）。你可以在 Vue 文档浏览更多 [for transition modes](https://vuejs.org/v2/api/#transition)。"
      }
    },
    {
      "name": "offset-x",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "v-badge",
      "description": {
        "en": "Offset the badge on the x-axis.",
        "ja": "X軸のオフセット",
        "zh-Hans": "将徽章在 x 轴上偏移。"
      }
    },
    {
      "name": "offset-y",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "v-badge",
      "description": {
        "en": "Offset the badge on the y-axis.",
        "ja": "Y軸のオフセット",
        "zh-Hans": "将徽章在 y 轴上偏移。"
      }
    },
    {
      "name": "origin",
      "type": "string",
      "default": "undefined",
      "source": "transitionable",
      "description": {
        "en": "Sets the transition origin on the element. You can find more information on the MDN documentation [for transition origin](https://developer.mozilla.org/en-US/docs/Web/CSS/transform-origin).",
        "ja": "要素にトランジションの起点を設定します。 MDNドキュメントで [for transition origin](https://developer.mozilla.org/ja/docs/Web/CSS/transform-origin) の詳細を参照できます。",
        "zh-Hans": "设置元素上的动画原点。您可以在 MDN 文档上浏览更多 [for transition origin](https://developer.mozilla.org/en-US/docs/Web/CSS/transform-origin)"
      }
    },
    {
      "name": "overlap",
      "type": "boolean",
      "default": "false",
      "source": "v-badge",
      "description": {
        "en": "Overlaps the slotted content on top of the component.",
        "ja": "コンポーネントの上にスロットされたコンテンツをオーバーラップします。",
        "zh-Hans": "与组件顶部的开槽内容重叠。"
      }
    },
    {
      "name": "tile",
      "type": "boolean",
      "default": "false",
      "source": "v-badge",
      "description": {
        "en": "Removes the component's border-radius.",
        "ja": "コンポーネントの角丸を除去します。",
        "zh-Hans": "删除组件的 border-radius。"
      }
    },
    {
      "name": "transition",
      "type": "string",
      "default": "'scale-rotate-transition'",
      "source": "transitionable",
      "description": {
        "en": "Sets the component transition. Can be one of the [built in transitions](/styles/transitions) or one your own.",
        "ja": "コンポーネントのトランジションを設定します。 [組み込みトランジション](/styles/transitions) のいずれか、または独自のものを使用できます。",
        "zh-Hans": "设置组件转换。可以是一个 [built in transitions](/styles/transitions) 或者是自己自定义的。"
      }
    },
    {
      "name": "value",
      "type": "any",
      "default": true,
      "source": "toggleable",
      "description": {
        "en": "Controls whether the component is visible or hidden.",
        "ja": "コンポーネントを表示するか非表示にするかを制御します。",
        "zh-Hans": "控制组件可见还是隐藏。"
      }
    }
  ],
  "mixins": [
    "colorable",
    "positionable",
    "themeable",
    "toggleable",
    "transitionable"
  ],
  "slots": [
    {
      "name": "badge",
      "description": {
        "en": "The slot used for the badge's content.",
        "ja": "バッジのコンテンツに使用されているスロット。",
        "zh-Hans": "用于标记内容的插槽。"
      }
    },
    {
      "name": "default",
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    }
  ],
  "events": [],
  "functions": [],
  "name": "v-badge",
  "sass": [
    {
      "name": "$badge-border-radius",
      "default": "10px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$badge-bordered-width",
      "default": "2px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$badge-color",
      "default": "map-get($shades, 'white') !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$badge-dot-border-radius",
      "default": "4.5px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$badge-dot-border-width",
      "default": "1.5px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$badge-dot-height",
      "default": "9px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$badge-dot-width",
      "default": "9px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$badge-font-family",
      "default": "$body-font-family !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$badge-font-size",
      "default": "12px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$badge-height",
      "default": "20px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$badge-icon-margin",
      "default": "0 -2px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$badge-icon-padding",
      "default": "4px 6px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$badge-letter-spacing",
      "default": "0 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$badge-line-height",
      "default": "1 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$badge-min-width",
      "default": "20px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$badge-padding",
      "default": "4px 6px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$badge-right",
      "default": "auto !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$badge-top",
      "default": "auto !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$badge-wrapper-margin",
      "default": "0 4px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "component": true
}