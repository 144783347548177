module.exports = {
  "props": [
    {
      "name": "activator",
      "type": "any",
      "default": "undefined",
      "source": "activatable",
      "description": {
        "en": "Designate a custom activator when the `activator` slot is not used. String can be any valid querySelector and Object can be any valid Node.",
        "ja": "`activator` slot が使われていない場合、カスタムの activator を指定します。String には任意の有効な querySelector が、Object には任意の有効な Node が指定できます。",
        "zh-Hans": "当未使用 `activator` 插槽时，指定自定义激活器。String 可以是任何有效的 querySelector，对象可以是任何有效的 Node。"
      }
    },
    {
      "name": "attach",
      "type": "any",
      "default": false,
      "source": "detachable",
      "description": {
        "en": "Specifies which DOM element that this component should detach to. String can be any valid querySelector and Object can be any valid Node. This will attach to the root `v-app` component by default.",
        "ja": "このコンポーネントが切り離すべき DOM 要素を指定します。String には任意の有効な querySelector、Object には任意の有効な Node を指定します。デフォルトではルートの `v-app` コンポーネントにアタッチされます。",
        "zh-Hans": "使组件脱离当前节点并且指向一个新的DOM元素。可以是任何document. querySelector 有效的字符串或者任何有效的节点。将默认指定并附加到根节点`v-app`下。"
      }
    },
    {
      "name": "close-delay",
      "type": [
        "number",
        "string"
      ],
      "default": 0,
      "source": "delayable",
      "description": {
        "en": "Milliseconds to wait before closing component. Only applies to hover and focus events.",
        "ja": "Milliseconds to wait before closing component. Only applies to hover and focus events.",
        "zh-Hans": "组件关闭前等待的毫秒数。仅应用于悬停和焦点事件。"
      }
    },
    {
      "name": "content-class",
      "type": "string",
      "default": "undefined",
      "source": "detachable",
      "description": {
        "en": "Applies a custom class to the detached element. This is useful because the content is moved to the beginning of the `v-app` component (unless the **attach** prop is provided) and is not targetable by classes passed directly on the component.",
        "ja": "デタッチされた要素にカスタムクラスを適用します。コンテンツが `v-app`コンポーネントの先頭に移動され(**attach** propが与えられない限り)、コンポーネントに直接渡されたクラスが対象とならないので便利です。",
        "zh-Hans": "将自定义的class应用于被分离的此元素。由于此元素的内容将被附加到`v-app`组件开头(除非你设置了**attach**属性)，并且class属性不会将css类直接传递给组件，所以这个实行非常有用。"
      }
    },
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "v-dialog",
      "description": {
        "en": "Applies the dark theme variant to the component. This will default the components color to _white_ unless you've configured your [application theme](/customization/theme) to **dark** or if you are using the **color** prop on the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにdarkテーマのバリエーションを適用します。[アプリケーションのテーマ](/customization/theme) を **dark** に設定している場合や、コンポーネントに **color** propを使用している場合を除き、コンポーネントの色はデフォルトで _white_ になります。Material Design documentationの[ダークテーマ](https://material.io/design/color/dark-theme.html) に詳しい情報があります。",
        "zh-Hans": "将深色主题变量应用于组件。 除非您已将 [application theme](/customization/theme) 配置为 **dark** 或在组件上使用 **color** 属性，否则组件的颜色默认为 _white_。 您可以在材料设计文档中找到更多信息 [dark themes](https://material.io/design/color/dark-theme.html)。"
      }
    },
    {
      "name": "disabled",
      "type": "boolean",
      "default": "false",
      "source": "activatable",
      "description": {
        "en": "Disables the ability to open the component.",
        "ja": "コンポーネントを open できる状態を無効化します。",
        "zh-Hans": "禁用打开组件的能力。"
      }
    },
    {
      "name": "eager",
      "type": "boolean",
      "default": "false",
      "source": "bootable",
      "description": {
        "en": "Will force the components content to render on mounted. This is useful if you have content that will not be rendered in the DOM that you want crawled for SEO.",
        "ja": "コンポーネントのコンテンツを mounted 時に render することを強制します。この機能は、SEO のために crawl させたい DOM の中に render されないコンテンツを持っているときに役に立ちます。",
        "zh-Hans": "将强制组件内容在加载时呈现。如果存在内容的话，则不会在 DOM 中渲染，如果你想优化 SEO，这是非常有用的。"
      }
    },
    {
      "name": "fullscreen",
      "type": "boolean",
      "default": "false",
      "source": "v-dialog",
      "description": {
        "en": "Changes layout for fullscreen display.",
        "ja": "フルスクリーン表示になるようレイアウトを変更します。",
        "zh-Hans": "改变全屏显示的布局。"
      }
    },
    {
      "name": "hide-overlay",
      "type": "boolean",
      "default": "false",
      "source": "overlayable",
      "description": {
        "en": "Hides the display of the overlay.",
        "ja": "オーバーレイを非表示にします。",
        "zh-Hans": "隐藏遮罩层的显示."
      }
    },
    {
      "name": "internal-activator",
      "type": "boolean",
      "default": "false",
      "source": "activatable",
      "description": {
        "en": "Detaches the menu content inside of the component as opposed to the document.",
        "ja": "ドキュメントではなく、コンポーネント内のメニューコンテンツを切り離します。",
        "zh-Hans": "与文档相反，分离组件内部的菜单内容。"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "v-dialog",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    },
    {
      "name": "max-width",
      "type": [
        "string",
        "number"
      ],
      "default": "undefined",
      "source": "v-dialog",
      "description": {
        "en": "Sets the maximum width for the component.",
        "ja": "コンテンツの横幅の最大値を設定します。",
        "zh-Hans": "设定组件的最大宽度。"
      }
    },
    {
      "name": "no-click-animation",
      "type": "boolean",
      "default": "false",
      "source": "v-dialog",
      "description": {
        "en": "Disables the bounce effect when clicking outside of a `v-dialog`'s content when using the **persistent** prop.",
        "ja": "**persistent** propを使用時 `v-dialog`のコンテンツの外側をクリックしても、バウンス効果が無効になります。",
        "zh-Hans": "使用 **persistent** 属性，在 `v-dialog` 的内容之外点击时，禁用弹跳效果。"
      }
    },
    {
      "name": "open-delay",
      "type": [
        "number",
        "string"
      ],
      "default": 0,
      "source": "delayable",
      "description": {
        "en": "Milliseconds to wait before opening component. Only applies to hover and focus events.",
        "ja": "コンポーネントを開くまでのミリ秒を指定。hoverとfocusイベントのみに反映します。",
        "zh-Hans": "组件打开前等待的毫秒数。仅应用于悬停和焦点事件。"
      }
    },
    {
      "name": "open-on-click",
      "type": "boolean",
      "default": "true",
      "source": "activatable",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "open-on-focus",
      "type": "boolean",
      "default": "false",
      "source": "activatable",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "origin",
      "type": "string",
      "default": "'center center'",
      "source": "v-dialog",
      "description": {
        "en": "Sets the transition origin on the element. You can find more information on the MDN documentation [for transition origin](https://developer.mozilla.org/en-US/docs/Web/CSS/transform-origin).",
        "ja": "要素にトランジションの起点を設定します。 MDNドキュメントで [for transition origin](https://developer.mozilla.org/ja/docs/Web/CSS/transform-origin) の詳細を参照できます。",
        "zh-Hans": "设置元素上的动画原点。您可以在 MDN 文档上浏览更多 [for transition origin](https://developer.mozilla.org/en-US/docs/Web/CSS/transform-origin)"
      }
    },
    {
      "name": "overlay-color",
      "type": "string",
      "default": "undefined",
      "source": "overlayable",
      "description": {
        "en": "Sets the overlay color.",
        "ja": "オーバーレイの色を設定します。",
        "zh-Hans": "设置遮罩层的颜色。"
      }
    },
    {
      "name": "overlay-opacity",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "overlayable",
      "description": {
        "en": "Sets the overlay opacity.",
        "ja": "オーバーレイの不透明度を設定します。",
        "zh-Hans": "设置遮罩层的透明度。"
      }
    },
    {
      "name": "persistent",
      "type": "boolean",
      "default": "false",
      "source": "v-dialog",
      "description": {
        "en": "Clicking outside of the element or pressing **esc** key will not deactivate it.",
        "ja": "要素の外側をクリックしたり、 **esc** キーを押したりしても、要素を無効にすることはできません。",
        "zh-Hans": "点击元素外面或按下 **esc** 键将不会关闭它。"
      }
    },
    {
      "name": "retain-focus",
      "type": "boolean",
      "default": "true",
      "source": "v-dialog",
      "description": {
        "en": "Tab focus will return to the first child of the dialog by default. Disable this when using external tools that require focus such as TinyMCE or vue-clipboard.",
        "ja": "タブフォーカスは、デフォルトでダイアログの最初の子に戻ります。TinyMCE や vue-clipboardなどのフォーカスが必要な外部ツールを使用する場合は、これを無効にします。",
        "zh-Hans": "默认情况下，制表符焦点将返回到对话框的第一个子级。在使用需要焦点的外部工具（如 TinyMCE 或 vue-clipboard）时禁用此选项。"
      }
    },
    {
      "name": "return-value",
      "type": "any",
      "default": "undefined",
      "source": "returnable",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "scrollable",
      "type": "boolean",
      "default": "false",
      "source": "v-dialog",
      "description": {
        "en": "When set to true, expects a `v-card` and a `v-card-text` component with a designated height. For more information, check out the [scrollable example](/components/dialogs#scrollable).",
        "ja": "true に設定すると、指定された高さの `v-card` と `v-card-text` コンポーネントが必要になります。詳細については、[scrollableの例](/components/dialogs#scrollable) をチェックしてください。",
        "zh-Hans": "当设置为 true 时，期望有一个 `v-card` 和一个 `v-card-text` 组件，并有指定的高度。更多信息，请查看 [可滚动示例](/components/dialogs#scrollable)。"
      }
    },
    {
      "name": "transition",
      "type": [
        "string",
        "boolean"
      ],
      "default": "dialog-transition",
      "source": "v-dialog",
      "description": {
        "en": "Sets the component transition. Can be one of the [built in transitions](/styles/transitions) or one your own.",
        "ja": "コンポーネントのトランジションを設定します。 [組み込みトランジション](/styles/transitions) のいずれか、または独自のものを使用できます。",
        "zh-Hans": "设置组件转换。可以是一个 [built in transitions](/styles/transitions) 或者是自己自定义的。"
      }
    },
    {
      "name": "value",
      "type": "any",
      "default": "undefined",
      "source": "toggleable",
      "description": {
        "en": "Controls whether the component is visible or hidden.",
        "ja": "コンポーネントを表示するか非表示にするかを制御します。",
        "zh-Hans": "控制组件可见还是隐藏。"
      }
    },
    {
      "name": "width",
      "type": [
        "string",
        "number"
      ],
      "default": "undefined",
      "source": "v-dialog",
      "description": {
        "en": "Sets the width for the component.",
        "ja": "コンポーネントに横幅を設定します。",
        "zh-Hans": "设定组件的宽度。"
      }
    }
  ],
  "mixins": [
    "activatable",
    "bootable",
    "delayable",
    "dependent",
    "detachable",
    "overlayable",
    "returnable",
    "stackable",
    "toggleable"
  ],
  "slots": [
    {
      "name": "activator",
      "props": {
        "attrs": "{ role: string, aria-haspopup: boolean, aria-expanded: string }",
        "on": "{ [eventName]: eventHandler }",
        "value": "boolean"
      },
      "description": {
        "en": "When used, will activate the component when clicked (or hover for specific components). This manually stops the event propagation. Without this slot, if you open the component through its model, you will need to manually stop the event propagation",
        "ja": "使用すると、クリック時 (または特定のコンポーネントではホバー時) にコンポーネントを有効にします。これにより手動でイベントの propagation を stop されます。この slot がない場合、モデル経由でコンポーネントを開いたときには、自分でイベントの propagation を stop する必要があります。",
        "zh-Hans": "使用时，将在点击后激活组件（或悬停特定组件），它手动阻止事件传播。如果通过模型打开组件而没有此插槽，则需要手动阻止事件传播。"
      }
    },
    {
      "name": "default",
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    }
  ],
  "events": [
    {
      "name": "click:outside",
      "value": "MouseEvent",
      "description": {
        "en": "Event that fires when clicking outside an active dialog.",
        "ja": "アクティブなダイアログの外側をクリックしたときに発生するイベント",
        "zh-Hans": "在活动对话框外部单击时激发的事件。"
      }
    },
    {
      "name": "input",
      "value": "Boolean",
      "description": {
        "en": "The updated bound model",
        "ja": "更新されたバウンドモデル",
        "zh-Hans": "被绑定模型的更新"
      }
    },
    {
      "name": "keydown",
      "value": "KeyboardEvent",
      "description": {
        "en": "Event that fires when key is pressed. If dialog is active and not using the **persistent** prop, the **esc** key will deactivate it.",
        "ja": "キーが押されたときに発生するイベント。ダイアログがアクティブで、**persistent** propを使用していない場合、**esc** キーは無効になります。",
        "zh-Hans": "按下 key 时激发的事件。如果对话处于激活状态且未使用 **persistent** 属性，则 **esc** 键会将其停用。"
      }
    }
  ],
  "functions": [],
  "exclude": {
    "props": [
      "open-on-hover"
    ]
  },
  "name": "v-dialog",
  "sass": [
    {
      "name": "$dialog-border-radius",
      "default": "$border-radius-root !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$dialog-card-subtitle-padding",
      "default": "0 24px 20px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$dialog-card-actions-padding",
      "default": "8px 16px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$dialog-card-text-padding",
      "default": "0 24px 20px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$dialog-card-title-font-size",
      "default": "map-deep-get($headings, 'h6', 'size') !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$dialog-card-title-font-weight",
      "default": "map-deep-get($headings, 'h6', 'weight') !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$dialog-card-title-letter-spacing",
      "default": "map-deep-get($headings, 'h6', 'letter-spacing') !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$dialog-card-title-padding",
      "default": "16px 24px 10px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$dialog-elevation",
      "default": "24 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$dialog-margin",
      "default": "24px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$dialog-max-height",
      "default": "90% !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "component": true
}