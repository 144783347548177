<template>
  <div>
    <v-alert type="info">
      Related Topics
    </v-alert>
  </div>
</template>

<script>
  export default { name: 'RelatedTopics' }
</script>
