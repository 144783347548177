module.exports = {
  "props": [
    {
      "name": "active-class",
      "type": "string",
      "source": "groupable",
      "description": {
        "en": "Configure the active CSS class applied when the link is active. You can find more information about the [**active-class** prop](https://router.vuejs.org/api/#active-class) on the vue-router documentation.",
        "ja": "リンクがアクティブなときに適用されるアクティブなCSSクラスを構成します。vue-routerのドキュメントで [**active-class** prop](https://router.vuejs.org/ja/api/#active-class) の詳細をご覧ください。",
        "zh-Hans": "配置在链接激活时应用的 CSS 类。你能够在 vue-router 文档浏览更多 [**active-class** prop](https://router.vuejs.org/api/#active-class)。"
      }
    },
    {
      "name": "color",
      "type": "string",
      "default": "undefined",
      "source": "colorable",
      "description": {
        "en": "Applies specified color to the control - it can be the name of material color (for example `success` or `purple`) or css color (`#033` or `rgba(255, 0, 0, 0.5)`). You can find a list of built-in classes on the [colors page](/styles/colors#material-colors).",
        "ja": "指定された色をコントロールに適用します。利用できるのはマテリアルカラーの名前 (たとえば、`success` または ` purple`) または css 表記の色 (`#033`または` rgba(255, 0, 0, 0.5) `) です。[colors のページ](/styles/colors#material-colors) で、組み込みクラスのリストが確認できます。",
        "zh-Hans": "指定控件颜色，颜色值可以是 Material 颜色（例如 <code>success</code>或者 <code>purple</code> ）也可以是 Css 颜色 （例如 <code>#033</code> 或者 <code>rgba(255, 0, 0, 0.5)</code>）。在 [colors page](/zh-Hans/styles/colors#material-colors)中可以找到内置颜色类列表。"
      }
    },
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにダークテーマを適応します。詳しく知りたい場合は、Material Design documentationの<a href=\"https://material.io/design/color/dark-theme.html\">dark themes</a>を参照してください。",
        "zh-Hans": "将暗色主题变量应用到组件。你可以在 [dark themes](https://material.io/design/color/dark-theme.html) 的 Material Design 文档中找到更多有关信息。"
      }
    },
    {
      "name": "disabled",
      "type": "boolean",
      "default": false,
      "source": "validatable",
      "description": {
        "en": "Removes the ability to click or target the component.",
        "ja": "コンポーネントをクリックまたはターゲットにできなくします。",
        "zh-Hans": "移除组件的单击或 target 功能。"
      }
    },
    {
      "name": "id",
      "type": "string",
      "default": "undefined",
      "source": "v-radio",
      "description": {
        "en": "Sets the DOM id on the component",
        "ja": "コンポーネントにDOM idを設定します",
        "zh-Hans": "设置组件上的 DOM id"
      }
    },
    {
      "name": "label",
      "type": "string",
      "source": "v-input",
      "description": {
        "en": "Sets input label",
        "ja": "ラベルを設定します。",
        "zh-Hans": "设置输入标签"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    },
    {
      "name": "name",
      "type": "string",
      "default": "undefined",
      "source": "v-radio",
      "description": {
        "en": "Sets the component's name attribute",
        "ja": "コンポーネントのname属性を設定します",
        "zh-Hans": "设置组件的 name 属性"
      }
    },
    {
      "name": "off-icon",
      "type": "string",
      "default": "'$radioOff'",
      "source": "v-radio",
      "description": {
        "en": "The icon used when inactive",
        "ja": "非アクティブ時に使用されるアイコン",
        "zh-Hans": "不活动时使用的图标"
      }
    },
    {
      "name": "on-icon",
      "type": "string",
      "default": "'$radioOn'",
      "source": "v-radio",
      "description": {
        "en": "The icon used when active",
        "ja": "アクティブなときに使用されるアイコン",
        "zh-Hans": "激活时使用的图标"
      }
    },
    {
      "name": "readonly",
      "type": "boolean",
      "default": false,
      "source": "validatable",
      "description": {
        "en": "Puts input in readonly state",
        "ja": "読み取り専用状態に設定します。",
        "zh-Hans": "将输入设置为只读状态"
      }
    },
    {
      "name": "ripple",
      "type": [
        "boolean",
        "object"
      ],
      "default": true,
      "source": "rippleable",
      "description": {
        "en": "Applies the [v-ripple](/directives/ripple) directive.",
        "ja": "[v-ripple](/directives/ripple) ディレクティブを適用します。",
        "zh-Hans": "应用 [v-ripple](/directives/ripples) 指令。"
      }
    },
    {
      "name": "value",
      "type": "any",
      "source": "v-input",
      "description": {
        "en": "The value used when the component is selected in a group. If not provided, the index will be used.",
        "ja": "コンポーネントがグループ内で選択されたときに使われる値です。もし指定しなかった場合、index が使用されます。",
        "zh-Hans": "在组中选择组件时使用的值。如果没有提供，则使用 index。"
      }
    }
  ],
  "mixins": [
    "colorable",
    "groupable",
    "rippleable",
    "themeable"
  ],
  "slots": [
    {
      "name": "default",
      "source": "v-input",
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    },
    {
      "name": "label",
      "source": "v-input",
      "description": {
        "en": "Replaces the default label",
        "ja": "デフォルトのラベルを置き換えます",
        "zh-Hans": "替换默认标签"
      }
    },
    {
      "name": "message",
      "props": {
        "key": "number, // the messages index",
        "message": "string, // the message"
      },
      "source": "v-input",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "events": [
    {
      "name": "change",
      "value": "any",
      "description": {
        "en": "Emitted when the input is changed by user interaction",
        "ja": "ユーザーの操作によって値が変更されたときに発生します。",
        "zh-Hans": "当用户交互更改输入时发出"
      }
    },
    {
      "name": "click",
      "value": "MouseEvent",
      "description": {
        "en": "Emitted when input is clicked. **Note:** the **change** event should be used instead of **click** when monitoring state change",
        "ja": "クリックされたときに発生します。**注意:** 状態変化を監視する場合は **click** の代わりに **change** イベントを使用してください。",
        "zh-Hans": "单击输入时触发。**注意：** 监视状态变化时，应使用 **change** 事件，而不是 **click** 事件。"
      }
    },
    {
      "name": "click:append",
      "source": "v-input",
      "value": "Event",
      "description": {
        "en": "Emitted when appended icon is clicked",
        "ja": "アペンドアイコンがクリックされたときに発生します",
        "zh-Hans": "单击附加图标时发出"
      }
    },
    {
      "name": "click:prepend",
      "source": "v-input",
      "value": "Event",
      "description": {
        "en": "Emitted when prepended icon is clicked",
        "ja": "プリペンドアイコンがクリックされたときに発生します",
        "zh-Hans": "单击前置图标时发出"
      }
    },
    {
      "name": "mousedown",
      "source": "v-input",
      "value": "MouseEvent",
      "description": {
        "en": "Emitted when click is pressed",
        "ja": "クリック時、押された（pressed）ときに発生します",
        "zh-Hans": "单击时发出"
      }
    },
    {
      "name": "mouseup",
      "source": "v-input",
      "value": "MouseEvent",
      "description": {
        "en": "Emitted when click is released",
        "ja": "クリック後、放された（released）ときに発生します",
        "zh-Hans": "当单击释放时，发出"
      }
    },
    {
      "name": "update:error",
      "source": "validatable",
      "value": "boolean",
      "description": {
        "en": "The `error.sync` event",
        "ja": "`error.sync` イベント",
        "zh-Hans": "`error.sync` 事件"
      }
    }
  ],
  "functions": [],
  "exclude": {
    "props": [
      "type",
      "hide-spin-buttons"
    ]
  },
  "name": "v-radio",
  "sass": [],
  "component": true
}