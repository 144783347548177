module.exports = {
  "props": [
    {
      "name": "disable-items-per-page",
      "type": "boolean",
      "default": "false",
      "source": "v-data-footer",
      "description": {
        "en": "Disables items-per-page dropdown",
        "ja": "items-per-page ドロップダウンを無効にします。",
        "zh-Hans": "禁用每页下拉菜单项"
      }
    },
    {
      "name": "disable-pagination",
      "type": "boolean",
      "default": "false",
      "source": "v-data-footer",
      "description": {
        "en": "Disables pagination buttons",
        "ja": "ページネーションボタンを無効にします",
        "zh-Hans": "禁用分页按钮"
      }
    },
    {
      "name": "first-icon",
      "type": "string",
      "default": "'$first'",
      "source": "v-data-footer",
      "description": {
        "en": "First icon",
        "ja": "最初のアイコン",
        "zh-Hans": "第一个图标"
      }
    },
    {
      "name": "items-per-page-all-text",
      "type": "string",
      "default": "'$vuetify.dataFooter.itemsPerPageAll'",
      "source": "v-data-footer",
      "description": {
        "en": "Text for 'All' option in items-per-page dropdown",
        "ja": "items-per-page ドロップダウンメニューの 'All' オプションのテキスト",
        "zh-Hans": "每页下拉菜单中 'All' 选项的文本"
      }
    },
    {
      "name": "items-per-page-options",
      "type": "array",
      "default": [
        5,
        10,
        15,
        -1
      ],
      "source": "v-data-footer",
      "description": {
        "en": "Array of options to show in the items-per-page dropdown",
        "ja": "items-per-pageドロップダウンに表示するオプションの配列",
        "zh-Hans": "每页项目下拉列表中显示的选项数组"
      }
    },
    {
      "name": "items-per-page-text",
      "type": "string",
      "default": "'$vuetify.dataFooter.itemsPerPageText'",
      "source": "v-data-footer",
      "description": {
        "en": "Text for items-per-page dropdown",
        "ja": "items-per-pageドロップダウンのテキスト",
        "zh-Hans": "每页项目的文本下拉菜单"
      }
    },
    {
      "name": "last-icon",
      "type": "string",
      "default": "'$last'",
      "source": "v-data-footer",
      "description": {
        "en": "Last icon",
        "ja": "最後のアイコン",
        "zh-Hans": "最后一个图标"
      }
    },
    {
      "name": "next-icon",
      "type": "string",
      "default": "'$next'",
      "source": "v-data-footer",
      "description": {
        "en": "Next icon",
        "ja": "次のアイコン",
        "zh-Hans": "下一图标"
      }
    },
    {
      "name": "options",
      "type": "object",
      "default": "undefined",
      "source": "v-data-footer",
      "example": {
        "page": "number",
        "itemsPerPage": "number",
        "sortBy": "string[]",
        "sortDesc": "boolean[]",
        "groupBy": "string[]",
        "groupDesc": "boolean[]",
        "multiSort": "boolean",
        "mustSort": "boolean"
      },
      "description": {
        "en": "DataOptions",
        "ja": "DataOptions",
        "zh-Hans": "数据选项"
      }
    },
    {
      "name": "page-text",
      "type": "string",
      "default": "'$vuetify.dataFooter.pageText'",
      "source": "v-data-footer",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "pagination",
      "type": "object",
      "default": "undefined",
      "source": "v-data-footer",
      "example": {
        "page": "number",
        "itemsPerPage": "number",
        "pageStart": "number",
        "pageStop": "number",
        "pageCount": "number",
        "itemsLength": "number"
      },
      "description": {
        "en": "DataPagination",
        "ja": "DataPagination",
        "zh-Hans": "数据分页"
      }
    },
    {
      "name": "prev-icon",
      "type": "string",
      "default": "'$prev'",
      "source": "v-data-footer",
      "description": {
        "en": "Previous icon",
        "ja": "前のアイコン",
        "zh-Hans": "上一个图标"
      }
    },
    {
      "name": "show-current-page",
      "type": "boolean",
      "default": "false",
      "source": "v-data-footer",
      "description": {
        "en": "Show current page number between prev/next icons",
        "ja": "現在のページ番号を前後のアイコンの間に表示します",
        "zh-Hans": "在 上一个/下一个 图标之间显示当前页码"
      }
    },
    {
      "name": "show-first-last-page",
      "type": "boolean",
      "default": "false",
      "source": "v-data-footer",
      "description": {
        "en": "Show first/last icons",
        "ja": "最初/最後のアイコンを表示する",
        "zh-Hans": "显示 第一个/最后一个 图标"
      }
    }
  ],
  "mixins": [],
  "slots": [
    {
      "name": "page-text",
      "props": {
        "pageStart": "number",
        "pageStop": "number",
        "itemsLength": "number"
      },
      "description": {
        "en": "Defines content for the items-per-page text",
        "ja": "items-per-pageテキストのコンテンツを定義します。",
        "zh-Hans": "定义每页项目文本的内容"
      }
    },
    {
      "name": "prepend",
      "description": {
        "en": "Adds content to the empty space in the footer",
        "ja": "フッターの空きスペースにコンテンツを追加します",
        "zh-Hans": "将内容添加到表脚中的空空间"
      }
    }
  ],
  "events": [
    {
      "name": "update:options",
      "value": {
        "page": "number",
        "itemsPerPage": "number",
        "sortBy": "string[]",
        "sortDesc": "boolean[]",
        "groupBy": "string[]",
        "groupDesc": "boolean[]",
        "multiSort": "boolean",
        "mustSort": "boolean"
      },
      "description": {
        "en": "The `.sync` event for `options` prop",
        "ja": "`options`プロパティの`.sync`イベント",
        "zh-Hans": "`options` 属性的 `.sync` 事件"
      }
    }
  ],
  "functions": [],
  "name": "v-data-footer",
  "sass": [],
  "component": true
}