module.exports = {
  "props": [
    {
      "name": "color",
      "type": "string",
      "default": "'primary'",
      "source": "colorable",
      "description": {
        "en": "Applies specified color to the control - it can be the name of material color (for example `success` or `purple`) or css color (`#033` or `rgba(255, 0, 0, 0.5)`). You can find a list of built-in classes on the [colors page](/styles/colors#material-colors).",
        "ja": "指定された色をコントロールに適用します。利用できるのはマテリアルカラーの名前 (たとえば、`success` または ` purple`) または css 表記の色 (`#033`または` rgba(255, 0, 0, 0.5) `) です。[colors のページ](/styles/colors#material-colors) で、組み込みクラスのリストが確認できます。",
        "zh-Hans": "指定控件颜色，颜色值可以是 Material 颜色（例如 <code>success</code>或者 <code>purple</code> ）也可以是 Css 颜色 （例如 <code>#033</code> 或者 <code>rgba(255, 0, 0, 0.5)</code>）。在 [colors page](/zh-Hans/styles/colors#material-colors)中可以找到内置颜色类列表。"
      }
    },
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにダークテーマを適応します。詳しく知りたい場合は、Material Design documentationの<a href=\"https://material.io/design/color/dark-theme.html\">dark themes</a>を参照してください。",
        "zh-Hans": "将暗色主题变量应用到组件。你可以在 [dark themes](https://material.io/design/color/dark-theme.html) 的 Material Design 文档中找到更多有关信息。"
      }
    },
    {
      "name": "fill-dot",
      "type": "boolean",
      "default": "false",
      "source": "v-timeline-item",
      "description": {
        "en": "Remove padding from dot container",
        "ja": "ドットコンテナからパディングを削除します",
        "zh-Hans": "从点容器中删除padding"
      }
    },
    {
      "name": "hide-dot",
      "type": "boolean",
      "default": "false",
      "source": "v-timeline-item",
      "description": {
        "en": "Hide display of timeline dot",
        "ja": "タイムラインドットの非表示にする",
        "zh-Hans": "隐藏时间线上的圆点"
      }
    },
    {
      "name": "icon",
      "type": "string",
      "default": "undefined",
      "source": "v-timeline-item",
      "description": {
        "en": "Specify icon for dot container",
        "ja": "ドットコンテナのアイコンを指定します",
        "zh-Hans": "为点容器指定图标"
      }
    },
    {
      "name": "icon-color",
      "type": "string",
      "default": "undefined",
      "source": "v-timeline-item",
      "description": {
        "en": "Applies specified color to the control - it can be the name of material color (for example `success` or `purple`) or css color (`#033` or `rgba(255, 0, 0, 0.5)`). You can find a list of built-in classes on the [colors page](/styles/colors#material-colors).",
        "ja": "Applies specified color to the control - it can be the name of material color (for example `success` or `purple`) or css color (`#033` or `rgba(255, 0, 0, 0.5)`). You can find a list of built-in classes on the [colors page](/styles/colors#material-colors).",
        "zh-Hans": "指定控件颜色，颜色值可以是 Material 颜色（例如 `success` 或者 `purple` ），也可以是 Css 颜色 （例如 `#033` 或者 `rgba(255, 0, 0, 0.5)`）。在 [colors page](/zh-Hans/styles/colors#material-colors) 中可以找到内置颜色类列表。"
      }
    },
    {
      "name": "large",
      "type": "boolean",
      "default": "false",
      "source": "v-timeline-item",
      "description": {
        "en": "Large size dot",
        "ja": "大きなドット",
        "zh-Hans": "大尺寸的圆点"
      }
    },
    {
      "name": "left",
      "type": "boolean",
      "default": "false",
      "source": "v-timeline-item",
      "description": {
        "en": "Explicitly set the item to a left orientation",
        "ja": "項目を左向きに明示的に設定します",
        "zh-Hans": "显式地将项目设置为左方向"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    },
    {
      "name": "right",
      "type": "boolean",
      "default": "false",
      "source": "v-timeline-item",
      "description": {
        "en": "Explicitly set the item to a right orientation",
        "ja": "項目を右向きに明示的に設定します",
        "zh-Hans": "显式地将项目设置为右方向"
      }
    },
    {
      "name": "small",
      "type": "boolean",
      "default": "false",
      "source": "v-timeline-item",
      "description": {
        "en": "Small size dot",
        "ja": "小さなドット",
        "zh-Hans": "小尺寸的圆点"
      }
    }
  ],
  "mixins": [
    "colorable",
    "themeable"
  ],
  "slots": [
    {
      "name": "default",
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    },
    {
      "name": "icon",
      "description": {
        "en": "Used to customize the icon inside the timeline item's dot",
        "ja": "タイムラインアイテムのドット内のアイコンをカスタマイズするために使用します。",
        "zh-Hans": "用于自定义时间线项的点内的图标"
      }
    },
    {
      "name": "opposite",
      "description": {
        "en": "Used to customize the opposite side of timeline items",
        "ja": "タイムラインのアイテムの反対側をカスタマイズするために使用します。",
        "zh-Hans": "用于自定义时间轴项目的另一面"
      }
    }
  ],
  "events": [],
  "functions": [],
  "name": "v-timeline-item",
  "sass": [],
  "component": true
}