module.exports = {
  "props": [
    {
      "name": "disabled",
      "type": "boolean",
      "default": "false",
      "source": "v-form",
      "description": {
        "en": "Puts all children inputs into a disabled state.",
        "ja": "すべての子要素の入力を無効にします。",
        "zh-Hans": "将所有的子表单组件设置为禁用状态."
      }
    },
    {
      "name": "lazy-validation",
      "type": "boolean",
      "default": "false",
      "source": "v-form",
      "description": {
        "en": "If enabled, **value** will always be _true_ unless there are visible validation errors. You can still call `validate()` to manually trigger validation",
        "ja": "有効にすると、表示されるバリデーションエラーが無い限り、 **value** は常に _true_ となります。",
        "zh-Hans": "如果启用，**value**将永远是 _true_ ，除非有可见的验证错误。您仍然可以调用`validate()`来手动触发验证。"
      }
    },
    {
      "name": "readonly",
      "type": "boolean",
      "default": "false",
      "source": "v-form",
      "description": {
        "en": "Puts all children inputs into a readonly state.",
        "ja": "すべての子要素の入力を読み取り専用にします。",
        "zh-Hans": "将所有的子表单组件设置为只读状态."
      }
    },
    {
      "name": "value",
      "type": "boolean",
      "default": "false",
      "source": "v-form",
      "description": {
        "en": "A boolean value representing the validity of the form.",
        "ja": "フォームの妥当性を表す真偽値",
        "zh-Hans": "表示表单有效性的布尔值。"
      }
    }
  ],
  "mixins": [
    "registrable-provide"
  ],
  "slots": [
    {
      "name": "default",
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    }
  ],
  "events": [
    {
      "name": "input",
      "value": "boolean",
      "description": {
        "en": "The updated bound model",
        "ja": "更新されたバウンドモデル",
        "zh-Hans": "被绑定模型的更新"
      }
    },
    {
      "name": "submit",
      "value": "event",
      "description": {
        "en": "Emitted when form is submitted",
        "ja": "フォーム送信（Submit）時に発生します。",
        "zh-Hans": "提交表单时发出"
      }
    }
  ],
  "functions": [
    {
      "name": "reset",
      "signature": "(): void",
      "description": {
        "en": "Resets the state of all registered inputs (inside the form) to **{}** for arrays and **null** for all other values. Resets errors bag when using the **lazy-validation** prop.",
        "ja": "（フォーム内の）登録されたすべての入力の状​​態を、配列では **{}** に、その他のすべての値では **null** にリセットします。 **lazy-validation** propを使用しているときにエラーバッグをリセットします。",
        "zh-Hans": "将所有已注册输入（在表单内）的状态重置为数组的 **{}** 和所有其他值的 **null**。 使用 **lazy-validation** 属性时，重置错误包。"
      }
    },
    {
      "name": "resetValidation",
      "signature": "(): void",
      "description": {
        "en": "Resets validation of all registered inputs without modifying their state",
        "ja": "状態を変更せずに登録済みのすべての入力の検証をリセットします",
        "zh-Hans": "重置所有已注册输入的验证，但不修改其状态"
      }
    },
    {
      "name": "validate",
      "signature": "(): boolean",
      "description": {
        "en": "Validates all registered inputs. Returns **true** if successful and **false** if not",
        "ja": "全ての入力値をバリデートし結果を返します。",
        "zh-Hans": "验证所有注册的输入。 如果成功返回 **true**，否则返回 **false**"
      }
    }
  ],
  "name": "v-form",
  "sass": [],
  "component": true
}