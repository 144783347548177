module.exports = {
  "props": [
    {
      "name": "calculate-widths",
      "type": "boolean",
      "default": "false",
      "source": "v-data-table",
      "description": {
        "en": "Enables calculation of column widths. `widths` property will be available in select scoped slots",
        "ja": "カラムの幅を計算できるようにします。scopedスロットで `widths` プロパティが利用可能になります。",
        "zh-Hans": "启用列宽计算。`widths` 属性将在部分作用域插槽内可用。"
      }
    },
    {
      "name": "caption",
      "type": "string",
      "default": "undefined",
      "source": "v-data-table",
      "description": {
        "en": "Set the caption (using `<caption>`)",
        "ja": "キャプションを設定する(`<caption>`を使用)",
        "zh-Hans": "设置标题（使用 `<caption>`）。"
      }
    },
    {
      "name": "checkbox-color",
      "type": "string",
      "default": "undefined",
      "source": "v-data",
      "description": {
        "en": "Set the color of the checkboxes (showSelect must be used)",
        "ja": "チェックボックスの色を設定します (showSelectを使用する必要があります)",
        "zh-Hans": "设置复选框的颜色 (当 `showSeelect` 被设置)"
      }
    },
    {
      "name": "custom-filter",
      "type": "function",
      "default": "gh:defaultFilter",
      "source": "v-data",
      "example": "(value: any, search: string | null, item: any) => boolean",
      "description": {
        "en": "Function to filter items",
        "ja": "Itemをfilterするための関数",
        "zh-Hans": "过滤数据的函数。"
      }
    },
    {
      "name": "custom-group",
      "type": "function",
      "default": "gh:groupItems",
      "source": "v-data",
      "example": "(items: any[], groupBy: string[], groupDesc: boolean[]) => Record<string, any[]>",
      "description": {
        "en": "Function used to group items",
        "ja": "Itemsをgroup化するために使われる関数",
        "zh-Hans": "对数据分组的函数。"
      }
    },
    {
      "name": "custom-sort",
      "type": "function",
      "default": "gh:sortItems",
      "source": "v-data",
      "example": "(items: any[], sortBy: string[], sortDesc: boolean[], locale: string, customSorters?: Record<string, compareFn>) => any[]",
      "description": {
        "en": "Function used to sort items",
        "ja": "Itemsをsortするために使われる関数",
        "zh-Hans": "数据排序的函数。"
      }
    },
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにダークテーマを適応します。詳しく知りたい場合は、Material Design documentationの<a href=\"https://material.io/design/color/dark-theme.html\">dark themes</a>を参照してください。",
        "zh-Hans": "将暗色主题变量应用到组件。你可以在 [dark themes](https://material.io/design/color/dark-theme.html) 的 Material Design 文档中找到更多有关信息。"
      }
    },
    {
      "name": "dense",
      "type": "boolean",
      "default": "false",
      "source": "v-data-table",
      "description": {
        "en": "Decreases the height of rows",
        "ja": "行の高さを下げます。",
        "zh-Hans": "减少行高。"
      }
    },
    {
      "name": "disable-filtering",
      "type": "boolean",
      "default": "false",
      "source": "v-data",
      "description": {
        "en": "Disables filtering completely",
        "ja": "Filterを完全に無効化します。",
        "zh-Hans": "完全禁用过滤功能。"
      }
    },
    {
      "name": "disable-pagination",
      "type": "boolean",
      "default": "false",
      "source": "v-data",
      "description": {
        "en": "Disables pagination completely",
        "ja": "Paginationを完全に無効化します。",
        "zh-Hans": "完全禁用分页功能。"
      }
    },
    {
      "name": "disable-sort",
      "type": "boolean",
      "default": "false",
      "source": "v-data",
      "description": {
        "en": "Disables sorting completely",
        "ja": "Sortingを完全に無効化します。",
        "zh-Hans": "完全禁用排序功能。"
      }
    },
    {
      "name": "expand-icon",
      "type": "string",
      "default": "'$expand'",
      "source": "v-data-table",
      "description": {
        "en": "Icon used for expand toggle button.",
        "ja": "切り替えボタンを展開するためのアイコン",
        "zh-Hans": "用于展开按钮的图标。"
      }
    },
    {
      "name": "expanded",
      "type": "array",
      "default": [],
      "source": "v-data-iterator",
      "description": {
        "en": "Array of expanded items. Can be used with `.sync` modifier",
        "ja": "拡張されたitemsのarrayです。 `.sync` 修飾子と一緒に使うことができます。",
        "zh-Hans": "扩展项目数组。可以与 `.sync` 修饰符一起使用"
      }
    },
    {
      "name": "filter-mode",
      "type": "string",
      "default": "'intersection'",
      "source": "v-data-table",
      "description": {
        "en": "Controls how how custom column filters are combined with the default filtering. Both modes only apply the default filter to columns not specified in `customKeyFilter`.\n\n- **union**: There is at least one match from the default filter, OR all custom column filters match.\n- **intersection**: There is at least one match from the default filter, AND all custom column filters match.",
        "ja": "Controls how how custom column filters are combined with the default filtering. Both modes only apply the default filter to columns not specified in `customKeyFilter`.\n\n- **union**: There is at least one match from the default filter, OR all custom column filters match.\n- **intersection**: There is at least one match from the default filter, AND all custom column filters match.",
        "zh-Hans": "Controls how how custom column filters are combined with the default filtering. Both modes only apply the default filter to columns not specified in `customKeyFilter`.\n\n- **union**: There is at least one match from the default filter, OR all custom column filters match.\n- **intersection**: There is at least one match from the default filter, AND all custom column filters match."
      }
    },
    {
      "name": "fixed-header",
      "type": "boolean",
      "default": "false",
      "source": "v-data-table",
      "description": {
        "en": "Fixed header to top of table. **NOTE:** Does not work in IE11",
        "ja": "Fixed header to top of table. **NOTE:** Does not work in IE11",
        "zh-Hans": "将表头固定在表格顶部。**注意：**在 IE11 中无法使用。"
      }
    },
    {
      "name": "footer-props",
      "type": "object",
      "default": "undefined",
      "source": "v-data-iterator",
      "description": {
        "en": "See the [`v-data-footer`](/api/v-data-footer) API for more information",
        "ja": "詳細は [`v-data-footer`](/api/v-data-footer) API を参照してください。",
        "zh-Hans": "更多信息请参阅[`v-data-footer`](/api/v-data-foter) API"
      }
    },
    {
      "name": "group-by",
      "type": [
        "string",
        "array"
      ],
      "default": [],
      "source": "v-data",
      "description": {
        "en": "Changes which item property should be used for grouping items. Currently only supports a single grouping in the format: `group` or `['group']`. When using an array, only the first element is considered. Can be used with `.sync` modifier",
        "ja": "アイテムのグループ化に使用するアイテムプロパティを変更します。現在は、`group` または `['group']` 形式でのみサポートされています。 配列を使用する場合、最初の要素のみが考慮されます。`.sync`修飾子と一緒に使用できます。",
        "zh-Hans": "更改数据分组时应该使用哪个数据项属性。目前只支持使用一个数据项属性，格式为：`group`或`['group']`。使用数组时，只考虑第一个元素。可与`.sync`修饰符一起使用。"
      }
    },
    {
      "name": "group-desc",
      "type": [
        "boolean",
        "array"
      ],
      "default": [],
      "source": "v-data",
      "description": {
        "en": "Changes which direction grouping is done. Can be used with `.sync` modifier",
        "ja": "グループ化する方向を変更します。`.sync` 修飾子と一緒に使用できます。",
        "zh-Hans": "更改分组的排序。可与`.sync`修饰符一起使用。"
      }
    },
    {
      "name": "header-props",
      "type": "object",
      "default": "undefined",
      "source": "v-data-table",
      "description": {
        "en": "Pass props to the default header. See [`v-data-table-header` API](/api/v-data-table-header) for more information",
        "ja": "デフォルトヘッダに props を渡します。詳細は[`v-data-table-header` API](/api/v-data-table-header) を参照してください。",
        "zh-Hans": "将props 传递到默认标题。请参阅[`v-data-table-header` API](/api/v-data-table-header) 获取更多信息"
      }
    },
    {
      "name": "headers",
      "type": "DataTableHeader[]",
      "default": [],
      "source": "v-data-table",
      "example": {
        "text": "string",
        "value": "string",
        "align?": "'start' | 'center' | 'end'",
        "sortable?": "boolean",
        "filterable?": "boolean",
        "groupable?": "boolean",
        "divider?": "boolean",
        "class?": "string | string[]",
        "cellClass?": "string | string[]",
        "width?": "string | number",
        "filter?": "(value: any, search: string, item: any) => boolean",
        "sort?": "(a: any, b: any) => number"
      },
      "description": {
        "en": "An array of objects that each describe a header column. See the example below for a definition of all properties",
        "ja": "ヘッダーのカラムとなる配列のオブジェクトを設定します。 全プロパティの定義については下記の例を参照してください。",
        "zh-Hans": "一个对象数组，每个对象描述一个表头列。所有属性的定义请参见下面的示例。"
      }
    },
    {
      "name": "headers-length",
      "type": "number",
      "default": "undefined",
      "source": "v-data-table",
      "description": {
        "en": "Can be used in combination with `hide-default-header` to specify the number of columns in the table to allow expansion rows and loading bar to function properly",
        "ja": "Can be used in combination with `hide-default-header` to specify the number of columns in the table to allow expansion rows and loading bar to function properly",
        "zh-Hans": "可与 `hide-default-header` 结合使用，指定表格列数，以使展开行和加载条正常运行。"
      }
    },
    {
      "name": "height",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "v-data-table",
      "description": {
        "en": "Set an explicit height of table",
        "ja": "テーブルの明示的な高さを設定します",
        "zh-Hans": "显式地设置表格高度。"
      }
    },
    {
      "name": "hide-default-footer",
      "type": "boolean",
      "default": "false",
      "source": "v-data-iterator",
      "description": {
        "en": "Hides default footer",
        "ja": "デフォルトのfooterを隠します。",
        "zh-Hans": "默认隐藏页脚"
      }
    },
    {
      "name": "hide-default-header",
      "type": "boolean",
      "default": "false",
      "source": "v-data-table",
      "description": {
        "en": "Hide the default headers",
        "ja": "既定のヘッダーを非表示にする",
        "zh-Hans": "隐藏默认表头。"
      }
    },
    {
      "name": "item-class",
      "type": [
        "string",
        "function"
      ],
      "default": "",
      "source": "v-data-table",
      "description": {
        "en": "Property on supplied `items` that contains item's row class or function that takes an item as an argument and returns the class of corresponding row",
        "ja": "Property on supplied `items` that contains item's row class or function that takes an item as an argument and returns the class of corresponding row",
        "zh-Hans": "提供的 `items` 上的属性，其中包含数据项行的类，或将数据项作为参数并返回相应行的类的函数。"
      }
    },
    {
      "name": "item-key",
      "type": "string",
      "default": "'id'",
      "source": "v-data-iterator",
      "description": {
        "en": "The property on each item that is used as a unique key",
        "ja": "各itemでユニークキーとして使われるプロパティ。",
        "zh-Hans": "每个项上用作唯一键的属性"
      }
    },
    {
      "name": "item-style",
      "type": [
        "string",
        "function"
      ],
      "default": "",
      "source": "v-data-table",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "items",
      "type": "array",
      "default": [],
      "source": "v-data",
      "description": {
        "en": "The array of items to display",
        "ja": "表示されるitemsのarray。",
        "zh-Hans": "要显示的数据数组。"
      }
    },
    {
      "name": "items-per-page",
      "type": "number",
      "default": 10,
      "source": "v-data",
      "description": {
        "en": "Changes how many items per page should be visible. Can be used with `.sync` modifier. Setting this prop to `-1` will display all items on the page",
        "ja": "ページごとに何個のアイテムを表示するかを変更します。`.sync` 修飾子と一緒に使うことができます。このプロパティを `-1` に設定すると、ページ上のすべてのアイテムを表示します。",
        "zh-Hans": "更改每页显示多少条目。可以与 `' 一起使用。 ync` 修饰符。设置此prop 到 `-1` 将显示页面上的所有项目"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    },
    {
      "name": "loader-height",
      "type": [
        "number",
        "string"
      ],
      "default": 4,
      "source": "loadable",
      "description": {
        "en": "Specifies the height of the loader",
        "ja": "loader の高さを指定します。",
        "zh-Hans": "指定加载器的高度"
      }
    },
    {
      "name": "loading",
      "type": [
        "boolean",
        "string"
      ],
      "default": false,
      "source": "v-data-iterator",
      "description": {
        "en": "If `true` and no items are provided, then a loading text will be shown",
        "ja": "もし`true`にして、itemsが設定されなかった場合、ここに設定したローディングテキストが表示されます。",
        "zh-Hans": "如果为 `true` 且未提供任何项目，则将显示加载文本"
      }
    },
    {
      "name": "loading-text",
      "type": "string",
      "default": "'$vuetify.dataIterator.loadingText'",
      "source": "v-data-iterator",
      "description": {
        "en": "Text shown when `loading` is true and no items are provided",
        "ja": "`loading`がtrueで、itemsが何も設定されなかった場合に表示されるテキスト。",
        "zh-Hans": "当 `loading` 为 true 且未提供任何物品时显示的文本"
      }
    },
    {
      "name": "locale",
      "type": "string",
      "default": "'en-US'",
      "source": "v-data",
      "description": {
        "en": "Sets the locale used for sorting. This is passed into [`Intl.Collator()`](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Collator/Collator) in the default `customSort` function",
        "ja": "ソートに使用されるロケールを設定します。これはデフォルトの `customSort` 関数の [`Intl.Collator()`](https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/Intl/Collator/Collator) に渡されます。",
        "zh-Hans": "设置用于排序的语言。向默认的`customSort` 函数传递 [`Intl.Collator()`](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Collator/Collator)"
      }
    },
    {
      "name": "mobile-breakpoint",
      "type": [
        "number",
        "string"
      ],
      "default": 600,
      "source": "mobile",
      "description": {
        "en": "Used to set when to toggle between regular table and mobile view",
        "ja": "通常のテーブルとモバイル環境でのビューを切り替えるタイミングを設定するために使用",
        "zh-Hans": "用于设置何时在常规表格和移动视图之间切换。"
      }
    },
    {
      "name": "multi-sort",
      "type": "boolean",
      "default": "false",
      "source": "v-data",
      "description": {
        "en": "If `true` then one can sort on multiple properties",
        "ja": "`true` の場合、複数のプロパティでソートすることができます。",
        "zh-Hans": "如果为 `true`，则可以根据多个属性排序。"
      }
    },
    {
      "name": "must-sort",
      "type": "boolean",
      "default": "false",
      "source": "v-data",
      "description": {
        "en": "If `true` then one can not disable sorting, it will always switch between ascending and descending",
        "ja": "`true` の場合、ソートを無効にすることはできず、常に昇順と降順を切り替えます。",
        "zh-Hans": "如果为 `true`，则不能禁用排序，它将始终在升序和降序之间切换。"
      }
    },
    {
      "name": "no-data-text",
      "type": "string",
      "default": "'$vuetify.noDataText'",
      "source": "v-data-iterator",
      "description": {
        "en": "Text shown when no items are provided to the component",
        "ja": "コンポーネントにアイテムが提供されていない場合に表示されるテキスト。",
        "zh-Hans": "未向组件提供任何项目时显示的文本"
      }
    },
    {
      "name": "no-results-text",
      "type": "string",
      "default": "'$vuetify.dataIterator.noResultsText'",
      "source": "v-data-iterator",
      "description": {
        "en": "Text shown when `search` prop is used and there are no results",
        "ja": "`search` propが使われ、結果が得られなかった場合に表示されるテキスト。",
        "zh-Hans": "使用 `search` 属性时显示的文本，但没有结果"
      }
    },
    {
      "name": "options",
      "type": "DataOptions",
      "default": {},
      "source": "v-data",
      "example": {
        "page": "number",
        "itemsPerPage": "number",
        "sortBy": "string[]",
        "sortDesc": "boolean[]",
        "groupBy": "string[]",
        "groupDesc": "boolean[]",
        "multiSort": "boolean",
        "mustSort": "boolean"
      },
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "page",
      "type": "number",
      "default": 1,
      "source": "v-data",
      "description": {
        "en": "The current displayed page number (1-indexed)",
        "ja": "The current displayed page number (1-indexed)",
        "zh-Hans": "当前显示的页码 (1-索引)"
      }
    },
    {
      "name": "search",
      "type": "string",
      "default": "undefined",
      "source": "v-data",
      "description": {
        "en": "Text input used to filter items",
        "ja": "Itemsをフィルタリングするために使用されるテキスト入力",
        "zh-Hans": "用于过滤数据的文本。"
      }
    },
    {
      "name": "selectable-key",
      "type": "string",
      "default": "'isSelectable'",
      "source": "v-data-iterator",
      "description": {
        "en": "The property on each item that is used to determine if it is selectable or not",
        "ja": "選択可能かどうかを判断するために使用される各項目のプロパティ。",
        "zh-Hans": "每个项目上的属性，用于确定其是否可选择。"
      }
    },
    {
      "name": "server-items-length",
      "type": "number",
      "default": -1,
      "source": "v-data",
      "description": {
        "en": "Used only when data is provided by a server. Should be set to the total amount of items available on server so that pagination works correctly",
        "ja": "データがサーバから提供される場合にのみ使用されます。 ページネーションが正しく動作するように、サーバで利用可能なアイテムの合計数に設定する必要があります",
        "zh-Hans": "仅当数据由服务器提供时使用。应该设置为服务器上可用的数据量，以便正确分页。"
      }
    },
    {
      "name": "show-expand",
      "type": "boolean",
      "default": "false",
      "source": "v-data-table",
      "description": {
        "en": "Shows the expand toggle in default rows",
        "ja": "Shows the expand toggle in default rows",
        "zh-Hans": "在默认行中显示展开切换。"
      }
    },
    {
      "name": "show-group-by",
      "type": "boolean",
      "default": "false",
      "source": "v-data-table",
      "description": {
        "en": "Shows the group by toggle in the header and enables grouped rows",
        "ja": "Shows the group by toggle in the header and enables grouped rows",
        "zh-Hans": "在表头中显示分组，并启用分组行。"
      }
    },
    {
      "name": "show-select",
      "type": "boolean",
      "default": "false",
      "source": "v-data-table",
      "description": {
        "en": "Shows the select checkboxes in both the header and rows (if using default rows)",
        "ja": "Shows the select checkboxes in both the header and rows (if using default rows)",
        "zh-Hans": "如果使用默认行，在表头和行中显示选择复选框。"
      }
    },
    {
      "name": "single-expand",
      "type": "boolean",
      "default": "false",
      "source": "v-data-iterator",
      "description": {
        "en": "Changes expansion mode to single expand",
        "ja": "展開モードをシングル展開に変更します",
        "zh-Hans": "将扩展模式更改为单个扩展"
      }
    },
    {
      "name": "single-select",
      "type": "boolean",
      "default": "false",
      "source": "v-data-iterator",
      "description": {
        "en": "Changes selection mode to single select",
        "ja": "選択モードを単一選択に変更します",
        "zh-Hans": "将选择模式更改为单选"
      }
    },
    {
      "name": "sort-by",
      "type": [
        "string",
        "array"
      ],
      "default": "undefined",
      "source": "v-data",
      "description": {
        "en": "Changes which item property (or properties) should be used for sort order. Can be used with `.sync` modifier",
        "ja": "どの項目プロパティ（またはプロパティ群）をソート順に使用するかを変更します。`.sync` 修飾子と一緒に使うことができます。",
        "zh-Hans": "更改用于排序顺序的数据项属性。可与 `.sync` 修饰符一起使用。"
      }
    },
    {
      "name": "sort-desc",
      "type": [
        "boolean",
        "array"
      ],
      "default": "undefined",
      "source": "v-data",
      "description": {
        "en": "Changes which direction sorting is done. Can be used with `.sync` modifier",
        "ja": "ソートする方向を変更します。`.sync` 修飾子と一緒に使うことができます。",
        "zh-Hans": "更改排序方向。可与 `.sync` 修饰器一起使用。"
      }
    },
    {
      "name": "value",
      "type": "array",
      "default": [],
      "source": "v-data-iterator",
      "description": {
        "en": "Used for controlling selected rows",
        "ja": "選択された行を制御するために使用します。",
        "zh-Hans": "用于控制选定的行"
      }
    }
  ],
  "mixins": [
    "loadable",
    "mobile",
    "mouse",
    "themeable",
    "v-data-iterator"
  ],
  "slots": [
    {
      "name": "body",
      "props": {
        "items": "any[]",
        "originalItemsLength": "number",
        "pagination": {
          "page": "number",
          "itemsPerPage": "number",
          "pageStart": "number",
          "pageStop": "number",
          "pageCount": "number",
          "itemsLength": "number"
        },
        "options": {
          "page": "number",
          "itemsPerPage": "number",
          "sortBy": "string[]",
          "sortDesc": "boolean[]",
          "groupBy": "string[]",
          "groupDesc": "boolean[]",
          "multiSort": "boolean",
          "mustSort": "boolean"
        },
        "groupedItems": "Array<{ name: string, items: any[] }>",
        "updateOptions": "(obj: any) => void",
        "sort": "(value: string) => void",
        "sortArray": "(sortBy: string[]) => void",
        "group": "(value: string) => void",
        "headers": "DataTableHeader[]",
        "isMobile": "boolean",
        "isSelected": "(item: any) => boolean",
        "select": "(item: any, value: boolean) => void",
        "isExpanded": "(item: any) => boolean",
        "expand": "(item: any, value: boolean) => void"
      },
      "description": {
        "en": "Slot to replace the default table `<tbody>`",
        "ja": "デフォルトのテーブル `<tbody>` を置き換えるスロット",
        "zh-Hans": "替换默认表 `<tbody>` 的插槽。"
      }
    },
    {
      "name": "body.append",
      "props": {
        "items": "any[]",
        "originalItemsLength": "number",
        "pagination": {
          "page": "number",
          "itemsPerPage": "number",
          "pageStart": "number",
          "pageStop": "number",
          "pageCount": "number",
          "itemsLength": "number"
        },
        "options": {
          "page": "number",
          "itemsPerPage": "number",
          "sortBy": "string[]",
          "sortDesc": "boolean[]",
          "groupBy": "string[]",
          "groupDesc": "boolean[]",
          "multiSort": "boolean",
          "mustSort": "boolean"
        },
        "groupedItems": "Array<{ name: string, items: any[] }>",
        "updateOptions": "(obj: any) => void",
        "sort": "(value: string) => void",
        "sortArray": "(sortBy: string[]) => void",
        "group": "(value: string) => void",
        "headers": "DataTableHeader[]",
        "isMobile": "boolean",
        "isSelected": "(item: any) => boolean",
        "select": "(item: any, value: boolean) => void",
        "isExpanded": "(item: any) => boolean",
        "expand": "(item: any, value: boolean) => void"
      },
      "description": {
        "en": "Appends elements to the end of the default table `<tbody>`",
        "ja": "Appends elements to the end of the default table `<tbody>`",
        "zh-Hans": "将元素添加到默认表 `<tbody>` 的末尾。"
      }
    },
    {
      "name": "body.prepend",
      "props": {
        "items": "any[]",
        "originalItemsLength": "number",
        "pagination": {
          "page": "number",
          "itemsPerPage": "number",
          "pageStart": "number",
          "pageStop": "number",
          "pageCount": "number",
          "itemsLength": "number"
        },
        "options": {
          "page": "number",
          "itemsPerPage": "number",
          "sortBy": "string[]",
          "sortDesc": "boolean[]",
          "groupBy": "string[]",
          "groupDesc": "boolean[]",
          "multiSort": "boolean",
          "mustSort": "boolean"
        },
        "groupedItems": "Array<{ name: string, items: any[] }>",
        "updateOptions": "(obj: any) => void",
        "sort": "(value: string) => void",
        "sortArray": "(sortBy: string[]) => void",
        "group": "(value: string) => void",
        "headers": "DataTableHeader[]",
        "isMobile": "boolean",
        "isSelected": "(item: any) => boolean",
        "select": "(item: any, value: boolean) => void",
        "isExpanded": "(item: any) => boolean",
        "expand": "(item: any, value: boolean) => void"
      },
      "description": {
        "en": "Prepends elements to the start of the default table `<tbody>`",
        "ja": "Prepends elements to the start of the default table `<tbody>`",
        "zh-Hans": "将元素添加到默认表 `<tbody>` 的开头。"
      }
    },
    {
      "name": "expanded-item",
      "props": {
        "isMobile": "boolean",
        "item": "any",
        "headers": "DataTableHeader[]"
      },
      "description": {
        "en": "Slot to customize expanded rows",
        "ja": "Slot to customize expanded rows",
        "zh-Hans": "自定义展开行的插槽。"
      }
    },
    {
      "name": "foot",
      "props": {
        "items": "any[]",
        "originalItemsLength": "number",
        "pagination": {
          "page": "number",
          "itemsPerPage": "number",
          "pageStart": "number",
          "pageStop": "number",
          "pageCount": "number",
          "itemsLength": "number"
        },
        "options": {
          "page": "number",
          "itemsPerPage": "number",
          "sortBy": "string[]",
          "sortDesc": "boolean[]",
          "groupBy": "string[]",
          "groupDesc": "boolean[]",
          "multiSort": "boolean",
          "mustSort": "boolean"
        },
        "groupedItems": "Array<{ name: string, items: any[] }>",
        "updateOptions": "(obj: any) => void",
        "sort": "(value: string) => void",
        "sortArray": "(sortBy: string[]) => void",
        "group": "(value: string) => void"
      },
      "description": {
        "en": "Slot to add a `<tfoot>` element after the `<tbody>`. Not to be confused with the `footer` slot",
        "ja": "Slot to add a `<tfoot>` element after the `<tbody>`. Not to be confused with the `footer` slot",
        "zh-Hans": "在`<tbody>`后面添加一个`<tfoot>`元素的插槽。不要与 `footer` 插槽混淆。"
      }
    },
    {
      "name": "footer",
      "props": {
        "props": {
          "options": {
            "page": "number",
            "itemsPerPage": "number",
            "sortBy": "string[]",
            "sortDesc": "boolean[]",
            "groupBy": "string[]",
            "groupDesc": "boolean[]",
            "multiSort": "boolean",
            "mustSort": "boolean"
          },
          "pagination": {
            "page": "number",
            "itemsPerPage": "number",
            "pageStart": "number",
            "pageStop": "number",
            "pageCount": "number",
            "itemsLength": "number"
          },
          "itemsPerPageText": "string"
        },
        "on": "{}",
        "headers": "DataTableHeader[]",
        "widths": "[]"
      },
      "description": {
        "en": "Slot to add a custom footer",
        "ja": "カスタム フッターを追加するスロット",
        "zh-Hans": "添加自定义表脚的插槽。"
      }
    },
    {
      "name": "footer.page-text",
      "props": {
        "pageStart": "number",
        "pageStop": "number",
        "itemsLength": "number"
      },
      "description": {
        "en": "Slot to customize footer page text",
        "ja": "フッターページテキストをカスタマイズするためのスロットです。",
        "zh-Hans": "自定义表格文本的插槽。"
      }
    },
    {
      "name": "footer.prepend",
      "description": {
        "en": "Adds content to the empty space in the footer",
        "ja": "Adds content to the empty space in the footer",
        "zh-Hans": "将内容添加到表脚中的空空间"
      }
    },
    {
      "name": "group",
      "props": {
        "group": "string",
        "options": {
          "page": "number",
          "itemsPerPage": "number",
          "sortBy": "string[]",
          "sortDesc": "boolean[]",
          "groupBy": "string[]",
          "groupDesc": "boolean[]",
          "multiSort": "boolean",
          "mustSort": "boolean"
        },
        "isMobile": "boolean",
        "items": "any[]",
        "headers": "DataTableHeader[]"
      },
      "description": {
        "en": "Slot to replace the default rendering of grouped rows",
        "ja": "グループ化された行のデフォルトのレンダリングを置き換えるためのスロット",
        "zh-Hans": "替换分组行的默认渲染的插槽。"
      }
    },
    {
      "name": "group.header",
      "props": {
        "group": "string",
        "groupBy": "string[]",
        "isMobile": "boolean",
        "items": "any[]",
        "headers": "DataTableHeader[]",
        "isOpen": "boolean",
        "toggle": "() => void",
        "remove": "() => void"
      },
      "description": {
        "en": "Slot to customize the default rendering of group headers",
        "ja": "グループヘッダーのデフォルトのレンダリングをカスタマイズするためのスロット",
        "zh-Hans": "自定义分组标题的默认渲染的插槽。"
      }
    },
    {
      "name": "group.summary",
      "props": {
        "group": "string",
        "groupBy": "string[]",
        "isMobile": "boolean",
        "items": "any[]",
        "headers": "DataTableHeader[]",
        "isOpen": "boolean",
        "toggle": "() => void"
      },
      "description": {
        "en": "Slot to customize the default rendering of group summaries",
        "ja": "グループサマリーのデフォルトのレンダリングをカスタマイズするためのスロット",
        "zh-Hans": "自定义分组摘要的默认渲染的插槽。"
      }
    },
    {
      "name": "header",
      "props": {
        "isMobile": "boolean",
        "props": {
          "headers": "DataTableHeader[]",
          "options": {
            "page": "number",
            "itemsPerPage": "number",
            "sortBy": "string[]",
            "sortDesc": "boolean[]",
            "groupBy": "string[]",
            "groupDesc": "boolean[]",
            "multiSort": "boolean",
            "mustSort": "boolean"
          },
          "mobile": "boolean",
          "showGroupBy": "boolean",
          "someItems": "boolean",
          "everyItem": "boolean"
        },
        "on": {
          "sort": "(value: string) => void",
          "group": "(value: string) => void",
          "toggle-select-all": "(value: boolean) => void"
        }
      },
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "header.<name>",
      "props": {
        "header": "DataTableHeader"
      },
      "description": {
        "en": "Slot to customize a specific header column",
        "ja": "特定のヘッダー列をカスタマイズするためのスロット",
        "zh-Hans": "自定义特定表头列的插槽。"
      }
    },
    {
      "name": "header.data-table-select",
      "props": {
        "isMobile": "boolean",
        "props": {
          "value": "boolean",
          "indeterminate": "boolean"
        },
        "on": {
          "input": "(value: boolean) => void"
        }
      },
      "description": {
        "en": "Slot to replace the default `v-simple-checkbox` in header",
        "ja": "ヘッダーのデフォルトの `v-simple-checkbox` を置き換えるスロット",
        "zh-Hans": "替换表头中默认的 `v-simple-checkbox` 的插槽。"
      }
    },
    {
      "name": "item",
      "props": {
        "expand": "(v: boolean) => void",
        "index": "number",
        "item": "any",
        "isExpanded": "boolean",
        "isMobile": "boolean",
        "isSelected": "boolean",
        "select": "(v: boolean) => void",
        "headers": "DataTableHeader[]",
        "on": {
          "click": "(event: MouseEvent) => void",
          "contextmenu": "(event: MouseEvent) => void",
          "dblclick": "(event: MouseEvent) => void"
        },
        "attrs": {
          "class": "object"
        }
      },
      "description": {
        "en": "Slot to replace the default rendering of a row",
        "ja": "row（行）の既定のレンダリングを置き換えるスロット",
        "zh-Hans": "替换行的默认渲染的插槽。"
      }
    },
    {
      "name": "item.<name>",
      "props": {
        "isMobile": "boolean",
        "item": "any",
        "header": "DataTableHeader",
        "value": "any",
        "index": "number"
      },
      "description": {
        "en": "Slot to customize a specific column",
        "ja": "特定の列をカスタマイズするためのスロット",
        "zh-Hans": "自定义特定列的插槽。"
      }
    },
    {
      "name": "item.data-table-expand",
      "props": {
        "expand": "(v: boolean) => void",
        "index": "number",
        "item": "any",
        "isExpanded": "boolean",
        "isMobile": "boolean",
        "isSelected": "boolean",
        "select": "(v: boolean) => void",
        "headers": "DataTableHeader[]",
        "on": {
          "click": "(event: MouseEvent) => void",
          "contextmenu": "(event: MouseEvent) => void",
          "dblclick": "(event: MouseEvent) => void"
        },
        "attrs": {
          "class": "object"
        }
      },
      "description": {
        "en": "Slot to replace the default `v-icon` used when expanding rows",
        "ja": "Slot to replace the default `v-icon` used when expanding rows",
        "zh-Hans": "替换展开行时默认使用的 `v-icon` 的插槽。"
      }
    },
    {
      "name": "item.data-table-select",
      "props": {
        "expand": "(v: boolean) => void",
        "index": "number",
        "item": "any",
        "isExpanded": "boolean",
        "isMobile": "boolean",
        "isSelected": "boolean",
        "select": "(v: boolean) => void",
        "headers": "DataTableHeader[]",
        "on": {
          "click": "(event: MouseEvent) => void",
          "contextmenu": "(event: MouseEvent) => void",
          "dblclick": "(event: MouseEvent) => void"
        },
        "attrs": {
          "class": "object"
        }
      },
      "description": {
        "en": "Slot to replace the default `v-simple-checkbox` used when selecting rows",
        "ja": "行を選択するときに使用されるデフォルトの `v-simple-checkbox` を置き換えるスロット",
        "zh-Hans": "替换选择行时默认使用的 `v-simple-checkbox` 的插槽。"
      }
    },
    {
      "name": "loading",
      "source": "v-data-iterator",
      "description": {
        "en": "Defines content for when `loading` is true and no items are provided",
        "ja": "`loading` が true で、itemsが提供されない場合のコンテンツを定義します。",
        "zh-Hans": "定义 `loading` 为 true 且未提供任何数据时的内容。"
      }
    },
    {
      "name": "no-data",
      "source": "v-data-iterator",
      "description": {
        "en": "Defines content for when no items are provided",
        "ja": "itemsが提供されない場合のコンテンツを定義します",
        "zh-Hans": "定义没有提供数据时的内容。"
      }
    },
    {
      "name": "no-results",
      "source": "v-data-iterator",
      "description": {
        "en": "Defines content for when `search` is provided but no results are found",
        "ja": "`search` が提供されているが、結果が見つからない場合のコンテンツを定義します。",
        "zh-Hans": "定义提供 `search` 但未找到结果时的内容。"
      }
    },
    {
      "name": "progress",
      "description": {
        "en": "Slot to replace the default `<v-progress-linear>` component",
        "ja": "Slot to replace the default `<v-progress-linear>` component",
        "zh-Hans": "替换默认 `<v-progress-linear>` 组件的插槽。"
      }
    },
    {
      "name": "top",
      "props": {
        "items": "any[]",
        "originalItemsLength": "number",
        "pagination": {
          "page": "number",
          "itemsPerPage": "number",
          "pageStart": "number",
          "pageStop": "number",
          "pageCount": "number",
          "itemsLength": "number"
        },
        "options": {
          "page": "number",
          "itemsPerPage": "number",
          "sortBy": "string[]",
          "sortDesc": "boolean[]",
          "groupBy": "string[]",
          "groupDesc": "boolean[]",
          "multiSort": "boolean",
          "mustSort": "boolean"
        },
        "groupedItems": "Array<{ name: string, items: any[] }>",
        "updateOptions": "(obj: any) => void",
        "sort": "(value: string) => void",
        "sortArray": "(sortBy: string[]) => void",
        "group": "(value: string) => void",
        "isMobile": "boolean"
      },
      "description": {
        "en": "Slot to add content above the table",
        "ja": "テーブルの上にコンテンツを追加するスロット",
        "zh-Hans": "在表格上方添加内容的插槽。"
      }
    }
  ],
  "events": [
    {
      "name": "<event>:row",
      "source": "v-data-table",
      "value": "{\n  expand: (value: boolean) => void,\n  headers: DataTableHeader[],\n  isExpanded: boolean,\n  isMobile: boolean,\n  isSelected: boolean,\n  item: any,\n  select: (value: boolean) => void\n}, Event",
      "description": {
        "en": "Pass through any native event listener to the table row elements. The first argument is the event object and the second is related data provided to the item slot. **NOTE:** will not emit when table rows are defined through a slot such as `item` or `body`.",
        "ja": "Pass through any native event listener to the table row elements. The first argument is the event object and the second is related data provided to the item slot. **NOTE:** will not emit when table rows are defined through a slot such as `item` or `body`.",
        "zh-Hans": "Pass through any native event listener to the table row elements. The first argument is the event object and the second is related data provided to the item slot. **NOTE:** will not emit when table rows are defined through a slot such as `item` or `body`."
      }
    },
    {
      "name": "click:row",
      "source": "v-data-table",
      "value": "any, {\n  expand: (value: boolean) => void,\n  headers: DataTableHeader[],\n  isExpanded: boolean,\n  isMobile: boolean,\n  isSelected: boolean,\n  item: any,\n  select: (value: boolean) => void\n}, MouseEvent",
      "description": {
        "en": "Emits when a table row is clicked. This event provides 3 arguments: the first is the item data that was clicked, the second is the other related data provided by the `item` slot, and the third is the native click event. **NOTE:** will not emit when table rows are defined through a slot such as `item` or `body`.",
        "ja": "Emits when a table row is clicked. This event provides 3 arguments: the first is the item data that was clicked, the second is the other related data provided by the `item` slot, and the third is the native click event. **NOTE:** will not emit when table rows are defined through a slot such as `item` or `body`.",
        "zh-Hans": "当表行被点击时触发。 此事件提供了3个参数：第 1 个是对应的数据项，第 2 个是 `item` 插槽提供的其他相关数据，第三个是本地点击事件。 **注意：** 当表格行通过诸如`item` 或 `body` 等栏位定义时，不会触发该事件。"
      }
    },
    {
      "name": "current-items",
      "source": "v-data",
      "value": "any[]",
      "description": {
        "en": "Emits the items provided via the **items** prop, every time the internal **computedItems** is changed.",
        "ja": "Emits the items provided via the **items** prop, every time the internal **computedItems** is changed.",
        "zh-Hans": "每当内部的**computedItems**被改变时，就会发射通过**items**道具提供的项目。"
      }
    },
    {
      "name": "input",
      "source": "v-data-iterator",
      "value": "any[]",
      "description": {
        "en": "Array of selected items",
        "ja": "選択した項目の配列",
        "zh-Hans": "选中项数组"
      }
    },
    {
      "name": "item-expanded",
      "source": "v-data-iterator",
      "value": "{ item: any, value: boolean }",
      "description": {
        "en": "Event emitted when an item is expanded or closed",
        "ja": "項目が展開または閉じられたときに発生するイベント",
        "zh-Hans": "展开或关闭项目时发出的事件"
      }
    },
    {
      "name": "item-selected",
      "source": "v-data-iterator",
      "value": "{ item: any, value: boolean }",
      "description": {
        "en": "Event emitted when an item is selected or deselected",
        "ja": "アイテムが選択または選択解除されたときに発生するイベント",
        "zh-Hans": "选中或取消选中某项时发出的事件"
      }
    },
    {
      "name": "page-count",
      "source": "v-data",
      "value": "number",
      "description": {
        "en": "Emits when the **pageCount** property of the **pagination** prop is updated",
        "ja": "Emits when the **pageCount** property of the **pagination** prop is updated",
        "zh-Hans": "当**pagination**的**pageCount**属性被更新时发出。"
      }
    },
    {
      "name": "pagination",
      "source": "v-data",
      "value": {
        "page": "number",
        "itemsPerPage": "number",
        "pageStart": "number",
        "pageStop": "number",
        "pageCount": "number",
        "itemsLength": "number"
      },
      "description": {
        "en": "Emits when something changed to the `pagination` which can be provided via the `pagination` prop",
        "ja": "Emits when something changed to the `pagination` which can be provided via the `pagination` prop",
        "zh-Hans": "当 \"pagination \"发生变化时发出，可以通过 \"pagination \"组件提供。"
      }
    },
    {
      "name": "toggle-select-all",
      "source": "v-data-iterator",
      "value": "{ items: any[], value: boolean }",
      "description": {
        "en": "Emits when the `select-all` checkbox in table header is clicked. This checkbox is enabled by the **show-select** prop",
        "ja": "Emits when the `select-all` checkbox in table header is clicked. This checkbox is enabled by the **show-select** prop",
        "zh-Hans": "Emits when the `select-all` checkbox in table header is clicked. This checkbox is enabled by the **show-select** prop"
      }
    },
    {
      "name": "update:expanded",
      "source": "v-data-iterator",
      "value": "any[]",
      "description": {
        "en": "The `.sync` event for `expanded` prop",
        "ja": "`expanded`プロパティの`.sync`イベント",
        "zh-Hans": "`expanded` 属性的 `.sync` 事件"
      }
    },
    {
      "name": "update:group-by",
      "source": "v-data",
      "value": "string | string[]",
      "description": {
        "en": "Emits when the **group-by** property of the **options** property is updated",
        "ja": "Emits when the **group-by** property of the **options** property is updated",
        "zh-Hans": "Emits when the **group-by** property of the **options** property is updated"
      }
    },
    {
      "name": "update:group-desc",
      "source": "v-data",
      "value": "boolean | boolean[]",
      "description": {
        "en": "Emits when the **group-desc** property of the **options** prop is updated",
        "ja": "Emits when the **group-desc** property of the **options** prop is updated",
        "zh-Hans": "Emits when the **group-desc** property of the **options** prop is updated"
      }
    },
    {
      "name": "update:items-per-page",
      "source": "v-data",
      "value": "number",
      "description": {
        "en": "Emits when the **items-per-page** property of the **options** prop is updated",
        "ja": "Emits when the **items-per-page** property of the **options** prop is updated",
        "zh-Hans": "Emits when the **items-per-page** property of the **options** prop is updated"
      }
    },
    {
      "name": "update:multi-sort",
      "source": "v-data",
      "value": "boolean",
      "description": {
        "en": "Emits when the **multi-sort** property of the **options** prop is updated",
        "ja": "Emits when the **multi-sort** property of the **options** prop is updated",
        "zh-Hans": "Emits when the **multi-sort** property of the **options** prop is updated"
      }
    },
    {
      "name": "update:must-sort",
      "source": "v-data",
      "value": "boolean",
      "description": {
        "en": "Emits when the **must-sort** property of the **options** prop is updated",
        "ja": "Emits when the **must-sort** property of the **options** prop is updated",
        "zh-Hans": "Emits when the **must-sort** property of the **options** prop is updated"
      }
    },
    {
      "name": "update:options",
      "source": "v-data",
      "value": {
        "page": "number",
        "itemsPerPage": "number",
        "sortBy": "string[]",
        "sortDesc": "boolean[]",
        "groupBy": "string[]",
        "groupDesc": "boolean[]",
        "multiSort": "boolean",
        "mustSort": "boolean"
      },
      "description": {
        "en": "Emits when one of the **options** properties is updated",
        "ja": "Emits when one of the **options** properties is updated",
        "zh-Hans": "Emits when one of the **options** properties is updated"
      }
    },
    {
      "name": "update:page",
      "source": "v-data",
      "value": "number",
      "description": {
        "en": "Emits when the **page** property of the **options** prop is updated",
        "ja": "Emits when the **page** property of the **options** prop is updated",
        "zh-Hans": "Emits when the **page** property of the **options** prop is updated"
      }
    },
    {
      "name": "update:sort-by",
      "source": "v-data",
      "value": "string | string[]",
      "description": {
        "en": "Emits when the **sort-by** property of the **options** prop is updated",
        "ja": "Emits when the **sort-by** property of the **options** prop is updated",
        "zh-Hans": "Emits when the **sort-by** property of the **options** prop is updated"
      }
    },
    {
      "name": "update:sort-desc",
      "source": "v-data",
      "value": "boolean | boolean[]",
      "description": {
        "en": "Emits when the **sort-desc** property of the **options** prop is updated",
        "ja": "Emits when the **sort-desc** property of the **options** prop is updated",
        "zh-Hans": "Emits when the **sort-desc** property of the **options** prop is updated"
      }
    }
  ],
  "functions": [],
  "name": "v-data-table",
  "sass": [
    {
      "name": "$data-table-border-radius",
      "default": "$border-radius-root !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$data-table-dense-header-height",
      "default": "32px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$data-table-dense-row-height",
      "default": "$data-table-dense-header-height !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$data-table-expanded-content-box-shadow",
      "default": "inset 0px 4px 8px -5px rgba(50, 50, 50, 0.75), inset 0px -4px 8px -5px rgba(50, 50, 50, 0.75) !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$data-table-header-mobile-select-chip-height",
      "default": "24px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$data-table-header-mobile-select-margin-bottom",
      "default": "8px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$data-table-header-mobile-select-max-width",
      "default": "56px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$data-table-header-sort-badge-height",
      "default": "18px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$data-table-header-sort-badge-min-height",
      "default": "18px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$data-table-header-sort-badge-min-width",
      "default": "18px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$data-table-header-sort-badge-width",
      "default": "18px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$data-table-mobile-row-header-font-weight",
      "default": "600 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$data-table-mobile-row-min-height",
      "default": "48px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$data-table-progress-border-radius",
      "default": "$data-table-border-radius $data-table-border-radius 0 0 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$data-table-regular-header-height",
      "default": "48px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$data-table-regular-row-height",
      "default": "48px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$data-table-row-group-children-td-height",
      "default": "35px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$data-table-scroll-bar-width",
      "default": "17px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$edit-dialog-content-padding",
      "default": "0 16px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$edit-dialog-actions-padding",
      "default": "8px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$data-table-regular-header-font-size",
      "default": "map-deep-get($headings, 'caption', 'size') !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$data-table-regular-row-font-size",
      "default": "map-deep-get($headings, 'subtitle-2', 'size') !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "component": true
}