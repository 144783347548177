module.exports = {
  "argument": [
    {
      "name": "value",
      "type": [
        "Function",
        "{ handler: Function, options?: IntersectionObserverInit }"
      ],
      "snippet": "\n<template>\n<v-card v-intersect=\"onIntersect\">...</v-card>\n</template>\n\n<script>\nexport default {\n  methods: {\n    // Will be invoked on DOM mount and when the element is intersected\n    onIntersect (entries, observer, isIntersecting) {}\n  }\n}\n</script>",
      "description": {
        "en": "The function to invoke when the targeted element is intersected.",
        "ja": "対象となる要素がIntersect（交差）したときに呼び出す関数。",
        "zh-Hans": "当目标元素相交时要调用的函数。"
      }
    }
  ],
  "modifiers": [
    {
      "name": "once",
      "default": false,
      "type": [
        "boolean"
      ],
      "snippet": "\n<template>\n  <v-card v-intersect.once=\"onIntersect\">...</v-card>\n</template>\n\n<script>\n  export default {\n    methods: {\n      // Will be invoked once at mount and once when it intersects\n      // After the intersection the callback unbinds\n      // Can be used with the quiet modifier\n      onIntersect (entries, observer, isIntersecting) {}\n    }\n  }\n</script>",
      "description": {
        "en": "Will only invoke the provided user callback on mount and once intersected. If using the **quiet** modifier will only invoke once.",
        "ja": "マウント時に指定されたユーザー コールバックのみを呼び出し、要素がビューポートに入ったときユーザー コールバックを 1 回だけ呼び出します。**quiet** 修飾子を使用する場合、呼び出されるのは 1 回だけです。",
        "zh-Hans": "将仅在挂载上调用所提供的用户回调，且只调用一次。如果使用 **quiet** 修饰符将只调用一次。"
      }
    },
    {
      "name": "quiet",
      "default": false,
      "type": [
        "boolean"
      ],
      "snippet": "\n<template>\n  <v-card v-intersect.quiet=\"onIntersect\">...</v-card>\n</template>\n\n<script>\n  export default {\n    methods: {\n      // Will only be called once the element is intersected\n      onIntersect (entries, observer, isIntersecting) {}\n    }\n  }\n</script>",
      "description": {
        "en": "Will not automatically invoke the provided callback on bind.",
        "ja": "指定されたコールバックを、バインド時に自動的に呼び出しません。",
        "zh-Hans": "不会在绑定时自动调用提供的回调。"
      }
    }
  ],
  "props": [],
  "name": "v-intersect",
  "directive": true
}