module.exports = {
  "props": [
    {
      "name": "canvas-height",
      "type": [
        "string",
        "number"
      ],
      "default": 150,
      "source": "v-color-picker",
      "description": {
        "en": "Height of canvas",
        "ja": "キャンバスの高さ",
        "zh-Hans": "画布高度"
      }
    },
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにダークテーマを適応します。詳しく知りたい場合は、Material Design documentationの<a href=\"https://material.io/design/color/dark-theme.html\">dark themes</a>を参照してください。",
        "zh-Hans": "将暗色主题变量应用到组件。你可以在 [dark themes](https://material.io/design/color/dark-theme.html) 的 Material Design 文档中找到更多有关信息。"
      }
    },
    {
      "name": "disabled",
      "type": "boolean",
      "default": "false",
      "source": "v-color-picker",
      "description": {
        "en": "Disables picker",
        "ja": "ピッカーを無効にします",
        "zh-Hans": "禁用选择器"
      }
    },
    {
      "name": "dot-size",
      "type": [
        "number",
        "string"
      ],
      "default": 10,
      "source": "v-color-picker",
      "description": {
        "en": "Changes the size of the selection dot on the canvas",
        "ja": "キャンバス上の選択ドットのサイズを変更します。",
        "zh-Hans": "更改画布上选择点的大小"
      }
    },
    {
      "name": "elevation",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "elevatable",
      "description": {
        "en": "Designates an elevation applied to the component between 0 and 24. You can find more information on the [elevation page](/styles/elevation).",
        "ja": "コンポーネントに適用される高さを0〜24の範囲で指定します。詳細は、[elevation のページ](/styles/elevation)で確認できます。",
        "zh-Hans": "组件的海拔可接受 0 到 24 之间的值。你可以在 [elevation page](/styles/elevation) 浏览更多信息。"
      }
    },
    {
      "name": "flat",
      "type": "boolean",
      "default": "false",
      "source": "v-color-picker",
      "description": {
        "en": "Removes elevation",
        "ja": "Elevationを削除します。",
        "zh-Hans": "移除海拔"
      }
    },
    {
      "name": "hide-canvas",
      "type": "boolean",
      "default": "false",
      "source": "v-color-picker",
      "description": {
        "en": "Hides canvas",
        "ja": "キャンバスを非表示にする",
        "zh-Hans": "隐藏画布"
      }
    },
    {
      "name": "hide-inputs",
      "type": "boolean",
      "default": "false",
      "source": "v-color-picker",
      "description": {
        "en": "Hides inputs",
        "ja": "入力フォームを隠す",
        "zh-Hans": "隐藏输入"
      }
    },
    {
      "name": "hide-mode-switch",
      "type": "boolean",
      "default": "false",
      "source": "v-color-picker",
      "description": {
        "en": "Hides mode switch",
        "ja": "モードスイッチを非表示にする",
        "zh-Hans": "隐藏模式开关"
      }
    },
    {
      "name": "hide-sliders",
      "type": "boolean",
      "default": "false",
      "source": "v-color-picker",
      "description": {
        "en": "Hides sliders",
        "ja": "スライダーを非表示にします",
        "zh-Hans": "隐藏滑块"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    },
    {
      "name": "mode",
      "type": "string",
      "default": "'rgba'",
      "source": "v-color-picker",
      "description": {
        "en": "Sets mode of inputs. Available modes are 'rgba', 'hsla', and 'hexa'. Can be synced with the `.sync` modifier.",
        "ja": "入力モードを設定します。利用可能なモードは'rgba'、'hsla'、'hexa'です。`.sync` 修飾子を使って同期できます。",
        "zh-Hans": "设置输入模式。可用的模式有 'rgba', 'hsla', 和 'hexa'。可以与 `.sync` 的修饰符同步使用。"
      }
    },
    {
      "name": "show-swatches",
      "type": "boolean",
      "default": "false",
      "source": "v-color-picker",
      "description": {
        "en": "Displays color swatches",
        "ja": "カラースウォッチを表示します",
        "zh-Hans": "显示色板"
      }
    },
    {
      "name": "swatches",
      "type": "array",
      "default": "undefined",
      "source": "v-color-picker",
      "description": {
        "en": "Sets the available color swatches to select from - This prop only accepts rgba hex strings",
        "ja": "選択可能なカラースウォッチを設定します - このpropは rgba 16進数文字列のみを受け入れます",
        "zh-Hans": "设置可供选择的颜色样本 - 此属性仅接受 rgba hex 字符串"
      }
    },
    {
      "name": "swatches-max-height",
      "type": [
        "number",
        "string"
      ],
      "default": 150,
      "source": "v-color-picker",
      "description": {
        "en": "Sets the maximum height of the swatches section",
        "ja": "スウォッチセクションの高さの最大値を設定します",
        "zh-Hans": "设置色板部分的最大高度"
      }
    },
    {
      "name": "value",
      "type": [
        "object",
        "string"
      ],
      "default": "undefined",
      "source": "v-color-picker",
      "description": {
        "en": "Current color. This can be either a string representing a hex color, or an object representing a RGBA, HSLA, or HSVA value",
        "ja": "現在の色。これは16進数を表す文字列、またはRGBA、HSLA、またはHSVAを表すオブジェクトのいずれかです。",
        "zh-Hans": "当前的颜色。可以是表示十六进制颜色的字符串，也可以是表示 RGBA、HSLA 或 HSVA 值的对象"
      }
    },
    {
      "name": "width",
      "type": [
        "number",
        "string"
      ],
      "default": 300,
      "source": "v-color-picker",
      "description": {
        "en": "Sets the width of the color picker",
        "ja": "カラー ピッカーの幅を設定します。",
        "zh-Hans": "设置取色器的宽度"
      }
    }
  ],
  "mixins": [
    "elevatable",
    "themeable"
  ],
  "slots": [],
  "events": [
    {
      "name": "input",
      "value": "string | object",
      "description": {
        "en": "Selected color. Depending on what you passed to the `value` prop this is either a string or an object",
        "ja": "選択した色: `value` propに渡したものに応じて、文字列またはオブジェクトのいずれかになります。",
        "zh-Hans": "选定的颜色。 根据您传递给 `value` 属性的内容，它可以是字符串或对象"
      }
    },
    {
      "name": "update:color",
      "value": {
        "alpha": "number",
        "hex": "string",
        "hexa": "string",
        "hsla": {
          "h": "number",
          "s": "number",
          "l": "number",
          "a": "number"
        },
        "hsva": {
          "h": "number",
          "s": "number",
          "v": "number",
          "a": "number"
        },
        "hue": "number",
        "rgba": {
          "r": "number",
          "g": "number",
          "b": "number",
          "a": "number"
        }
      },
      "description": {
        "en": "Selected color. This is the internal representation of the color, containing all values.",
        "ja": "選択した色。これは、すべての値を含む色の内部表現です。",
        "zh-Hans": "选定的颜色。 这是颜色的内部表示，包含所有值。"
      }
    },
    {
      "name": "update:mode",
      "value": "string",
      "description": {
        "en": "Selected mode",
        "ja": "色選択モード",
        "zh-Hans": "选择模式"
      }
    }
  ],
  "functions": [],
  "name": "v-color-picker",
  "sass": [
    {
      "name": "$color-picker-border-radius",
      "default": "$border-radius-root !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$color-picker-checkerboard",
      "default": "url(data:image/png;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$color-picker-swatch-color-width",
      "default": "45px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$color-picker-swatch-color-height",
      "default": "18px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$color-picker-swatch-color-margin",
      "default": "2px 4px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$color-picker-swatch-color-border-radius",
      "default": "2px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$color-picker-swatch-margin-bottom",
      "default": "10px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$color-picker-swatches-border-radius",
      "default": "8px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$color-picker-canvas-dot-size",
      "default": "15px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$color-picker-canvas-dot-box-shadow",
      "default": "0px 0px 0px 1.5px rgba(255, 255, 255, 1), inset 0px 0px 1px 1.5px rgba(0, 0, 0, 0.3) !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$color-picker-canvas-dot-disabled-box-shadow",
      "default": "0px 0px 0px 1.5px rgba(255, 255, 255, 0.7), inset 0px 0px 1px 1.5px rgba(0, 0, 0, 0.3) !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$color-picker-controls-padding",
      "default": "16px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$color-picker-edit-margin-top",
      "default": "24px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$color-picker-input-height",
      "default": "28px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$color-picker-input-font-size",
      "default": "0.75rem !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$color-picker-input-margin",
      "default": "8px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$color-picker-input-margin-bottom",
      "default": "8px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$color-picker-slider-height",
      "default": "10px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$color-picker-slider-border-radius",
      "default": "$color-picker-slider-height / 2 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$color-picker-preview-dot-size",
      "default": "30px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$color-picker-preview-dot-margin",
      "default": "24px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$color-picker-hue-margin-bottom",
      "default": "24px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "component": true
}