module.exports = {
  "props": [
    {
      "name": "color",
      "type": "string",
      "default": "undefined",
      "source": "colorable",
      "description": {
        "en": "Applies specified color to the control - it can be the name of material color (for example `success` or `purple`) or css color (`#033` or `rgba(255, 0, 0, 0.5)`). You can find a list of built-in classes on the [colors page](/styles/colors#material-colors).",
        "ja": "指定された色をコントロールに適用します。利用できるのはマテリアルカラーの名前 (たとえば、`success` または ` purple`) または css 表記の色 (`#033`または` rgba(255, 0, 0, 0.5) `) です。[colors のページ](/styles/colors#material-colors) で、組み込みクラスのリストが確認できます。",
        "zh-Hans": "指定控件颜色，颜色值可以是 Material 颜色（例如 <code>success</code>或者 <code>purple</code> ）也可以是 Css 颜色 （例如 <code>#033</code> 或者 <code>rgba(255, 0, 0, 0.5)</code>）。在 [colors page](/zh-Hans/styles/colors#material-colors)中可以找到内置颜色类列表。"
      }
    },
    {
      "name": "disable-icon-rotate",
      "type": "boolean",
      "default": "false",
      "source": "v-expansion-panel-header",
      "description": {
        "en": "Removes the icon rotation animation when expanding a panel",
        "ja": "パネルを展開するときのアイコンの回転アニメーションを削除します",
        "zh-Hans": "展开面板时删除图标旋转动画"
      }
    },
    {
      "name": "expand-icon",
      "type": "string",
      "default": "'$expand'",
      "source": "v-expansion-panel-header",
      "description": {
        "en": "Set the expand action icon",
        "ja": "展開アクション アイコンを設定する",
        "zh-Hans": "设置图标展开动作"
      }
    },
    {
      "name": "hide-actions",
      "type": "boolean",
      "default": "false",
      "source": "v-expansion-panel-header",
      "description": {
        "en": "Hide the expand icon in the content header",
        "ja": "コンテンツ ヘッダーの展開アイコンを非表示にする",
        "zh-Hans": "隐藏内容标题中的展开图标"
      }
    },
    {
      "name": "ripple",
      "type": [
        "boolean",
        "object"
      ],
      "default": false,
      "source": "v-expansion-panel-header",
      "description": {
        "en": "Applies the [v-ripple](/directives/ripple) directive.",
        "ja": "[v-ripple](/directives/ripple) ディレクティブを適用します。",
        "zh-Hans": "应用 [v-ripple](/directives/ripples) 指令。"
      }
    }
  ],
  "mixins": [
    "colorable",
    "registrable-inject"
  ],
  "slots": [
    {
      "name": "actions",
      "props": {
        "open": "boolean"
      },
      "description": {
        "en": "Expansion header actions",
        "ja": "Expansion header actions",
        "zh-Hans": "扩展标题动作"
      }
    },
    {
      "name": "default",
      "props": {
        "open": "boolean"
      },
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    }
  ],
  "events": [
    {
      "name": "click",
      "value": "MouseEvent",
      "description": {
        "en": "Mouse click event",
        "ja": "マウスクリック時のイベント",
        "zh-Hans": "鼠标点击事件"
      }
    }
  ],
  "functions": [],
  "name": "v-expansion-panel-header",
  "sass": [],
  "component": true
}