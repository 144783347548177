module.exports = {
  "props": [
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにダークテーマを適応します。詳しく知りたい場合は、Material Design documentationの<a href=\"https://material.io/design/color/dark-theme.html\">dark themes</a>を参照してください。",
        "zh-Hans": "将暗色主题变量应用到组件。你可以在 [dark themes](https://material.io/design/color/dark-theme.html) 的 Material Design 文档中找到更多有关信息。"
      }
    },
    {
      "name": "dense",
      "type": "boolean",
      "default": "false",
      "source": "v-simple-table",
      "description": {
        "en": "Decreases paddings to render a dense table",
        "ja": "密なテーブルをレンダリングするためにpaddingを減らします",
        "zh-Hans": "减少填充以呈现一个密集的表"
      }
    },
    {
      "name": "fixed-header",
      "type": "boolean",
      "default": "false",
      "source": "v-simple-table",
      "description": {
        "en": "Sets table header to fixed mode",
        "ja": "テーブルヘッダを固定モードにします",
        "zh-Hans": "将表头设置为固定模式"
      }
    },
    {
      "name": "height",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "v-simple-table",
      "description": {
        "en": "Sets the height for the component",
        "ja": "コンポーネントの高さを設定します",
        "zh-Hans": "设置组件高度"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    }
  ],
  "mixins": [
    "themeable"
  ],
  "slots": [
    {
      "name": "default",
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    }
  ],
  "events": [],
  "functions": [],
  "name": "v-simple-table",
  "sass": [],
  "component": true
}