module.exports = {
  "props": [
    {
      "name": "checkbox-color",
      "type": "string",
      "default": "undefined",
      "source": "v-data-table-header",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "disable-sort",
      "type": "boolean",
      "default": "false",
      "source": "v-data-table-header",
      "description": {
        "en": "Toggles rendering of sort button",
        "ja": "並べ替えボタンの表示を切り替えます",
        "zh-Hans": "切换排序按钮的渲染方式。"
      }
    },
    {
      "name": "every-item",
      "type": "boolean",
      "default": "false",
      "source": "v-data-table-header",
      "description": {
        "en": "Indicates if all items in table are selected",
        "ja": "Indicates if all items in table are selected",
        "zh-Hans": "表示是否选择了表格中的所有项目。"
      }
    },
    {
      "name": "headers",
      "type": "array",
      "default": [],
      "source": "v-data-table-header",
      "description": {
        "en": "Array of header items to display",
        "ja": "表示するヘッダアイテムの配列",
        "zh-Hans": "要显示的表头数组。"
      }
    },
    {
      "name": "mobile",
      "type": "boolean",
      "default": "false",
      "source": "v-data-table-header",
      "description": {
        "en": "Renders mobile view of headers",
        "ja": "ヘッダーのmobileビューをレンダリングします",
        "zh-Hans": "渲染表头的移动视图。"
      }
    },
    {
      "name": "options",
      "type": "object",
      "default": {
        "page": 1,
        "itemsPerPage": 10,
        "sortBy": [],
        "sortDesc": [],
        "groupBy": [],
        "groupDesc": [],
        "multiSort": false,
        "mustSort": false
      },
      "source": "v-data-table-header",
      "description": {
        "en": "Options object. Identical to the one on `v-data-table`",
        "ja": "Options オブジェクト。`v-data-table`のものと同じ。",
        "zh-Hans": "选项对象。与 `v-data-table` 上的相同。"
      }
    },
    {
      "name": "show-group-by",
      "type": "boolean",
      "default": "false",
      "source": "v-data-table-header",
      "description": {
        "en": "Shows group by button",
        "ja": "ボタンでグループを表示",
        "zh-Hans": "显示分组按钮。"
      }
    },
    {
      "name": "single-select",
      "type": "boolean",
      "default": "false",
      "source": "v-data-table-header",
      "description": {
        "en": "Toggles rendering of select-all checkbox",
        "ja": "全選択チェックボックスのレンダリングを切り替えます。",
        "zh-Hans": "切换全选框的渲染方式。"
      }
    },
    {
      "name": "some-items",
      "type": "boolean",
      "default": "false",
      "source": "v-data-table-header",
      "description": {
        "en": "Indicates if one or more items in table are selected",
        "ja": "Indicates if one or more items in table are selected",
        "zh-Hans": "表示是否选择了表格中的一个或多个数据项。"
      }
    },
    {
      "name": "sort-by-text",
      "default": "'Sort by'",
      "source": "v-data-table-header",
      "type": "string",
      "description": {
        "en": "Sets the label text used by the default sort-by selector when `v-data-table` is rendering the mobile view",
        "ja": "`v-data-table` がモバイルビューでレンダリングされる時の、デフォルトの sort-by セレクタが使用するラベルテキストを設定します。",
        "zh-Hans": "设置当 `v-data-table` 渲染移动视图时，默认的排序选择器使用的标签文本。"
      }
    },
    {
      "name": "sort-icon",
      "type": "string",
      "default": "'$sort'",
      "source": "v-data-table-header",
      "description": {
        "en": "Icon used for sort button",
        "ja": "ソートボタンに使用するアイコン",
        "zh-Hans": "排序按钮的图标。"
      }
    }
  ],
  "mixins": [],
  "slots": [],
  "events": [],
  "functions": [],
  "name": "v-data-table-header",
  "sass": [],
  "component": true
}