module.exports = {
  "props": [
    {
      "name": "circle",
      "type": "boolean",
      "default": "false",
      "source": "v-pagination",
      "description": {
        "en": "Shape pagination elements as circles",
        "ja": "Pagination要素を円形で描画します",
        "zh-Hans": "将分页元素的形状改变圆形"
      }
    },
    {
      "name": "color",
      "type": "string",
      "default": "undefined",
      "source": "colorable",
      "description": {
        "en": "Applies specified color to the control - it can be the name of material color (for example `success` or `purple`) or css color (`#033` or `rgba(255, 0, 0, 0.5)`). You can find a list of built-in classes on the [colors page](/styles/colors#material-colors).",
        "ja": "指定された色をコントロールに適用します。利用できるのはマテリアルカラーの名前 (たとえば、`success` または ` purple`) または css 表記の色 (`#033`または` rgba(255, 0, 0, 0.5) `) です。[colors のページ](/styles/colors#material-colors) で、組み込みクラスのリストが確認できます。",
        "zh-Hans": "指定控件颜色，颜色值可以是 Material 颜色（例如 <code>success</code>或者 <code>purple</code> ）也可以是 Css 颜色 （例如 <code>#033</code> 或者 <code>rgba(255, 0, 0, 0.5)</code>）。在 [colors page](/zh-Hans/styles/colors#material-colors)中可以找到内置颜色类列表。"
      }
    },
    {
      "name": "current-page-aria-label",
      "type": "string",
      "default": "'$vuetify.pagination.ariaLabel.currentPage'",
      "source": "v-pagination",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにダークテーマを適応します。詳しく知りたい場合は、Material Design documentationの<a href=\"https://material.io/design/color/dark-theme.html\">dark themes</a>を参照してください。",
        "zh-Hans": "将暗色主题变量应用到组件。你可以在 [dark themes](https://material.io/design/color/dark-theme.html) 的 Material Design 文档中找到更多有关信息。"
      }
    },
    {
      "name": "disabled",
      "type": "boolean",
      "default": "false",
      "source": "v-pagination",
      "description": {
        "en": "Disables component",
        "ja": "コンポーネントを無効にします。",
        "zh-Hans": "禁用组件"
      }
    },
    {
      "name": "length",
      "type": "number",
      "default": 0,
      "source": "v-pagination",
      "description": {
        "en": "The length of the pagination component",
        "ja": "paginationコンポーネントの長さ",
        "zh-Hans": "分页组件的长度"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    },
    {
      "name": "navigation-color",
      "type": "string",
      "default": "undefined",
      "source": "v-pagination",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "navigation-text-color",
      "type": "string",
      "default": "undefined",
      "source": "v-pagination",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "next-aria-label",
      "type": "string",
      "default": "'$vuetify.pagination.ariaLabel.next'",
      "source": "v-pagination",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "next-icon",
      "type": "string",
      "default": "'$next'",
      "source": "v-pagination",
      "description": {
        "en": "Specify the icon to use for the next icon",
        "ja": "「次」アイコンを指定します。",
        "zh-Hans": "指定用于下一个图标的图标"
      }
    },
    {
      "name": "page-aria-label",
      "type": "string",
      "default": "'$vuetify.pagination.ariaLabel.page'",
      "source": "v-pagination",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "prev-icon",
      "type": "string",
      "default": "'$prev'",
      "source": "v-pagination",
      "description": {
        "en": "Specify the icon to use for the prev icon",
        "ja": "「前」アイコンを指定します。",
        "zh-Hans": "指定用于上一个图标的图标"
      }
    },
    {
      "name": "previous-aria-label",
      "type": "string",
      "default": "'$vuetify.pagination.ariaLabel.previous'",
      "source": "v-pagination",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "total-visible",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "v-pagination",
      "description": {
        "en": "Specify the max total visible pagination numbers",
        "ja": "表示可能なpaginationの最大数を指定します",
        "zh-Hans": "指定最大可见分页数"
      }
    },
    {
      "name": "value",
      "type": "number",
      "default": 0,
      "source": "v-pagination",
      "description": {
        "en": "Current selected page",
        "ja": "現在選択しているページ",
        "zh-Hans": "当前选定页面"
      }
    },
    {
      "name": "wrapper-aria-label",
      "type": "string",
      "default": "'$vuetify.pagination.ariaLabel.wrapper'",
      "source": "v-pagination",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "mixins": [
    "colorable",
    "intersectable",
    "themeable"
  ],
  "slots": [],
  "events": [
    {
      "name": "input",
      "value": "number",
      "description": {
        "en": "The updated bound model",
        "ja": "更新されたバウンドモデル",
        "zh-Hans": "被绑定模型的更新"
      }
    },
    {
      "name": "next",
      "value": "void",
      "description": {
        "en": "Emitted when going to next item",
        "ja": "次の項目に移動するときに発生します。",
        "zh-Hans": "转到下一个项目时发出"
      }
    },
    {
      "name": "previous",
      "value": "void",
      "description": {
        "en": "Emitted when going to previous item",
        "ja": "以前の項目に移動したときに発生します。",
        "zh-Hans": "转到上一个项目时发出"
      }
    }
  ],
  "functions": [],
  "name": "v-pagination",
  "sass": [
    {
      "name": "$pagination-border-radius",
      "default": "$border-radius-root !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$pagination-disabled-opacity",
      "default": "0.6 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$pagination-item-font-size",
      "default": "map-deep-get($headings, 'subtitle-1', 'size') !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$pagination-item-height",
      "default": "34px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$pagination-item-margin",
      "default": ".3rem !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$pagination-item-min-width",
      "default": "34px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$pagination-item-padding",
      "default": "0 5px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$pagination-more-height",
      "default": "32px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$pagination-more-margin",
      "default": ".3rem !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$pagination-more-width",
      "default": "32px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$pagination-navigation-disabled-opacity",
      "default": "0.6 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$pagination-navigation-height",
      "default": "32px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$pagination-navigation-margin",
      "default": ".3rem 10px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$pagination-navigation-width",
      "default": "32px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "component": true
}