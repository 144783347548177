module.exports = {
  "props": [
    {
      "name": "active-class",
      "type": "string",
      "default": "undefined",
      "source": "v-list-group",
      "description": {
        "en": "Configure the active CSS class applied when the link is active. You can find more information about the [**active-class** prop](https://router.vuejs.org/api/#active-class) on the vue-router documentation.",
        "ja": "リンクがアクティブなときに適用されるアクティブなCSSクラスを構成します。vue-routerのドキュメントで [**active-class** prop](https://router.vuejs.org/ja/api/#active-class) の詳細をご覧ください。",
        "zh-Hans": "配置在链接激活时应用的 CSS 类。你能够在 vue-router 文档浏览更多 [**active-class** prop](https://router.vuejs.org/api/#active-class)。"
      }
    },
    {
      "name": "append-icon",
      "type": "string",
      "default": "'$expand'",
      "source": "v-list-group",
      "description": {
        "en": "Appends an icon to the component, uses the same syntax as `v-icon`",
        "ja": "`v-icon`と同じ構文を使用し、コンポーネントにアイコンを追加します",
        "zh-Hans": "在组件上附加一个图标，使用与 `v-icon` 相同的语法"
      }
    },
    {
      "name": "color",
      "type": "string",
      "default": "'primary'",
      "source": "colorable",
      "description": {
        "en": "Applies specified color to the control - it can be the name of material color (for example `success` or `purple`) or css color (`#033` or `rgba(255, 0, 0, 0.5)`). You can find a list of built-in classes on the [colors page](/styles/colors#material-colors).",
        "ja": "指定された色をコントロールに適用します。利用できるのはマテリアルカラーの名前 (たとえば、`success` または ` purple`) または css 表記の色 (`#033`または` rgba(255, 0, 0, 0.5) `) です。[colors のページ](/styles/colors#material-colors) で、組み込みクラスのリストが確認できます。",
        "zh-Hans": "指定控件颜色，颜色值可以是 Material 颜色（例如 <code>success</code>或者 <code>purple</code> ）也可以是 Css 颜色 （例如 <code>#033</code> 或者 <code>rgba(255, 0, 0, 0.5)</code>）。在 [colors page](/zh-Hans/styles/colors#material-colors)中可以找到内置颜色类列表。"
      }
    },
    {
      "name": "disabled",
      "type": "boolean",
      "default": "false",
      "source": "v-list-group",
      "description": {
        "en": "Disables all children `v-list-item` components",
        "ja": "全ての子要素`v-list-item`コンポーネントを無効にします",
        "zh-Hans": "禁用所有子级的 `v-list-item` 组件"
      }
    },
    {
      "name": "eager",
      "type": "boolean",
      "default": "false",
      "source": "bootable",
      "description": {
        "en": "Will force the components content to render on mounted. This is useful if you have content that will not be rendered in the DOM that you want crawled for SEO.",
        "ja": "コンポーネントのコンテンツを mounted 時に render することを強制します。この機能は、SEO のために crawl させたい DOM の中に render されないコンテンツを持っているときに役に立ちます。",
        "zh-Hans": "将强制组件内容在加载时呈现。如果存在内容的话，则不会在 DOM 中渲染，如果你想优化 SEO，这是非常有用的。"
      }
    },
    {
      "name": "group",
      "type": [
        "string",
        "regexp"
      ],
      "default": "undefined",
      "source": "v-list-group",
      "description": {
        "en": "Assign a route namespace. Accepts a string or regexp for determining active state",
        "ja": "ルート名の名前空間を割り当てます。アクティブ状態とする条件に、ルート名の文字列と正規表現が使えます。",
        "zh-Hans": "分配路由名称空间。 接受字符串或正则表达式以确定活动状态"
      }
    },
    {
      "name": "no-action",
      "type": "boolean",
      "default": "false",
      "source": "v-list-group",
      "description": {
        "en": "Removes left padding assigned for action icons from group items",
        "ja": "グループアイテムからアクションアイコンに割り当てられた左のパディングを削除します。",
        "zh-Hans": "从组项目中删除分配给操作图标的左边距"
      }
    },
    {
      "name": "prepend-icon",
      "type": "string",
      "default": "undefined",
      "source": "v-list-group",
      "description": {
        "en": "Prepends an icon to the component, uses the same syntax as `v-icon`",
        "ja": "`v-icon`と同じ構文を使用して、コンポーネント内の先頭にアイコンを追加します",
        "zh-Hans": "在组件前添加一个图标，使用与 `v-icon` 相同的语法"
      }
    },
    {
      "name": "ripple",
      "type": [
        "boolean",
        "object"
      ],
      "default": true,
      "source": "v-list-group",
      "description": {
        "en": "Applies the [v-ripple](/directives/ripple) directive.",
        "ja": "[v-ripple](/directives/ripple) ディレクティブを適用します。",
        "zh-Hans": "应用 [v-ripple](/directives/ripples) 指令。"
      }
    },
    {
      "name": "sub-group",
      "type": "boolean",
      "default": "false",
      "source": "v-list-group",
      "description": {
        "en": "Designate the component as nested list group",
        "ja": "コンポーネントをネストしたリストグループとして指定します",
        "zh-Hans": "将组件指定为嵌套列表组"
      }
    },
    {
      "name": "value",
      "type": "any",
      "default": "undefined",
      "source": "toggleable",
      "description": {
        "en": "Expands / Collapse the list-group",
        "ja": "リストグループを展開／閉じる",
        "zh-Hans": "展开/折叠列表组"
      }
    }
  ],
  "mixins": [
    "bootable",
    "colorable",
    "registrable-inject",
    "toggleable"
  ],
  "slots": [
    {
      "name": "activator",
      "description": {
        "en": "When used, will activate the component when clicked (or hover for specific components). This manually stops the event propagation. Without this slot, if you open the component through its model, you will need to manually stop the event propagation",
        "ja": "使用すると、クリック時 (または特定のコンポーネントではホバー時) にコンポーネントを有効にします。これにより手動でイベントの propagation を stop されます。この slot がない場合、モデル経由でコンポーネントを開いたときには、自分でイベントの propagation を stop する必要があります。",
        "zh-Hans": "使用时，将在点击后激活组件（或悬停特定组件），它手动阻止事件传播。如果通过模型打开组件而没有此插槽，则需要手动阻止事件传播。"
      }
    },
    {
      "name": "appendIcon",
      "description": {
        "en": "Appends an icon to the component, uses the same syntax as `v-icon`",
        "ja": "`v-icon`と同じ構文を使用し、コンポーネントにアイコンを追加します",
        "zh-Hans": "在组件上附加一个图标，使用与 `v-icon` 相同的语法"
      }
    },
    {
      "name": "default",
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    },
    {
      "name": "prependIcon",
      "description": {
        "en": "Prepends an icon to the component, uses the same syntax as `v-icon`",
        "ja": "`v-icon`と同じ構文を使用して、コンポーネント内の先頭にアイコンを追加します",
        "zh-Hans": "在组件前添加一个图标，使用与 `v-icon` 相同的语法"
      }
    }
  ],
  "events": [
    {
      "name": "click",
      "value": "MouseEvent",
      "description": {
        "en": "Event that is emitted when the component is clicked",
        "ja": "コンポーネントがクリックされたときに発生するイベント",
        "zh-Hans": "单击组件时发出的事件"
      }
    }
  ],
  "functions": [],
  "name": "v-list-group",
  "sass": [],
  "component": true
}