module.exports = {
  "props": [
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにダークテーマを適応します。詳しく知りたい場合は、Material Design documentationの<a href=\"https://material.io/design/color/dark-theme.html\">dark themes</a>を参照してください。",
        "zh-Hans": "将暗色主题变量应用到组件。你可以在 [dark themes](https://material.io/design/color/dark-theme.html) 的 Material Design 文档中找到更多有关信息。"
      }
    },
    {
      "name": "divider",
      "type": "string",
      "default": "'/'",
      "source": "v-breadcrumbs",
      "description": {
        "en": "Specifies the dividing character between items.",
        "ja": "項目間の区切り文字を指定します。",
        "zh-Hans": "指定项之间的分隔字符。"
      }
    },
    {
      "name": "items",
      "type": "array",
      "default": [],
      "source": "v-breadcrumbs",
      "example": [
        {
          "disabled": "boolean",
          "exact": "boolean",
          "href": "string",
          "link": "boolean",
          "text": "string | number",
          "to": "string | object"
        }
      ],
      "description": {
        "en": "An array of objects for each breadcrumb.",
        "ja": "各パンくずのオブジェクトの配列。",
        "zh-Hans": "每个面包屑对象的数组。"
      }
    },
    {
      "name": "large",
      "type": "boolean",
      "default": "false",
      "source": "v-breadcrumbs",
      "description": {
        "en": "Increase the font-size of the breadcrumb item text to 16px (14px default).",
        "ja": "パンくず項目のテキストのフォントサイズを16px（デフォルトは14px）にします。",
        "zh-Hans": "将面包屑项文本的字体大小增加到 16px（默认为 14px）。"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    }
  ],
  "mixins": [
    "themeable"
  ],
  "slots": [
    {
      "name": "default",
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    },
    {
      "name": "divider",
      "description": {
        "en": "The slot used for dividers.",
        "ja": "区切りに使うスロット",
        "zh-Hans": "用于分隔器的插槽。"
      }
    },
    {
      "name": "item",
      "props": {
        "item": "any[]"
      },
      "description": {
        "en": "The slot used to override default `v-breadcrumbs-item` behavior when using the **items** prop.",
        "ja": "**items** propを使用する際に、デフォルトの `v-breadcrumbs-item` の動作を上書きするために使用されるスロットです。",
        "zh-Hans": "使用 **items** 属性时，用于覆盖默认 `v-breadcrumbs-item` 行为的插槽。"
      }
    }
  ],
  "events": [],
  "functions": [],
  "name": "v-breadcrumbs",
  "sass": [
    {
      "name": "$breadcrumbs-flex",
      "default": "0 1 auto !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$breadcrumbs-padding",
      "default": "18px 12px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$breadcrumbs-even-child-padding",
      "default": "0 12px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$breadcrumbs-item-font-size",
      "default": "14px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$breadcrumbs-item-large-font-size",
      "default": "16px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$breadcrumbs-margin",
      "default": "0 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "component": true
}