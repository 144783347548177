var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-btn',_vm._g(_vm._b({attrs:{"aria-label":_vm.$t('become-a-sponsor'),"to":{
    name: 'Documentation',
    params: {
      category: 'about',
      page: 'sponsors-and-backers'
    }
  },"color":"primary","outlined":""},on:{"click":_vm.onClick}},'v-btn',_vm.$attrs,false),_vm.$listeners),[_c('span',{staticClass:"text-capitalize font-weight-regular",domProps:{"textContent":_vm._s(_vm.$t('become-a-sponsor'))}})])
}
var staticRenderFns = []

export { render, staticRenderFns }