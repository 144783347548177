module.exports = {
  "props": [
    {
      "name": "accordion",
      "type": "boolean",
      "default": "false",
      "source": "v-expansion-panels",
      "description": {
        "en": "Removes the margin around open panels",
        "ja": "パネルの周りの余白を削除します。",
        "zh-Hans": "移除打开面板周围的边距"
      }
    },
    {
      "name": "active-class",
      "type": "string",
      "default": "'v-item--active'",
      "source": "base-item-group",
      "description": {
        "en": "The **active-class** applied to children when they are activated.",
        "ja": "子要素が有効な場合、子要素に **active-class** が適用されます。",
        "zh-Hans": "**active-class** 在子组件（children ）被激活时应用。"
      }
    },
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにダークテーマを適応します。詳しく知りたい場合は、Material Design documentationの<a href=\"https://material.io/design/color/dark-theme.html\">dark themes</a>を参照してください。",
        "zh-Hans": "将暗色主题变量应用到组件。你可以在 [dark themes](https://material.io/design/color/dark-theme.html) 的 Material Design 文档中找到更多有关信息。"
      }
    },
    {
      "name": "disabled",
      "type": "boolean",
      "default": "false",
      "source": "v-expansion-panels",
      "description": {
        "en": "Disables the entire expansion-panel",
        "ja": "expansion-panel全体を無効にします。",
        "zh-Hans": "禁用整个扩展面板"
      }
    },
    {
      "name": "flat",
      "type": "boolean",
      "default": "false",
      "source": "v-expansion-panels",
      "description": {
        "en": "Removes the expansion-panel's elevation and borders",
        "ja": "エクスパンションパネルのElevation（高さ）とボーダーを削除します。",
        "zh-Hans": "移除扩展面板的海拔和边框。"
      }
    },
    {
      "name": "focusable",
      "type": "boolean",
      "default": "false",
      "source": "v-expansion-panels",
      "description": {
        "en": "Makes the expansion-panel headers focusable",
        "ja": "expansion-panel のヘッダーをフォーカス可能にします。",
        "zh-Hans": "使扩展面板标题可以调焦"
      }
    },
    {
      "name": "hover",
      "type": "boolean",
      "default": "false",
      "source": "v-expansion-panels",
      "description": {
        "en": "Applies a background-color shift on hover to expansion panel headers",
        "ja": "expansion panel のヘッダーにカーソルを合わせた時に背景色を変更します",
        "zh-Hans": "在悬停时对扩展面板页眉应用背景颜色转移。"
      }
    },
    {
      "name": "inset",
      "type": "boolean",
      "default": "false",
      "source": "v-expansion-panels",
      "description": {
        "en": "Makes the expansion-panel open with a inset style",
        "ja": "エクスパンションパネルをインセットスタイルで開きます",
        "zh-Hans": "使扩展面板以嵌入的样式打开"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    },
    {
      "name": "mandatory",
      "type": "boolean",
      "default": "false",
      "source": "base-item-group",
      "description": {
        "en": "Forces a value to always be selected (if available).",
        "ja": "値が常に選択状態であることを強制します (可能な場合)。",
        "zh-Hans": "强制始终选择一个值（如果可用）。"
      }
    },
    {
      "name": "max",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "base-item-group",
      "description": {
        "en": "Sets a maximum number of selections that can be made.",
        "ja": "選択できる最大値を設定します。",
        "zh-Hans": "设置可以选择的最大数量。"
      }
    },
    {
      "name": "multiple",
      "type": "boolean",
      "default": "false",
      "source": "base-item-group",
      "description": {
        "en": "Allow multiple selections. The **value** prop must be an _array_.",
        "ja": "複数の選択を許可します。この場合、**value** prop は _array_ でなければなりません。",
        "zh-Hans": "允许多个选择。**value** 属性必须是 _array_。"
      }
    },
    {
      "name": "popout",
      "type": "boolean",
      "default": "false",
      "source": "v-expansion-panels",
      "description": {
        "en": "Makes the expansion-panel open with an popout style",
        "ja": "エクスパンションパネルをポップアウトスタイルで開きます",
        "zh-Hans": "使扩展面板以弹出的样式打开"
      }
    },
    {
      "name": "readonly",
      "type": "boolean",
      "default": "false",
      "source": "v-expansion-panels",
      "description": {
        "en": "Makes the entire expansion-panel read only.",
        "ja": "expansion-panel 全体を読み取り専用にします。",
        "zh-Hans": "使整个扩展面板为只读。"
      }
    },
    {
      "name": "tag",
      "type": "string",
      "default": "'div'",
      "source": "base-item-group",
      "description": {
        "en": "Specify a custom tag used on the root element.",
        "ja": "コンポーネント内で使用するカスタムタグを指定します。",
        "zh-Hans": "指定在根元素上使用的自定义标签。"
      }
    },
    {
      "name": "tile",
      "type": "boolean",
      "default": "false",
      "source": "v-expansion-panels",
      "description": {
        "en": "Removes the border-radius",
        "ja": "枠の半径（border-radius）を削除します",
        "zh-Hans": "移除 border-radius"
      }
    },
    {
      "name": "value",
      "type": "any",
      "default": "undefined",
      "source": "proxyable",
      "description": {
        "en": "Controls the opened/closed state of content in the expansion-panel. Corresponds to a zero-based index of the currently opened content. If the `multiple` prop (previously `expand` in 1.5.x) is used then it is an array of numbers where each entry corresponds to the index of the opened content.  The index order is not relevant.",
        "ja": "エクスパンションパネル内のコンテンツの開閉状態を制御します。現在開いているコンテンツのゼロベースのインデックスに対応します。`multiple` prop (1.5.xでは `expand`) が使われている場合、それは数値の配列で、各エントリは開いているコンテンツのインデックスに対応します。 インデックスの順番は関係ありません。",
        "zh-Hans": "控制扩展面板中内容的 打开/关闭 状态。对应于当前打开的内容的从零开始的索引。如果使用了 `multiple` 属性（以前在  1.5.x 中使用 `expand`），则它是一个数字数组，其中每个条目对应于打开内容的索引。 索引顺序不相关。"
      }
    },
    {
      "name": "value-comparator",
      "type": "function",
      "default": "null",
      "source": "comparable",
      "description": {
        "en": "Apply a custom value comparator function",
        "ja": "カスタムの値の比較関数を適用します",
        "zh-Hans": "应用自定义的 comparator function"
      }
    }
  ],
  "mixins": [
    "comparable",
    "proxyable",
    "themeable"
  ],
  "slots": [],
  "events": [],
  "functions": [],
  "name": "v-expansion-panels",
  "sass": [],
  "component": true
}